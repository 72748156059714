import React from 'react';
import PropTypes from 'prop-types';
import Marquee from 'react-fast-marquee';

const TextSlider = ({ children }) => {
  return (
    <div className="text-slide">
      <Marquee direction="left" speed={140}>
        {children}
      </Marquee>
    </div>
  );
};

TextSlider.propTypes = {
  children: PropTypes.array.isRequired,
};

export default TextSlider;
