export const enterpriseData = [
  {
    title: 'Employee Productivity',
    contentPart:
      'Accelerate employee productivity by providing timely access to corporate resources without the burden of manual approvals',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Enhanced Security',
    contentPart:
      'Combat the rising threat of compromised identities and access abuse by implementing recurring access certifications to detect inappropriate access and policy violations',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Operational Efficiency',
    contentPart:
      'Automate labor-intensive access certification and provisioning tasks to empower your workforce with self service toolkit',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Improved Compliance and Audit',
    contentPart:
      'Ensure regulatory adherence by streamlining access management processes, reducing compliance costs, and establishing standardized, auditable access protocols',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Single Source of Truth',
    contentPart:
      'Unified Identity view gives you complete visibility into applications and data access across your enterprise',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
