import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { Home } from './pages/Home';
import { AuditCompliance } from './pages/AuditCompliance';
import { OperationalSupportMaintenance } from './pages/OperationalSupportMaintenance';
import { StaffAugmentation } from './pages/StaffAugmentation';
import { VirtualIAMArchitect } from './pages/VirtualIAMArchitect';
import { CIAM } from './pages/CIAM';
import { EIAM } from './pages/EIAM';
import { IGA } from './pages/IGA';
import { PAM } from './pages/PAM';
import { ImplementationIntegration } from './pages/ImplementationIntegration';
import { StrategyRoadmap } from './pages/StrategyRoadmap';
import { Services } from './pages/Services';
import { OurWins } from './pages/OurWins';
import { Solutions } from './pages/Solutions';
import { Industries } from './pages/Industries';
import { Contact } from './pages/Contact';
import { Resources } from './pages/Resources';
import { About } from './pages/About';
import AOS from 'aos';
import 'aos/dist/aos.css';

const getRouter = () => {
  return createBrowserRouter([
    {
      path: '/*',
      element: (
        <>
          <Header />
          <Home />
          <Footer />
        </>
      ),
    },
    {
      path: '/audit',
      element: (
        <>
          <Header />
          <AuditCompliance />
          <Footer />
        </>
      ),
    },
    {
      path: '/opssupport',
      element: (
        <>
          <Header />
          <OperationalSupportMaintenance />
          <Footer />
        </>
      ),
    },
    {
      path: '/staff',
      element: (
        <>
          <Header />
          <StaffAugmentation />
          <Footer />
        </>
      ),
    },
    {
      path: '/virtualiam',
      element: (
        <>
          <Header />
          <VirtualIAMArchitect />
          <Footer />
        </>
      ),
    },
    {
      path: '/ciam',
      element: (
        <>
          <Header />
          <CIAM />
          <Footer />
        </>
      ),
    },
    {
      path: '/eiam',
      element: (
        <>
          <Header />
          <EIAM />
          <Footer />
        </>
      ),
    },
    {
      path: '/iga',
      element: (
        <>
          <Header />
          <IGA />
          <Footer />
        </>
      ),
    },
    {
      path: '/pam',
      element: (
        <>
          <Header />
          <PAM />
          <Footer />
        </>
      ),
    },
    {
      path: '/implementation',
      element: (
        <>
          <Header />
          <ImplementationIntegration />
          <Footer />
        </>
      ),
    },
    {
      path: '/strategy',
      element: (
        <>
          <Header />
          <StrategyRoadmap />
          <Footer />
        </>
      ),
    },
    {
      path: '/services',
      element: (
        <>
          <Header />
          <Services />
          <Footer />
        </>
      ),
    },
    {
      path: '/our-wins',
      element: (
        <>
          <Header />
          <OurWins />
          <Footer />
        </>
      ),
    },
    {
      path: '/solutions',
      element: (
        <>
          <Header />
          <Solutions />
          <Footer />
        </>
      ),
    },
    {
      path: '/industries',
      element: (
        <>
          <Header />
          <Industries />
          <Footer />
        </>
      ),
    },
    {
      path: '/contact',
      element: (
        <>
          <Header />
          <Contact />
          <Footer />
        </>
      ),
    },
    {
      path: '/resources',
      element: (
        <>
          <Header />
          <Resources />
          <Footer />
        </>
      ),
    },
    {
      path: '/about',
      element: (
        <>
          <Header />
          <About />
          <Footer />
        </>
      ),
    },
  ]);
};

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      offset: 200,
      once: false,
    });
  }, []);

  return <RouterProvider router={getRouter()} />;
}

export default App;
