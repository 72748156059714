import React from 'react';
import './StaffAnimation.scss';

const StaffAnimation = () => {
  return (
    <div className="staff-animation">
      {/* Row 1 */}
      <svg
        className="row-1"
        width="185"
        height="53"
        viewBox="0 0 185 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4501 27.74C27.6961 27.74 33.5701 21.866 33.5701 14.62C33.5701 7.37402 27.6961 1.5 20.4501 1.5C13.2041 1.5 7.33008 7.37402 7.33008 14.62C7.33008 21.866 13.2041 27.74 20.4501 27.74Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.4102 50.7798C39.4102 40.3098 30.9202 31.8198 20.4502 31.8198C9.98024 31.8198 1.49023 40.3098 1.49023 50.7798H39.4002H39.4102Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.4403 27.74C75.6863 27.74 81.5603 21.866 81.5603 14.62C81.5603 7.37402 75.6863 1.5 68.4403 1.5C61.1943 1.5 55.3203 7.37402 55.3203 14.62C55.3203 21.866 61.1943 27.74 68.4403 27.74Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.4005 50.7798C87.4005 40.3098 78.9105 31.8198 68.4405 31.8198C57.9705 31.8198 49.4805 40.3098 49.4805 50.7798H87.3905H87.4005Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M116.44 27.74C123.686 27.74 129.56 21.866 129.56 14.62C129.56 7.37402 123.686 1.5 116.44 1.5C109.194 1.5 103.32 7.37402 103.32 14.62C103.32 21.866 109.194 27.74 116.44 27.74Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.391 50.7798C135.391 40.3098 126.901 31.8198 116.431 31.8198C105.961 31.8198 97.4707 40.3098 97.4707 50.7798H135.381H135.391Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M164.43 27.74C171.676 27.74 177.55 21.866 177.55 14.62C177.55 7.37402 171.676 1.5 164.43 1.5C157.184 1.5 151.31 7.37402 151.31 14.62C151.31 21.866 157.184 27.74 164.43 27.74Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M183.38 50.7798C183.38 40.3098 174.89 31.8198 164.42 31.8198C153.95 31.8198 145.46 40.3098 145.46 50.7798H183.37H183.38Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {/* Row 2 */}
      <svg
        className="row-2"
        width="233"
        height="53"
        viewBox="0 0 233 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4598 28.2698C27.7058 28.2698 33.5798 22.3958 33.5798 15.1498C33.5798 7.90381 27.7058 2.02979 20.4598 2.02979C13.2139 2.02979 7.33984 7.90381 7.33984 15.1498C7.33984 22.3958 13.2139 28.2698 20.4598 28.2698Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.4102 51.3096C39.4102 40.8396 30.9202 32.3496 20.4502 32.3496C9.98024 32.3496 1.49023 40.8396 1.49023 51.3096H39.4002H39.4102Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.4501 28.2698C75.696 28.2698 81.5701 22.3958 81.5701 15.1498C81.5701 7.90381 75.696 2.02979 68.4501 2.02979C61.2041 2.02979 55.3301 7.90381 55.3301 15.1498C55.3301 22.3958 61.2041 28.2698 68.4501 28.2698Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.4102 51.3096C87.4102 40.8396 78.9202 32.3496 68.4502 32.3496C57.9802 32.3496 49.4902 40.8396 49.4902 51.3096H87.4002H87.4102Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M116.44 28.2698C123.686 28.2698 129.56 22.3958 129.56 15.1498C129.56 7.90381 123.686 2.02979 116.44 2.02979C109.194 2.02979 103.32 7.90381 103.32 15.1498C103.32 22.3958 109.194 28.2698 116.44 28.2698Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.4 51.3096C135.4 40.8396 126.91 32.3496 116.44 32.3496C105.97 32.3496 97.4805 40.8396 97.4805 51.3096H135.39H135.4Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M164.431 28.2698C171.677 28.2698 177.551 22.3958 177.551 15.1498C177.551 7.90381 171.677 2.02979 164.431 2.02979C157.185 2.02979 151.311 7.90381 151.311 15.1498C151.311 22.3958 157.185 28.2698 164.431 28.2698Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M183.391 51.3096C183.391 40.8396 174.901 32.3496 164.431 32.3496C153.961 32.3496 145.471 40.8396 145.471 51.3096H183.381H183.391Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M212.42 28.2698C219.666 28.2698 225.54 22.3958 225.54 15.1498C225.54 7.90381 219.666 2.02979 212.42 2.02979C205.174 2.02979 199.3 7.90381 199.3 15.1498C199.3 22.3958 205.174 28.2698 212.42 28.2698Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M231.38 51.3096C231.38 40.8396 222.89 32.3496 212.42 32.3496C201.95 32.3496 193.46 40.8396 193.46 51.3096H231.37H231.38Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {/* Star */}
      <svg
        width="189"
        height="28"
        viewBox="0 0 189 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stars-animation"
      >
        <path
          d="M14.9909 1.72998L18.9409 9.72998L27.7709 11.02L21.3809 17.25L22.8909 26.04L14.9909 21.89L7.09094 26.04L8.60094 17.25L2.21094 11.02L11.0409 9.72998L14.9909 1.72998Z"
          stroke="#8889e8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.7107 1.72998L58.6607 9.72998L67.4907 11.02L61.1007 17.25L62.6107 26.04L54.7107 21.89L46.8207 26.04L48.3207 17.25L41.9307 11.02L50.7707 9.72998L54.7107 1.72998Z"
          stroke="#8889e8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.4411 1.72998L98.3911 9.72998L107.221 11.02L100.831 17.25L102.341 26.04L94.4411 21.89L86.5411 26.04L88.0511 17.25L81.6611 11.02L90.4911 9.72998L94.4411 1.72998Z"
          stroke="#8889e8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.171 1.72998L138.111 9.72998L146.941 11.02L140.551 17.25L142.061 26.04L134.171 21.89L126.271 26.04L127.781 17.25L121.391 11.02L130.221 9.72998L134.171 1.72998Z"
          stroke="#8889e8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.891 1.72998L177.841 9.72998L186.671 11.02L180.281 17.25L181.791 26.04L173.891 21.89L165.991 26.04L167.501 17.25L161.111 11.02L169.941 9.72998L173.891 1.72998Z"
          stroke="#8889e8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {/* Star container */}
      <svg
        className="star-container"
        width="233"
        height="55"
        viewBox="0 0 233 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M231.38 1.58984H1.5V52.5898H231.38V1.58984Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default StaffAnimation;
