import React from 'react';
import './About.scss';
import AboutAnimation from '../../components/animations/AboutAnimation/AboutAnimation.jsx';
import AccordianSection from '../../components/common/AccordianSection/AccordianSection';
import { visionMissionData } from '../../assets/data/visionMissionData';
import { valuesData } from '../../assets/data/valuesData';
import { Link } from 'react-router-dom';
import ResourcesSection from '../../components/sections/ResourcesSection/ResourcesSection';

import dotimg from '../../assets/images/grid dots.png';
import Design_assets from '../../assets/images/Design_assets.svg';
import Frame from '../../assets/images/Frame.svg';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import OfferCard from '../../components/common/OfferCard/OfferCard.jsx';
import useVisibilityAnimation from '../../Features/onLoadAnimation.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const About = () => {
  const { isVisible, targetRef } = useVisibilityAnimation();
  useMoveToTopFeature();

  return (
    <div>
      {/* About section */}
      <HeroSection
        labelText="ABOUT US"
        mainText="Your Trusted Cybersecurity Partner to Unlock Secure Futures"
        paragarphText="Navigating the Cyber Frontier with Proven Expertise"
        AnimationComponent={<AboutAnimation></AboutAnimation>}
      />

      {/* who we are */}
      <div className="whoweare-section">
        <div className="container">
          <div className="whoweare-text">
            <label htmlFor="">WHO ARE WE</label>
            <h1>OUR IDENTITY</h1>
            <span>
              At ComplySimpli, we&apos;re on a mission to redefine cybersecurity. We
              understand that <br />
              Identity and Access Management (IAM) is the new perimeter of
              security in the digital <br />
              age. Founded by passionate IAM leaders, we aim to reinvent and
              simplify security, <br />
              making it affordable and accessible for SMBs.
            </span>
            <br />
            <br />
            <span>
              We focus on the fewest moves you can make that could get you
              compliant in the <br />
              shortest amount of time With 50 years of core IAM expertise, we&apos;re
              your trusted guides <br />
              in the complex landscape of cybersecurity.
            </span>
            <br />
            <Link to="/services">
              <div className="deep-dive-link purpule">
                <a>Explore Our IAM Expertise</a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="16"
                  viewBox="0 0 46 16"
                  fill="none"
                >
                  <path
                    d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.5588V7.165H1V8.835Z"
                    fill="#9654F4"
                  />
                  <circle
                    cx="38.0589"
                    cy="8.00001"
                    r="7.10618"
                    stroke="#9654F4"
                    strokeWidth="1.67"
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div className="whoweare-box-data">
            <div
              className="whoweare-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>IAM Visionaries</h2>
              <span>with over 50 years of core IM Expertise</span>
            </div>
            <div
              className="whoweare-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>Accessible</h2>
              <span>compliance for small & medium businesses</span>
            </div>
            <div
              className="whoweare-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>The Fastest Path</h2>
              <span>with the fewest moves to compliance</span>
            </div>
            <div
              className="whoweare-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>Ambient Security</h2>
              <span>excellence is our unwavering goal</span>
            </div>
          </div>
        </div>
      </div>

      {/* WHY PARTNER WITH US*/}
      <div className="whywithus-section round-animation">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <div className="whywithus-section-row">
            <div className="whywithus-text">
              <label htmlFor="">WHY PARTNER WITH US</label>
              <h1>THE COMPLYsimpli advantage</h1>
              <span>
                Choose ComplySimpli for a business-driven approach to
                cybersecurity. We prioritize security based on risks, ensuring
                that your investments are aligned with your business objectives.
                Our Virtual IAM Architect is here for the long haul, providing
                continuous support and insights. Partner with us for a strategic
                cybersecurity journey.
              </span>
              <br />
              <Link to="/contact?focus=contact">
                <div className="deep-dive-link">
                  <a>Work With Us</a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="16"
                    viewBox="0 0 46 16"
                    fill="none"
                  >
                    <path
                      d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.0115V7.165H1V8.835Z"
                      fill="#4E4FDC"
                    />
                    <circle
                      cx="37.3726"
                      cy="7.99994"
                      r="6.95913"
                      stroke="#4E4FDC"
                      strokeWidth="1.67"
                    />
                  </svg>
                </div>
              </Link>
            </div>
            <div className="whywithus-text paddi-left">
              <h4>Risk-Based Approach</h4>
              <span>
                We align security with your business goals, adopting a
                risk-based strategy.
              </span>

              <h4 className="mar-top">Long-Term Support</h4>
              <span>
                Our Virtual IAM Architect stands by your side for the duration.
              </span>
            </div>
          </div>
          <div className="dot-img">
            <img src={dotimg} alt="" />
          </div>
        </div>
      </div>

      {/* SERVICES AND SOLUTIONS */}
      <div className="serandsol-section">
        <div className="serandsol-bg">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2180"
            height="435"
            viewBox="0 0 1440 439"
            fill="none"
            ref={targetFirstRef}
            className={`${isFirstTimeVisible ? "about-line-animation" : ""}`}
          >
            {isFirstTimeVisible ? (
              <path
                // className="about-line-animation"
                d="M1691 437C1512 285.496 1398 183 1333 129C1087 -96 928 26.0002 795.991 123.728L591.25 285.496C409.909 405.194 310 495.985 142.121 350.357C-7 221 -284.129 -28.7717 -489 9.29138"
                stroke="#9654F4"
                strokeWidth="3"
              />
            ) : (
              ""
            )}
          </svg> */}
          <svg
            width="3000"
            height="446"
            viewBox="0 0 3000 446"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={targetRef}
          >
            {isVisible ? (
              <path
                className={`${isVisible ? 'about-line-animation' : ''}`}
                d="M-77.0009 350.735C-77.0009 350.735 -149.002 517.969 27.4991 350.735C204 183.5 240 40.2239 409.999 9.66935C579.998 -20.8852 891.999 221.378 1041.12 350.735C1209 496.363 1308.91 405.572 1490.25 285.874L1694.99 124.106C1827 26.3783 1986 -95.6218 2232 129.378C2297 183.378 2478 391.756 2590 437.378C2702 483 2900.5 266.5 2983.5 124.106C3066.5 -18.2884 3080.5 124.106 3080.5 124.106"
                stroke="#9654F4"
                strokeWidth="3"
              />
            ) : (
              ''
            )}
          </svg>
        </div>
        <div className="container">
          <div className="serandsol-text">
            <label htmlFor="">SERVICES AND SOLUTIONS</label>
            <h1>WHAT WE OFFER</h1>
            <span>
              Discover our comprehensive range of services, innovative
              solutions, and a wealth of <br />
              resources. Click on the cards below to delve deeper into our
              offerings
            </span>
          </div>
          <div className="serandsol-data-box">
            <OfferCard
              title="SERVICES"
              paragraph="Explore how we can elevate your cybersecurity strategy."
              linkSrc="/services"
              imgSrc={Frame}
            />
            <OfferCard
              title="SOLUTIONS"
              paragraph="Find the perfect solutions to meet your unique business
            challenges."
              linkSrc="/solutions"
              imgSrc={Design_assets}
            />
          </div>
        </div>
      </div>

      {/* VISION, MISSION AND VALUES */}
      <div className="vimiva-section round-animation">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <div className="vimiva-text">
            <label htmlFor="">VISION, MISSION AND VALUES</label>
            <h1>OUR NORTH STAR</h1>
          </div>
          <AccordianSection dataArray={visionMissionData} />
          <hr />
          <div className="vimiva-text">
            <h1>OUR Values</h1>
          </div>
          <AccordianSection dataArray={valuesData} />
        </div>
      </div>

      {/* MEET THE TEAM */}
      {/* <div className="team-section">
        <div className="container">
          <div className="team-text">
            <label htmlFor="">MEET THE TEAM</label>
            <h1>THE MINDS BEHIND COMPLYSIMPLI</h1>
            <span>
              Our team is a diverse blend of cybersecurity visionaries, IAM
              pioneers, and digital <br />
              strategists. We're united by a passion for reimagining security
              and a shared <br />
              commitment to your success. Meet the individuals dedicated to
              safeguarding your <br /> digital future.
            </span>
            <br />

            <Link to="/contact">
              <div className="deep-dive-link purpule">
                <a>Meet Our Experts</a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="16"
                  viewBox="0 0 46 16"
                  fill="none"
                >
                  <path
                    d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.5588V7.165H1V8.835Z"
                    fill="#9654F4"
                  />
                  <circle
                    cx="38.0589"
                    cy="8.00001"
                    r="7.10618"
                    stroke="#9654F4"
                    strokeWidth="1.67"
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div className="team-slider">
            <SliderComponent>
              <div className="team-slider-item">
                <img src={photo} alt="" />
                <h4>Devin Murray</h4>
                <label htmlFor="">CEO, XYZ Bank</label>
              </div>
              <div className="team-slider-item">
                <img src={photo1} alt="" />
                <h4>Devin Murray</h4>
                <label htmlFor="">CEO, XYZ Bank</label>
              </div>
              <div className="team-slider-item">
                <img src={photo2} alt="" />
                <h4>Devin Murray</h4>
                <label htmlFor="">CEO, XYZ Bank</label>
              </div>
            </SliderComponent>
          </div>
        </div>
      </div> */}

      {/* Resources */}
      <div className="white-resources">
        <ResourcesSection />
      </div>

      <ReadyToUseSection
        titleText="Ready to transform your cybersecurity?"
        bodyText="If you're ready to experience the ComplySimpli difference, reach out to us today. We're just a message away from initiating your journey towards simplified cybersecurity solutions."
        linkSrc="/contact?focus=contact"
        btnText="Connect With Us"
      />
    </div>
  );
};
