export const implementationEnterpriseData = [
  {
    title: 'Minimized Disruption',
    contentPart:
      'Our expertise in deployment and integration minimizes disruption to your daily operations, ensuring a smooth transition.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Maximized Efficiency',
    contentPart:
      "The seamless integration of IAM solutions enhances the organization's overall efficiency and productivity.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Improved Security',
    contentPart:
      "As well-implemented IAM system strengthens your organization's security, reducing risk of unauthorized access and data breaches.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Customized Solutions',
    contentPart:
      "We tailor our approach to fit your organization's specific needs and IT environment.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Expert Guidance',
    contentPart:
      'Our team provides expert guidance at every step, ensuring you get the most of your IAM system.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
