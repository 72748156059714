import { useState, useEffect, useRef } from 'react';

function useVisibilityAnimation() {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const boundingBox = targetRef.current.getBoundingClientRect();
        const { top } = boundingBox;
        const threshold = window.innerHeight * 0.2; // 20% of the viewport height
        // const threshold = (window.innerHeight - 800) * 0.002; // 20% of the viewport height
        const isVisibleNow = top < window.innerHeight - threshold;
        // const isVisibleNow =
        //   top < window.innerHeight - threshold && bottom >= threshold;
        setIsVisible(isVisibleNow);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isVisible, targetRef };
}

export default useVisibilityAnimation;
