import React, { useEffect } from 'react';
import './style.scss';
import gsap from 'gsap';

const OurWinsAnimation = () => {
  useEffect(() => {
    const groups = [
      '#group1',
      '#group2',
      '#group3',
      '#group4',
      '#group5',
      '#group6',
    ];

    const tl = gsap.timeline({ repeat: -1, delay: 0.5 });
    groups.reverse().forEach((group) => {
      tl.fromTo(
        group,
        { scale: 1, opacity: 0 },
        {
          scale: 0,
          opacity: 1,
          duration: 0.0002,
          ease: 'power2.out',
        }
      );
    });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group,
        { scale: 0 },
        {
          scale: 1,
          duration: 0.5,
          ease: 'power2.out',
        }
      );
    });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.2,
          ease: 'power2.out',
        }
      );
    });
  }, []);

  return (
    <svg
      className="our-wins-animation-2"
      width="349"
      height="255"
      viewBox="0 0 349 255"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="group2" className={`group-animation `}>
        <path
          d="M346.96 21.8201H238.8V167.75H346.96V21.8201Z"
          fill="#091A2A"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M292.88 49.29H259.97V82.2H292.88V49.29Z"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.97 100.79H320.63"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.97 114.6H320.63"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M261.64 133.92L263.26 138.92H268.52L264.27 142.01L265.89 147.01L261.64 143.92L257.39 147.01L259.01 142.01L254.76 138.92H260.01L261.64 133.92Z"
          fill="#FFA900"
        />
        <path
          d="M276.89 133.92L278.52 138.92H283.78L279.52 142.01L281.15 147.01L276.89 143.92L272.64 147.01L274.27 142.01L270.01 138.92H275.27L276.89 133.92Z"
          fill="#FFA900"
        />
        <path
          d="M292.15 133.92L293.77 138.92H299.03L294.78 142.01L296.4 147.01L292.15 143.92L287.9 147.01L289.52 142.01L285.27 138.92H290.53L292.15 133.92Z"
          fill="#FFA900"
        />
        <path
          d="M307.41 133.92L309.03 138.92H314.29L310.03 142.01L311.66 147.01L307.41 143.92L303.15 147.01L304.78 142.01L300.52 138.92H305.78L307.41 133.92Z"
          fill="#FFA900"
        />
        <path
          d="M322.66 133.92L324.29 138.92H329.54L325.29 142.01L326.91 147.01L322.66 143.92L318.41 147.01L320.03 142.01L315.78 138.92H321.04L322.66 133.92Z"
          fill="#FFA900"
        />
      </g>

      <g id="group5" className={`group-animation `}>
        <path
          d="M332.43 83.05H224.27V228.98H332.43V83.05Z"
          fill="#091A2A"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M278.36 110.52H245.45V143.43H278.36V110.52Z"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M245.45 162.03H306.11"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M245.45 175.84H306.11"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M247.11 195.15L248.74 200.15H254L249.74 203.24L251.37 208.24L247.11 205.15L242.86 208.24L244.48 203.24L240.23 200.15H245.49L247.11 195.15Z"
          fill="#FFA900"
        />
        <path
          d="M262.37 195.15L263.99 200.15H269.25L265 203.24L266.62 208.24L262.37 205.15L258.12 208.24L259.74 203.24L255.49 200.15H260.74L262.37 195.15Z"
          fill="#FFA900"
        />
        <path
          d="M277.62 195.15L279.25 200.15H284.51L280.25 203.24L281.88 208.24L277.62 205.15L273.37 208.24L275 203.24L270.74 200.15H276L277.62 195.15Z"
          fill="#FFA900"
        />
        <path
          d="M292.88 195.15L294.5 200.15H299.76L295.51 203.24L297.13 208.24L292.88 205.15L288.63 208.24L290.25 203.24L286 200.15H291.25L292.88 195.15Z"
          fill="#FFA900"
        />
        <path
          d="M308.13 195.15L309.76 200.15H315.02L310.76 203.24L312.39 208.24L308.13 205.15L303.88 208.24L305.51 203.24L301.25 200.15H306.51L308.13 195.15Z"
          fill="#FFA900"
        />
      </g>

      <g id="group4" className={`group-animation `}>
        <path
          d="M263.19 54.23H155.03V200.16H263.19V54.23Z"
          fill="#091A2A"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M209.11 81.6899H176.2V114.6H209.11V81.6899Z"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.2 133.2H236.87"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.2 147.01H236.87"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.87 166.32L179.49 171.32H184.75L180.5 174.42L182.12 179.42L177.87 176.33L173.62 179.42L175.24 174.42L170.99 171.32H176.25L177.87 166.32Z"
          fill="#FFA900"
        />
        <path
          d="M193.13 166.32L194.75 171.32H200.01L195.75 174.42L197.38 179.42L193.13 176.33L188.87 179.42L190.5 174.42L186.24 171.32H191.5L193.13 166.32Z"
          fill="#FFA900"
        />
        <path
          d="M208.38 166.32L210.01 171.32H215.26L211.01 174.42L212.63 179.42L208.38 176.33L204.13 179.42L205.75 174.42L201.5 171.32H206.76L208.38 166.32Z"
          fill="#FFA900"
        />
        <path
          d="M223.64 166.32L225.26 171.32H230.52L226.26 174.42L227.89 179.42L223.64 176.33L219.38 179.42L221.01 174.42L216.75 171.32H222.01L223.64 166.32Z"
          fill="#FFA900"
        />
        <path
          d="M238.89 166.32L240.52 171.32H245.77L241.52 174.42L243.14 179.42L238.89 176.33L234.64 179.42L236.26 174.42L232.01 171.32H237.27L238.89 166.32Z"
          fill="#FFA900"
        />
      </g>

      <g id="group3" className={`group-animation `}>
        <path
          d="M202.96 2H94.7998V147.93H202.96V2Z"
          fill="#091A2A"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.88 29.47H115.97V62.38H148.88V29.47Z"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115.97 80.97H176.63"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115.97 94.78H176.63"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.64 114.1L119.26 119.1H124.52L120.27 122.19L121.89 127.19L117.64 124.1L113.38 127.19L115.01 122.19L110.75 119.1H116.01L117.64 114.1Z"
          fill="#FFA900"
        />
        <path
          d="M132.89 114.1L134.52 119.1H139.78L135.52 122.19L137.15 127.19L132.89 124.1L128.64 127.19L130.26 122.19L126.01 119.1H131.27L132.89 114.1Z"
          fill="#FFA900"
        />
        <path
          d="M148.15 114.1L149.77 119.1H155.03L150.78 122.19L152.4 127.19L148.15 124.1L143.89 127.19L145.52 122.19L141.27 119.1H146.52L148.15 114.1Z"
          fill="#FFA900"
        />
        <path
          d="M163.4 114.1L165.03 119.1H170.29L166.03 122.19L167.66 127.19L163.4 124.1L159.15 127.19L160.77 122.19L156.52 119.1H161.78L163.4 114.1Z"
          fill="#FFA900"
        />
        <path
          d="M178.66 114.1L180.28 119.1H185.54L181.29 122.19L182.91 127.19L178.66 124.1L174.41 127.19L176.03 122.19L171.78 119.1H177.03L178.66 114.1Z"
          fill="#FFA900"
        />
      </g>

      <g id="group6" className={`group-animation `}>
        <path
          d="M210.6 106.8H102.44V252.73H210.6V106.8Z"
          fill="#091A2A"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M156.53 134.27H123.62V167.18H156.53V134.27Z"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.62 185.77H184.28"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.62 199.58H184.28"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.28 218.9L126.91 223.9H132.16L127.91 226.99L129.53 231.99L125.28 228.9L121.03 231.99L122.65 226.99L118.4 223.9H123.66L125.28 218.9Z"
          fill="#FFA900"
        />
        <path
          d="M140.54 218.9L142.16 223.9H147.42L143.16 226.99L144.79 231.99L140.54 228.9L136.28 231.99L137.91 226.99L133.65 223.9H138.91L140.54 218.9Z"
          fill="#FFA900"
        />
        <path
          d="M155.79 218.9L157.42 223.9H162.67L158.42 226.99L160.04 231.99L155.79 228.9L151.54 231.99L153.16 226.99L148.91 223.9H154.17L155.79 218.9Z"
          fill="#FFA900"
        />
        <path
          d="M171.05 218.9L172.67 223.9H177.93L173.68 226.99L175.3 231.99L171.05 228.9L166.79 231.99L168.42 226.99L164.16 223.9H169.42L171.05 218.9Z"
          fill="#FFA900"
        />
        <path
          d="M186.3 218.9L187.93 223.9H193.18L188.93 226.99L190.56 231.99L186.3 228.9L182.05 231.99L183.67 226.99L179.42 223.9H184.68L186.3 218.9Z"
          fill="#FFA900"
        />
      </g>
      <g id="group1" className={`group-animation `}>
        <path
          d="M110.16 47.6799H2V193.61H110.16V47.6799Z"
          fill="#091A2A"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.0799 75.1499H23.1699V108.06H56.0799V75.1499Z"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1699 126.65H83.8299"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1699 140.46H83.8299"
          stroke="#8889E8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.84 159.78L26.46 164.78H31.72L27.47 167.87L29.09 172.87L24.84 169.78L20.59 172.87L22.21 167.87L17.96 164.78H23.21L24.84 159.78Z"
          fill="#FFA900"
        />
        <path
          d="M40.09 159.78L41.72 164.78H46.98L42.72 167.87L44.35 172.87L40.09 169.78L35.84 172.87L37.47 167.87L33.21 164.78H38.47L40.09 159.78Z"
          fill="#FFA900"
        />
        <path
          d="M55.3497 159.78L56.9697 164.78H62.2297L57.9797 167.87L59.5997 172.87L55.3497 169.78L51.0997 172.87L52.7197 167.87L48.4697 164.78H53.7297L55.3497 159.78Z"
          fill="#FFA900"
        />
        <path
          d="M70.6097 159.78L72.2297 164.78H77.4897L73.2297 167.87L74.8597 172.87L70.6097 169.78L66.3497 172.87L67.9797 167.87L63.7197 164.78H68.9797L70.6097 159.78Z"
          fill="#FFA900"
        />
        <path
          d="M85.8605 159.78L87.4905 164.78H92.7405L88.4905 167.87L90.1105 172.87L85.8605 169.78L81.6105 172.87L83.2305 167.87L78.9805 164.78H84.2405L85.8605 159.78Z"
          fill="#FFA900"
        />
      </g>
    </svg>
  );
};

export default OurWinsAnimation;
