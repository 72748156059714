export const implementationFocusData = [
  {
    title: 'Deployment Planning',
    contentPart:
      'We collaborate with your team to create a comprehensive deployment plan, including timelines and responsibilities.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Testing and Validation',
    contentPart:
      'Rigorous testing and validation of the IAM solution to ensure it meets security and performance standards.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Integration Strategy',
    contentPart:
      'Developing a robust integration strategy to connect the IAM system with your existing IT infrastructure.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'User Training',
    contentPart:
      'Training your staff on the new IAM system, ensuring they understand how to use it effectively.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
