export const focusData = [
  {
    title: 'Passwordless Login',
    contentPart:
      'FIDO2 Standards based Phishing resistant login to Corporate endpoints using an external security key or a platform key built into a device',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Multi-Factor Authentication (MFA)',
    contentPart:
      'Enhance security by implementing multiple layers of authentication for user access.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Adaptive Authentication',
    contentPart:
      'Improve security and minimizes user friction by integrating contextual information to establish a greater level of assurance',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Single Sign-On (SSO)',
    contentPart:
      'Simplify user access and authentication processes with a single sign-on solution',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Federation and Identity Federation',
    contentPart:
      'Enable secure identity and access across multiple systems and applications',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
