export const auditFocusData = [
  {
    title: 'Regulatory Compliance Assessment',
    contentPart:
      'We collaborate with your team to create a comprehensive deployment plan, including timelines and responsibilities.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Data Protection Measures',
    contentPart:
      'Implementing security measures to protect the sensitive data from breaches.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Regulatory Adaptation',
    contentPart:
      'Staying up to date with regulatory changes and making necessary adjustments.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
