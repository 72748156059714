import React from 'react';
import PropTypes from 'prop-types';
import griddots from '../../../assets/images/griddots.png';
import './ImportanceSection.scss';

const ImportanceSection = ({ title, paragraphText }) => {
  return (
    <div>
      <div className="importance-section">
        <div className="container">
          <h1>{title}</h1>
          <div className="span-text">
            <span>{paragraphText}</span>
          </div>
          <div className="griddot">
            <img src={griddots} alt="" />
          </div>
        </div>
        {/* <div className="griddot">
            <img src={griddots} alt="" />
          </div> */}
      </div>
    </div>
  );
};

ImportanceSection.propTypes = {
  title: PropTypes.array.isRequired,
  paragraphText: PropTypes.array.isRequired,
};

export default ImportanceSection;
