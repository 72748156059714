import React from 'react';
import './StrategyRoadmap.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import StrategyAnimation from '../../components/animations/StrategyAnimation/StrategyAnimation.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';
import { strategyEnterpriseData } from '../../assets/data/strategyEnterpriseData.js';
import img1 from '../../assets/images/stratgey/StragtegyUnderstand_Card.svg';
import img2 from '../../assets/images/stratgey/StrategyEmpower_Card.svg';
import img3 from '../../assets/images/stratgey/StrategyHomeIdentify_Card.svg';
import useVisibilityAnimation from '../../Features/onLoadAnimation.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const StrategyRoadmap = () => {
  const { isVisible, targetRef } = useVisibilityAnimation();
  useMoveToTopFeature();

  const { isVisible: mobileLineVisible1, targetRef: mobileLineRef1 } =
    useVisibilityAnimation();
  const { isVisible: mobileLineVisible2, targetRef: mobileLineRef2 } =
    useVisibilityAnimation();
  useMoveToTopFeature();

  return (
    <div>
      <div className="strategy-hero-section">
        <HeroSection
          labelText="STRATEGY AND ROADMAP DEVELOPMENT"
          mainText="Strategic Cybersecurity Excellence"
          paragarphText="This service is the right choice for organizations seeking a well-structured and holistic approach to cybersecurity. With our Cyber Maturity Accelerator™, and expert guidance, we empower you with a clear, actionable roadmap to enhance your cybersecurity posture. Our business-driven security approach, cost-efficiency, and compliance assurance further solidify the benefits of choosing ComplySimpli as your cybersecurity partner."
          AnimationComponent={<StrategyAnimation></StrategyAnimation>}
        />
      </div>

      <div className="intro-section">
        <div className="container">
          <div className="intro-bg">
            <svg
              width="3000"
              height="2522"
              viewBox="0 0 3000 2522"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              ref={targetRef}
            >
              {isVisible ? (
                <path
                  className={`${isVisible ? 'line-animation' : ''}`}
                  d="M-6 1075C-6 1075 29.5 1085.04 205 956.771C380.5 828.5 340.634 568.7 515.5 383.771C722.53 164.827 967.5 44.1413 1191.5 11.7713C1422.17 -21.562 1859.5 11.7713 1763.5 411.771C1643.5 911.771 643.5 1079.77 1355.5 1703.77C1925.1 2202.97 1819.5 2519.77 1819.5 2519.77H2437.5H3026"
                  stroke="#9654F4"
                  strokeWidth="3"
                />
              ) : (
                ''
              )}
            </svg>
          </div>
          <div className="intro-text">
            <label htmlFor="">YOUR PATH TO CYBER RESILIENCE</label>
            <h1>CYBER MATURITY ACCELERATOR™</h1>
            <span>
              This comprehensive strategic framework takes your organization
              through <br /> three essential steps, guiding you towards enhanced
              cybersecurity
            </span>
          </div>

          <div className="intro-content-row">
            <div
              className="intro-content-box"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img src={img1} alt="" />
              <label htmlFor="">STEP 1: UNDERSTAND</label>
              <h1>Comprehensive Assessment</h1>
              <span>
                In this foundational step, we embark on a journey to understand
                your organization&apos;s cybersecurity ecosystem in its entirety.
                This includes:
              </span>
              <br />
              <ul>
                <li>
                  <b>Systemic Information Gathering:</b> We meticulously collect
                  readily available and self-discoverable information, ensuring
                  that no detail is overlooked. This extends to historical data,
                  past decisions, technology, processes, and the people involved
                  in your security landscape.
                </li>
                <li>
                  <b>Free-Flow Conversations:</b> We engage in open and
                  insightful conversations to understand the business&apos;s
                  operational model and the pivotal role of security within it.
                </li>
              </ul>
            </div>
            <div></div>
          </div>

          {/* Line */}
          <div className="intro-bg-mobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="250"
              viewBox="0 0 4 250"
              fill="none"
            >
              {mobileLineVisible1 ? (
                <path
                  id={`${mobileLineVisible1 ? 'strategy-mobile-draw-animation-1' : ''}`}
                  d="M2 0V250"
                  stroke="#9654F4"
                  strokeWidth="3"
                />
              ) : (
                ''
              )}
            </svg>
          </div>
          {/* Line */}

          <div className="intro-content-row reverse-col">
            <div></div>
            <div
              className="intro-content-box"
              data-aos="fade-up"
              ref={mobileLineRef1}
              data-aos-duration="800"
            >
              <img src={img3} alt="" />
              <label htmlFor="">STEP 2: IDENTIFY</label>
              <h1>Current State Definition</h1>
              <span>
                Building on the foundation of understanding established in Step
                1, we proceed with a systematic organization of collected data.
                This step involves:{' '}
              </span>
              <br />
              <ul>
                <li>
                  <b>Capability Mapping Exercise:</b>We utilize a structured
                  capability mapping exercise to comprehensively understand the
                  current state of your IAM security. Our approach is grounded
                  in the Gartner Maturity Model, providing a robust framework.
                </li>
                <li>
                  <b>Iterative Evaluation:</b> We iterate through the evaluation
                  process, ensuring alignment and consensus on maturity scores.
                  This step results in an overall maturity score and specific
                  capability scores.
                </li>
              </ul>
            </div>
          </div>

          {/* Line */}
          <div className="intro-bg-mobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="250"
              viewBox="0 0 4 250"
              fill="none"
            >
              {mobileLineVisible2 ? (
                <path
                  id={`${mobileLineVisible2 ? 'strategy-mobile-draw-animation-2' : ''}`}
                  d="M2 0V250"
                  stroke="#9654F4"
                  strokeWidth="3"
                />
              ) : (
                ''
              )}
            </svg>
          </div>
          {/* Line */}

          <div className="intro-content-row">
            <div
              className="intro-content-box"
              ref={mobileLineRef2}
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img src={img2} alt="" />
              <label htmlFor="">STEP 3: EMPOWER</label>
              <h1>Target State and Actionable Roadmap</h1>
              <span>
                In the final phase of our &quot;Cyber Maturity Accelerator,&quot; we
                empower your organization with a clear and actionable roadmap to
                elevate your cybersecurity posture. This involves:
              </span>
              <br />
              <ul>
                <li>
                  <b>Optimal Path to Security:</b> Taking into account your
                  unique business needs, industry regulations, and best
                  practices, we craft an actionable plan covering 1, 3, and
                  5-year horizons.
                </li>
                <li>
                  <b>LEAN Plan:</b>We define what must be done and what would be
                  advantageous in terms of security measures. Our goal is to
                  find what are the fewest moves you can make that could get you
                  compliant in the shortest amount of time - the most efficient
                  path to compliance, optimizing efforts and resources
                </li>
                <li>
                  <b>People, Process, Technology (PPT):</b>Our roadmap
                  highlights the key areas where changes to personnel,
                  processes, and technology upgrades are needed.
                </li>
              </ul>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      {/* ENTERPRISE GAINS */}
      <div className="bg-add">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <EnterpriseGainSection
          data={strategyEnterpriseData}
        ></EnterpriseGainSection>
      </div>

      {/* Talk To Us */}
      {/* <div className="bg-remove">
        <TalkToUsSection></TalkToUsSection>
      </div> */}
      <div className="ready-mar0 butmar">
        <ReadyToUseSection
          titleText="Experience Service Excellence"
          bodyText="Our service portfolio showcases our unwavering dedication to your business's success. With a wealth of experience and expertise, we stand out in meeting your distinct requirements. Explore the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
