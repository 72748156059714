import React from 'react';
import './IGA.scss';

import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import ImportanceSection from '../../components/sections/ImportanceSection/ImportanceSection.jsx';
import IGAAnimation from '../../components/animations/IGAAnimation/IGAAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { focusData } from '../../assets/data/igaFocusData.js';
import { enterpriseData } from '../../assets/data/igaEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';

import vendor2 from '../../assets/images/Okta.png';
import vendor3 from '../../assets/images/ForgeRock1.png';
import vendor4 from '../../assets/images/Okta3.png';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const IGA = () => {
  useMoveToTopFeature();

  return (
    <div className="iga-page">
      <div className="iga-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="IDENTITY GOVERNANCE AND ADMINISTRATION (IGA)"
          mainText="Boost Productivity and Ensure Compliance"
          paragarphText="End-to-End Identity Security"
          AnimationComponent={<IGAAnimation></IGAAnimation>}
        />
      </div>

      {/* Importance Section */}
      <ImportanceSection
        title="IMPORTANCE OF IGA"
        paragraphText="In the landscape of digital transformation, enterprises witness an extensive proliferation of digital assets essential for employee productivity. Identity Governance and Administration (IGA) becomes critical as it ensures precise access, granting the right individuals access to the appropriate resources at the exact time required. This becomes crucial within the swiftly evolving digital terrain, where interconnected devices and applications are the norm. Furthermore, IGA plays a pivotal role in compliance adherence by meticulously maintaining records of user access, actions, and privileges, thereby meeting regulatory requirements and compliance standards"
      ></ImportanceSection>

      {/* Approach Section */}
      <div className="darkapproach">
        <ApproachSection paragraphText="ComplySimpli embraces a proactive strategy in Identity Governance and Administration (IGA), emphasizing centralized visibility, role-based access control, and policy enforcement. Our approach focuses on streamlining access, ensuring compliance, and reducing risks by implementing robust identity lifecycle management and stringent access governance policies" />
      </div>

      {/* OUR FOCUS */}
      <div className="focus-bg-remove">
        <OurFocusSection data={focusData}></OurFocusSection>
      </div>

      {/* ENTERPRISE GAINS */}
      <div className="bg-add">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <EnterpriseGainSection data={enterpriseData}></EnterpriseGainSection>
      </div>

      {/* OUR VENDORS */}
      <div className="ourvendor-section col2vendor">
        <div className="container">
          <h1>OUR VENDORS</h1>
          <div className="vendor-img">
            <img src={vendor2} alt="" />
            <img src={vendor3} alt="" />
            <img src={vendor4} alt="" />
          </div>
        </div>
      </div>

      {/* HarnessIAMMastery */}
      {/* <HarnessIAMMastery></HarnessIAMMastery> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Harness Decades of IAM Mastery"
          bodyText="Our cybersecurity solutions are expertly crafted to empower your organization's digital transformation. With decades of expertise and a relentless commitment to excellence, we provide practical, tailor-made solutions that optimize your security posture and ROI. Experience the ComplySimpli difference today."
          linkSrc="/contact?focus=contact"
          btnText="Connect With Us"
        />
      </div>
    </div>
  );
};
