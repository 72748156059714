import React, { useEffect } from 'react';
import './CiamMobileAnimation.scss';
import gsap from 'gsap';

const CiamAnimationMobile = () => {
  useEffect(() => {
    const groups = gsap.utils.toArray("[class^='cg']");
    // const text = [
    //   '.cg1 .ct1',
    //   '.cg2 .ct1',
    //   '.cg3 .ct1',
    //   '.cg4 .ct1',
    //   '.cg5 .ct1',
    //   '.cg6 .ct1',
    // ];

    const timeline = gsap.timeline({ repeat: -1 });

    groups.forEach((group, index) => {
      timeline.add(
        () => {
          const prevGroupIndex = index === 0 ? groups.length - 1 : index - 1;
          const prevGroup = groups[prevGroupIndex];
          console.log(prevGroup.children[1]);

          gsap.fromTo(
            prevGroup.children[0].children,
            {
              scale: 1,
              stroke: '#9654F4',
              strokeWidth: 3,
            },
            {
              scale: 0.9,
              stroke: '#8889E8',
              strokeWidth: 1.84069,
              duration: 1,
              delay: 0.2,
            }
          );

          gsap.fromTo(
            prevGroup.children[1],
            {
              opacity: 1,
            },
            {
              opacity: 0,

              duration: 0.8,
              delay: 0,
            }
          );

          gsap.fromTo(
            group.children[0].children,
            {
              scale: 0.9,
              stroke: '#8889E8',
              strokeWidth: 1.84069,
            },
            {
              scale: 1,
              stroke: '#9654F4',
              strokeWidth: 3,
              duration: 1,
              ease: 'linear',
              delay: 0.2,
            }
          );

          gsap.fromTo(
            group.children[1],
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 0.8,
              ease: 'linear',
              delay: 0,
            }
          );
        },

        // Give delay which should be constant
        `${(index + 1) * 2}`
      );
    });
  }, []);

  return (
    <div className="svg-img-mobile">
      <div className="cg1 group-hover">
        <svg
          width="78"
          height="68"
          viewBox="0 0 78 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6855 23.3282C12.4749 21.4573 15.2657 19.9593 15.2657 19.9593C21.3632 16.2921 29.17 18.0884 32.7005 23.9621C33.9809 26.0879 34.7454 28.6798 34.7578 31.1598C34.7827 35.1129 31.6252 41.3596 27.1189 41.4964C24.2535 41.5834 22.0905 39.3893 22.2521 36.5363C22.4261 33.5155 25.7763 30.1467 22.1464 28.0023C19.5794 26.4857 16.5026 29.7178 15.2906 31.6322C13.4197 34.5846 12.9846 38.3326 14.3023 41.5958"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.0437 32.9688C19.0437 32.9688 14.4815 39.4019 21.4989 44.0636"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.0296 36.984C29.2057 34.9951 30.0013 31.2906 28.5344 27.9528C26.794 23.9934 23.2698 21.8553 18.9438 23.2351C14.8166 24.5529 11.2675 28.3195 9.76953 32.5647"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.6875 44.0139C57.785 27.4866 54.0557 8.67823 54.0557 8.67823C54.0557 8.67823 36.1734 19.5182 28.7582 2.30725C28.522 1.74784 27.6767 1.74784 27.4405 2.30725C20.0253 19.512 2.14304 8.67823 2.14304 8.67823C2.14304 8.67823 -7.2425 56.01 28.0994 66.4771C31.1761 65.5634 33.9171 64.3762 36.3474 62.9528"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.9122 64.861C49.6885 64.861 55.1817 59.3677 55.1817 52.5914C55.1817 45.8151 49.6885 40.3218 42.9122 40.3218C36.1359 40.3218 30.6426 45.8151 30.6426 52.5914C30.6426 59.3677 36.1359 64.861 42.9122 64.861Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.4863 52.933L40.6003 56.4075L50.3215 48.7686"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.7383 21.7993H76.4762"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.7383 27.126H72.2993"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.7383 32.4526H66.7363"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="ct1">Customer MFA</p>
      </div>

      <div className="cg2 group-hover">
        <svg
          width="63"
          height="75"
          viewBox="0 0 63 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.2092 70.2595H12.518C9.62774 70.2595 7.29688 67.9225 7.29688 65.0384V21.0258C7.29688 18.1418 9.62774 15.8047 12.518 15.8047H46.4986C49.3827 15.8047 51.7197 18.1418 51.7197 21.0258V49.86"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.30284 61.7382H6.89883C4.01479 61.7382 1.67773 59.4011 1.67773 56.517V18.1171C1.67773 15.2331 4.01479 12.896 6.89883 12.896H10.6158"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.1544 15.7986H11.0019L9.17446 2.16153C9.09988 1.61456 9.52875 1.12354 10.0819 1.12354H39.3947C39.8546 1.12354 40.24 1.45917 40.3022 1.91291L42.1606 15.7923L42.1544 15.7986Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6426 6.93506H35.3965"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9551 11.0376H36.7089"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.7294 73.784C56.3821 73.784 61.7752 68.3909 61.7752 61.7382C61.7752 55.0855 56.3821 49.6924 49.7294 49.6924C43.0767 49.6924 37.6836 55.0855 37.6836 61.7382C37.6836 68.3909 43.0767 73.784 49.7294 73.784Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.7891 62.8444L47.8168 66.9903L56.1022 57.729"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.1538 52.3837V44.8567C42.1538 43.4146 40.979 42.2461 39.5432 42.2461H21.8723C20.4303 42.2461 19.2617 43.4146 19.2617 44.8567V60.3583C19.2617 61.8003 20.4303 62.9689 21.8723 62.9689H37.7531"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.3457 35.9247V31.2443C25.3457 28.6586 27.7449 26.564 30.7098 26.564C33.6746 26.564 36.0738 28.6586 36.0738 31.2443V42.2335"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="ct1">Privacy and Consent Management</p>
      </div>

      <div className="cg3 group-hover">
        <svg
          width="77"
          height="81"
          viewBox="0 0 77 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M65.7633 65.8123C65.7633 67.4542 64.4318 68.7917 62.7898 68.7917C61.1479 68.7917 59.8164 67.4542 59.8164 65.8123C59.8164 64.1703 61.1479 62.8389 62.7898 62.8389C64.4318 62.8389 65.7633 64.1703 65.7633 65.8123Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.1585 67.7049C55.0093 67.0959 54.9316 66.463 54.9316 65.8122C54.9316 65.1613 55.0152 64.5285 55.1585 63.9194"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M68.2407 60.152C67.3332 59.2743 66.2107 58.6175 64.9688 58.2593"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M68.2407 71.4727C67.3332 72.3504 66.2107 73.0072 64.9688 73.3654"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.4238 63.9258C70.5731 64.5288 70.6507 65.1677 70.6507 65.8185C70.6507 66.4693 70.5671 67.1022 70.4238 67.7113"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.6138 73.3654C59.3659 73.0131 58.2494 72.3504 57.3418 71.4727"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.6138 58.2593C59.3659 58.6116 58.2494 59.2743 57.3418 60.152"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.6152 58.2595V54.9756H64.9679V58.2595"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.1565 63.9257L52.3145 62.2778L54.4878 58.5103L57.3359 60.1522"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.1565 67.7051L52.3145 69.347L54.4878 73.1205L57.3359 71.4726"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M64.9679 73.3652V76.6551H60.6152V73.3652"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.4236 67.7051L73.2657 69.347L71.0863 73.1205L68.2383 71.4726"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.4236 63.9257L73.2657 62.2778L71.0863 58.5103L68.2383 60.1522"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M73.7251 52.9396C76.5553 43.0818 75.1521 32.2629 69.5098 23.3545"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.332 66.9468C11.8395 67.5259 12.353 68.0812 12.9023 68.6305C22.8556 78.5838 37.1257 81.5751 49.6762 77.6225"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.4277 8.50523C39.2768 3.93163 54.1798 6.78565 64.4674 17.0673C65.0167 17.6166 65.5362 18.1778 66.0496 18.757"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.8701 62.3434C3.51146 55.4591 1.67844 47.4344 2.37701 39.5889"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M58.2988 19.8854L66.0309 18.8644L67.2848 10.8994"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.0836 65.812L11.3455 66.833L10.0977 74.804"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.5449 42.6636C43.4196 42.6636 46.5606 39.5226 46.5606 35.648C46.5606 31.7733 43.4196 28.6323 39.5449 28.6323C35.6703 28.6323 32.5293 31.7733 32.5293 35.648C32.5293 39.5226 35.6703 42.6636 39.5449 42.6636Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.6749 54.9751C49.6749 49.3805 45.1371 44.8428 39.5425 44.8428C33.9479 44.8428 29.4102 49.3805 29.4102 54.9751H49.6809H49.6749Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.723 1.90137H5.3125V29.3907H20.723V1.90137Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.4554 29.3907V33.63H1.04492V6.14062H5.31401"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.69531 8.50488H16.896"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.69531 12.9116H16.896"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.69531 17.312H16.896"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="ct1">Self-Service Account Management</p>
      </div>

      <div className="cg4 group-hover">
        <svg
          width="83"
          height="84"
          viewBox="0 0 83 84"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M71.7833 43.218C75.6386 43.218 78.7639 39.9482 78.7639 35.9147C78.7639 31.8811 75.6386 28.6113 71.7833 28.6113C67.9281 28.6113 64.8027 31.8811 64.8027 35.9147C64.8027 39.9482 67.9281 43.218 71.7833 43.218Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M81.8647 56.041C81.8647 50.217 77.3496 45.4932 71.7829 45.4932C66.2163 45.4932 61.7012 50.217 61.7012 56.041H81.8706H81.8647Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.9365 82.9982H21.2793C22.8002 82.9982 24.0359 81.7115 24.0359 80.1141C24.0359 78.5167 22.8002 77.2363 21.2793 77.2363C20.6793 77.2363 20.1268 77.4352 19.6812 77.7771C19.699 77.6652 19.7109 77.5533 19.7109 77.4352C19.7109 76.3164 18.8495 75.4151 17.7801 75.4151C17.388 75.4151 17.0197 75.5394 16.7107 75.7508C16.1701 73.1154 13.9304 71.145 11.257 71.145C8.39937 71.145 6.04676 73.3889 5.71407 76.2853C5.41703 76.1921 5.10216 76.1424 4.78135 76.1424C2.96936 76.1424 1.50195 77.6776 1.50195 79.5734C1.50195 81.4691 2.96936 82.9982 4.78135 82.9982H16.9424H16.9365Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.0952 1.66064H9.18359V21.2273H21.0952V1.66064Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1417 39.2152C18.0914 39.2152 20.4826 36.7134 20.4826 33.6274C20.4826 30.5413 18.0914 28.0396 15.1417 28.0396C12.192 28.0396 9.80078 30.5413 9.80078 33.6274C9.80078 36.7134 12.192 39.2152 15.1417 39.2152Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6669 49.7261H10.9062V61.6414H14.6669V49.7261Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.5047 53.8096H17.7441V61.635H21.5047V53.8096Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.83483 57.7065H4.07422V61.641H7.83483V57.7065Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.20508 61.6411H24.3272"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6348 17.5293H33.3162C36.358 17.5293 38.8175 20.1088 38.8175 23.2849V40.4462"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6348 32.8257H33.3162C36.358 32.8257 38.8175 35.4052 38.8175 38.5813"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.1523 46.649H44.3316C41.2839 46.649 38.8125 44.0634 38.8125 40.8748V37.7607"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6348 75.8749H33.3162C36.358 75.8749 38.8175 73.2954 38.8175 70.1193V52.958"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6348 61.2H33.3162C36.358 61.2 38.8175 58.6205 38.8175 55.4443"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.1523 46.7549H44.3316C41.2839 46.7549 38.8125 49.3406 38.8125 52.5292V55.6432"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="ct1">Unified Customer Profile</p>
      </div>

      <div className="cg5 group-hover">
        <svg
          width="49"
          height="66"
          viewBox="0 0 49 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M47.4433 37.028V50.8857C47.4433 58.2641 41.1653 64.2473 33.4234 64.2473H27.2191C19.4723 64.2473 13.1992 58.2641 13.1992 50.8857V20.7288C13.1992 19.2649 14.4371 18.0859 15.9698 18.0859H16.8049C18.3425 18.0859 19.5853 19.2649 19.5853 20.7288V39.2238C19.5853 40.1965 20.3762 40.9873 21.3488 40.9873C22.0955 40.9873 22.6997 40.3831 22.6997 39.6364V37.0329C22.6997 35.569 23.9425 34.3802 25.4801 34.3802H26.3152C27.843 34.3802 29.0858 35.5641 29.0858 37.0329V39.6364C29.0858 40.3831 29.69 40.9873 30.4367 40.9873H30.5055C31.2521 40.9873 31.8564 40.3831 31.8564 39.6364V37.0329C31.8564 35.569 33.0943 34.3802 34.6269 34.3802H35.462C36.9996 34.3802 38.2424 35.5641 38.2424 37.0329V39.6364C38.2424 40.3831 38.8466 40.9873 39.5933 40.9873C40.34 40.9873 40.9442 40.3831 40.9442 39.6364V37.0329C40.9442 35.569 42.1821 34.3802 43.7148 34.3802H44.6579C46.1857 34.3802 47.4285 35.5641 47.4285 37.0329L47.4433 37.028Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2005 45.6886V51.4901C13.2005 53.0277 11.9577 54.2607 10.4201 54.2607H9.58503C8.04746 54.2607 6.81445 53.0227 6.81445 51.4901V45.6886C6.81445 44.151 8.05237 42.9082 9.58503 42.9082H10.4201C11.9577 42.9082 13.2005 44.151 13.2005 45.6886Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.26827 20.7831C8.24159 19.388 7.62754 17.6687 7.60789 15.8069C7.55877 11.0812 11.3511 7.20536 16.0768 7.15623C20.8025 7.10711 24.6783 10.8994 24.7275 15.6251C24.7471 17.3985 24.2264 19.049 23.3176 20.4245"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.11282 27.2475C3.79207 24.6341 1.6208 20.6109 1.51764 16.0571C1.33097 7.95664 7.74652 1.23654 15.847 1.04987C23.9475 0.863201 30.6676 7.27874 30.8542 15.3792C30.9623 20.1 28.8303 24.3541 25.4261 27.1148"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="ct1">Self-Service Registration</p>
      </div>

      <div className="cg6 group-hover">
        <svg
          width="72"
          height="70"
          viewBox="0 0 72 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.8829 31.5192C36.8224 31.5192 38.3947 29.9469 38.3947 28.0074C38.3947 26.0679 36.8224 24.4956 34.8829 24.4956C32.9434 24.4956 31.3711 26.0679 31.3711 28.0074C31.3711 29.9469 32.9434 31.5192 34.8829 31.5192Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8828 41.0599C40.1521 41.0599 44.4237 36.7883 44.4237 31.519C44.4237 26.2497 40.1521 21.978 34.8828 21.978C29.6134 21.978 25.3418 26.2497 25.3418 31.519C25.3418 36.7883 29.6134 41.0599 34.8828 41.0599Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.6041 40.9048V68.7445C36.6041 68.7445 34.8824 61.2361 29.7173 57.9604C30.0529 58.0226 30.9417 55.412 31.0225 55.151C31.5819 53.2863 31.9984 51.2289 29.0957 47.1639L31.8181 40.563"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.5189 36.3981H11.1078C5.5324 36.3981 1.01367 31.8856 1.01367 26.3102C1.01367 20.7348 5.53862 16.2161 11.1078 16.2161C12.1085 16.2161 13.0781 16.3653 13.9856 16.6325C15.0112 8.10474 22.2648 1.49756 31.0661 1.49756C39.3142 1.49756 46.2072 7.30292 47.873 15.0538C48.8178 14.426 49.9491 14.0655 51.1611 14.0655C54.4429 14.0655 57.1032 16.7258 57.1032 20.0138C57.1032 20.3619 57.0721 20.6913 57.0162 21.0145C58.4085 20.0138 60.1053 19.4296 61.9451 19.4296C66.6317 19.4296 70.4294 23.2273 70.4294 27.9076C70.4294 32.588 66.6255 36.3919 61.9451 36.3919H48.5754"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="ct1">Single Sign-On</p>
      </div>

      <div id="center">
        <svg
          width="112"
          height="112"
          viewBox="0 0 112 112"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M56.5789 51.2662C62.8098 51.2662 67.861 46.2151 67.861 39.9842C67.861 33.7533 62.8098 28.7021 56.5789 28.7021C50.348 28.7021 45.2969 33.7533 45.2969 39.9842C45.2969 46.2151 50.348 51.2662 56.5789 51.2662Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.1732 54.7754H57.9846C66.2088 54.7754 72.8857 61.4523 72.8857 69.6765V82.7104H40.2773V69.6765C40.2773 61.4523 46.9543 54.7754 55.1785 54.7754H55.1732Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.5284 37.2674C69.1754 35.804 71.3521 34.9229 73.7281 34.9229C78.8944 34.9229 83.0852 39.1084 83.0852 44.28C83.0852 49.4516 78.8944 53.6371 73.7281 53.6371C69.7051 53.6371 66.2749 51.109 64.9531 47.5476"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M72.8861 79.7257H87.2522V70.0801C87.2522 66.3457 85.7364 62.9679 83.2923 60.5132C80.8428 58.0638 77.4545 56.5532 73.7253 56.5532C71.6588 56.5532 69.6972 57.0148 67.9453 57.8435"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.5845 37.4982C43.9166 35.9037 41.6455 34.9229 39.1384 34.9229C33.9668 34.9229 29.7812 39.1084 29.7812 44.28C29.7812 49.4516 33.9668 53.6371 39.1384 53.6371C43.2505 53.6371 46.7489 50.9884 48.0025 47.2959"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.1071 57.9379C43.3028 57.0515 41.2782 56.5532 39.1382 56.5532C31.6641 56.5532 25.6113 62.606 25.6113 70.0801V79.7257H40.2764"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.4309 110.32C86.5947 110.32 111.047 85.8672 111.047 55.7034C111.047 25.5395 86.5947 1.08691 56.4309 1.08691C26.2671 1.08691 1.81445 25.5395 1.81445 55.7034C1.81445 85.8672 26.2671 110.32 56.4309 110.32Z"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div className="line1">
        <svg
          width="3"
          height="202"
          viewBox="0 0 3 202"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.51172 1.33496V36.4531"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
          />
          <path
            d="M1.51172 165.427V200.545"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <div className="line3">
        <svg
          width="175"
          height="102"
          viewBox="0 0 175 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25195 1.13721L31.6651 18.6963"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
          />
          <path
            d="M143.359 83.1831L173.773 100.742"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <div className="line2">
        <svg
          width="175"
          height="102"
          viewBox="0 0 175 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25195 100.742L31.6651 83.1831"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
          />
          <path
            d="M143.359 18.6963L173.773 1.13723"
            stroke="#8889E8"
            strokeWidth="1.86468"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default CiamAnimationMobile;
