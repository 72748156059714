import React, { useEffect } from 'react';
import gsap from 'gsap';

const IgaAnimation = () => {
  useEffect(() => {
    const groups = gsap.utils.toArray("svg g[id^='plus']");
    const tl = gsap.timeline({ repeat: -1 });

    groups.forEach((group, index) => {
      const prevGroupIndex = index === 0 ? groups.length - 1 : index - 1;
      const nextGroupIndex = index === groups.length - 1 ? 0 : index + 1;
      const prevGroup = groups[prevGroupIndex];
      const nextGroup = groups[nextGroupIndex];

      tl.fromTo(
        prevGroup.children,
        { stroke: '#FFA900' },
        {
          stroke: '#8889E8',
          duration: 2,
          ease: 'power2.out',
        }
      )
        .fromTo(
          nextGroup.children,
          { stroke: '#8889E8' },
          {
            stroke: '#8889E8',
            duration: 2,
            ease: 'power2.out',
          },
          '<'
        )
        .fromTo(
          '#center-iga',
          {
            rotation: 120 * index,
          },
          {
            rotation: 120 * index + 120,
            transformOrigin: 'center',
            duration: 2,
          },
          '<'
        )

        .fromTo(
          group.children,
          { stroke: '#8889E8' },
          {
            stroke: '#FFA900',
            duration: 2,
            ease: 'power2.out',
          },
          '<'
        );
    });
  }, []);

  return (
    <svg
      className="iga-animation"
      width="381"
      height="381"
      viewBox="0 0 381 381"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_8196)">
        <g id="others">
          <path
            d="M300.3 172.34C297.66 165.81 298.53 158.3 302.28 152.38C304.72 148.55 310.87 142.08 316.02 145.11C323.28 149.39 316.57 156.15 316.23 162.2C315.9 167.92 320.23 172.31 325.97 172.14C326.01 172.14 326.05 172.14 326.09 172.14"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M295.041 135.75C296.631 132.01 302.221 129 302.221 129C314.431 121.66 330.071 125.25 337.141 137.02C339.711 141.28 341.231 146.47 341.261 151.44C341.291 155.6 339.561 161.03 336.601 165.28"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M309.791 155.06C309.791 155.06 300.651 167.94 314.701 177.28"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M323.791 163.11C330.151 159.12 331.751 151.7 328.811 145.02C325.321 137.09 318.261 132.81 309.601 135.57C301.331 138.2 294.221 145.76 291.221 154.25"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M336.921 189.21C343.532 189.21 348.891 183.851 348.891 177.24C348.891 170.629 343.532 165.27 336.921 165.27C330.31 165.27 324.951 170.629 324.951 177.24C324.951 183.851 330.31 189.21 336.921 189.21Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M331.621 177.58L334.661 180.97L344.151 173.52"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.4305 144.14C93.0973 144.14 96.8805 140.357 96.8805 135.69C96.8805 131.023 93.0973 127.24 88.4305 127.24C83.7637 127.24 79.9805 131.023 79.9805 135.69C79.9805 140.357 83.7637 144.14 88.4305 144.14Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M100.641 158.98C100.641 152.24 95.1707 146.77 88.4307 146.77C81.6907 146.77 76.2207 152.24 76.2207 158.98H100.631H100.641Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M109.761 72.96C112.92 72.96 115.481 70.3991 115.481 67.24C115.481 64.0809 112.92 61.52 109.761 61.52C106.602 61.52 104.041 64.0809 104.041 67.24C104.041 70.3991 106.602 72.96 109.761 72.96Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M118.03 83.0102C118.03 78.4402 114.33 74.7402 109.76 74.7402C105.19 74.7402 101.49 78.4402 101.49 83.0102H118.03Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M147.659 53.29H97.1294C93.4347 53.29 90.4395 56.2853 90.4395 59.98V85.48C90.4395 89.1748 93.4347 92.17 97.1294 92.17H147.659C151.354 92.17 154.349 89.1748 154.349 85.48V59.98C154.349 56.2853 151.354 53.29 147.659 53.29Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125 64.7002H136.74"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125 72.6099H136.74"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125 80.5103H144.73"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M332.549 264.97V282.24C332.549 284.55 330.269 286.43 327.459 286.43H310.289C307.479 286.43 305.199 284.55 305.199 282.24V264.97C305.199 262.66 307.479 260.78 310.289 260.78H327.459C330.269 260.78 332.549 262.66 332.549 264.97Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M311.5 260.77V245.6C311.5 243.1 314.8 241.06 318.86 241.06C320.89 241.06 322.73 241.57 324.08 242.39C325.41 243.22 326.24 244.35 326.24 245.6V253.66"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M318.87 275.63C320.869 275.63 322.49 274.009 322.49 272.01C322.49 270.011 320.869 268.39 318.87 268.39C316.871 268.39 315.25 270.011 315.25 272.01C315.25 274.009 316.871 275.63 318.87 275.63Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M320.709 275.63H317.219V280.43H320.709V275.63Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            d="M266.609 69.21H296.759"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M266.609 81.4102H296.759"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M266.609 93.6001H296.759"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M245.289 68.4301L249.619 71.9001L257.529 63.3301"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M245.289 80.63L249.619 84.09L257.529 75.52"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M245.289 92.8302L249.619 96.2902L257.529 87.7202"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M192.32 300.35V349.06C192.32 351.14 190.64 352.82 188.56 352.82H149.27C147.19 352.82 145.51 351.14 145.51 349.06V290.07C145.51 287.99 147.19 286.31 149.27 286.31H179.89"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M168.909 325.59C173.62 325.59 177.439 321.771 177.439 317.06C177.439 312.349 173.62 308.53 168.909 308.53C164.198 308.53 160.379 312.349 160.379 317.06C160.379 321.771 164.198 325.59 168.909 325.59Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M165.14 325.59L163.52 338.27L169.13 334.27L174.42 337.95L172.81 325.59"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M192.321 300.35H182.121C180.891 300.35 179.891 299.35 179.891 298.12V286.31L192.321 300.35Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.051 269.28C65.5418 269.28 67.561 267.261 67.561 264.77C67.561 262.279 65.5418 260.26 63.051 260.26C60.5602 260.26 58.541 262.279 58.541 264.77C58.541 267.261 60.5602 269.28 63.051 269.28Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.5805 277.04C74.3571 277.04 79.8505 271.547 79.8505 264.77C79.8505 257.993 74.3571 252.5 67.5805 252.5C60.804 252.5 55.3105 257.993 55.3105 264.77C55.3105 271.547 60.804 277.04 67.5805 277.04Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M79.6412 262.55H115.441C115.441 262.55 105.791 264.76 101.571 271.4C101.651 270.96 98.2912 269.82 97.9612 269.72C95.5612 269 92.9212 268.47 87.6912 272.2L79.2012 268.7"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.059 220.58C137.449 244.49 161.849 261.02 190.099 261.02C194.109 261.02 198.049 260.68 201.879 260.04"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.59961 241.52C30.9796 320.69 103.77 378.71 190.1 378.71C211.44 378.71 231.94 375.17 251.07 368.63"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.06 220.58L8.59961 241.52"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M251.069 368.63L201.879 260.04"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M228.751 249.57C248.171 236.92 261.021 215.01 261.021 190.1C261.021 159.06 241.081 132.68 213.311 123.07L221.461 4.08984C310.701 19.0198 378.721 96.6199 378.721 190.1C378.721 262.4 338.041 325.19 278.331 356.85"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M221.451 4.08984L213.301 123.07"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M278.32 356.84L228.75 249.57"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.4 208.62C1.8 202.53 1.5 196.35 1.5 190.1C1.5 85.94 85.94 1.5 190.1 1.5C190.36 1.5 190.62 1.5 190.88 1.5L184.6 119.38C148.01 122.19 119.18 152.77 119.18 190.09"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M190.88 1.50977L184.6 119.39"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M119.18 190.11L2.40039 208.62"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="plus1">
          <path
            d="M55.2491 136.01C61.8544 136.01 67.2091 130.655 67.2091 124.05C67.2091 117.445 61.8544 112.09 55.2491 112.09C48.6437 112.09 43.2891 117.445 43.2891 124.05C43.2891 130.655 48.6437 136.01 55.2491 136.01Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.2496 142.7C44.9496 142.7 36.5996 134.35 36.5996 124.05C36.5996 113.75 44.9496 105.4 55.2496 105.4"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.25 118.94V129.15"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.3604 124.05H50.1504"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="plus2">
          <path
            d="M291.13 241.77C297.735 241.77 303.09 236.415 303.09 229.81C303.09 223.205 297.735 217.85 291.13 217.85C284.525 217.85 279.17 223.205 279.17 229.81C279.17 236.415 284.525 241.77 291.13 241.77Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M291.13 248.47C280.83 248.47 272.48 240.12 272.48 229.82C272.48 219.52 280.83 211.17 291.13 211.17"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M291.131 224.71V234.92"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M296.231 229.81H286.031"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="plus3">
          <path
            d="M105.999 320.49C112.604 320.49 117.959 315.135 117.959 308.53C117.959 301.924 112.604 296.57 105.999 296.57C99.3937 296.57 94.0391 301.924 94.0391 308.53C94.0391 315.135 99.3937 320.49 105.999 320.49Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M106 327.18C95.6996 327.18 87.3496 318.83 87.3496 308.53C87.3496 298.23 95.6996 289.88 106 289.88"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M111.11 308.53H100.9"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="center-iga">
          <path
            d="M141.789 218.99L144.269 211.32C152.579 229.46 171.149 241.82 192.359 241.19C220.629 240.36 242.869 216.76 242.039 188.49C241.309 163.75 223.159 143.63 199.689 139.54"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M151.72 214.26L144.26 211.32"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M139.67 190.11C139.67 161.83 162.6 138.9 190.88 138.9"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="12 10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_8196">
          <rect width="380.2" height="380.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IgaAnimation;
