import React from 'react';
import './ImplementationAnimation.scss';

const ImplementationAnimation = () => {
  return (
    <div className="implementation-animation">
      {/* Setting 1 */}
      <div className="setting1 settings">
        <svg
          width="124"
          height="133"
          viewBox="0 0 124 133"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M57.5999 76.7796C52.4883 74.7414 49.7002 69.3437 50.7724 64.1309C50.9022 63.4998 51.0929 62.8689 51.3444 62.2384C53.634 56.4964 60.1527 53.7073 65.8948 55.997C68.7086 57.124 70.8172 59.2511 71.9569 61.8245C73.1522 64.4825 73.3013 67.6075 72.141 70.524C69.8514 76.2661 63.342 79.0692 57.5999 76.7796Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M65.7669 37.0758C68.0983 37.4091 70.4118 38.0068 72.6979 38.9156C73.9916 39.4375 75.245 40.0364 76.4207 40.7169C77.3166 41.2179 78.1655 41.7701 78.9837 42.3526"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M85.0996 84.5193C82.1369 88.3809 78.2685 91.374 73.9332 93.3207Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.319 63.4663C31.8222 68.3101 32.568 73.1538 34.3694 77.5445Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M57.7168 95.6984C55.3971 95.3675 53.0602 94.765 50.7975 93.861C48.5114 92.9523 46.4116 91.7894 44.4884 90.4192"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.5642 39.4447C45.2264 41.4032 41.3581 44.3963 38.3954 48.2579Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M89.1279 55.2208C90.9269 59.6232 91.6751 64.4552 91.1782 69.299Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M91.1782 69.299L102.663 73.8784L96.5956 89.1011L85.0996 84.5193"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M78.9837 42.3526L88.6948 34.7018L98.842 47.5582L89.1279 55.2208"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M65.7669 37.0758L63.994 24.8458L47.7822 27.2004L49.5642 39.4447"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.319 63.4663L20.8347 58.8868L21.167 58.0415L26.8994 43.6758L38.3954 48.2579"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M44.4884 90.4192L34.8002 98.0751L24.653 85.2187L34.3694 77.5445"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M73.9332 93.3207L75.7128 105.577L59.5034 107.92L57.7168 95.6984"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 2 */}
      <div className="setting2 settings">
        <svg
          width="139"
          height="149"
          viewBox="0 0 139 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M65.2476 85.9056C59.521 83.6222 56.3973 77.575 57.5986 71.735C57.744 71.0279 57.9576 70.3211 58.2394 69.6148C60.8046 63.1818 68.1076 60.0572 74.5406 62.6223C77.693 63.8849 80.0553 66.2679 81.3321 69.151C82.6713 72.1288 82.8384 75.6298 81.5384 78.8974C78.9733 85.3304 71.6806 88.4708 65.2476 85.9056Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M74.3974 41.4244C77.0092 41.7979 79.6012 42.4675 82.1624 43.4856C83.6116 44.0703 85.0159 44.7413 86.3331 45.5036C87.3368 46.0649 88.2878 46.6836 89.2045 47.3362"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M96.0563 94.5767C92.737 98.9029 88.4032 102.256 83.5462 104.437Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.9248 70.9904C36.3681 76.4171 37.2037 81.8436 39.2218 86.7626Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M65.3786 107.101C62.7798 106.73 60.1617 106.055 57.6267 105.042C55.0655 104.024 52.713 102.722 50.5584 101.186"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.245 44.0784C51.3853 46.2725 47.0515 49.6258 43.7323 53.952Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M100.569 61.7528C102.585 66.6849 103.423 72.0983 102.866 77.525Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M102.866 77.525L115.733 82.6553L108.936 99.7097L96.0563 94.5767"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M89.2045 47.3362L100.084 38.7648L111.452 53.1682L100.569 61.7528"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M74.3974 41.4244L72.4111 27.7228L54.2485 30.3608L56.245 44.0784"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.9248 70.9904L24.0586 65.8599L24.4308 64.9128L30.853 48.8186L43.7323 53.952"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.5584 101.186L39.7045 109.764L28.3363 95.3602L39.2218 86.7626"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M83.5462 104.437L85.54 118.168L67.3802 120.793L65.3786 107.101"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 3 */}
      <div className="setting3 settings">
        <svg
          width="92"
          height="92"
          viewBox="0 0 92 92"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49.6141 51.5763C46.8528 53.3339 43.2785 52.8019 41.1361 50.4715C40.8767 50.1893 40.6386 49.8779 40.4219 49.5372C38.4475 46.4353 39.369 42.3198 42.471 40.3455C43.9934 39.3797 45.7532 39.1021 47.3913 39.4472C49.0896 39.7949 50.6494 40.8098 51.6531 42.3841C53.6275 45.4861 52.716 49.602 49.6141 51.5763Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.2965 33.8262C34.2682 32.8172 35.3613 31.9088 36.5949 31.1217C37.2967 30.6791 38.0217 30.2826 38.7589 29.9519C39.3123 29.6938 39.874 29.4763 40.4396 29.2841"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.9133 40.9989C63.7461 43.7716 63.845 46.6792 63.2765 49.4482Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.3972 59.1445C36.5617 61.069 39.1582 62.3938 41.9034 63.0517Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M58.7836 58.1C57.8171 59.1042 56.7136 60.0223 55.4904 60.7997C54.2568 61.5867 52.9653 62.1955 51.6353 62.6469"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.8042 42.4684C28.2405 45.2427 28.3393 48.1502 29.1721 50.9229Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.1771 28.8649C52.9271 29.5281 55.5188 30.8476 57.6833 32.7721Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M57.6833 32.7721L63.8873 28.8233L69.1225 37.0454L62.9133 40.9989"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.4396 29.2841L40.1255 21.9372L49.8583 21.5125L50.1771 28.8649"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.2965 33.8262L26.7786 30.428L22.2762 39.0696L28.8042 42.4684"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.3972 59.1445L28.1931 63.0932L27.9008 62.6389L22.9628 54.8764L29.1721 50.9229"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.6353 62.6469L51.9598 69.9845L42.227 70.4092L41.9034 63.0517"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.2765 49.4482L69.8092 52.8522L65.302 61.4885L58.7836 58.1"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 4 */}
      <div className="setting4 settings">
        <svg
          width="68"
          height="72"
          viewBox="0 0 68 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.6904 41.4141C31.9333 41.8314 29.3322 40.1848 28.4666 37.6307C28.3618 37.3215 28.2837 36.9968 28.2323 36.6567C27.7635 33.5596 29.9001 30.6711 32.9973 30.2024C34.5163 29.9749 35.9829 30.3652 37.1401 31.1988C38.3433 32.0551 39.2085 33.3835 39.4477 34.956C39.9165 38.0531 37.7875 40.9454 34.6904 41.4141Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.0975 21.9609C29.2023 21.5099 30.367 21.179 31.5994 20.9911C32.2987 20.8882 33.0002 20.8292 33.6885 20.8257C34.2085 20.8155 34.7211 20.8397 35.228 20.8849"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.6973 37.7586C48.3903 40.2058 47.4649 42.5049 46.0675 44.4661Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.2269 42.0642C21.2496 44.3096 22.8158 46.2368 24.7275 47.6957Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.5848 49.6573C38.4857 50.1065 37.3095 50.4413 36.0886 50.6252C34.8563 50.8131 33.6403 50.8421 32.4485 50.7353"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.6185 27.1448C20.223 29.1117 19.2976 31.4109 18.9906 33.858Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.9584 23.9152C44.8721 25.3798 46.4363 27.3013 47.4591 29.5467Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.4591 29.5467L53.6535 28.6092L54.8974 36.8192L48.6973 37.7586"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.228 20.8849L37.5161 15.0532L45.2447 18.0776L42.9584 23.9152"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.0975 21.9609L24.1912 17.0667L17.7047 22.2466L21.6185 27.1448"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.2269 42.0642L14.0324 43.0017L13.9613 42.5469L12.7904 34.7973L18.9906 33.858"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.4485 50.7353L30.1718 56.5634L22.4432 53.539L24.7275 47.6957"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.0675 44.4661L49.9833 49.37L43.4947 54.5442L39.5848 49.6573"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 5 */}
      <div className="setting5 settings">
        <svg
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48.6371 50.5361C45.9672 52.2869 42.4741 51.8153 40.3537 49.5708C40.0969 49.299 39.8606 48.9984 39.6447 48.669C37.678 45.6698 38.5222 41.6437 41.5215 39.677C42.9936 38.7149 44.7063 38.4208 46.3086 38.7357C47.9696 39.0523 49.5043 40.0216 50.5041 41.5437C52.4708 44.543 51.6364 48.5694 48.6371 50.5361Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.4871 33.4401C33.4215 32.4431 34.4756 31.5426 35.6683 30.7586C36.3469 30.3176 37.0487 29.9213 37.7634 29.589C38.2997 29.33 38.8447 29.1104 39.3938 28.9154"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M61.4681 40.0436C62.3171 42.7368 62.452 45.5714 61.9342 48.2796Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.8959 58.119C36.0324 59.9674 38.5825 61.2251 41.2687 61.8304Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M57.6667 56.7774C56.7374 57.7696 55.6733 58.6796 54.4905 59.4541C53.2978 60.238 52.0462 60.8489 50.755 61.3068"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.2201 41.9285C27.707 44.6417 27.8419 47.4763 28.6909 50.1695Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.8855 28.3777C51.5764 28.988 54.1217 30.2407 56.2582 32.089Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.2582 32.089L62.2569 28.1555L67.4717 36.1054L61.4681 40.0436"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.3938 28.9154L38.9902 21.754L48.4772 21.2109L48.8855 28.3777"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.4871 33.4401L26.085 30.212L21.8081 38.7L28.2201 41.9285"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.8959 58.119L27.8972 62.0525L27.6061 61.6132L22.6871 54.1076L28.6909 50.1695"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.755 61.3068L51.1687 68.459L41.6817 69.0021L41.2687 61.8304"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M61.9342 48.2796L68.3508 51.5131L64.0692 59.996L57.6667 56.7774"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 6 */}
      <div className="setting6 settings">
        <svg
          width="103"
          height="102"
          viewBox="0 0 103 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M56.1014 56.5428C53.2778 58.9076 49.2124 58.8368 46.4814 56.5503C46.1507 56.2734 45.8395 55.9608 45.5479 55.6125C42.8915 52.4406 43.3176 47.7144 46.4895 45.058C48.0468 43.7579 49.9694 43.1907 51.8475 43.3362C53.793 43.4756 55.6816 44.3798 57.0317 45.9893C59.6881 49.1613 59.2733 53.8865 56.1014 56.5428Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.3005 39.1267C36.237 37.8589 37.3236 36.6855 38.5848 35.627C39.303 35.0305 40.0538 34.4821 40.8279 34.0054C41.4075 33.6365 42.0024 33.3117 42.6053 33.0145"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M69.3915 42.797C70.7262 45.7685 71.2617 48.9979 71.0325 52.1703Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.2317 67.212C42.928 69.0425 46.0186 70.1407 49.1775 70.4731Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.2855 62.4798C66.3541 63.7415 65.2573 64.9271 64.0064 65.9734C62.7452 67.0319 61.3934 67.8999 59.9756 68.5981"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.5527 49.4254C31.3296 52.603 31.8651 55.8324 33.1998 58.8039Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.4076 31.1226C56.5726 31.4602 59.6571 32.5533 62.3534 34.3837Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.3534 34.3837L68.6973 29.0708L75.7405 37.478L69.3915 42.797"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.6053 33.0145L41.1803 24.864L51.9765 22.9666L53.4076 31.1226"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.3005 39.1267L27.5317 36.2889L23.7726 46.5884L31.5527 49.4254"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.2317 67.212L33.8878 72.5248L33.4952 72.0607L26.8506 64.1227L33.1998 58.8039"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.9756 68.5981L61.4109 76.7368L50.6147 78.6342L49.1775 70.4731"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M71.0325 52.1703L78.8186 55.0124L75.0534 65.3067L67.2855 62.4798"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 7 */}
      <div className="setting7 settings">
        <svg
          width="157"
          height="167"
          viewBox="0 0 157 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M78.7601 97.0653C71.9037 97.3864 65.9537 92.6939 64.4833 86.2206C64.3053 85.4369 64.1965 84.6221 64.1571 83.7763C63.7965 76.0742 69.7617 69.5451 77.4639 69.1846C81.2407 69.0135 84.7324 70.3419 87.3538 72.677C90.0821 75.0793 91.8629 78.5516 92.0491 82.4623C92.4096 90.1645 86.4622 96.7048 78.7601 97.0653Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.5616 47.7611C70.3813 46.9375 73.317 46.4233 76.3821 46.2765C78.1204 46.2022 79.8528 46.2361 81.539 46.4025C82.8149 46.5096 84.0637 46.6991 85.2933 46.9387"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M113.983 91.6749C112.61 97.5883 109.76 102.982 105.84 107.429Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.1826 94.9816C45.1161 100.739 48.4609 105.856 52.7709 109.913Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.6487 118.492C85.8436 119.312 82.8788 119.833 79.8428 119.973C76.7777 120.12 73.7932 119.882 70.9025 119.318"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.3812 58.8069C46.4647 63.268 43.6147 68.6621 42.2411 74.5756Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M103.45 56.3224C107.763 60.3947 111.105 65.4966 113.038 71.2542Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M113.038 71.2542L128.443 70.5329L129.402 90.9504L113.983 91.6749"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M85.2933 46.9387L92.3773 33.2417L110.531 42.6106L103.45 56.3224"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.5616 47.7611L59.2413 34.7854L42.0433 45.8196L50.3812 58.8069"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.1826 94.9816L27.7781 95.7027L27.7195 94.5713L26.822 75.2998L42.2411 74.5756"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.9025 119.318L63.8472 133.008L45.6931 123.64L52.7709 109.913"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M105.84 107.429L114.181 120.431L96.9799 131.45L88.6487 118.492"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Setting 8 */}
      <div className="setting8 settings">
        <svg
          width="106"
          height="113"
          viewBox="0 0 106 113"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M53.7542 65.5379C49.1476 65.7536 45.15 62.6008 44.1621 58.2516C44.0425 57.725 43.9694 57.1776 43.9429 56.6093C43.7006 51.4345 47.7085 47.0478 52.8834 46.8055C55.4209 46.6906 57.7669 47.5831 59.5282 49.152C61.3612 50.766 62.5577 53.0989 62.6828 55.7265C62.9251 60.9014 58.9291 65.2956 53.7542 65.5379Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.2303 32.4116C48.1247 31.8582 50.0972 31.5128 52.1565 31.4142C53.3245 31.3642 54.4884 31.387 55.6213 31.4988C56.4786 31.5707 57.3176 31.698 58.1438 31.859"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M77.4199 61.9162C76.4971 65.8893 74.5823 69.5134 71.9486 72.501Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.8506 64.1379C31.1497 68.0062 33.397 71.4439 36.2928 74.1702Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.3982 79.9338C58.5135 80.4849 56.5215 80.8349 54.4817 80.929C52.4224 81.0276 50.4172 80.8678 48.4749 80.4886"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.6872 39.833C32.0558 42.8303 30.141 46.4545 29.2181 50.4276Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.3429 38.1638C73.2409 40.8998 75.486 44.3277 76.7851 48.196Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M76.7851 48.196L87.135 47.7114L87.7796 61.4294L77.4199 61.9162"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M58.1438 31.859L62.9033 22.6564L75.1006 28.9511L70.3429 38.1638"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.2303 32.4116L40.6401 23.6936L29.0852 31.1071L34.6872 39.833"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.8506 64.1379L19.5007 64.6224L19.4614 63.8622L18.8584 50.9141L29.2181 50.4276"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.4749 80.4886L43.7347 89.6872L31.5374 83.3925L36.2928 74.1702"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M71.9486 72.501L77.5528 81.2365L65.9957 88.6403L60.3982 79.9338"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default ImplementationAnimation;
