import React from 'react';
import PropTypes from 'prop-types';
import './ServiceSubComponentCard.scss';

const ServiceSubComponentCard = ({ title, paragraphText, ImageComponent }) => {
  return (
    <div
      className="subdata-card-box"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div>
        <h4>{title}</h4>
        <span>{paragraphText}</span>
      </div>
      {/* Image component */}
      {ImageComponent}
    </div>
  );
};

ServiceSubComponentCard.propTypes = {
  title: PropTypes.array.isRequired,
  paragraphText: PropTypes.array.isRequired,
  ImageComponent: PropTypes.array.isRequired,
};

export default ServiceSubComponentCard;
