export const opsupportFocusData = [
  {
    title: 'Continuous Monitoring',
    contentPart:
      'Vigilant observation of security systems for potential threats.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Immediate Issue Resolution',
    contentPart: 'Swift response and solution to security issues.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Proactive Maintenance',
    contentPart: 'Regular updates and system optimization.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Round-the-Clock Support',
    contentPart: '24/7 assistance from our dedicated team.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Security Health Checks',
    contentPart: 'Regular assessments to maintain system health.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Expert Consultation',
    contentPart: 'Access to seasoned professionals for security advice.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
