import React, { useEffect, useState } from 'react';
// import "./style.scss";
import gsap from 'gsap';

const ResourcesAnimation = () => {
  const [restartAnimation] = useState(false);

  useEffect(() => {
    // Initially set
    const groups = gsap.utils.toArray("svg g[id^='group']");

    const glowGroups = gsap.utils.toArray("path[id^='glow']");

    // Make one timeline object
    const timeline = gsap.timeline({ repeat: -1 });

    groups.forEach((group) => {
      timeline.add(() => {
        gsap.to(group.children, {
          strokeWidth: 3,
          stroke: '#8889E8',
          opacity: 0,
          duration: 0.005,
        });
      }, '+=0.0005');
    });

    groups.forEach((group, index) => {
      timeline.add(() => {
        gsap.to(group.children, {
          opacity: 1,
          strokeWidth: 3,
          duration: 0.5,
          ease: 'linear',
        });
      }, index * 0.5);
    });

    timeline.add(() => {
      gsap.to(glowGroups, {
        stroke: '#FFA900',
        duration: 1,
        ease: 'linear',
      });
    }, '+=0.5');

    timeline.add(() => {
      gsap.to(groups, {
        opacity: 0,
        duration: 0.005,
        ease: 'linear',
      });
    }, '+=2');

    timeline.add(() => {
      gsap.to(groups, {
        opacity: 1,
        duration: 0.005,
        ease: 'linear',
      });
    }, '+=0.005');

    timeline.add(() => {
      gsap.to(glowGroups, {
        stroke: '#8889E8',
        duration: 0.001,
        ease: 'linear',
      });
    }, '<');
  }, [restartAnimation]);

  return (
    <>
      <svg
        className="resources-animation-2"
        width="262"
        height="182"
        viewBox="0 0 262 182"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* First */}
        <g id="group1">
          <path
            d="M11.42 125.65H5.96C3.5 125.65 1.5 123.43 1.5 120.7V72.6302C1.5 69.8902 3.49 67.6802 5.96 67.6802H49.22C51.68 67.6802 53.68 69.9002 53.68 72.6302V78.7002"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.5902 78.6904H15.4102C13.201 78.6904 11.4102 80.4813 11.4102 82.6904V132.67C11.4102 134.88 13.201 136.67 15.4102 136.67H59.5902C61.7993 136.67 63.5902 134.88 63.5902 132.67V82.6904C63.5902 80.4813 61.7993 78.6904 59.5902 78.6904Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.75 107.13H54.38"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.75 115.78H54.38"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.75 124.43H54.38"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            id="glow1"
            d="M34.1998 97.1904L25.8698 89.7604L17.5498 97.1904V78.6904H34.1998V97.1904Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group2">
          <path
            d="M27.1895 57.9798C35.9295 47.1098 52.0094 40.9298 66.1694 44.9898"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group3">
          <path
            d="M124.32 46.79V5.69C124.32 3.38 122.43 1.5 120.13 1.5H76.5201C74.2101 1.5 72.3301 3.37 72.3301 5.69V54.02C72.3301 54.14 72.3301 54.26 72.3501 54.38C72.4101 55.24 72.7501 56.02 73.2601 56.65C74.0201 57.6 75.2001 58.21 76.5201 58.21H107.38"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M82.6895 1.5V58.2"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M97.4004 15.5098H108.29"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M92.5195 24.2202H113.17"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M110.38 63.9401H76.1998L73.2698 56.6501L72.3598 54.3901V54.3701L72.3398 54.3301"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="glow2"
            d="M118.309 67.26C124.451 67.26 129.429 62.2814 129.429 56.14C129.429 49.9986 124.451 45.02 118.309 45.02C112.168 45.02 107.189 49.9986 107.189 56.14C107.189 62.2814 112.168 67.26 118.309 67.26Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            id="glow3"
            d="M113.6 57.1299L118.16 61.6899L123.28 57.1299"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="glow4"
            d="M118.31 51.3901L118.16 61.6901"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group4">
          <path
            d="M104.25 73.0898C109.29 76.6898 114.69 84.8498 114.28 90.7798"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group5">
          <path
            d="M118.061 112.82H137.251"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M118.061 121.07H137.251"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M157.131 109.65V147.44C157.131 149.61 155.451 151.38 153.391 151.38H120.881L111.701 157.43C110.291 158.36 108.471 157.29 108.471 155.54V103.88C108.471 101.71 110.131 99.9399 112.191 99.9399H152.891"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            id="glow5"
            d="M134.57 131.77L135.03 138.8L141.53 135.91L164.06 98.0101L157.1 93.8701L134.57 131.77Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group6">
          <path
            d="M165.34 131.84C190.32 138.37 204.22 112.98 204.32 92.1699"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group7">
          <path
            d="M218.281 22.9902H170.841C167.234 22.9902 164.311 25.9138 164.311 29.5202V66.8802C164.311 70.4867 167.234 73.4102 170.841 73.4102H218.281C221.887 73.4102 224.811 70.4867 224.811 66.8802V29.5202C224.811 25.9138 221.887 22.9902 218.281 22.9902Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M182.25 73.54L176.42 83.49H212.7L206.51 73.54"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M165.44 66.8003H223.68"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            id="glow6"
            d="M194.56 59.44C202.275 59.44 208.53 53.1854 208.53 45.47C208.53 37.7546 202.275 31.5 194.56 31.5C186.844 31.5 180.59 37.7546 180.59 45.47C180.59 53.1854 186.844 59.44 194.56 59.44Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="glow7"
            d="M190.18 38.8501V52.2601L202.3 45.4301L190.18 38.8501Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export default ResourcesAnimation;
