import React from 'react';
import './anim.scss';

const ServiceHeroAnimation = () => {
  return (
    <div className="services-animation">
      {/* Status */}
      <div className="status-position">
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 31.8699C25.4363 31.8699 31.87 25.4362 31.87 17.4999C31.87 9.56355 25.4363 3.12988 17.5 3.12988C9.56367 3.12988 3.13 9.56355 3.13 17.4999C3.13 25.4362 9.56367 31.8699 17.5 31.8699Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="status-animation"
          />
          <path
            d="M10.5597 17.6701L14.7997 22.6601L24.4397 12.3501"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="status-animation"
          />
        </svg>
      </div>

      {/* Virtual IAM */}
      <div className="virtualiam-position">
        <svg
          width="143"
          height="123"
          viewBox="0 0 143 123"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M84.7998 120.72H44.5098V106.23C44.5098 89.1398 58.3698 75.2798 75.4598 75.2798C81.0398 75.2798 86.2698 76.7598 90.7798 79.3298C93.1898 80.7098 95.3898 82.3998 97.3398 84.3498C102.43 89.4398 105.76 96.3098 106.32 103.94"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.3501 23.6402C53.3501 23.6402 53.3501 23.5402 53.3801 23.3602C53.6401 20.8502 56.3201 2.16016 76.9301 2.16016C90.7601 2.16016 95.9401 12.0202 97.8801 19.4002C99.0301 23.8102 99.0301 27.3302 99.0301 27.3302L99.0501 32.7302L113.03 37.8902L100.51 43.7802C100.51 43.7802 100.78 55.1702 92.6501 62.0702C88.5301 65.5702 82.2701 67.9102 72.7301 67.0602C65.5101 66.4202 60.4601 63.1502 57.3501 57.5202C54.6901 52.7302 53.4301 46.2402 53.4301 38.1702"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M90.7798 79.3302C94.8598 76.1102 100.02 74.1802 105.63 74.1802C112.25 74.1802 118.25 76.8702 122.6 81.2102C122.87 81.4802 123.13 81.7502 123.38 82.0302C127.26 86.2902 129.63 91.9602 129.63 98.1802V109.42H111.17"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M97.8799 19.4002C100.22 18.2202 103.13 17.4702 106.77 17.4702C123.91 17.4702 123.91 36.9802 123.91 36.9802L123.93 41.1702L134.77 45.1702L126.3 49.1502L125.05 49.7402C125.05 49.7402 125.39 63.6802 112.71 67.1502C110.2 67.8502 107.16 68.1302 103.51 67.8002C98.6699 67.3702 95.0799 65.4202 92.6499 62.0702"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.27 96.9998V102.59H129.63V98.1798C129.63 91.9598 127.26 86.2898 123.38 82.0298C127.48 82.0498 131.2 83.7298 133.89 86.4198C136.59 89.1298 138.27 92.8698 138.27 96.9998Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.3 49.1499C134.71 50.6299 134.71 60.3399 134.71 60.3399V62.7799L141.48 65.1099L135.42 67.7799C135.42 67.7799 135.72 79.4599 121.98 78.3199C116.07 77.8199 113.15 73.9199 112.71 67.1499"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.78 81.5602C56.68 81.4402 56.56 81.3202 56.45 81.2102C52.11 76.8702 46.11 74.1802 39.48 74.1802C32.1 74.1802 25.49 77.5102 21.09 82.7602C17.59 86.9302 15.48 92.3102 15.48 98.1802V109.42H44.51"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.3999 45.3901C22.3999 47.1101 22.4699 48.7301 22.6199 50.2601C23.4199 58.4101 26.3499 63.9101 31.7599 66.3801C33.3999 67.1401 35.2699 67.6101 37.3699 67.8001C49.9199 68.9201 55.1599 62.9001 57.3499 57.5201"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.3398 34.1202C22.3398 34.1202 23.4798 17.4702 40.6298 17.4702C46.8798 17.4702 50.8498 20.0602 53.3798 23.3602"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.48 102.59H1.5V96.9998C1.5 88.7398 8.2 82.0298 16.46 82.0298C18.08 82.0298 19.63 82.2898 21.09 82.7598"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.77002 58.6702C5.77002 58.6702 6.49002 48.9502 17.18 48.9502C19.44 48.9502 21.22 49.4602 22.62 50.2602"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.81006 65.2402C5.81006 73.1802 8.69006 77.7802 15.1401 78.3202C28.8801 79.4602 28.5801 67.7802 28.5801 67.7802"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Speed line */}
      <div className="speedline-position">
        <svg
          width="22"
          height="13"
          viewBox="0 0 22 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.7002 1.58984H19.8802"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.7002 10.9302H9.88019"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Monitor */}
      <div className="monitor-position">
        <svg
          width="256"
          height="206"
          viewBox="0 0 256 206"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.74023 52.2001V8.26014C1.74023 4.60014 4.70023 1.64014 8.35023 1.64014H212.84"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M254.39 53.0698V174.1C254.39 177.76 251.43 180.72 247.77 180.72H53.0898"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M76.3299 182.04L54.9199 203.83H188.1L165.37 182.04"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Shield */}
      <div className="shield-position">
        <svg
          width="103"
          height="115"
          viewBox="0 0 103 115"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M95.21 72.8899C88.8 89.9399 76.0501 105.74 51.6601 112.53C-12.9899 94.5499 4.18004 13.2099 4.18004 13.2099C4.18004 13.2099 36.9 31.8299 50.46 2.25986C50.9 1.30986 52.43 1.30986 52.87 2.25986C66.43 31.8299 99.15 13.2099 99.15 13.2099C99.15 13.2099 101.46 24.1499 101.2 38.8899"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Pulse */}
      <div className="pulse-position">
        <svg
          width="169"
          height="87"
          viewBox="0 0 169 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="static-pulse"
        >
          <path
            d="M2 40.36H21.43L28.16 53.65L30.82 25.09L39.24 40.36H101.6L112.51 85.26L127.04 2L133.69 40.36H166.94"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="pulse-position">
        <svg
          width="169"
          height="87"
          viewBox="0 0 169 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animated-pulse"
        >
          <path
            d="M2 40.36H21.43L28.16 53.65L30.82 25.09L39.24 40.36H101.6L112.51 85.26L127.04 2L133.69 40.36H166.94"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Status 3 */}
      <div className="statusthree-position">
        <svg
          className="status-three-anim"
          width="108"
          height="20"
          viewBox="0 0 108 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2H34.45L27.86 17.97H2V2Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="sp-1"
          />
          <path
            d="M44.7001 2H73.9201L67.3301 17.97H38.1001L44.7001 2Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="sp-2"
          />
          <path
            d="M105.84 2V17.97H77.5703L84.1703 2H105.84Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="sp-3"
          />
        </svg>
      </div>

      {/* Gear anim */}
      <div className="gear-position">
        <svg
          width="73"
          height="74"
          viewBox="0 0 73 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="continuous-gear-anim"
        >
          <path
            d="M45.7348 38.6501C44.9248 43.1901 40.9649 46.3901 36.5049 46.3901C35.9649 46.3901 35.4148 46.3401 34.8548 46.2401C29.7548 45.3301 26.3649 40.4501 27.2749 35.3501C27.7249 32.8501 29.1149 30.7601 31.0349 29.3901C33.0149 27.9601 35.5549 27.3101 38.1449 27.7701C43.2449 28.6801 46.6448 33.5501 45.7348 38.6501Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.7649 38.6497C11.6449 36.6797 11.7449 34.6797 12.1049 32.6497C12.3149 31.4997 12.5949 30.3697 12.9549 29.2897C13.2149 28.4697 13.5249 27.6797 13.8649 26.9097"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.4451 14.77C51.1151 16.55 54.2251 19.22 56.5551 22.45Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.075 61.6498C43.135 61.2398 46.985 59.8098 50.285 57.5898Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M61.2448 35.3599C61.3648 37.3199 61.2648 39.3399 60.9048 41.3499C60.5448 43.3799 59.9448 45.2999 59.1448 47.1099"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.4451 51.5498C18.7851 54.7798 21.8951 57.4498 25.5651 59.2298Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.7151 16.4101C26.0251 14.1901 29.8651 12.7601 33.9251 12.3501Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9251 12.3501L35.7451 2.1499L49.265 4.5599L47.4451 14.77"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.8649 26.9097L5.94507 20.23L14.7851 9.72998L22.7151 16.4101"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.7649 38.6497L2.02515 42.1699L6.69513 55.0799L16.4451 51.5498"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.075 61.6498L37.2549 71.85L36.5049 71.72L23.7449 69.44L25.5651 59.2298"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.1448 47.1099L67.0649 53.7699L58.2249 64.2699L50.285 57.5898"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.5551 22.45L66.3149 18.9199L70.9749 31.8299L61.2448 35.3599"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Clock */}
      <div className="clock-position">
        <svg
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_8894)">
            <path
              d="M45.5801 26.6602H42.6401"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5601 26.6602H7.62012"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6001 7.68018V10.6202"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6001 45.6402V42.7002"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6 51.7C40.4623 51.7 51.7 40.4623 51.7 26.6C51.7 12.7377 40.4623 1.5 26.6 1.5C12.7377 1.5 1.5 12.7377 1.5 26.6C1.5 40.4623 12.7377 51.7 26.6 51.7Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6001 26.6001V42.7001"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="clock-hand-anim"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_8894">
              <rect width="53.2" height="53.2" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default ServiceHeroAnimation;
