import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ReadyToUseSection.scss';

const ReadyToUseSection = ({ titleText, bodyText, linkSrc, btnText }) => {
  return (
    <div className="readytouse-section">
      <div className="container">
        <div>
          <h1> {titleText}</h1>
          <span>{bodyText}</span>
        </div>
        <div>
          <Link to={linkSrc}>
            <button>
              {btnText}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8.4043 1.65436L14.7499 8.00001L8.4043 14.3457"
                  stroke="#4E4FDC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.7499 8L1.25 8"
                  stroke="#4E4FDC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

ReadyToUseSection.propTypes = {
  titleText: PropTypes.array.isRequired,
  bodyText: PropTypes.array.isRequired,
  linkSrc: PropTypes.array.isRequired,
  btnText: PropTypes.array.isRequired,
};

export default ReadyToUseSection;
