import React from 'react';
import './PAM.scss';

import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import ImportanceSection from '../../components/sections/ImportanceSection/ImportanceSection.jsx';
import PAMAnimation from '../../components/animations/PAMAnimation/PAMAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { focusData } from '../../assets/data/pamFocusData.js';
import { enterpriseData } from '../../assets/data/pamEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';

import vendor2 from '../../assets/images/pingIdentity2.png';
import vendor3 from '../../assets/images/ForgeRock2.png';
import vendor4 from '../../assets/images/Okta4.png';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const PAM = () => {
  useMoveToTopFeature();

  return (
    <div className="pam-page">
      <div className="pam-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="PRIVILEGED ACCESS MANAGEMENT (PAM)"
          mainText="Discover, Control, and Monitor"
          paragarphText="Enforce least privilege access through policy-based centrally managed elevated credentials"
          AnimationComponent={<PAMAnimation></PAMAnimation>}
        />
      </div>

      {/* Importance Section */}
      <ImportanceSection
        title="IMPORTANCE OF PAM"
        paragraphText="As businesses integrate new technologies and cloud-based solutions, their attack surface expands due to emerging trends like hybrid cloud, mobility, big data, and IoT. Moreover, insider threats, which account for nearly half of all security breaches, pose a significant risk, particularly when linked to employees with unnecessary access privileges. In this context, Privileged Access Management (PAM) assumes a critical role in mitigating the risks associated with privileged access and insider threats, addressing the evolving complexities of modern IT landscapes, thereby becoming an indispensable element in fortifying organizational security"
      ></ImportanceSection>

      {/* Approach Section */}
      <div className="darkapproach">
        <ApproachSection paragraphText="ComplySimpli employs a proactive approach to Privileged Access Management (PAM), focusing on least privilege access and just-in-time access policies. Our strategy emphasizes comprehensive monitoring, rigorous control, and the implementation of modern, policy-based methods, ensuring a secure and efficient access environment while reducing potential risks" />
      </div>

      {/* OUR FOCUS */}
      <div className="focus-bg-remove">
        <OurFocusSection data={focusData}></OurFocusSection>
      </div>

      {/* ENTERPRISE GAINS */}
      <div className="bg-add">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <EnterpriseGainSection data={enterpriseData}></EnterpriseGainSection>
      </div>

      {/* OUR VENDORS */}
      <div className="ourvendor-section col2vendor">
        <div className="container">
          <h1>OUR VENDORS</h1>
          <div className="vendor-img">
            <img src={vendor2} alt="" />
            <img src={vendor3} alt="" />
            <img src={vendor4} alt="" />
          </div>
        </div>
      </div>

      {/* HarnessIAMMastery */}
      {/* <HarnessIAMMastery></HarnessIAMMastery> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Harness Decades of IAM Mastery"
          bodyText="Our cybersecurity solutions are expertly crafted to empower your organization's digital transformation. With decades of expertise and a relentless commitment to excellence, we provide practical, tailor-made solutions that optimize your security posture and ROI. Experience the ComplySimpli difference today."
          linkSrc="/contact?focus=contact"
          btnText="Connect With Us"
        />
      </div>
    </div>
  );
};
