export const virtualIamEnterpriseData = [
  {
    title: 'Integrated',
    contentPart: 'Praveen to provide the content for this piece.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Proactive Security',
    contentPart:
      'The Virtual IAM Architect takes a proactive approach to security, addressing potential threats before they become critical issues. This results in reduced risks and more robust security.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Cost-Efficiency',
    contentPart:
      'By preventing security incidents and minimizing their impact, the Virtual IAM Architect helps you avoid costly data breaches and downtime.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Peace of Mind',
    contentPart:
      'Knowing that an experienced professional is continually monitoring and safeguarding your security infrastructure provides a peace of mind. You can focus on your core business operations, confident that your digital assets are protected.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Adaptive Security',
    contentPart:
      'With continuous monitoring and threat detection, your security systems can adapt and evolve to meet new challenges. This flexibility ensures your security remains effective in the face of emerging threats.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Compliance',
    contentPart:
      'The Virtual IAM Architect helps you maintain compliance with industry regulations and standards, reducing the risk of regulatory fines and penalties.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
