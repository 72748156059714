export const virtualIamFocusData = [
  {
    title: 'Continuous Monitoring',
    contentPart:
      "The Virtual IAM Architect keeps a watchful eye on your organization's identity and access management (IAM) systems 24/7. They utilize advanced tools and technologies to monitor the health and performance of your security infrastructure.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Threat Detection',
    contentPart:
      'In the ever-evolving threat landscape, the Virtual IAM Architect is on the front lines, identifying potential threats and vulnerabilities. They proactively detect and respond to emerging risks, safeguarding your data and systems.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Incident Response',
    contentPart:
      'Should a security incident occur, the Virtual IAM Architect springs into action, orchestrating an efficient and effective response. They minimize the impact of security breaches and work to restore normal operations promptly.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Health Assessment',
    contentPart:
      'Regular health assessments of your IAM systems are conducted to identify areas of improvement. The Virtual IAM Architect provides valuable insights into the strengths and weaknesses of your security infrastructure.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
