import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Accordian = ({
  title,
  content,
  ImageComponent,
  linkSrc,
  isAccordianOpen,
  toggleAccordion,
  onClickAccordian,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <div
          // className="accordian-item openitem"
          className={`accordian-item ${isAccordianOpen ? 'openitem' : ''}`}
          onClick={onClickAccordian}
        >
          <div className="accordian-head">
            <div className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M1.33325 1.08333L7.16658 6.91666L12.9999 1.08333"
                  stroke="white"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3>{title}</h3>
          </div>
          <div className="accordian-body">
            <div className="accordian-body-data">
              <span>{content}</span>
              {linkSrc ? (
                <Link to={`${linkSrc}`}>
                  <div className="deep-dive-link">
                    <a>Deep Dive</a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="16"
                      viewBox="0 0 46 16"
                      fill="none"
                    >
                      <path
                        d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.0115V7.165H1V8.835Z"
                        fill="#4E4FDC"
                      />
                      <circle
                        cx="37.3726"
                        cy="7.99994"
                        r="6.95913"
                        stroke="#4E4FDC"
                        strokeWidth="1.67"
                      />
                    </svg>
                  </div>
                </Link>
              ) : (
                ''
              )}
            </div>
            <div className="accordian-img">{ImageComponent}</div>
          </div>
        </div>
      ) : (
        <div
          // className="accordian-item openitem"
          className={`accordian-item ${isAccordianOpen ? 'openitem' : ''}`}
          onMouseEnter={toggleAccordion}
        >
          <div className="accordian-head">
            <div className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M1.33325 1.08333L7.16658 6.91666L12.9999 1.08333"
                  stroke="white"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3>{title}</h3>
          </div>
          <div className="accordian-body">
            <div className="accordian-body-data">
              <span>{content}</span>
              {linkSrc ? (
                <Link to={`${linkSrc}`}>
                  <div className="deep-dive-link">
                    <a>Deep Dive</a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="16"
                      viewBox="0 0 46 16"
                      fill="none"
                    >
                      <path
                        d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.0115V7.165H1V8.835Z"
                        fill="#4E4FDC"
                      />
                      <circle
                        cx="37.3726"
                        cy="7.99994"
                        r="6.95913"
                        stroke="#4E4FDC"
                        strokeWidth="1.67"
                      />
                    </svg>
                  </div>
                </Link>
              ) : (
                ''
              )}
            </div>
            <div className="accordian-img">{ImageComponent}</div>
          </div>
        </div>
      )}
    </>
  );
};

Accordian.propTypes = {
  title: PropTypes.array.isRequired,
  content: PropTypes.array.isRequired,
  ImageComponent: PropTypes.array.isRequired,
  linkSrc: PropTypes.array.isRequired,
  isAccordianOpen: PropTypes.array.isRequired,
  toggleAccordion: PropTypes.array.isRequired,
  onClickAccordian: PropTypes.array.isRequired,
};

export default Accordian;
