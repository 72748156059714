import { ReactComponent as ComprehensiveComplianceChecks } from '../images/services/audit and compliance/ComprehensiveComplianceChecks_card.svg';
import { ReactComponent as SensitiveDataProtection } from '../images/services/audit and compliance/SensitiveDataProtection_card.svg';
import { ReactComponent as ProactiveRegulatoryMonitoring } from '../images/services/audit and compliance/ProactiveRegulatoryMonitoring_card.svg';

export const auditApproachData = [
  {
    title: 'Comprehensive Compliance Checks',
    paragraphText:
      'We perform thorough compliance checks to ensure that your organization adheres to all relevant regulations, mitigating the risk of non-compliance.',
    imgComponent: <ComprehensiveComplianceChecks />,
  },
  {
    title: 'Sensitive Data Protection',
    paragraphText:
      'We help protect your sensitive data, ensuring that it remains secure and compliant with data protection regulations.',
    imgComponent: <SensitiveDataProtection />,
  },
  {
    title: 'Proactive Regulatory Monitoring',
    paragraphText:
      'Our team keeps a vigilant eye on the evolving regulatory landscape, helping you adapt and stay compliant as regulations change.',
    imgComponent: <ProactiveRegulatoryMonitoring />,
  },
];
