import React, { useEffect } from 'react';
import gsap from 'gsap';

const PamAnimation = () => {
  useEffect(() => {
    const groups = gsap.utils.toArray("svg g[id^='group']");
    const key = document.querySelector('#key');
    console.log(key);
    const tl = gsap.timeline({ repeat: -1, delay: 0.5 });

    tl.fromTo(
      key.children,
      { stroke: '#FFA900' },
      {
        stroke: '#8889E8',
        duration: 0.0001,
        ease: 'power2.out',
      }
    );

    groups.forEach((group) => {
      tl.fromTo(
        group.children,
        { stroke: '#8889E8' },
        {
          stroke: 'transparent',
          duration: 0.0001,
          ease: 'power2.out',
        }
      );
    });

    tl.fromTo(
      // "#key path",
      key.children,
      { stroke: '#8889E8' },
      {
        stroke: '#FFA900',
        duration: 2,
        ease: 'power2.out',
      }
    );

    groups.forEach((group) => {
      tl.fromTo(
        group.children,
        { stroke: 'transparent' },
        {
          stroke: '#8889E8',
          duration: 1,
          ease: 'power2.out',
        }
      );
    });
  }, []);
  return (
    <svg
      width="420"
      height="376"
      viewBox="0 0 420 376"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="pam-animation"
    >
      <g clipPath="url(#clip0_852_26880)">
        <g id="others">
          <path
            d="M372.08 139.34C369.5 139.35 367.39 137.27 367.38 134.69C367.37 132.11 369.46 130.01 372.04 130C374.62 129.99 376.72 132.07 376.73 134.65C376.74 137.23 374.66 139.33 372.08 139.34Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M369.02 122.69C369.98 122.45 370.97 122.32 372 122.32C373.03 122.32 374.02 122.44 374.97 122.66"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M380.99 143.19C382.36 141.75 383.39 139.99 383.93 138.03"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M363.2 143.28C361.81 141.86 360.77 140.1 360.2 138.15"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M375.08 146.64C374.13 146.88 373.13 147.01 372.11 147.01C371.09 147.01 370.09 146.89 369.13 146.67"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M360.17 131.3C360.72 129.34 361.74 127.58 363.11 126.14"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M383.899 131.19C383.329 129.24 382.289 127.48 380.899 126.06"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M383.899 131.19L389.069 131.16L389.099 138L383.939 138.03"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M374.96 122.66L377.52 118.18L383.47 121.57L380.91 126.06"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M369.02 122.69L366.42 118.23L360.51 121.68L363.12 126.15"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M360.21 138.15L355.04 138.17L355.01 131.33L360.17 131.3"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M369.14 146.67L366.58 151.15L360.64 147.76L363.2 143.28"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M375.08 146.64L377.69 151.1L383.6 147.65L380.99 143.19"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M348.42 157.1C347.22 158.64 345 158.93 343.46 157.74C341.92 156.55 341.64 154.32 342.83 152.77C344.02 151.22 346.25 150.94 347.79 152.14C349.33 153.34 349.62 155.56 348.42 157.1Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M337.07 151.17C337.37 150.49 337.76 149.84 338.23 149.22C338.7 148.6 339.24 148.07 339.82 147.61"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M354.88 153.58C354.66 152.09 354.09 150.66 353.18 149.42"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M346.64 164.23C345.15 164.39 343.61 164.2 342.18 163.63"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M354.18 158.71C353.88 159.39 353.49 160.04 353.02 160.65C352.55 161.26 352.01 161.8 351.43 162.27"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M338.08 160.46C337.17 159.22 336.59 157.79 336.38 156.3"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M349.08 146.25C347.65 145.68 346.12 145.48 344.62 145.65"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M349.08 146.25L351.47 143.16L355.56 146.33L353.17 149.42"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M339.82 147.61L338.34 143.99L343.14 142.03L344.62 145.65"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M337.07 151.17L333.2 150.64L332.5 155.77L336.38 156.3"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M342.18 163.63L339.79 166.72L335.69 163.55L338.08 160.46"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M351.43 162.27L352.91 165.89L348.12 167.85L346.64 164.23"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M354.18 158.71L358.05 159.24L358.75 154.11L354.88 153.58"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M168.6 298.02H160.88C156.75 298.02 153.39 294.67 153.39 290.54C153.39 286.41 156.74 283.05 160.88 283.05C161.62 283.05 162.34 283.16 163.01 283.36C163.77 277.04 169.15 272.14 175.68 272.14C181.8 272.14 186.91 276.45 188.14 282.19C188.84 281.72 189.68 281.46 190.58 281.46C193.01 281.46 194.98 283.43 194.98 285.87C194.98 286.13 194.96 286.37 194.92 286.61C195.95 285.87 197.21 285.43 198.58 285.43C202.05 285.43 204.87 288.24 204.87 291.72C204.87 295.2 202.05 298.01 198.58 298.01H188.67"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M170.899 304.8L173.879 306.67V290.26"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M176.86 304.8L173.88 306.67V290.26"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M180.27 292.13L183.26 290.26V306.67"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M186.24 292.13L183.26 290.26V306.67"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M325.28 51.27L321.27 57.07H346.19L341.93 51.27"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M348.05 21.79H319.41C315.848 21.79 312.96 24.6778 312.96 28.24V44.74C312.96 48.3023 315.848 51.19 319.41 51.19H348.05C351.612 51.19 354.5 48.3023 354.5 44.74V28.24C354.5 24.6778 351.612 21.79 348.05 21.79Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M189.51 185.35C195.077 185.35 199.59 183.917 199.59 182.15C199.59 180.383 195.077 178.95 189.51 178.95C183.943 178.95 179.43 180.383 179.43 182.15C179.43 183.917 183.943 185.35 189.51 185.35Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M179.44 210.6C179.44 212.37 183.95 213.8 189.52 213.8C195.09 213.8 199.6 212.37 199.6 210.6"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M179.44 201.14C179.44 202.91 183.95 204.34 189.52 204.34C195.09 204.34 199.6 202.91 199.6 201.14"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M179.44 191.26C179.44 193.03 183.95 194.46 189.52 194.46C195.09 194.46 199.6 193.03 199.6 191.26"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M199.6 182.15V210.6"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M179.43 182.15V210.6"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M189.19 178.95C190.27 178.44 190.91 177.82 190.91 177.16C190.91 175.39 186.4 173.96 180.83 173.96C175.26 173.96 170.75 175.39 170.75 177.16C170.75 178.93 175.26 180.37 180.83 180.37C180.94 180.37 181.05 180.37 181.15 180.37"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M170.75 205.61C170.75 207.23 174.53 208.57 179.44 208.78"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M170.75 196.15C170.75 197.77 174.53 199.11 179.44 199.32"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M170.75 186.27C170.75 187.89 174.53 189.23 179.44 189.44"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M190.91 178.98V177.15"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M170.75 177.15V205.61"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M296.2 58.6701V66.7001C296.2 67.7801 297.26 68.6501 298.57 68.6501H306.56C307.87 68.6501 308.93 67.7801 308.93 66.7001V58.6701C308.93 57.5901 307.87 56.7201 306.56 56.7201H298.57C297.26 56.7201 296.2 57.5901 296.2 58.6701Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M306 56.71V49.65C306 48.49 304.47 47.54 302.57 47.54C301.62 47.54 300.77 47.78 300.14 48.16C299.52 48.55 299.13 49.07 299.13 49.65V51.23"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            d="M287.68 345.96C294.142 345.96 299.38 340.722 299.38 334.26C299.38 327.798 294.142 322.56 287.68 322.56C281.219 322.56 275.98 327.798 275.98 334.26C275.98 340.722 281.219 345.96 287.68 345.96Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M301.07 344.003L297.627 347.94L309.551 358.368L312.994 354.431L301.07 344.003Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M296.12 343.13L299.35 345.95"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M297.95 37.28C297.95 17.52 313.97 1.5 333.73 1.5C353.49 1.5 369.51 17.52 369.51 37.28C369.51 57.04 353.49 73.06 333.73 73.06C329.12 73.06 324.71 72.19 320.66 70.6"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M147.63 214.88V222.98C147.63 224.07 148.7 224.95 150.02 224.95H158.07C159.39 224.95 160.46 224.07 160.46 222.98V214.88C160.46 213.79 159.39 212.91 158.07 212.91H150.02C148.7 212.91 147.63 213.79 147.63 214.88Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M157.51 212.91V205.79C157.51 204.62 155.96 203.66 154.06 203.66C153.11 203.66 152.24 203.9 151.61 204.28C150.99 204.67 150.6 205.2 150.6 205.78V207.38"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M149.39 193.32C149.39 173.39 165.55 157.23 185.48 157.23C205.41 157.23 221.57 173.39 221.57 193.32C221.57 213.25 205.41 229.41 185.48 229.41C180.83 229.41 176.38 228.53 172.3 226.93"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M314.29 233.68V251.28C314.29 252.82 313.04 254.07 311.5 254.07H280.71C279.17 254.07 277.92 252.82 277.92 251.28V233.68C277.92 232.44 278.73 231.38 279.86 231.02C280.13 230.93 280.41 230.88 280.71 230.88H311.5C311.8 230.88 312.09 230.93 312.35 231.02C313.47 231.38 314.29 232.43 314.29 233.68Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M312.36 231.02L300.59 242.79C298.12 245.26 294.1 245.26 291.63 242.79L279.86 231.02C280.13 230.93 280.41 230.88 280.71 230.88H311.5C311.8 230.88 312.09 230.93 312.35 231.02H312.36Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M260 261.39V269.07C260 270.1 261.01 270.93 262.26 270.93H269.89C271.14 270.93 272.15 270.09 272.15 269.07V261.39C272.15 260.36 271.14 259.53 269.89 259.53H262.26C261.01 259.53 260 260.37 260 261.39Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M269.35 259.52V252.78C269.35 251.67 267.88 250.76 266.08 250.76C265.18 250.76 264.36 250.99 263.76 251.35C263.17 251.72 262.8 252.22 262.8 252.78V254.24"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M261.66 240.95C261.66 222.07 276.97 206.76 295.85 206.76C314.73 206.76 330.04 222.07 330.04 240.95C330.04 259.83 314.73 275.14 295.85 275.14C291.44 275.14 287.23 274.31 283.36 272.79"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M312.6 170.79V180.79C312.6 182.13 313.92 183.22 315.55 183.22H325.49C327.12 183.22 328.44 182.13 328.44 180.79V170.79C328.44 169.45 327.12 168.36 325.49 168.36H315.55C313.92 168.36 312.6 169.45 312.6 170.79Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M324.79 168.36V159.58C324.79 158.13 322.88 156.95 320.53 156.95C319.35 156.95 318.29 157.25 317.51 157.72C316.74 158.2 316.26 158.85 316.26 159.58V161.79"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M314.77 144.19C314.77 119.6 334.7 99.67 359.29 99.67C383.88 99.67 403.81 119.6 403.81 144.19C403.81 168.78 383.88 188.71 359.29 188.71C353.55 188.71 348.06 187.62 343.02 185.64"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M259.04 359.53V366.86C259.04 367.84 260.01 368.64 261.2 368.64H268.48C269.67 368.64 270.64 367.84 270.64 366.86V359.53C270.64 358.55 269.67 357.75 268.48 357.75H261.2C260.01 357.75 259.04 358.55 259.04 359.53Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M267.97 357.74V351.3C267.97 350.24 266.57 349.38 264.85 349.38C263.99 349.38 263.21 349.6 262.63 349.94C262.07 350.29 261.71 350.77 261.71 351.3V352.65"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M260.63 341.02C260.63 322.99 275.24 308.38 293.27 308.38C311.3 308.38 325.91 322.99 325.91 341.02C325.91 359.05 311.3 373.66 293.27 373.66C289.06 373.66 285.04 372.86 281.34 371.41"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M220.03 73.04H167.97C164.529 73.04 161.74 75.8293 161.74 79.27V104.35C161.74 107.791 164.529 110.58 167.97 110.58H220.03C223.471 110.58 226.26 107.791 226.26 104.35V79.27C226.26 75.8293 223.471 73.04 220.03 73.04Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M219.99 73.04V71.64C219.99 69.07 217.91 66.99 215.34 66.99H160.13C157.56 66.99 155.48 69.07 155.48 71.64V99.87C155.48 102.44 157.56 104.52 160.13 104.52H161.74"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M155.481 99.57H154.251C151.681 99.57 149.601 97.49 149.601 94.92V66.69C149.601 64.12 151.681 62.04 154.251 62.04H209.461C212.031 62.04 214.111 64.12 214.111 66.69V67"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M161.74 82.03H226.25"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.73 121.24V134.12C126.73 135.85 128.43 137.25 130.52 137.25H143.32C145.41 137.25 147.11 135.85 147.11 134.12V121.24C147.11 119.51 145.41 118.11 143.32 118.11H130.52C128.43 118.11 126.73 119.51 126.73 121.24Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M142.43 118.11V106.8C142.43 104.94 139.97 103.42 136.94 103.42C135.42 103.42 134.05 103.8 133.05 104.41C132.06 105.03 131.44 105.87 131.44 106.8V110.93"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M129.53 86.97C129.53 55.3 155.2 29.62 186.88 29.62C218.56 29.62 244.23 55.29 244.23 86.97C244.23 118.65 218.56 144.32 186.88 144.32C179.49 144.32 172.42 142.92 165.93 140.37"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M134.06 314.72V324.23C134.06 325.5 135.32 326.54 136.86 326.54H146.32C147.87 326.54 149.12 325.5 149.12 324.23V314.72C149.12 313.45 147.86 312.41 146.32 312.41H136.86C135.31 312.41 134.06 313.45 134.06 314.72Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M145.659 312.41V304.05C145.659 302.67 143.839 301.55 141.599 301.55C140.479 301.55 139.469 301.83 138.719 302.28C137.989 302.74 137.529 303.36 137.529 304.05V306.1"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M136.13 289.4C136.13 266 155.1 247.03 178.5 247.03C201.9 247.03 220.87 266 220.87 289.4C220.87 312.8 201.9 331.77 178.5 331.77C173.04 331.77 167.81 330.74 163.02 328.85"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M361.07 300.65V306.89C361.07 307.73 361.89 308.41 362.91 308.41H369.12C370.13 308.41 370.96 307.73 370.96 306.89V300.65C370.96 299.81 370.14 299.13 369.12 299.13H362.91C361.9 299.13 361.07 299.81 361.07 300.65Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M368.681 299.13V293.65C368.681 292.75 367.491 292.01 366.021 292.01C365.291 292.01 364.621 292.2 364.131 292.49C363.651 292.79 363.351 293.2 363.351 293.65V294.65"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M362.431 284.03C362.431 268.67 374.881 256.23 390.231 256.23C405.581 256.23 418.031 268.68 418.031 284.03C418.031 299.38 405.581 311.83 390.231 311.83C386.641 311.83 383.221 311.15 380.071 309.92"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M397.45 277.34C399.505 277.34 401.17 275.674 401.17 273.62C401.17 271.565 399.505 269.9 397.45 269.9C395.396 269.9 393.73 271.565 393.73 273.62C393.73 275.674 395.396 277.34 397.45 277.34Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M397.45 298.16C399.505 298.16 401.17 296.494 401.17 294.44C401.17 292.385 399.505 290.72 397.45 290.72C395.396 290.72 393.73 292.385 393.73 294.44C393.73 296.494 395.396 298.16 397.45 298.16Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M380.241 289.38C383.123 289.38 385.461 287.043 385.461 284.16C385.461 281.277 383.123 278.94 380.241 278.94C377.358 278.94 375.021 281.277 375.021 284.16C375.021 287.043 377.358 289.38 380.241 289.38Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M394.12 275.29L384.87 281.75"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M394.121 292.76L384.961 286.37"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="key">
          <path
            d="M33.7296 146.09C40.1747 146.09 45.3996 140.865 45.3996 134.42C45.3996 127.975 40.1747 122.75 33.7296 122.75C27.2844 122.75 22.0596 127.975 22.0596 134.42C22.0596 140.865 27.2844 146.09 33.7296 146.09Z"
            stroke="#FFA900"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.73 177.84C51.254 177.84 65.46 163.634 65.46 146.11C65.46 128.586 51.254 114.38 33.73 114.38C16.206 114.38 2 128.586 2 146.11C2 163.634 16.206 177.84 33.73 177.84Z"
            stroke="#FFA900"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.7495 176.54V269.9C42.7495 269.9 37.0195 244.94 19.8495 234.03C20.9695 234.24 23.9295 225.56 24.1795 224.69C26.0395 218.5 27.4195 211.65 17.7695 198.13L26.4895 177.02"
            stroke="#FFA900"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group1">
          <path
            d="M84.1299 153.23L113.62 140.16"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M84.1299 210.8L136.13 198.54"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M84.1299 242.52L134.06 265.96"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group2">
          <path
            d="M257.04 76.57L285.68 63.87"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M230.72 175.22L306.36 157.37"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M225.35 219.26L252.72 231.6"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M227.28 309.38L255.35 322.56"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group3">
          <path
            d="M330.05 266.45L354.45 277.75"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_852_26880">
          <rect width="419.53" height="375.16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PamAnimation;
