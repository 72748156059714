import React, { useEffect } from 'react';
import './style.scss';
import gsap from 'gsap';

const EiamAnimation = () => {
  useEffect(() => {
    const groups = gsap.utils.toArray("svg g[id^='group']");

    const tl = gsap.timeline({ repeat: -1, delay: 0.01 });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group.children,
        {
          // scale: 1
          stroke: '#FFA900',
        },
        {
          //   scale: 0,
          stroke: '#8889E8',

          duration: 0.001,
          ease: 'power2.out',
        }
      );
    });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group.children,
        {
          stroke: '#8889E8',
        },
        {
          stroke: '#FFA900',
          duration: 0.4,
          ease: 'power2.out',
        }
      );
    });

    const elementToRotate = document.querySelectorAll('.setting1');

    // GSAP animation
    gsap.to(elementToRotate, {
      rotation: 360,
      transformOrigin: 'center',
      duration: 4,
      ease: 'linear',
      repeat: -1,
    });
  }, []);

  return (
    <svg
      className="eiam-animation"
      width="507"
      height="341"
      viewBox="0 0 507 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_7851)">
        <g opacity="0.64">
          <path
            d="M173.794 116.405C172.422 120.062 168.482 123.424 163.703 123.403H163.605C161.602 123.392 158.303 122.586 155.68 120.083C156.387 119.594 157.846 118.592 158.532 118.168C158.608 118.124 158.684 118.07 158.771 118.026C158.869 117.972 158.967 117.918 159.054 117.863C159.501 118.19 159.98 118.484 160.502 118.723C162.157 119.485 163.648 119.517 163.659 119.507H163.692C166.206 119.517 168.275 118.059 169.418 116.394H169.973L173.783 116.405H173.794Z"
            fill="#FFA900"
          />
          <path
            d="M156.258 113.858C156.171 113.913 156.084 113.967 155.996 114.021C155.169 114.533 153.493 115.687 152.927 116.078C152.796 115.774 152.676 115.469 152.567 115.142C152.459 114.816 152.35 114.479 152.263 114.13C152.263 114.13 152.263 114.119 152.263 114.108C152.175 113.782 152.078 113.466 151.969 113.173C151.49 111.834 150.804 110.724 149.922 109.842C149.672 109.603 149.421 109.374 149.138 109.168C148.648 108.798 148.115 108.493 147.527 108.232C145.796 107.492 144.087 107.524 144.087 107.524C141.79 107.513 139.733 108.732 138.655 110.593C138.47 110.898 138.318 111.224 138.198 111.573H134.148C134.148 111.507 134.17 111.453 134.181 111.41C134.246 111.127 134.323 110.854 134.41 110.593C135.727 106.512 139.668 103.639 144.098 103.639C146.21 103.552 149.737 104.292 152.513 106.958C152.752 107.187 152.981 107.426 153.199 107.677C154.004 108.58 154.712 109.679 155.278 110.985C155.409 111.29 155.528 111.595 155.648 111.921C155.768 112.247 155.866 112.574 155.964 112.922C155.986 112.988 155.996 113.053 156.018 113.129C156.084 113.39 156.16 113.641 156.258 113.891V113.858Z"
            fill="#FFA900"
          />
          <path
            d="M151.086 113.651C149.235 114.664 146.938 115.567 144.261 115.48L139.22 115.458L127.191 115.415V111.508L139.242 111.551L144.326 111.573H144.348C145.817 111.627 147.265 111.246 149.007 110.321C149.072 110.387 149.148 110.452 149.214 110.517C149.997 111.29 150.607 112.291 151.032 113.488C151.053 113.543 151.075 113.597 151.086 113.651Z"
            fill="#FFA900"
          />
          <path
            d="M181.056 115.458L174.1 115.436H169.985L163.932 115.404H163.9C162.441 115.36 160.939 115.763 159.175 116.699C158.881 116.851 158.587 117.014 158.282 117.199C158.195 117.243 158.119 117.297 158.032 117.352C157.194 117.874 155.289 119.191 154.995 119.387C154.973 119.409 154.962 119.409 154.962 119.409C154.712 119.583 154.451 119.768 154.189 119.942C151.435 121.77 148.267 123.337 144.044 123.316C139.493 123.294 135.651 120.508 134.377 116.438H138.633C139.689 118.244 141.725 119.398 144.065 119.42C147.179 119.43 149.508 118.386 152.099 116.644C152.306 116.503 152.513 116.361 152.731 116.22C152.731 116.22 152.807 116.176 152.927 116.089C153.493 115.698 155.169 114.544 155.996 114.032C156.083 113.978 156.171 113.924 156.258 113.869C156.53 113.706 156.824 113.543 157.118 113.369C158.968 112.356 161.287 111.421 163.997 111.508L169.974 111.529H174.067L181.056 111.573V115.48V115.458Z"
            fill="#FFA900"
          />
          <path
            d="M174.077 111.529H169.984C169.843 111.17 169.658 110.843 169.451 110.539C168.308 108.819 166.25 107.633 163.964 107.622C160.938 107.611 158.793 108.58 156.105 110.397C155.92 110.517 155.735 110.648 155.539 110.778C155.539 110.778 155.441 110.843 155.288 110.952C154.722 109.646 154.004 108.547 153.209 107.644C153.274 107.6 153.318 107.568 153.318 107.568C153.557 107.404 153.797 107.241 154.025 107.089C158.271 104.27 161.014 103.693 163.997 103.704C168.482 103.726 172.401 106.632 173.794 110.539C173.914 110.854 174.012 111.181 174.088 111.518L174.077 111.529Z"
            fill="#FFA900"
          />
        </g>

        <g id="others">
          <path
            d="M155.168 64.0792C166.86 91.1561 195.087 74.1134 195.087 74.1134C195.087 74.1134 209.892 148.607 154.134 165.073C98.3767 148.607 113.181 74.1134 113.181 74.1134C113.181 74.1134 141.409 91.167 153.1 64.0792C153.47 63.2086 154.798 63.2086 155.179 64.0792H155.168Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2564 26.8157C24.6112 26.8157 29.7628 21.6655 29.7628 15.3124C29.7628 8.9593 24.6112 3.80908 18.2564 3.80908C11.9016 3.80908 6.75 8.9593 6.75 15.3124C6.75 21.6655 11.9016 26.8157 18.2564 26.8157Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8784 47.0148C34.8784 37.8405 27.4324 30.3965 18.2556 30.3965C9.07877 30.3965 1.63281 37.8405 1.63281 47.0148H34.8784Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2564 80.9696C24.6112 80.9696 29.7628 75.8193 29.7628 69.4662C29.7628 63.1131 24.6112 57.9629 18.2564 57.9629C11.9016 57.9629 6.75 63.1131 6.75 69.4662C6.75 75.8193 11.9016 80.9696 18.2564 80.9696Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8784 101.169C34.8784 91.9943 27.4324 84.5503 18.2556 84.5503C9.07877 84.5503 1.63281 91.9943 1.63281 101.169H34.8784Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2564 135.123C24.6112 135.123 29.7628 129.973 29.7628 123.62C29.7628 117.267 24.6112 112.117 18.2564 112.117C11.9016 112.117 6.75 117.267 6.75 123.62C6.75 129.973 11.9016 135.123 18.2564 135.123Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8784 155.322C34.8784 146.148 27.4324 138.704 18.2556 138.704C9.07877 138.704 1.63281 146.148 1.63281 155.322H34.8784Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2564 189.277C24.6112 189.277 29.7628 184.127 29.7628 177.774C29.7628 171.421 24.6112 166.271 18.2564 166.271C11.9016 166.271 6.75 171.421 6.75 177.774C6.75 184.127 11.9016 189.277 18.2564 189.277Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8784 209.476C34.8784 200.302 27.4324 192.858 18.2556 192.858C9.07877 192.858 1.63281 200.302 1.63281 209.476H34.8784Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.0137 1.63232H67.5572C73.5663 1.63232 78.4323 6.6385 78.4323 12.8092V202.87C78.4323 209.04 73.5663 214.047 67.5572 214.047H53.0137"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M250.397 1.63232H235.854C229.845 1.63232 224.979 6.6385 224.979 12.8092V202.87C224.979 209.04 229.845 214.047 235.854 214.047H250.397"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M328.209 156.073H269.785C265.763 156.073 262.502 159.332 262.502 163.353V201.727C262.502 205.748 265.763 209.008 269.785 209.008H328.209C332.231 209.008 335.492 205.748 335.492 201.727V163.353C335.492 159.332 332.231 156.073 328.209 156.073Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M299.885 171.603H322.408"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M299.885 182.366H322.408"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M299.885 193.129H322.408"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M288.172 166.009H275.381V178.796H288.172V166.009Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M288.172 185.859H275.381V198.647H288.172V185.859Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M410.746 101.756L397.781 120.529H478.446L464.675 101.756"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M505.368 89.0229H370.861"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M498.335 6.33398H377.883C374.005 6.33398 370.861 9.47674 370.861 13.3535V94.4754C370.861 98.3522 374.005 101.495 377.883 101.495H498.335C502.213 101.495 505.357 98.3522 505.357 94.4754V13.3535C505.357 9.47674 502.213 6.33398 498.335 6.33398Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M476.782 32.127H415.592C411.847 32.127 408.811 35.1625 408.811 38.9071V68.8353C408.811 72.5799 411.847 75.6154 415.592 75.6154H476.782C480.528 75.6154 483.564 72.5799 483.564 68.8353V38.9071C483.564 35.1625 480.528 32.127 476.782 32.127Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M476.303 32.1268V30.5052C476.303 27.5233 473.886 25.1182 470.915 25.1182H406.949C403.966 25.1182 401.561 27.5342 401.561 30.5052V63.2087C401.561 66.1906 403.977 68.5957 406.949 68.5957H408.811"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M401.549 62.8604H400.123C397.14 62.8604 394.734 60.4444 394.734 57.4733V24.7699C394.734 21.788 397.151 19.3828 400.123 19.3828H464.099C467.082 19.3828 469.488 21.7988 469.488 24.7699V25.129"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M408.811 42.542H483.564"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M502.352 162.352V201.912C502.352 205.373 499.544 208.181 496.082 208.181H426.837C423.375 208.181 420.566 205.373 420.566 201.912V162.352C420.566 159.555 422.395 157.183 424.921 156.377C425.519 156.182 426.162 156.073 426.837 156.073H496.082C496.746 156.073 497.399 156.182 497.998 156.377C500.523 157.183 502.352 159.555 502.352 162.352Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M497.997 156.377L471.533 182.834C465.971 188.395 456.946 188.395 451.384 182.834L424.92 156.377C425.519 156.182 426.161 156.073 426.836 156.073H496.081C496.745 156.073 497.398 156.182 497.997 156.377Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M385.437 154.832C394.93 154.832 402.626 152.386 402.626 149.369C402.626 146.352 394.93 143.906 385.437 143.906C375.944 143.906 368.248 146.352 368.248 149.369C368.248 152.386 375.944 154.832 385.437 154.832Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M368.26 197.864C368.26 200.878 375.945 203.316 385.438 203.316C394.93 203.316 402.627 200.867 402.627 197.864"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M368.26 181.735C368.26 184.75 375.945 187.188 385.438 187.188C394.93 187.188 402.627 184.739 402.627 181.735"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M368.26 164.899C368.26 167.914 375.945 170.352 385.438 170.352C394.93 170.352 402.627 167.903 402.627 164.899"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M402.625 149.369V197.864"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M368.248 149.369V197.864"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M384.882 143.917C386.732 143.035 387.81 141.99 387.81 140.859C387.81 137.844 380.125 135.406 370.632 135.406C361.14 135.406 353.443 137.855 353.443 140.859C353.443 143.862 361.14 146.322 370.632 146.322C370.817 146.322 371.002 146.322 371.177 146.322"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M353.443 189.354C353.443 192.107 359.877 194.392 368.248 194.751"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M353.443 173.235C353.443 175.989 359.877 178.274 368.248 178.633"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M353.443 156.399C353.443 159.153 359.877 161.438 368.248 161.797"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M387.811 143.96V140.858"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M353.443 140.858V189.353"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.0137 71.9692H78.4323"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.0137 143.895H78.4323"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M78.4336 109.417H111.396"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M196.131 109.417H224.739"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M279.252 72.0212V92.3724C279.252 95.5285 276.149 98.086 272.317 98.086H248.934C245.113 98.086 242 95.5285 242 92.3724V72.0212C242 68.8651 245.102 66.3076 248.934 66.3076H272.317C276.138 66.3076 279.252 68.8651 279.252 72.0212Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M250.578 66.3077V52.1815C250.578 48.7752 255.063 46 260.604 46C263.369 46 265.884 46.6965 267.713 47.8066C269.52 48.9384 270.652 50.4729 270.652 52.1707V66.2968"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M260.627 83.3722C263.35 83.3722 265.558 81.165 265.558 78.4422C265.558 75.7194 263.35 73.5122 260.627 73.5122C257.903 73.5122 255.695 75.7194 255.695 78.4422C255.695 81.165 257.903 83.3722 260.627 83.3722Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M263.14 83.3721H258.383V89.9019H263.14V83.3721Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M232.367 234H72.2572C61.6217 234 53 242.619 53 253.252V253.263C53 263.896 61.6217 272.515 72.2572 272.515H232.367C243.002 272.515 251.624 263.896 251.624 253.263V253.252C251.624 242.619 243.002 234 232.367 234Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group1">
          <path
            d="M79.6605 258.541C82.5764 258.541 84.9402 256.178 84.9402 253.263C84.9402 250.348 82.5764 247.985 79.6605 247.985C76.7447 247.985 74.3809 250.348 74.3809 253.263C74.3809 256.178 76.7447 258.541 79.6605 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group1">
          <path
            d="M104.043 258.541C106.959 258.541 109.323 256.178 109.323 253.263C109.323 250.348 106.959 247.985 104.043 247.985C101.127 247.985 98.7637 250.348 98.7637 253.263C98.7637 256.178 101.127 258.541 104.043 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group2">
          <path
            d="M128.428 258.541C131.344 258.541 133.708 256.178 133.708 253.263C133.708 250.348 131.344 247.985 128.428 247.985C125.512 247.985 123.148 250.348 123.148 253.263C123.148 256.178 125.512 258.541 128.428 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group3">
          <path
            d="M152.813 258.541C155.729 258.541 158.093 256.178 158.093 253.263C158.093 250.348 155.729 247.985 152.813 247.985C149.897 247.985 147.533 250.348 147.533 253.263C147.533 256.178 149.897 258.541 152.813 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group4">
          <path
            d="M177.198 258.541C180.113 258.541 182.477 256.178 182.477 253.263C182.477 250.348 180.113 247.985 177.198 247.985C174.282 247.985 171.918 250.348 171.918 253.263C171.918 256.178 174.282 258.541 177.198 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group5">
          <path
            d="M201.582 258.541C204.498 258.541 206.862 256.178 206.862 253.263C206.862 250.348 204.498 247.985 201.582 247.985C198.667 247.985 196.303 250.348 196.303 253.263C196.303 256.178 198.667 258.541 201.582 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group6">
          <path
            d="M225.965 258.541C228.881 258.541 231.245 256.178 231.245 253.263C231.245 250.348 228.881 247.985 225.965 247.985C223.049 247.985 220.686 250.348 220.686 253.263C220.686 256.178 223.049 258.541 225.965 258.541Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g className="setting1">
          <path
            d="M337.941 97.3042C336.389 99.9994 333.144 101.124 330.315 100.104C329.972 99.9805 329.635 99.823 329.302 99.6315C326.274 97.8877 325.24 94.0163 326.983 90.9887C327.841 89.5054 329.201 88.4973 330.732 88.0672C332.315 87.6128 334.076 87.7813 335.614 88.6655C338.641 90.4092 339.685 94.2766 337.941 97.3042Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M316.388 89.5349C316.763 88.2576 317.284 87.0116 317.976 85.8059C318.373 85.1243 318.809 84.4715 319.284 83.8686C319.637 83.4078 320.014 82.9775 320.406 82.5667"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M344.487 82.5445C346.409 84.5132 347.771 86.9185 348.511 89.5006Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M328.455 110.373C331.125 111.042 333.895 111.015 336.496 110.361Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M348.534 98.7639C348.162 100.035 347.636 101.294 346.948 102.487C346.255 103.692 345.436 104.773 344.514 105.739"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M316.408 98.7899C317.153 101.374 318.516 103.78 320.437 105.748Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M328.422 77.9292C331.03 77.2778 333.793 77.2487 336.463 77.9171Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M336.463 77.9171L339.95 71.8618L347.977 76.4829L344.487 82.5445"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M320.406 82.5667L316.909 76.5174L324.919 71.8774L328.422 77.9292"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M316.388 89.5349L309.404 89.5409L309.414 98.7997L316.408 98.7899"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M328.455 110.373L324.968 116.429L324.522 116.175L316.947 111.81L320.437 105.748"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M344.514 105.739L348.016 111.775L340.006 116.415L336.496 110.361"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M348.511 89.5006L355.51 89.493L355.514 98.7496L348.534 98.7639"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g className="setting1">
          <path
            d="M301.207 124.265C299.757 126.095 297.224 126.571 295.236 125.502C294.995 125.372 294.762 125.218 294.537 125.039C292.481 123.41 292.141 120.422 293.769 118.366C294.57 117.36 295.691 116.762 296.875 116.611C298.101 116.449 299.389 116.768 300.433 117.594C302.488 119.223 302.836 122.21 301.207 124.265Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M286.064 116.117C286.483 115.21 287.007 114.343 287.655 113.524C288.024 113.062 288.42 112.625 288.839 112.23C289.152 111.927 289.48 111.649 289.816 111.387"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M307.697 114.03C308.906 115.704 309.652 117.64 309.916 119.638Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M292.722 132.92C294.63 133.712 296.689 133.997 298.693 133.799Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M308.91 126.518C308.494 127.421 307.965 128.297 307.322 129.107C306.675 129.926 305.947 130.638 305.156 131.253"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M285.056 122.99C285.325 124.992 286.071 126.928 287.28 128.601Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M296.28 108.83C298.288 108.634 300.342 108.917 302.25 109.708Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M302.25 109.708L305.508 105.598L310.957 109.915L307.697 114.03"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M289.816 111.387L287.888 106.51L294.347 103.95L296.28 108.83"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M286.064 116.117L280.878 115.35L279.863 122.226L285.056 122.99"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M292.722 132.92L289.464 137.031L289.161 136.793L284.02 132.716L287.28 128.601"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M305.156 131.253L307.089 136.121L300.63 138.681L298.693 133.799"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M309.916 119.638L315.114 120.406L314.094 127.278L308.91 126.518"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g clipPath="url(#clip1_1_7851)">
          <path
            d="M301.41 39.08H290.79C285.11 39.08 280.5 34.48 280.5 28.79C280.5 23.1 285.11 18.5 290.79 18.5C291.81 18.5 292.8 18.65 293.72 18.92C294.77 10.23 302.17 3.5 311.14 3.5C319.55 3.5 326.58 9.42 328.27 17.33C329.23 16.69 330.38 16.32 331.62 16.32C334.96 16.32 337.68 19.03 337.68 22.38C337.68 22.73 337.65 23.07 337.59 23.4C339.01 22.38 340.74 21.78 342.62 21.78C347.4 21.78 351.27 25.65 351.27 30.43C351.27 35.21 347.39 39.08 342.62 39.08H328.99"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M304.561 48.4099L308.671 50.9799V28.4199"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M312.77 48.4099L308.67 50.9799V28.4199"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M317.461 30.9899L321.561 28.4199V50.9799"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M325.661 30.9899L321.561 28.4199V50.9799"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default EiamAnimation;
