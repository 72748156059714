export const staffEnterPriseData = [
  {
    title: 'Expertise Amplified',
    contentPart:
      "Access an elevated level of cybersecurity knowledge and skills to enhance your organization's security posture.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Tailored Staffing Precision',
    contentPart:
      'Enjoy flexibility of staffing solutions customized to the specific requirements and dynamics of our cybersecurity projects.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Dedicated Cybersecurity Champions',
    contentPart:
      "Leverage a team of committed professionals entirely focused on fortifying and advancing your organization's cybersecurity initiatives.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Streamlined Project Mastery',
    contentPart:
      'Achieve project efficiency with expert roles seamlessly integrated, ensuring a smooth and effective execution.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Long-Term Excellence Assurance',
    contentPart:
      'Receive continuous support and expertise, fostering enduring partnerships and consistently reinforcing your cybersecurity capabilities.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
