import React from 'react';
import './Services.scss';
import ServiceAnimation from '../../components/animations/ServiceAnimation/ServiceAnimation.jsx';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';
import AccordianSection from '../../components/common/AccordianSection/AccordianSection';
import { servicesData } from '../../assets/data/servicesData';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';

export const Services = () => {
  useMoveToTopFeature();

  return (
    <div>
      {/* Hero Section */}
      <div className="services-hero-section">
        <HeroSection
          labelText="SERVICES"
          mainText="Highly Specialized Identity Services From Seasoned Professionals"
          paragarphText="We understand that cybersecurity solutions should be custom-tailored to your organization's specific needs and goals to ensure the best return on investment. It can’t be a one-size-fits-all approach."
          AnimationComponent={<ServiceAnimation></ServiceAnimation>}
        />
      </div>

      {/* Services Accordian */}
      <div className="services-accordian-section">
        <div className="container">
          <div className="services-accordian-text">
            <span>
              Our services span the entire spectrum of Identity and Access
              Management (IAM),
              <br />
              guaranteeing the security, compliance, and resilience of your
              digital assets. Our trusted <br />
              approach is anchored in a proven 3-step advisory framework called
              the Cyber Maturity <br />
              Accelerator. We provide end-to-end services, from cybersecurity
              guidance to solution <br />
              implementation, with continuous monitoring led by a dedicated
              Virtual IAM architect
            </span>
            <br />
            <br />
            <span>
              Our commitment to growth and adaptability is grounded in our
              flexibility to the ever- <br />
              changing business landscape, and our relentless pursuit of
              knowledge to enhance our <br /> services and their delivery.
            </span>
          </div>
          <div className="services-accordian">
            <AccordianSection dataArray={servicesData} />
          </div>
        </div>
      </div>

      {/* Talk To Us */}
      {/* <TalkToUsSection></TalkToUsSection> */}
      <div className="ready-mar0 butmar">
        <ReadyToUseSection
          titleText="Experience Service Excellence"
          bodyText="Our service portfolio showcases our unwavering dedication to your business's success. With a wealth of experience and expertise, we stand out in meeting your distinct requirements. Explore the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
