import { ReactComponent as Strategy_Services } from '../images/services/Strategy_Services_Accordian.svg';
import { ReactComponent as Staff_Services } from '../images/services/Staff_Services_Accordian.svg';
import { ReactComponent as OpsSupport_Services } from '../images/services/OpsSupport_Services_Accordian.svg';
import { ReactComponent as Implementation_Services } from '../images/services/Implementation_Services_Accordian.svg';
import { ReactComponent as IAM_Services } from '../images/services/IAM_Services_Accordian.svg';
import { ReactComponent as Audit_Services } from '../images/services/Audit_Services_Accordian.svg';

export const servicesData = [
  {
    title: 'Strategy and Roadmap Development',
    contentPart:
      "Our approach to cybersecurity begins with a well-defined strategy: 'Cyber Maturity Accelerator™,' a 3-step advisory framework. Whether you're aiming to enhance your cybersecurity posture, ensure compliance, or make informed decisions, this service empowers you to reach your cybersecurity goals with confidence and provides lasting value to your organization.",
    imgSrc: <Strategy_Services />,
    linkSrc: '/strategy',
    linksbtn: true,
  },
  {
    title: 'Implementation and Integration',
    contentPart:
      'We offer hands-on support for the implementation and integration of the IAM solutions we recommend. We work closely with your team, ensuring seamless deployment and alignment with your existing IT infrastructure.',
    imgSrc: <Implementation_Services />,
    linkSrc: '/implementation',
    linksbtn: true,
  },
  {
    title: 'Audit and Compliance',
    contentPart:
      'Our Audit and Compliance services help you maintain compliance, protect sensitive data, and navigate the ever-evolving regulatory landscape. We keep your organization ahead of the curve.',
    imgSrc: <Audit_Services />,
    linkSrc: '/audit',
    linksbtn: true,
  },
  {
    title: 'Virtual IAM Architect Advisory Services',
    contentPart:
      'Harness IAM expertise at a fractional cost by augmenting your internal IAM team with skilled IAM experts who work on a flexible schedule, offering valuable insights, strategic guidance, and practical support.',
    imgSrc: <IAM_Services />,
    linkSrc: '/virtualiam',
    linksbtn: true,
  },
  {
    title: 'Operational Support and Maintenance',
    contentPart:
      "Security doesn't end at implementation. We offer ongoing operational support and maintenance to keep your security systems running smoothly. Trust in our expertise for round-the-clock assistance.",
    imgSrc: <OpsSupport_Services />,
    linkSrc: '/opssupport',
    linksbtn: true,
  },
  {
    title: 'Staff Augmentation',
    contentPart:
      "When your team needs additional cybersecurity expertise, we provide skilled professionals to augment your staff. Whether it's project-based or long-term support, we're here to strengthen your cybersecurity efforts.",
    imgSrc: <Staff_Services />,
    linkSrc: '/staff',
    linksbtn: true,
  },
];
