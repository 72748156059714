import React from 'react';
import './Solutions.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import SolutionsAnimation from '../../components/animations/SolutionsAnimation/SolutionAnimation.jsx';
import OfferCard from '../../components/common/OfferCard/OfferCard.jsx';
import CIA from '../../assets/images/solutions/CIAM.svg';
import EIA from '../../assets/images/solutions/EIAM.svg';
import IGA from '../../assets/images/solutions/IGA.svg';
import PAM from '../../assets/images/solutions/PAM.svg';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const Solutions = () => {
  useMoveToTopFeature();

  return (
    <div>
      <div className="solution-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="SOLUTIONS"
          mainText="Practical, Bespoke, Innovative & ROI-Driven Solutions"
          paragarphText="Our IAM approach isn't one-size-fits-all; it's a collaborative journey designed to enhance your organization's security, efficiency, and overall success. We dig deep into your business and industry nuances to craft tailored solutions that make your identity management journey as unique as your business itself."
          AnimationComponent={<SolutionsAnimation></SolutionsAnimation>}
        />
      </div>

      {/* SOLUTIONS */}
      <div className="serandsol-section removehead-margin">
        <div className="container">
          <div className="serandsol-data-box">
            <OfferCard
              title="Customer Identity and Access Management"
              paragraph="Your Key to Secure and Seamless Digital Experiences"
              linkSrc="/ciam"
              imgSrc={CIA}
            />
            <OfferCard
              title="Enterprise Identity and Access Management"
              paragraph="Embrace Zero Trust with Identity as the Perimeter"
              linkSrc="/eiam"
              imgSrc={EIA}
            />
            <OfferCard
              title="Identity Governance and Administration"
              paragraph="Secure your enterprise with Comprehensive Identity Lifecyle management"
              linkSrc="/iga"
              imgSrc={IGA}
            />
            <OfferCard
              title="Privileged Access Management"
              paragraph="Safeguard keys to your enterprise critical data"
              linkSrc="/pam"
              imgSrc={PAM}
            />
          </div>
        </div>
      </div>

      {/* HarnessIAMMastery */}
      {/* <HassIrneAMMastery></HarnessIAMMastery> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Harness Decades of IAM Mastery"
          bodyText="Our cybersecurity solutions are expertly crafted to empower your organization's digital transformation. With decades of expertise and a relentless commitment to excellence, we provide practical, tailor-made solutions that optimize your security posture and ROI. Experience the ComplySimpli difference today."
          linkSrc="/contact?focus=contact"
          btnText="Connect With Us"
        />
      </div>
    </div>
  );
};
