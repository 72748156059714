import React from 'react';
import './style.scss';

const SolutionsAnimation = () => {
  return (
    <>
      <svg
        className="solutions-hero-2"
        width="244"
        height="227"
        viewBox="0 0 244 227"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* First */}
        <path
          className="orange-card"
          d="M242.244 1.71631H1.75586V149.243H242.244V1.71631Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        {/* Second */}
        <path
          d="M164.413 47.0127V72.7368H133.05V103.946H88.4199V47.0127H164.413Z"
          fill="#8889E8"
        />

        {/* Third */}
        <path
          className="card-1"
          d="M68.9236 167.904H1.75586V224.829H68.9236V167.904Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="card-1"
          d="M35.3382 200.576C37.9951 200.576 40.149 198.422 40.149 195.765C40.149 193.108 37.9951 190.955 35.3382 190.955C32.6812 190.955 30.5273 193.108 30.5273 195.765C30.5273 198.422 32.6812 200.576 35.3382 200.576Z"
          fill="#8889E8"
        />

        {/* Fourth */}
        <path
          className="card-2"
          d="M117.98 165.292C134.297 165.292 147.55 178.537 147.55 194.862V224.631H88.418V194.862C88.418 178.545 101.663 165.292 117.988 165.292H117.98Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="card-2"
          d="M117.981 200.577C120.638 200.577 122.792 198.423 122.792 195.766C122.792 193.109 120.638 190.955 117.981 190.955C115.324 190.955 113.17 193.109 113.17 195.766C113.17 198.423 115.324 200.577 117.981 200.577Z"
          fill="#8889E8"
        />

        {/* Fifth */}
        <path
          className="card-3"
          d="M242.245 167.705V193.422H210.89V224.631H166.252V167.705H242.245Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="card-3"
          d="M191.241 200.577C193.897 200.577 196.051 198.423 196.051 195.766C196.051 193.109 193.897 190.955 191.241 190.955C188.584 190.955 186.43 193.109 186.43 195.766C186.43 198.423 188.584 200.577 191.241 200.577Z"
          fill="#8889E8"
        />
      </svg>
    </>
  );
};

export default SolutionsAnimation;
