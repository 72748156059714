import { ReactComponent as FinancialService } from '../images/industries/FinaniclaService_Accordian.svg';
import { ReactComponent as Healthcare } from '../images/industries/Healthcare_Accordian.svg';
import { ReactComponent as RetailAndEcommerce } from '../images/industries/RetailAndEcommerce_Accordian.svg';
import { ReactComponent as Manufacturing } from '../images/industries/Manufacturing_Accordian.svg';
import { ReactComponent as GovernmentAndPublicSector } from '../images/industries/GovernmentAndPublicSector_Accordian.svg';
import { ReactComponent as TechnologyAndItServices } from '../images/industries/TechnologyAndITService_Accordian.svg';
import { ReactComponent as EnergyAndUtilities } from '../images/industries/EnergyAndUtilities_Accordian.svg';
import { ReactComponent as Education } from '../images/industries/Education_Accordian.svg';

export const industriesData = [
  {
    title: 'Financial Services',
    contentPart:
      'In the fast-paced realm of financial services, trust and security are paramount. Our expertise helps banks, fintech firms, and financial institutions navigate regulatory waters while staying resilient against cyber threats.',
    imgSrc: <FinancialService />,
    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Healthcare',
    contentPart:
      'In healthcare, safeguarding sensitive patient data is non-negotiable. Our solutions enable healthcare providers to maintain compliance and protect critical healthcare information.',
    imgSrc: <Healthcare />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Retail and E-Commerce',
    contentPart:
      'The focus should always be on improving customer access, securing data, and enhancing the overall shopping experience while ensuring identity control at all organizational levels.',
    imgSrc: <RetailAndEcommerce />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Manufacturing',
    contentPart:
      'Manufacturing processes require uninterrupted operations. We fortify manufacturing units against cyber disruptions, ensuring seamless production.',
    imgSrc: <Manufacturing />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Government and Public Sector',
    contentPart:
      "Government agencies handle vast amounts of sensitive data. ComplySimpli's solutions bolster public trust by safeguarding government systems and citizens' information.",
    imgSrc: <GovernmentAndPublicSector />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Technology and IT Services',
    contentPart:
      'In the tech-driven world, cybersecurity is at the forefront. We empower tech and IT firms to innovate securely and protect their intellectual assets.',
    imgSrc: <TechnologyAndItServices />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Energy and Utilities',
    contentPart:
      'Reliable energy is critical. We ensure the integrity of energy and utility networks, shielding them from cyber threats.',
    imgSrc: <EnergyAndUtilities />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Education',
    contentPart:
      'Institutions of learning deserve a secure environment. Our solutions protect educational institutions, preserving the future of students.',
    imgSrc: <Education />,
    linkSrc: '/industries',
    linksbtn: true,
  },
];
