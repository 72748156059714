import React, { useEffect } from 'react';
import gsap from 'gsap';

const AboutAnimation = () => {
  useEffect(() => {
    const groups = gsap.utils.toArray("svg g[id^='group']");

    const tl = gsap.timeline({ repeat: -1, delay: 0.5 });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.001,
          ease: 'power2.out',
        }
      );
    });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          ease: 'power2.out',
        }
      );
    });
  }, []);
  return (
    <svg
      width="128"
      height="276"
      viewBox="0 0 128 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="group1">
        <path
          d="M109.248 47.5947C106.606 49.1319 103.572 49.977 100.517 50.0236C99.6656 50.0369 98.6808 49.9238 98.195 49.2251C97.8556 48.7393 97.869 48.0739 98.0886 47.5215C98.3081 46.9692 98.7141 46.5101 99.1333 46.0975C103.392 41.8587 109.847 41.2664 115.35 43.2761C120.407 45.126 123.116 49.6509 120.307 54.675C118.604 57.7227 115.463 59.7589 112.149 60.8436C108.835 61.9282 105.309 62.1811 101.828 62.4273C100.111 62.5471 97.7093 62.6935 96.4316 61.2562C95.3802 60.0717 95.6797 58.1086 96.6512 56.9641C97.7691 55.6465 99.5259 55.0876 101.216 54.7216C106.926 53.4839 112.961 53.8033 118.524 55.5933C120.68 56.2853 123.042 56.8842 123.222 59.5393C123.375 61.7552 121.159 63.5519 119.123 63.7915C117.173 64.0177 115.244 63.3456 113.394 62.6802L104.317 59.4129C103.286 59.0403 102.174 58.9005 101.096 59.1068C99.852 59.3397 98.6542 59.912 98.2948 61.2295C98.0287 62.1944 98.235 63.2258 98.5743 64.1708C100.471 69.4543 105.615 71.3176 110.785 71.6636C113.227 71.8299 116.661 72.4355 118.418 70.2063C119.429 68.922 118.93 67.0987 117.167 67.0055C116.468 66.9656 115.789 67.2185 115.184 67.5711C113.387 68.6092 112.143 70.5523 111.956 72.6152C111.777 74.5982 112.495 76.7941 114.159 77.9852C115.037 78.6174 116.149 78.9102 116.954 79.6289C117.892 80.4673 117.945 81.6385 117.1 82.5634C116.574 83.1424 115.842 83.495 115.13 83.8344"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <g id="group2">
        <path
          d="M42.7388 187.991V251.068L70.2612 273.466V209.844V137.71V94.8364V86.8911V2.53369L42.7388 50.2987V92.8002"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <g id="group3">
        <path
          d="M62.868 253.303C62.4687 255.019 61.3242 256.124 59.9201 255.279L59.8935 255.266C59.3079 254.906 58.3364 253.901 57.5645 252.105C57.7707 251.965 58.1966 251.686 58.3962 251.579C58.4162 251.566 58.4428 251.552 58.4694 251.546C58.4961 251.532 58.5227 251.519 58.5493 251.506C58.6824 251.759 58.8221 251.998 58.9752 252.218C59.4609 252.917 59.9001 253.196 59.9001 253.189H59.9068C60.6454 253.642 61.2443 253.223 61.5837 252.531L61.7434 252.63L62.8613 253.303H62.868Z"
          fill="#FFA900"
        />
        <path
          d="M57.7243 248.87C57.7243 248.87 57.6711 248.897 57.6445 248.91C57.4049 249.037 56.9125 249.363 56.7461 249.469C56.7062 249.283 56.6729 249.097 56.6397 248.904C56.6064 248.711 56.5731 248.511 56.5465 248.305C56.5465 248.305 56.5465 248.298 56.5465 248.292C56.5199 248.105 56.4933 247.919 56.46 247.739C56.3203 246.941 56.114 246.229 55.8545 245.603C55.7813 245.43 55.7081 245.264 55.6216 245.104C55.4752 244.825 55.3221 244.565 55.1491 244.326C54.6434 243.627 54.1376 243.347 54.1376 243.347C53.4655 242.941 52.8667 243.234 52.5539 244.033C52.5007 244.166 52.4541 244.312 52.4208 244.472L51.2363 243.753C51.2363 243.753 51.2363 243.7 51.243 243.68C51.2629 243.54 51.2829 243.407 51.3095 243.281C51.6888 241.331 52.84 240.486 54.1376 241.258C54.7565 241.584 55.7879 242.589 56.6064 244.505C56.6729 244.665 56.7461 244.831 56.806 245.011C57.0456 245.636 57.2519 246.342 57.4249 247.14C57.4648 247.327 57.4981 247.513 57.5314 247.706C57.5646 247.899 57.5979 248.092 57.6245 248.298C57.6245 248.338 57.6378 248.378 57.6378 248.418C57.6578 248.571 57.6844 248.717 57.711 248.864L57.7243 248.87Z"
          fill="#FFA900"
        />
        <path
          d="M56.2069 247.859C55.6679 248.072 54.9958 248.159 54.2106 247.64L52.7334 246.748L49.2065 244.618V242.536L52.7334 244.665L54.2239 245.563H54.2306C54.6631 245.85 55.0823 245.903 55.5947 245.716C55.6147 245.763 55.6347 245.81 55.6546 245.856C55.8875 246.408 56.0672 247.047 56.1936 247.759C56.1936 247.793 56.2069 247.826 56.2136 247.852L56.2069 247.859Z"
          fill="#FFA900"
        />
        <path
          d="M64.9973 254.068L62.9611 252.837L61.7566 252.111L59.9799 251.04H59.9733C59.5474 250.754 59.1082 250.707 58.5891 250.9C58.5026 250.933 58.4161 250.967 58.3296 251.013C58.303 251.027 58.283 251.04 58.2564 251.046C58.0102 251.173 57.4512 251.546 57.3714 251.605C57.3647 251.605 57.3581 251.612 57.3581 251.612C57.2849 251.659 57.205 251.712 57.1318 251.758C56.3267 252.251 55.4017 252.537 54.164 251.785C52.8265 250.973 51.6952 248.817 51.3159 246.415L52.5669 247.167C52.8797 248.312 53.4786 249.283 54.164 249.702C55.0756 250.255 55.7544 250.102 56.513 249.629C56.5729 249.589 56.6328 249.549 56.6993 249.509C56.6993 249.509 56.7193 249.496 56.7525 249.476C56.9189 249.363 57.4047 249.044 57.6509 248.917C57.6775 248.904 57.7041 248.89 57.7307 248.877C57.8106 248.837 57.8971 248.797 57.9836 248.764C58.5226 248.544 59.2013 248.451 59.9999 248.97L61.75 250.028L62.9544 250.754L65.004 251.991V254.074L64.9973 254.068Z"
          fill="#FFA900"
        />
        <path
          d="M62.941 250.747L61.7365 250.022C61.6966 249.815 61.6434 249.609 61.5768 249.403C61.2375 248.285 60.6319 247.293 59.9665 246.888C59.0748 246.349 58.4493 246.495 57.6641 246.994C57.6108 247.027 57.5576 247.061 57.4977 247.1C57.4977 247.1 57.4711 247.12 57.4245 247.147C57.2582 246.349 57.0452 245.637 56.8057 245.018C56.8256 245.004 56.8389 244.998 56.8389 244.998C56.9121 244.951 56.9787 244.905 57.0452 244.865C58.2896 244.099 59.0881 244.272 59.9665 244.805C61.2841 245.603 62.4353 247.839 62.8478 250.175C62.8811 250.368 62.9144 250.554 62.9343 250.747H62.941Z"
          fill="#FFA900"
        />
      </g>

      <g id="group4">
        <path
          d="M53.0327 102.554H63.2738"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.0327 108.843H63.2738"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.0327 115.124H63.2738"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <g id="group5">
        <path
          d="M20.8926 73.335L28.5052 82.0988"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.12451 91.6611L17.6451 96.5654"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 114L13.6052 114.206"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <path
        d="M97.6437 93.5177C92.3934 90.2504 86.1516 88.2009 79.7235 87.3025C65.7494 85.3461 49.4861 84.8138 39.1053 95.9865C34.6269 100.811 31.5326 106.92 30.3215 113.388C28.9973 120.475 31.7455 128.001 29.7625 134.875C28.6246 138.821 26.0427 142.248 22.8287 144.756C18.3503 148.237 16.1078 150.572 14.9965 152.083C14.2978 153.028 15.0364 154.352 16.2076 154.245C19.7411 153.933 26.1426 153.493 26.3355 154.472C27.8727 162.078 26.3422 169.464 30.2949 176.558C33.5289 182.373 38.9256 186.792 45.5533 187.996C48.8805 188.602 51.0365 188.715 52.4672 192.076C54.224 196.195 56.1271 217.262 55.8743 218.427L91.3687 196.84C91.3687 196.84 88.7202 174.874 90.0511 159.23C90.6234 152.509 94.2833 146.413 99.9062 142.693C108.603 136.944 112.703 126.37 111.864 116.182C111.012 105.835 105.409 98.3421 97.6371 93.511L97.6437 93.5177Z"
        stroke="#8889E8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.1628 113.98C39.3509 108.643 44.4815 102.308 48.3876 99.5598C56.985 93.5044 69.5485 93.4378 79.4901 95.3343C85.3326 96.4456 91.0487 98.901 95.2476 103.107C108.689 116.582 99.8458 144.324 78.9577 141.243C62.4948 138.814 73.4279 121.539 51.7081 120.461C49.1927 120.335 46.6375 120.089 44.3018 119.05C42.6781 118.325 41.1942 117.101 40.5487 115.444C40.3624 114.965 40.236 114.479 40.1628 113.98Z"
        stroke="#8889E8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="">
        <path
          d="M84.9133 101.276C81.1403 109.335 77.3673 117.387 73.5942 125.445"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.9551 108.563L88.6734 121.413"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.933 108.676C85.1533 115.424 81.3803 122.171 77.6006 128.912"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.3018 114.459V124.361"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.7935 123.648C84.3613 124.048 90.9292 124.454 97.5037 124.853"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.1646 115.23C92.9457 122.065 88.0281 128.612 82.4917 134.781"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.3076 129.93C85.7128 130.469 90.1247 131.008 94.5298 131.554"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.665 100.172L75.604 112.09"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.2671 102.98C79.6638 104.69 85.0605 106.4 90.4572 108.111"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.0337 117.174C79.9028 115.836 85.772 114.492 91.6411 113.154"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.9238 110.04C94.7357 113.906 95.5408 117.772 96.3527 121.645"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default AboutAnimation;
