export const strategyEnterpriseData = [
  {
    title: 'Enhanced Security',
    contentPart:
      'Our comprehensive approach leads to enhanced security, reducing the risk of data breaches and cyberattacks.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Regulatory Compliance',
    contentPart:
      'By aligning your IAM capabilities with industry standards, we ensure compliance with the latest regulations.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Informed Decision-Making',
    contentPart:
      'Our insights empower you to make well-informed decisions about your cybersecurity strategy.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Efficiency',
    contentPart:
      'We identify the most efficient path to compliance, streamlining your efforts and expenditures.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Continuous Monitoring',
    contentPart:
      'Our Virtual IAM Architect ensures that your security remains robust and adaptive, reducing the risk of security incidents.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
