import React from 'react';
import './style.scss';

const IndustriesAnimation = () => {
  return (
    <>
      <svg
        className="industries-animation-2"
        width="388"
        height="323"
        viewBox="0 0 388 323"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_9050)">
          {/* Factory Start */}
          <g className="factory">
            <path
              d="M8.86035 192.39V232.42H24.0304V180.34L8.86035 192.39Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.6299 192.39V232.42H47.7999V180.34L32.6299 192.39Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.78 143.6C22.78 139.95 19.82 137 16.18 137C13.49 137 11.17 138.62 10.15 140.93C9.65 140.81 9.13 140.74 8.6 140.74C4.95 140.74 2 143.7 2 147.34C2 150.98 4.96 153.94 8.6 153.94C10.83 153.94 12.8 152.83 14 151.13C14.03 153.17 15.69 154.81 17.73 154.81C19.77 154.81 21.47 153.14 21.47 151.07C21.47 150.11 21.1 149.24 20.5 148.58C21.9 147.37 22.79 145.59 22.79 143.59L22.78 143.6Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M47.1999 165.56C49.4899 162.73 49.0599 158.57 46.2199 156.27C43.3799 153.97 39.2299 154.41 36.9299 157.25C35.5299 158.99 35.1499 161.22 35.7199 163.21C34.1199 161.95 31.7999 162.21 30.5099 163.8C29.2099 165.41 29.4599 167.76 31.0599 169.06C31.8099 169.66 32.7099 169.92 33.5999 169.87C33.6599 171.72 34.4899 173.53 36.0399 174.78C38.8799 177.07 43.0299 176.64 45.3299 173.8C47.0199 171.71 47.2199 168.89 46.0699 166.64C46.4799 166.33 46.8599 165.97 47.1899 165.55L47.1999 165.56Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.7198 174.62C16.7198 174.62 16.7198 174.6 16.7198 174.58C19.9998 173.93 22.3198 170.88 21.9898 167.48C21.6498 163.85 18.4198 161.18 14.7898 161.53C11.1598 161.88 8.49983 165.1 8.83983 168.73C8.96983 170.1 9.50983 171.34 10.3298 172.32C9.55983 173.09 9.11983 174.16 9.22983 175.32C9.42983 177.39 11.2598 178.91 13.3298 178.71C15.3998 178.51 16.9198 176.68 16.7198 174.61V174.62Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            className="line-factory"
            d="M69 194L132 178"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />

          {/* Factory End */}

          {/* Dollar start */}
          <g className="dollar">
            <path
              d="M184.59 69.0598C184.59 61.4698 177.68 56.5898 170.08 56.5898C161.73 56.5898 154 61.3298 154 69.0598C154 74.8498 157.52 78.5598 170.08 80.5298C180.75 82.1998 185.45 83.6798 185.45 92.1098C185.45 100.54 178.57 104.39 170.08 104.39C162.46 104.39 154.86 100.5 154.86 92.1098"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M169.72 45V55.5"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M169.72 105.48V115.98"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          {/* Dollar end */}

          {/* Medical Start */}
          <g className="medical">
            <path
              d="M233.55 64.6H290.14L300.76 87.71L316.38 30.02L330.86 76.38L336.01 66.83H349.69"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M288.16 19.38H270.29V1.5H254.91V19.38H237.04V34.75H254.91V52.62H270.29V34.75H288.16V19.38Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>

          <path
            className="line-medical"
            d="M274 82L230 126"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />

          {/* Medical End */}

          {/* Setting Start */}
          <g className="setting">
            <path
              d="M362.84 157.8C359.21 157.82 356.24 154.89 356.22 151.25C356.2 147.62 359.14 144.66 362.78 144.64C366.42 144.62 369.37 147.55 369.39 151.19C369.41 154.83 366.48 157.78 362.84 157.8Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M358.53 134.35C359.88 134.02 361.27 133.83 362.72 133.82C364.16 133.82 365.57 133.98 366.9 134.3"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M375.39 163.22C377.32 161.2 378.77 158.71 379.54 155.95"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M350.34 163.34C348.39 161.34 346.92 158.87 346.12 156.12"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M367.07 168.08C365.73 168.41 364.33 168.6 362.89 168.61C361.45 168.62 360.04 168.45 358.7 168.13"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M346.07 146.48C346.84 143.72 348.28 141.24 350.22 139.21"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M379.49 146.32C378.69 143.57 377.22 141.1 375.27 139.1"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M379.49 146.32L386.77 146.28L386.82 155.91L379.54 155.95"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M366.91 134.31L370.51 128L378.88 132.77L375.27 139.09"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M358.53 134.35L354.87 128.07L346.55 132.93L350.22 139.22"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M346.12 156.12L338.85 156.15L338.8 146.52L346.07 146.48"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M358.7 168.13L355.1 174.44L346.73 169.66L350.34 163.34"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M367.07 168.08L370.74 174.36L379.06 169.5L375.39 163.22"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M326.95 179.84C324.71 181.44 321.6 180.93 320 178.7C318.4 176.47 318.92 173.35 321.15 171.75C323.38 170.15 326.5 170.66 328.1 172.9C329.7 175.14 329.19 178.25 326.95 179.85V179.84Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M314.02 167.24C314.71 166.44 315.49 165.72 316.38 165.08C317.27 164.44 318.21 163.94 319.18 163.55"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M337.07 177.69C337.38 175.6 337.18 173.43 336.45 171.39"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M321.65 188.73C319.57 188.35 317.58 187.47 315.88 186.12"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M334.06 184.34C333.38 185.13 332.59 185.86 331.71 186.49C330.83 187.12 329.88 187.63 328.91 188.03"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M311.64 180.19C310.91 178.15 310.71 175.98 311.02 173.89"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M332.21 165.46C330.51 164.11 328.53 163.23 326.44 162.85"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M332.21 165.46L336.68 162.25L340.93 168.18L336.45 171.39"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M319.18 163.55L318.64 158.08L325.9 157.36L326.44 162.84"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M314.02 167.24L309.02 164.97L306 171.61L311.02 173.89"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M315.89 186.12L311.41 189.32L307.16 183.4L311.64 180.19"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M328.91 188.03L329.45 193.5L322.19 194.21L321.65 188.73"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M334.06 184.34L339.08 186.61L342.09 179.96L337.07 177.69"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          {/* Setting End */}

          <g className="center-part">
            <path
              d="M232.55 167.126C229.86 174.406 222.08 181.126 212.58 181.136H212.4C208.41 181.136 201.87 179.576 196.64 174.626C198.05 173.646 200.93 171.636 202.29 170.786C202.45 170.696 202.6 170.596 202.77 170.496C202.96 170.376 203.15 170.266 203.33 170.166C204.22 170.816 205.18 171.386 206.22 171.856C209.52 173.346 212.48 173.396 212.5 173.376H212.56C217.54 173.376 221.64 170.466 223.9 167.136H232.56L232.55 167.126Z"
              fill="#FFA900"
            />
            <path
              d="M197.69 162.236C197.51 162.346 197.34 162.446 197.17 162.556C195.53 163.576 192.21 165.886 191.1 166.666C190.84 166.066 190.6 165.456 190.37 164.806C190.14 164.166 189.93 163.486 189.74 162.796C189.74 162.796 189.74 162.776 189.74 162.766C189.57 162.126 189.37 161.496 189.15 160.896C188.19 158.236 186.82 156.036 185.06 154.306C184.57 153.826 184.05 153.376 183.5 152.976C182.53 152.246 181.46 151.636 180.3 151.146C176.86 149.686 173.45 149.776 173.45 149.776C168.88 149.776 164.82 152.216 162.71 155.916C162.35 156.536 162.06 157.176 161.81 157.856H153.78C153.8 157.766 153.82 157.666 153.84 157.566C153.96 157.016 154.11 156.466 154.28 155.936C156.85 147.806 164.65 142.066 173.46 142.016C177.65 141.826 184.66 143.246 190.2 148.526C190.67 148.966 191.13 149.446 191.57 149.946C193.17 151.736 194.6 153.906 195.74 156.496C196 157.096 196.25 157.706 196.48 158.346C196.71 158.986 196.93 159.646 197.12 160.336C197.16 160.466 197.19 160.606 197.23 160.736C197.37 161.256 197.53 161.756 197.71 162.246L197.69 162.236Z"
              fill="#FFA900"
            />
            <path
              d="M187.43 161.886C183.76 163.906 179.21 165.736 173.89 165.596H163.89L140 165.646V157.886L163.88 157.846H173.98H174.03C176.95 157.926 179.81 157.156 183.27 155.316C183.41 155.436 183.55 155.566 183.68 155.696C185.24 157.226 186.46 159.206 187.31 161.576C187.35 161.686 187.39 161.786 187.43 161.896V161.886Z"
              fill="#FFA900"
            />
            <path
              d="M246.95 165.166L233.15 165.186H224.98L212.96 165.226H212.9C210 165.146 207.03 165.966 203.52 167.846C202.95 168.156 202.36 168.486 201.76 168.846C201.6 168.946 201.43 169.046 201.27 169.146C199.6 170.186 195.83 172.826 195.27 173.226C195.22 173.256 195.2 173.276 195.2 173.276C194.7 173.626 194.18 173.986 193.66 174.336C188.21 177.996 181.95 181.136 173.55 181.136C164.51 181.136 156.85 175.646 154.28 167.566H162.73C164.85 171.096 168.9 173.376 173.55 173.376C179.72 173.376 184.34 171.266 189.48 167.786C189.89 167.506 190.3 167.226 190.73 166.926C190.73 166.926 190.87 166.826 191.11 166.666C192.23 165.876 195.54 163.576 197.18 162.556C197.35 162.446 197.52 162.346 197.7 162.236C198.24 161.906 198.81 161.576 199.41 161.246C203.07 159.206 207.66 157.336 213.06 157.466L224.93 157.446H233.07L246.95 157.406V165.166Z"
              fill="#FFA900"
            />
            <path
              d="M233.06 157.427H224.92C224.63 156.767 224.28 156.117 223.86 155.507C221.56 152.097 217.47 149.777 212.93 149.777C206.91 149.777 202.67 151.737 197.34 155.377C196.97 155.627 196.6 155.877 196.22 156.147C196.22 156.147 196.04 156.277 195.72 156.497C194.58 153.907 193.15 151.737 191.55 149.947C191.69 149.857 191.76 149.797 191.76 149.797C192.24 149.467 192.7 149.147 193.16 148.837C201.57 143.197 207.01 142.017 212.93 142.017C221.84 142.017 229.66 147.747 232.47 155.487C232.7 156.117 232.9 156.767 233.06 157.427Z"
              fill="#FFA900"
            />
          </g>

          {/* Notebook Start */}

          <g className="notebook">
            <path
              d="M242.18 237.04H266.54"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M242.18 247.51H266.54"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M291.76 233.02V280.98C291.76 283.74 289.63 285.99 287.01 285.99H245.75L234.1 293.67C232.31 294.85 230 293.5 230 291.27V225.71C230 222.95 232.11 220.7 234.72 220.7H286.36"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M263.13 261.09L263.72 270.01L271.96 266.34L300.55 218.25L291.72 213L263.13 261.09Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            className="line-notebook"
            d="M227 191L239 208"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />

          {/* Notebook End */}

          {/*  Lighting Start*/}
          <g className="lighting">
            <path
              d="M48.0801 46.29H66.5101L54.4001 81.68L89.4301 40.1H70.0801L81.5501 7.54004L48.0801 46.29Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            className="line-lighting"
            d="M88 74.5L145 129"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/*  Lighting End*/}

          {/* Trolley start */}
          <g className="trolley">
            <path
              d="M103.9 263.83H174.59L165.96 295.28H116.69H112.89L103.9 263.83ZM103.9 263.83L99.9802 249.88L89.4102 244.7"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M167.69 305.2H112.89L116.69 295.28"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M113.87 321.46C117.04 321.46 119.61 318.891 119.61 315.72C119.61 312.55 117.04 309.98 113.87 309.98C110.7 309.98 108.13 312.55 108.13 315.72C108.13 318.891 110.7 321.46 113.87 321.46Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M168.66 321.46C171.83 321.46 174.4 318.891 174.4 315.72C174.4 312.55 171.83 309.98 168.66 309.98C165.49 309.98 162.92 312.55 162.92 315.72C162.92 318.891 165.49 321.46 168.66 321.46Z"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M125.84 263.83L132.21 293.98"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M152.26 263.83L147.69 293.98"
              stroke="#8889E8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            className="line-trolley"
            d="M170 201L161 246"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />

          {/* Trolley end */}
        </g>
        <defs>
          <clipPath id="clip0_1_9050">
            <rect width="387.11" height="322.95" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default IndustriesAnimation;
