export const opsupportEnterpriseData = [
  {
    title: '24/7 Reliability',
    contentPart: 'Confidence in a continuously secure environment',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Reduced Downtime',
    contentPart: 'Minimal disruption with rapid issue resolution',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Cost-Efficiency',
    contentPart: 'Savings from preventing major security incidents',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Peace of Mind',
    contentPart: 'Knowing your security is in good hands around the clock',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Long-Term Efficiency',
    contentPart: 'Extended lifespan of your security systems',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Access to Experts',
    contentPart: 'Expert advice and assistance for all security needs',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
