import React from 'react';
import './ResourcesSection.scss';
import { ReactComponent as Whitepapers } from '../../../assets/images/resources/Whitepapers.svg';
import { ReactComponent as EBooks } from '../../../assets/images/resources/EBooks.svg';
import { ReactComponent as Blogs } from '../../../assets/images/resources/Blogs.svg';
import { ReactComponent as Webinars } from '../../../assets/images/resources/Webinars.svg';
import { Link } from 'react-router-dom';

const ResourcesSection = () => {
  return (
    <div className="resources-section round-animation">
      <div className="animation-section-round1"></div>
      <div className="animation-section-round2"></div>
      <div className="animation-section-round3"></div>
      <div className="container">
        <div className="resources-row">
          <div className="resources-text">
            <label htmlFor="">RESOURCES</label>
            <h1>Unlock Valuable Resources</h1>
            <span>
              We offer a wealth of insightful resources to enhance your
              cybersecurity knowledge. Stay informed and make informed
              decisions.
            </span>
            <br />
            <Link to="/resources">
              <div className="deep-dive-link purpule">
                <a>Deep Dive</a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="16"
                  viewBox="0 0 46 16"
                  fill="none"
                >
                  <path
                    d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.5588V7.165H1V8.835Z"
                    fill="#9654F4"
                  />
                  <circle
                    cx="38.0589"
                    cy="8.00001"
                    r="7.10618"
                    stroke="#9654F4"
                    strokeWidth="1.67"
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div className="resources-box-data">
            <Link
              to="/resources"
              className="resources-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Whitepapers />
              <h1>Whitepapers</h1>
            </Link>
            <Link
              to="/resources"
              className="resources-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <EBooks />
              <h1>EBooks</h1>
            </Link>
            <Link
              to="/resources"
              className="resources-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Webinars />
              <h1>Webinars</h1>
            </Link>
            <Link
              to="/resources"
              className="resources-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Blogs />
              <h1>Blogs</h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesSection;
