export const ciamEnterpriseData = [
  {
    title: 'Digital Business Transformation',
    contentPart:
      'Support positive customer interactions and personalization across digital channels and applications',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Privacy Regulation Compliance',
    contentPart:
      'Simplified Compliance with data regulations in a dynamic privacy landscape by centralizing policies and fine-grained data access governance that can be used to enforce customer consent',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Enhanced Data Security',
    contentPart:
      "Safeguard your customers' data, reinforcing trust and loyalty.",
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Improved User Experience',
    contentPart:
      'Provide a seamless and user-friendly experience that keeps clients coming back.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Scalability',
    contentPart:
      'Our solutions adapt to your business growth, preventing the need for major overhauls.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Proactive Security',
    contentPart:
      'Address challenges before they become issues, by taking a proactive stance against the surging number of attack vectors and data breaches that pose significant threats to brands',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Continuous Improvement',
    contentPart:
      'Stay ahead of the curve with our innovative approach, continuously enhancing our solutions to meet the evolving demands of the digital landscape.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
