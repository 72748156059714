export const visionMissionData = [
  {
    title: 'Ultimate Goal',
    contentPart:
      'To make cybersecurity compliance accessible, simple, and effective for small and medium businesses, ensuring their digital environments are secure and resilient.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Vision',
    contentPart: (
      <ul>
        <li>
          We envision a digital landscape where cybersecurity seamlessly
          integrates with the operations of small and medium businesses in every
          aspect, simplifying compliance and fostering resilience.
        </li>
        <li>
          This future, driven by our expertise and tailored solutions, empowers
          businesses to focus on their core activities with confidence, knowing
          their digital assets are safeguarded in the ever-evolving digital
          realm.
        </li>
      </ul>
    ),
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Mission',
    contentPart: (
      <ul>
        <li>
          We are committed to redefine the landscape of cybersecurity compliance
          for small and medium businesses. With a relentless focus on
          simplicity, we aim to guide organizations through the intricacies of
          security standards and regulations.
        </li>
        <li>
          Our mission involves providing comprehensive assessments that unearth
          key insights, enabling the creation of tailored strategies that
          empower businesses to navigate the cybersecurity journey with
          confidence. By staying ahead of industry trends and maintaining a
          collaborative approach, we strive to be the trusted partner that
          enhances cybersecurity, ensures regulatory adherence, and fosters a
          culture of continuous improvement.
        </li>
      </ul>
    ),

    contentPart2: '',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
