export const focusData = [
  {
    title: 'Comprehensive Inventory',
    contentPart:
      'Identifying and cataloging all privileged accounts and access points across the enterprise.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Least Privilege Principle',
    contentPart:
      'Implementing least privilege access controls to restrict permissions based on user roles and responsibilities.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Centralized Management',
    contentPart:
      'Establishing a centralized platform to oversee and manage privileged accounts, access, and sessions',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Automated Provisioning',
    contentPart:
      'Employing automated systems for provisioning and deprovisioning privileges to reduce human error and ensure timely access',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
