export const enterpriseData = [
  {
    title: 'Centralize credential management',
    contentPart: 'Enforce consistent privileged access policies and controls',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Enforce Zero Trust',
    contentPart: 'Flexible least privilege enforcement methods',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Improve Security Posture',
    contentPart: 'Risk-based access enforcement, analysis, and reporting',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Compliance Adherence',
    contentPart: 'Streamlined reporting to help satisfy audit reviews.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Enhanced Security',
    contentPart:
      'Safeguards critical data by enforcing strict access controls and reducing the risk of unauthorized access or breaches.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Enhanced Productivity',
    contentPart:
      'Provides authorized users with efficient access to necessary resources, enhancing their productivity while maintaining security protocols',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
