import { ReactComponent as CybersecurityProjectManager } from '../images/services/staff/CybersecurityProjectManager_card.svg';
import { ReactComponent as SecurityArchitect } from '../images/services/staff/SecurityArchitect_card.svg';
import { ReactComponent as IdentityEngineer } from '../images/services/staff/IdentityEngineer_card.svg';
import { ReactComponent as IdentityDeveloper } from '../images/services/staff/IdentityDeveloper_card.svg';
import { ReactComponent as DevOpsEngineer } from '../images/services/staff/DevOpsEngineer_card.svg';

export const staffDigitalTeamData = [
  {
    title: 'Cybersecurity Project Manager',
    paragraphText:
      'The maestro of project orchestration, orchestrating and coordinating every element to ensure your cybersecurity initiatives are executed with precision and effectiveness.',
    imgComponent: <CybersecurityProjectManager />,
  },
  {
    title: 'Security Architect',
    paragraphText:
      "The guardian of your digital fortress, architecting and implementing robust security solutions to safeguard your organization's assets and data.",
    imgComponent: <SecurityArchitect />,
  },
  {
    title: 'Identity Engineer',
    paragraphText:
      'Crafting and maintaining secure digital identities, the Identity Engineer is dedicated to building a foundation of trust and security within your digital ecosystem.',
    imgComponent: <IdentityEngineer />,
  },
  {
    title: 'Identity Developer',
    paragraphText:
      'The artisan of secure identity applications, the Identity Developer crafts applications with a focus on robust security measures to protect user identities.',
    imgComponent: <IdentityDeveloper />,
  },
  {
    title: 'DevOps Engineer',
    paragraphText:
      'Bridging development and security, the DevOps Engineer ensures smooth operations by integrating security practices seamlessly into the development lifecycle.',
    imgComponent: <DevOpsEngineer />,
  },
];
