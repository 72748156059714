import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordian from '../Accordian/Accordian';

const AccordianSection = ({ dataArray }) => {
  const [openStates, setOpenStates] = useState(
    Array(dataArray.length).fill(false)
  );

  const toggleAccordion = (index) => {
    const newOpenStates = Array(openStates.length).fill(false);
    newOpenStates[index] = true;
    setOpenStates(newOpenStates);
  };

  const leaveAccordion = () => {
    const newOpenStates = Array(openStates.length).fill(false);
    setOpenStates(newOpenStates);
  };

  const onClickAccordian = (index) => {
    const newOpenStates = Array(openStates.length).fill(false);
    newOpenStates[index] = !openStates[index];
    setOpenStates(newOpenStates);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="accordian-section">
          {dataArray.map((data, index) => {
            return (
              <Accordian
                key={index}
                title={data.title}
                content={data.contentPart}
                ImageComponent={data.imgSrc}
                linkSrc={data.linkSrc}
                isAccordianOpen={openStates[index]}
                toggleAccordion={() => toggleAccordion(index)}
                onClickAccordian={() => onClickAccordian(index)}
              />
            );
          })}
        </div>
      ) : (
        <div className="accordian-section" onMouseLeave={leaveAccordion}>
          {dataArray.map((data, index) => {
            return (
              <Accordian
                key={index}
                title={data.title}
                content={data.contentPart}
                ImageComponent={data.imgSrc}
                linkSrc={data.linkSrc}
                isAccordianOpen={openStates[index]}
                toggleAccordion={() => toggleAccordion(index)}
                onClickAccordian={() => onClickAccordian(index)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

AccordianSection.propTypes = {
  dataArray: PropTypes.array.isRequired,
};

export default AccordianSection;
