import React from 'react';
import PropTypes from 'prop-types';
import './EnterpriseGainSection.scss';
import AccordianSection from '../../common/AccordianSection/AccordianSection';

const EnterpriseGainSection = ({ data }) => {
  return (
    <div>
      <div className="enterprisegains-section">
        <div className="container">
          <div className="enterprisegains-section-data">
            <h1>ENTERPRISE GAINS</h1>
            <AccordianSection dataArray={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

EnterpriseGainSection.propTypes = {
  data: PropTypes.array.isRequired,
};

export default EnterpriseGainSection;
