import { ReactComponent as FinancialService } from '../images/industries/Finance_ColorDarkBg.svg';
import { ReactComponent as Healthcare } from '../images/industries/IconHealthcare_ColorDarkBg.svg';
import { ReactComponent as RetailAndEcommerce } from '../images/industries/IconRetail_ColorDarkBg.svg';
import { ReactComponent as Manufacturing } from '../images/industries/IconManufacturing_ColorDarkBg.svg';
import { ReactComponent as GovernmentAndPublicSector } from '../images/industries/IconGovernment_ColorDarkBg.svg';
import { ReactComponent as TechnologyAndItServices } from '../images/industries/IconTechnology_ColorDarkBg.svg';
import { ReactComponent as EnergyAndUtilities } from '../images/industries/IconEnergy_ColorDarkBg.svg';
import { ReactComponent as Education } from '../images/industries/IconEducation_ColorDarkBg.svg';

export const industriesHeroData = [
  {
    title: 'Financial Services',
    contentPart:
      'We specialize in delivering IAM Compliance solutions for Financial Services. Our expertise lies in addressing the complex web of regulations governing this industry, ensuring the secure management of sensitive financial data and enabling smooth access across all levels of the organization.',
    imgSrc: <FinancialService />,
    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Healthcare',
    contentPart:
      'In the healthcare industry, data security is paramount. ComplySimpli takes pride in securing patient data while complying with HIPAA. Our solutions efficiently manage identities and access hierarchies, ensuring that sensitive healthcare data is safeguarded.',
    imgSrc: <Healthcare />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Retail and E-Commerce',
    contentPart:
      'Retail and e-commerce face unique identity and access challenges. Our industry-specific IAM solutions focus on improving customer access, securing data, and enhancing the overall shopping experience. We excel in managing retail-specific data and ensuring identity control at all organizational levels.',
    imgSrc: <RetailAndEcommerce />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Manufacturing',
    contentPart:
      'In the manufacturing industry, IAM plays a pivotal role in protecting operational data. We offer tailored solutions that focus on identity management across various levels of manufacturing organizations, securing sensitive data, and enhancing operational efficiency.',
    imgSrc: <Manufacturing />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Government and Public Sector',
    contentPart:
      'Our industry-specific IAM solutions ensure accessibility, security, and regulatory compliance for all citizen services. Our solutions cover a wide range of regulations, securing government-specific data, and managing identity hierarchies effectively.',
    imgSrc: <GovernmentAndPublicSector />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Technology and IT Services',
    contentPart:
      'Our advanced IAM solutions provide optimal access control, data security, and operational efficiency. Our expertise extends to managing IT-specific data and ensuring precise identity control.',
    imgSrc: <TechnologyAndItServices />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Energy and Utilities',
    contentPart:
      'Energy and utilities companies rely on IAM for data protection and operational continuity. We offers IAM solutions that focus on securing energy and utility data, managing identities across different organizational levels, and ensuring regulatory compliance.',
    imgSrc: <EnergyAndUtilities />,

    linkSrc: '/industries',
    linksbtn: true,
  },
  {
    title: 'Education',
    contentPart:
      'IAM in education is pivotal for the learning experience. We facilitate seamless access, protect sensitive educational data, and support the educational journey. We specialize in managing identity hierarchies across various educational institutions.',
    imgSrc: <Education />,
    linkSrc: '/industries',
    linksbtn: true,
  },
];
