import React, { useEffect, useState } from 'react';
import './OurWins.scss';
import OurWinsAnimation from '../../components/animations/OurWinsAnimation/OurWinsAnimation.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

import 'react-perfect-scrollbar/dist/css/styles.css';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export const OurWins = () => {
  useMoveToTopFeature();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  return (
    <div>
      <div className="secondmain-section ourwinsection round-animation">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <div className="secondmain-section-data">
            <div>
              <label htmlFor="">OUR WINS</label>
              <h1>Celebrating Impactful Client Journeys</h1>
              <span>
                We cherish the trust and heartfelt appreciation earned by
                profoundly reshaping our clients&apos; businesses across diverse
                industries. Our unwavering passion for excellence fuels our
                commitment, and these success stories stand as a testament to
                the sheer brilliance of our solutions.
              </span>
            </div>
            <div className="animationimg">
              <OurWinsAnimation></OurWinsAnimation>
            </div>
          </div>
          {isMobile ? (
            <SimpleBar
              forceVisible="x"
              autoHide={false}
              style={{ overflowX: 'auto' }}
            >
              <div className="secondmain-box-data">
                <div className="secondmain-box">
                  <div>
                    <h3>Financial Service Success</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="18"
                      viewBox="0 0 120 18"
                      fill="none"
                    >
                      <path
                        d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                        fill="#FFBA33"
                      />
                    </svg>
                    <span>
                      “They didn&apos;t just provide solutions; they ignited a
                      transformation. Our partnership with them turned
                      compliance from a burden into a strategic advantage. Their
                      expertise not only safeguarded our business but also
                      boosted our revenue.”
                    </span>
                  </div>
                  <div>
                    <h4>John Smith</h4>
                    <label htmlFor="">CEO, XYZ Bank</label>
                  </div>
                </div>
                <div className="secondmain-box">
                  <div>
                    <h3>Healthcare Triumph</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="18"
                      viewBox="0 0 120 18"
                      fill="none"
                    >
                      <path
                        d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                        fill="#FFBA33"
                      />
                    </svg>
                    <span>
                      “Working with ComplySimpli was like having a dedicated
                      guardian for our data. Their meticulous approach ensured
                      our compliance, and their solutions simplified our
                      processes. Our patients&apos; trust in us has never been
                      stronger.”
                    </span>
                  </div>
                  <div>
                    <h4>Dr. Emily Davis</h4>
                    <label htmlFor="">CIO of ABC Hospital</label>
                  </div>
                </div>
                <div className="secondmain-box">
                  <div>
                    <h3>Manufacturing Victory</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="18"
                      viewBox="0 0 120 18"
                      fill="none"
                    >
                      <path
                        d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                        fill="#FFBA33"
                      />
                    </svg>
                    <span>
                      “ComplySimpli&apos;s IAM solutions optimized our manufacturing
                      operations. They secured our sensitive data and improved
                      access control, contributing to operational efficiency.”
                    </span>
                  </div>
                  <div>
                    <h4>Robert Miller</h4>
                    <label htmlFor="">
                      Operations Director at LMN Manufacturing
                    </label>
                  </div>
                </div>
                <div className="secondmain-box">
                  <div>
                    <h3>Impact is Incredible</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="18"
                      viewBox="0 0 120 18"
                      fill="none"
                    >
                      <path
                        d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                        fill="#FFBA33"
                      />
                    </svg>
                    <span>
                      “Navigating the complex regulatory landscape is no small
                      feat. ComplySimpli didn&apos;t just guide us; they held our
                      hand through it all. Their solutions fortified our
                      security and improved our efficiency. We&apos;ve become a model
                      of compliance in our sector.”
                    </span>
                  </div>
                  <div>
                    <h4>Jane Adams</h4>
                    <label htmlFor="">Government Officer</label>
                  </div>
                </div>
                <div className="secondmain-box">
                  <div>
                    <h3>Retail Success Story</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="18"
                      viewBox="0 0 120 18"
                      fill="none"
                    >
                      <path
                        d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                        fill="#FFBA33"
                      />
                    </svg>
                    <span>
                    &quot;ComplySimpli is the reason our business thrives today.
                      Their tailored solutions not only secured our customer
                      data but also streamlined our operations. We&apos;ve witnessed
                      a remarkable boost in customer loyalty and bottom-line
                      growth.&quot;
                    </span>
                  </div>
                  <div>
                    <h4>Sarah Johnson</h4>
                    <label htmlFor="">COO of ZYX Retail</label>
                  </div>
                </div>
                <div className="secondmain-box">
                  <div>
                    <h3>Technology and IT Excellence</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="18"
                      viewBox="0 0 120 18"
                      fill="none"
                    >
                      <path
                        d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                        fill="#FFBA33"
                      />
                    </svg>
                    <span>
                    &quot;ComplySimpli wasn&apos;t just a service provider; they were a
                      partner in our success. Their solutions didn&apos;t just secure
                      our data; they opened doors to new opportunities. We&apos;ve
                      scaled faster, attracted more investors, and all thanks to
                      our trusted partnership.&quot;
                    </span>
                  </div>
                  <div>
                    <h4>Mark Walker</h4>
                    <label htmlFor="">CTO of PQR Tech</label>
                  </div>
                </div>
              </div>
            </SimpleBar>
          ) : (
            <div className="secondmain-box-data">
              <div className="secondmain-box">
                <div>
                  <h3>Financial Service Success</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="18"
                    viewBox="0 0 120 18"
                    fill="none"
                  >
                    <path
                      d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                      fill="#FFBA33"
                    />
                  </svg>
                  <span>
                    “They didn&apos;t just provide solutions; they ignited a
                    transformation. Our partnership with them turned compliance
                    from a burden into a strategic advantage. Their expertise
                    not only safeguarded our business but also boosted our
                    revenue.”
                  </span>
                </div>
                <div>
                  <h4>John Smith</h4>
                  <label htmlFor="">CEO, XYZ Bank</label>
                </div>
              </div>
              <div className="secondmain-box">
                <div>
                  <h3>Healthcare Triumph</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="18"
                    viewBox="0 0 120 18"
                    fill="none"
                  >
                    <path
                      d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                      fill="#FFBA33"
                    />
                  </svg>
                  <span>
                    “Working with ComplySimpli was like having a dedicated
                    guardian for our data. Their meticulous approach ensured our
                    compliance, and their solutions simplified our processes.
                    Our patients&apos; trust in us has never been stronger.”
                  </span>
                </div>
                <div>
                  <h4>Dr. Emily Davis</h4>
                  <label htmlFor="">CIO of ABC Hospital</label>
                </div>
              </div>
              <div className="secondmain-box">
                <div>
                  <h3>Manufacturing Victory</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="18"
                    viewBox="0 0 120 18"
                    fill="none"
                  >
                    <path
                      d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                      fill="#FFBA33"
                    />
                  </svg>
                  <span>
                    “ComplySimpli&apos;s IAM solutions optimized our manufacturing
                    operations. They secured our sensitive data and improved
                    access control, contributing to operational efficiency.”
                  </span>
                </div>
                <div>
                  <h4>Robert Miller</h4>
                  <label htmlFor="">
                    Operations Director at LMN Manufacturing
                  </label>
                </div>
              </div>
              <div className="secondmain-box">
                <div>
                  <h3>Impact is Incredible</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="18"
                    viewBox="0 0 120 18"
                    fill="none"
                  >
                    <path
                      d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                      fill="#FFBA33"
                    />
                  </svg>
                  <span>
                    “Navigating the complex regulatory landscape is no small
                    feat. ComplySimpli didn&apos;t just guide us; they held our hand
                    through it all. Their solutions fortified our security and
                    improved our efficiency. We&apos;ve become a model of compliance
                    in our sector.”
                  </span>
                </div>
                <div>
                  <h4>Jane Adams</h4>
                  <label htmlFor="">Government Officer</label>
                </div>
              </div>
              <div className="secondmain-box">
                <div>
                  <h3>Retail Success Story</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="18"
                    viewBox="0 0 120 18"
                    fill="none"
                  >
                    <path
                      d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                      fill="#FFBA33"
                    />
                  </svg>
                  <span>
                  &quot;ComplySimpli is the reason our business thrives today.
                    Their tailored solutions not only secured our customer data
                    but also streamlined our operations. We&apos;ve witnessed a
                    remarkable boost in customer loyalty and bottom-line
                    growth.&quot;
                  </span>
                </div>
                <div>
                  <h4>Sarah Johnson</h4>
                  <label htmlFor="">COO of ZYX Retail</label>
                </div>
              </div>
              <div className="secondmain-box">
                <div>
                  <h3>Technology and IT Excellence</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="18"
                    viewBox="0 0 120 18"
                    fill="none"
                  >
                    <path
                      d="M8.8 0.612941L11.5187 6.33683L17.6 7.25425L13.1994 11.7097L14.2387 18L8.8 15.031L3.36128 18L4.40064 11.711L0 7.25292L6.08 6.3355L8.8 0.612941ZM34.4 0L37.12 5.72389L43.2 6.64131L38.7994 11.0954L39.8387 17.3871L34.4 14.4168L28.9613 17.3871L29.9994 11.0968L25.6 6.64131L31.68 5.72389L34.4 0ZM60 0L62.7187 5.72389L68.8 6.64131L64.3994 11.0954L65.4387 17.3871L60 14.4168L54.5613 17.3871L55.6006 11.0968L51.2 6.64131L57.28 5.72389L60 0ZM85.6 0L88.3187 5.72389L94.4 6.64131L89.9994 11.0954L91.0387 17.3871L85.6 14.4168L80.1613 17.3871L81.2006 11.0968L76.8 6.64131L82.88 5.72389L85.6 0ZM111.2 0L113.919 5.72389L120 6.64131L115.599 11.0954L116.639 17.3871L111.2 14.4168L105.761 17.3871L106.799 11.0968L102.4 6.64131L108.48 5.72389L111.2 0Z"
                      fill="#FFBA33"
                    />
                  </svg>
                  <span>
                  &quot;ComplySimpli wasn&apos;t just a service provider; they were a
                    partner in our success. Their solutions didn&apos;t just secure
                    our data; they opened doors to new opportunities. We&apos;ve
                    scaled faster, attracted more investors, and all thanks to
                    our trusted partnership.&quot;
                  </span>
                </div>
                <div>
                  <h4>Mark Walker</h4>
                  <label htmlFor="">CTO of PQR Tech</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Lets Begin Already */}
      {/* <div className="ourletsbegin-section">
        <div className="container">
          <div className="ourletsbegin-text">
            <label htmlFor="">LET’S BEGIN ALREADY!</label>
            <h1>Ready to Transform Your Story?</h1>
            <span>
              Your success story could be next. Partner with ComplySimpli and{" "}
              <br />
              experience tailored IAM solutions that make a real difference.
              Take the first <br />
              step to towards compliance simplicity at it’s best.
            </span>
            <br />
            <Link to="/contact">
              <button>
                Connect With Us
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.4043 1.65436L14.7499 8.00001L8.4043 14.3457"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.7499 8L1.25 8"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div> */}

      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Ready to Transform Your Story?"
          bodyText="Your success story could be next. Partner with ComplySimpli and experience tailored IAM solutions that make a real difference. Take the first step to towards compliance simplicity at it’s best."
          linkSrc="/contact?focus=contact"
          btnText="Connect With Us"
        />
      </div>
    </div>
  );
};
