import gsap, { CSSPlugin } from 'gsap';
import React, { useEffect } from 'react';

const StrategyAnimation = () => {
  gsap.registerPlugin(CSSPlugin);

  useEffect(() => {
    gsap.set('.animation-timeline svg g *', {
      stroke: '#8889E8',
      strokeWidth: 3,
    });

    const groups = gsap.utils.toArray("svg g[id^='group']");
    const timeline = gsap.timeline({ repeat: -1 }); // repeat: -1 for infinite iteration

    groups.forEach((group, index) => {
      timeline.add(
        () => {
          const prevGroupIndex = index === 0 ? groups.length - 1 : index - 1;
          const prevGroup = groups[prevGroupIndex];

          gsap.fromTo(
            group.children,
            {
              stroke: '#8889E8',
              strokeWidth: 3,
            },
            {
              stroke: index === groups.length - 1 ? '#fdbd19' : '#8133F1',
              // duration: 1,
              // ease: "linear",
            }
          );

          gsap.fromTo(
            prevGroup.children,
            {
              stroke: index === 6 ? '#fdbd19' : '#8133F1',
            },
            {
              stroke: '#8889E8',
              strokeWidth: 3,
              // duration: 1,
              // ease: "linear",
            }
          );
        },
        `${(index + 1) * 1}`
      );
    });

    // Additional duration for the last animation to allow smooth color transition
    timeline.duration((groups.length + 1) * 1.3);
  }, []);

  return (
    <div className="animation-timeline">
      <svg
        width="310"
        height="201"
        viewBox="0 0 310 201"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <g clipPath="url(#clip0_743_14829)"> */}
        <g id="group1">
          <path
            className="g1"
            d="M21.4567 45.0112C30.3746 45.0112 37.6039 37.7818 37.6039 28.864C37.6039 19.9461 30.3746 12.7168 21.4567 12.7168C12.5389 12.7168 5.30957 19.9461 5.30957 28.864C5.30957 37.7818 12.5389 45.0112 21.4567 45.0112Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="g1"
            d="M21.4562 34.239C24.4248 34.239 26.8313 31.8325 26.8313 28.8639C26.8313 25.8953 24.4248 23.4888 21.4562 23.4888C18.4876 23.4888 16.0811 25.8953 16.0811 28.8639C16.0811 31.8325 18.4876 34.239 21.4562 34.239Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group2">
          <path
            d="M159.941 18.0151H172.866"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M145.423 18.0152L148.853 20.8229L154.381 12.7166"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M159.941 30.1091H172.866"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M145.423 30.1092L148.853 32.9169L154.381 24.8105"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M159.941 42.2031H172.866"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M145.423 42.2031L148.853 45.0108L154.381 36.8936"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M173.871 1.63867H143.521C139.4 1.63867 136.06 4.97943 136.06 9.10046V48.6272C136.06 52.7482 139.4 56.089 143.521 56.089H173.871C177.992 56.089 181.333 52.7482 181.333 48.6272V9.10046C181.333 4.97943 177.992 1.63867 173.871 1.63867Z"
            stroke="#8133F1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group3">
          <path
            d="M99.898 84.5488H78.1572V93.3872H99.898V84.5488Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M105.361 93.4309H84.9424V102.269H105.361V93.4309Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M129.876 111.424H94.0859V120.033H129.876V111.424Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.4482 102.837V125.988"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.4482 84.5488V78.8022"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M99.8984 78.8022V84.5488"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M99.8984 125.998V120.022"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M99.8984 109.283V103.591"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M111.839 120.121V125.987"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M111.839 78.8022V110.321"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.528 78.8022V110.321"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.912 120.066V125.988"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group4">
          <path
            d="M61.5518 197.754H96.0311"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.4414 180.58V195.274"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M78.791 175.816V195.274"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M90.1426 171.064V195.274"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.5059 169.6L67.4389 173.588L96.0296 158.992"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.5254 157.047L96.0309 158.992L94.3594 165.514"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group5">
          <path
            d="M186.436 156.927C191.691 168.245 204.386 161.122 204.386 161.122C204.386 161.122 211.039 192.258 185.977 199.141C160.904 192.258 167.568 161.122 167.568 161.122C167.568 161.122 180.252 168.245 185.518 156.927C185.682 156.566 186.283 156.566 186.458 156.927H186.436Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M177.554 179.815L183.923 185.092L197.907 172.222"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group6">
          <path
            d="M271.598 155.277V152.24V123.147L307.738 121.781L293.076 137.022L307.738 152.24H271.598V155.277ZM271.598 155.277V176.297"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M271.596 191.614C275.826 191.614 279.254 188.185 279.254 183.956C279.254 179.726 275.826 176.297 271.596 176.297C267.366 176.297 263.938 179.726 263.938 183.956C263.938 188.185 267.366 191.614 271.596 191.614Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <path
          id="p1"
          d="M191.145 28.864L240.963 28.8859C270.002 28.9077 293.512 45.5902 293.512 66.1511C293.512 86.7448 269.936 103.427 240.865 103.416L136.979 103.384"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          id="p2"
          d="M158.249 183.966H103.843"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="p3"
          d="M256.182 183.956H213.269"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="p4"
          d="M126.687 28.864H45.9619"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          id="p5"
          d="M52.8661 183.759C39.4065 182.852 27.3562 178.57 18.3103 172.157C7.97518 164.87 1.63867 154.786 1.63867 143.675C1.63867 121.421 27.1049 103.384 58.5252 103.384H74.4976"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default StrategyAnimation;
