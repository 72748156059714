export const enterpriseData = [
  {
    title: 'Employee Experience',
    contentPart:
      'Enhance employee experiences via streamlined single sign-on, federated access, unified user views, and adherence to modern standards compliance',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Single Source of Truth',
    contentPart:
      'Unify diverse systems to maximize the value of existing and new investments, culminating in a comprehensive user view facilitated by directory services',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Strong Authentication',
    contentPart:
      'Identify and protect against fraudulent and malicious activities by adopting a Zero Trust security model',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Scalability',
    contentPart:
      'Support across on-premises, cloud, and SaaS resources using industry standard modern protocols',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Ease of Cloud Migration',
    contentPart:
      'Portable modern Identity solution deployable in a public, private or managed cloud environment',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
