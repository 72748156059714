import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const OfferCard = ({ title, paragraph, linkSrc, imgSrc }) => {
  return (
    <div className="serandsol-box" data-aos="fade-up" data-aos-duration="1000">
      <div className="for-flex">
        <div>
          <h4>{title}</h4>
          <br />
          <span>{paragraph}</span>
          <br />
        </div>
        <Link to={linkSrc}>
          <div className="deep-dive-link">
            <a>Deep Dive</a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="16"
              viewBox="0 0 46 16"
              fill="none"
            >
              <path
                d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.0115V7.165H1V8.835Z"
                fill="#FFF"
              />
              <circle
                cx="37.3726"
                cy="7.99994"
                r="6.95913"
                stroke="#fff"
                strokeWidth="1.67"
              />
            </svg>
          </div>
        </Link>
      </div>
      <img src={imgSrc} alt="" />
    </div>
  );
};

OfferCard.propTypes = {
  title: PropTypes.array.isRequired,
  paragraph: PropTypes.array.isRequired,
  linkSrc: PropTypes.array.isRequired,
  imgSrc: PropTypes.array.isRequired,
};

export default OfferCard;
