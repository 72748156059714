export const focusData = [
  {
    title: 'Unified view of Corporate Identity',
    contentPart:
      'Get a holistic view of identity and an understanding of access risk across the organization',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Automated Identity Lifecycle Management',
    contentPart:
      'Our solutions offer a balance between stringent security and user-friendliness, allowing your clients to manage their digital identities seamlessly.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Role based Access Control (RBAC)',
    contentPart:
      'We provide CIAM solutions that grow with your business, adapting to changing requirements and ensuring long-term viability.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Support for diverse environments',
    contentPart:
      'Our solutions are rooted in vendor-agnostic principles and industry-standard access protocols to ensure interoperability across platforms and services',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
