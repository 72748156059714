import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Contact.scss';
import ContactHeroAnimation from '../../components/animations/ContactHeroAnimation/ContactHeroAnimation.jsx';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import TextSlider from '../../components/common/TextSlider/TextSlider';
import emailjs from '@emailjs/browser';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import img from '../../assets/images/Frame 82.png';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const Contact = () => {
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const userId = process.env.REACT_APP_USER_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;

  const [emailData, setEmailData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    message: '',
  });

  const formRef = useRef(null);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setEmailData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(serviceId);
    console.log(templateId);
    console.log(emailData);
    console.log(publicKey);
    console.log(userId);
    if (
      !emailData.name ||
      !emailData.phone ||
      !emailData.email ||
      !emailData.company ||
      !emailData.message
    ) {
      alert('Please fill all the details');
    } else {
      emailjs
        .send(
          serviceId,
          templateId,
          emailData,
          publicKey
          // "service id here",
          // "template id here",
          // emailData,
          // "public id here"
        )
        .then(
          (result) => {
            console.log(result);
            console.log('Email sent');
            alert('Email is sent');
            setEmailData({
              ...emailData,
              name: '',
              phone: '',
              email: '',
              company: '',
              message: '',
            });
          },
          (error) => {
            console.log('Error exist');
            console.log(error);
          }
        );
    }
  };

  useMoveToTopFeature();

  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('focus=contact')) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
      formRef.current.focus();
    }
  }, [location]);

  return (
    <div>
      {/* Hero section */}
      <div className="contact-hero-section">
        <HeroSection
          labelText="CONTACT US"
          mainText="Let's Simplify Your Compliance Journey Together"
          paragarphText="Ignite Success with the Ultimate Compliance Experience Awaiting You!"
          AnimationComponent={<ContactHeroAnimation></ContactHeroAnimation>}
        />
      </div>

      {/* Marque Headline */}
      <div className="marque-section">
        <TextSlider>
          <svg
            width="1440"
            height="84"
            viewBox="0 0 1440 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.848633 64.3257H43.4853V51.5704H15.1552V37.9941H42.1209V25.2388H15.1552V13.5761H43.4853V0.820801H0.848633V64.3257Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M80.9303 19.4074L73.0043 32.7969L65.0782 19.4074H49.0448L63.8043 41.6345L49.0448 64.3257H64.9876L73.0043 50.5683L80.9303 64.3257H96.9693L82.023 41.6345L96.9693 19.4074H80.9303Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M138.695 21.457C135.96 19.4245 132.648 18.4054 128.764 18.4054C126.941 18.4054 125.045 18.7678 123.069 19.4981C121.093 20.2284 119.304 21.6381 117.696 23.7329C117.424 24.0839 117.17 24.4802 116.92 24.8935V19.4018H103.254V82.9068H116.92V58.7322C117.068 58.9757 117.215 59.2191 117.373 59.4456C118.828 61.5403 120.578 63.0463 122.61 63.9578C124.643 64.8693 126.692 65.3222 128.759 65.3222C132.161 65.3222 135.241 64.4107 138.004 62.5877C140.767 60.7647 142.969 58.1094 144.611 54.6163C146.253 51.1232 147.074 46.8884 147.074 41.9063C147.074 37.411 146.36 33.3857 144.933 29.836C143.507 26.2806 141.423 23.4894 138.695 21.4513V21.457ZM132.269 47.7433C131.572 49.3851 130.57 50.6306 129.262 51.4798C127.955 52.3291 126.364 52.7537 124.479 52.7537C122.593 52.7537 121.076 52.3121 119.74 51.4346C118.404 50.557 117.385 49.3115 116.688 47.698C115.992 46.0901 115.641 44.1595 115.641 41.9119C115.641 39.6643 115.992 37.6432 116.688 36.0353C117.385 34.4274 118.404 33.1819 119.74 32.2987C121.076 31.4212 122.656 30.9796 124.479 30.9796C126.302 30.9796 127.955 31.4042 129.262 32.2534C130.57 33.1026 131.572 34.3482 132.269 35.99C132.965 37.6318 133.316 39.602 133.316 41.9119C133.316 44.2218 132.965 46.1014 132.269 47.7433Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M190.854 25.1483C189.059 23.0252 186.828 21.3664 184.156 20.1831C181.484 18.9999 178.387 18.4054 174.866 18.4054C170.857 18.4054 167.438 19.1018 164.618 20.5002C161.793 21.8986 159.517 23.7499 157.785 26.0598C156.052 28.3696 154.779 30.9173 153.958 33.7141C153.137 36.5109 152.729 39.302 152.729 42.0988C152.729 44.8955 153.137 47.8565 153.958 50.6193C154.779 53.3821 156.098 55.8732 157.921 58.0925C159.744 60.3118 162.11 62.0725 165.026 63.3746C167.942 64.6824 171.463 65.3335 175.596 65.3335C178.268 65.3335 180.623 65.1354 182.656 64.7391C184.688 64.3428 186.681 63.6747 188.623 62.7349C190.565 61.7951 192.722 60.5326 195.094 58.953L189.263 49.2945C187.564 50.6306 185.56 51.678 183.25 52.4366C180.94 53.1953 178.693 53.5746 176.507 53.5746C174.016 53.5746 171.814 53.0254 169.9 51.9328C167.987 50.8401 166.849 49.0794 166.487 46.6506H196.006C196.124 45.6825 196.204 44.8616 196.232 44.1935C196.26 43.5255 196.277 42.8857 196.277 42.2799C196.277 38.8151 195.824 35.6277 194.913 32.712C194.001 29.7963 192.648 27.277 190.859 25.1483H190.854ZM166.3 36.5392C166.481 35.5711 166.877 34.5633 167.483 33.5329C168.089 32.5025 169.017 31.6193 170.263 30.889C171.508 30.1587 173.133 29.7963 175.137 29.7963C176.23 29.7963 177.26 29.9662 178.234 30.2946C179.202 30.6286 180.069 31.0985 180.833 31.7043C181.591 32.31 182.186 33.0574 182.611 33.9349C182.979 34.6992 183.177 35.5654 183.228 36.5335H166.305L166.3 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M229.347 18.4054C227.218 18.4054 225.168 18.9829 223.198 20.1378C221.222 21.2928 219.615 22.8723 218.369 24.8765C218.256 25.0577 218.154 25.2445 218.052 25.4313V19.4075H204.204V64.3258H217.961V46.1977C217.961 43.6443 218.035 41.7194 218.188 40.4116C218.341 39.1038 218.567 38.0281 218.873 37.1789C219.297 36.024 219.858 35.0672 220.56 34.3085C221.256 33.5499 222.077 32.9894 223.017 32.6214C223.957 32.2591 224.942 32.0723 225.978 32.0723C227.071 32.0723 228.163 32.2421 229.256 32.5761C230.349 32.9102 231.198 33.2895 231.809 33.7141L238.371 21.3211C236.791 20.2907 235.286 19.5434 233.859 19.0905C232.432 18.6375 230.926 18.4054 229.347 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M258.044 0.820801H244.015V13.2138H258.044V0.820801Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M257.863 19.4074H244.106V64.3257H257.863V19.4074Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M303.733 25.1483C301.938 23.0252 299.708 21.3664 297.035 20.1831C294.363 18.9999 291.266 18.4054 287.745 18.4054C283.737 18.4054 280.317 19.1018 277.498 20.5002C274.673 21.8986 272.397 23.7499 270.664 26.0598C268.932 28.3696 267.658 30.9173 266.837 33.7141C266.016 36.5109 265.608 39.302 265.608 42.0988C265.608 44.8955 266.016 47.8565 266.837 50.6193C267.658 53.3821 268.977 55.8732 270.8 58.0925C272.623 60.3118 274.99 62.0725 277.905 63.3746C280.821 64.6824 284.342 65.3335 288.475 65.3335C291.147 65.3335 293.503 65.1354 295.535 64.7391C297.568 64.3428 299.56 63.6747 301.502 62.7349C303.444 61.7951 305.601 60.5326 307.973 58.953L302.142 49.2945C300.444 50.6306 298.439 51.678 296.13 52.4366C293.82 53.1953 291.572 53.5746 289.387 53.5746C286.896 53.5746 284.693 53.0254 282.78 51.9328C280.866 50.8401 279.728 49.0794 279.366 46.6506H308.885C309.004 45.6825 309.083 44.8616 309.111 44.1935C309.14 43.5255 309.157 42.8857 309.157 42.2799C309.157 38.8151 308.704 35.6277 307.792 32.712C306.881 29.7963 305.528 27.277 303.739 25.1483H303.733ZM279.179 36.5392C279.36 35.5711 279.757 34.5633 280.362 33.5329C280.968 32.5025 281.897 31.6193 283.142 30.889C284.388 30.1587 286.012 29.7963 288.017 29.7963C289.109 29.7963 290.14 29.9662 291.113 30.2946C292.082 30.6286 292.948 31.0985 293.712 31.7043C294.471 32.31 295.065 33.0574 295.49 33.9349C295.858 34.6992 296.056 35.5654 296.107 36.5335H279.185L279.179 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M343.595 18.4054C341.71 18.4054 339.785 18.7395 337.809 19.4075C335.834 20.0756 334.056 21.2475 332.482 22.9176C331.87 23.563 331.327 24.316 330.84 25.1652V19.4132H317.083V64.3314H330.84V43.3783C330.84 40.3437 331.146 37.864 331.752 35.9504C332.357 34.0368 333.286 32.6271 334.531 31.7156C335.777 30.8041 337.368 30.3512 339.315 30.3512C341.806 30.3512 343.612 31.0815 344.739 32.5365C345.86 33.9972 346.426 36.3354 346.426 39.5511V64.3314H360.184V34.4501C360.184 29.5302 358.729 25.6295 355.813 22.7421C352.897 19.8604 348.827 18.4167 343.607 18.4167L343.595 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M384.907 32.0269C386.215 30.9059 387.93 30.3398 390.053 30.3398C392.363 30.3398 394.135 30.9456 395.381 32.1628C396.626 33.38 397.549 34.9256 398.161 36.8109L410.095 32.0722C408.397 27.4581 405.815 24.0272 402.35 21.7796C398.885 19.532 394.786 18.411 390.048 18.411C386.221 18.411 382.897 19.0791 380.072 20.4152C377.247 21.7513 374.892 23.529 373.012 25.7427C371.127 27.962 369.734 30.4643 368.823 33.2611C367.911 36.0579 367.458 38.9113 367.458 41.8269C367.458 44.7426 367.911 47.596 368.823 50.3928C369.734 53.1896 371.133 55.7089 373.012 57.9565C374.892 60.2041 377.247 61.9988 380.072 63.3293C382.897 64.6654 386.221 65.3335 390.048 65.3335C394.786 65.3335 398.885 64.2125 402.35 61.9649C405.815 59.7173 408.391 56.2864 410.095 51.6723L398.161 46.9336C397.555 48.8189 396.626 50.3645 395.381 51.5817C394.135 52.7989 392.358 53.4047 390.053 53.4047C387.925 53.4047 386.209 52.8442 384.907 51.7176C383.599 50.5966 382.659 49.1359 382.082 47.3469C381.504 45.5579 381.216 43.7179 381.216 41.8326C381.216 39.9473 381.504 38.13 382.082 36.3636C382.659 34.6029 383.599 33.1592 384.907 32.0382V32.0269Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M457.476 32.7063C456.565 29.7907 455.211 27.2713 453.422 25.1426C451.628 23.0195 449.397 21.3607 446.725 20.1775C444.053 18.9942 440.956 18.3998 437.434 18.3998C433.426 18.3998 430.006 19.0961 427.187 20.4945C424.362 21.8929 422.086 23.7442 420.354 26.0541C418.621 28.364 417.347 30.9116 416.526 33.7084C415.706 36.5052 415.298 39.2963 415.298 42.0931C415.298 44.8899 415.706 47.8508 416.526 50.6136C417.347 53.3764 418.667 55.8675 420.49 58.0868C422.313 60.3061 424.679 62.0668 427.595 63.369C430.51 64.6768 434.032 65.3278 438.165 65.3278C440.837 65.3278 443.192 65.1297 445.225 64.7334C447.257 64.3371 449.25 63.669 451.192 62.7292C453.134 61.7894 455.291 60.5269 457.663 58.9473L451.832 49.2888C450.133 50.625 448.129 51.6723 445.819 52.431C443.509 53.1896 441.262 53.5689 439.076 53.5689C436.585 53.5689 434.383 53.0198 432.469 51.9271C430.556 50.8344 429.418 49.0737 429.055 46.6449H458.574C458.693 45.6768 458.773 44.8559 458.801 44.1878C458.829 43.5198 458.846 42.88 458.846 42.2743C458.846 38.8094 458.393 35.622 457.482 32.7063H457.476ZM428.869 36.5335C429.05 35.5654 429.446 34.5576 430.052 33.5273C430.658 32.4969 431.586 31.6137 432.832 30.8833C434.077 30.153 435.702 29.7907 437.706 29.7907C438.799 29.7907 439.829 29.9605 440.803 30.2889C441.771 30.6229 442.637 31.0928 443.402 31.6986C444.16 32.3044 444.755 33.0517 445.179 33.9292C445.547 34.6935 445.745 35.5597 445.796 36.5278H428.874L428.869 36.5335Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M490.913 65.1411H533.55V52.3858H505.214V38.8152H532.185V26.0599H505.214V14.3972H533.55V1.64185H490.913V65.1411Z"
              fill="#8889E8"
            />
            <path
              d="M587.668 13.2592C588.03 12.4383 588.789 12.0307 589.944 12.0307C590.549 12.0307 591.144 12.1382 591.721 12.3477C592.299 12.5628 592.859 12.8176 593.408 13.1233L597.779 2.64391C596.443 2.03813 595.045 1.55125 593.59 1.18325C592.129 0.820913 590.402 0.634084 588.398 0.634084C586.513 0.634084 584.69 0.849221 582.929 1.27383C581.168 1.69844 579.589 2.45708 578.19 3.54975C576.367 2.51936 574.425 1.77204 572.359 1.31912C570.293 0.866205 568.226 0.634084 566.165 0.634084C562.706 0.634084 559.44 1.28516 556.371 2.59296C553.302 3.90076 550.828 5.81435 548.943 8.33371C547.058 10.8531 546.118 13.9386 546.118 17.5789V20.2228H538.645V30.8834H546.118V65.1411H559.785V30.8834H573.361V65.1411H587.119V30.8834H596.047V20.2228H587.119V16.3051C587.119 15.0935 587.3 14.0745 587.668 13.2535V13.2592ZM573.457 14.0348V20.2285H559.791V17.862C559.791 16.039 560.306 14.55 561.342 13.4007C562.372 12.2458 564.195 11.6683 566.811 11.6683C568.209 11.6683 569.421 11.8212 570.457 12.1212C571.487 12.427 572.489 12.8176 573.463 13.3045V14.0348H573.457Z"
              fill="#8889E8"
            />
            <path
              d="M634.953 22.2327C631.488 20.2285 627.542 19.2264 623.109 19.2264C618.676 19.2264 614.713 20.2285 611.22 22.2327C607.727 24.2368 604.981 26.9996 602.977 30.5211C600.972 34.0425 599.97 38.0848 599.97 42.6367C599.97 47.1885 600.972 51.1572 602.977 54.707C604.981 58.2624 607.727 61.0535 611.22 63.086C614.713 65.1241 618.676 66.1375 623.109 66.1375C627.542 66.1375 631.493 65.1184 634.953 63.086C638.417 61.0535 641.146 58.2567 643.15 54.707C645.155 51.1515 646.157 47.1319 646.157 42.6367C646.157 38.1414 645.155 34.0425 643.15 30.5211C641.146 26.9996 638.412 24.2368 634.953 22.2327ZM631.352 48.8813C630.656 50.6137 629.608 51.9215 628.21 52.799C626.811 53.6822 625.113 54.1182 623.109 54.1182C621.105 54.1182 619.401 53.6766 618.008 52.799C616.609 51.9215 615.545 50.6137 614.82 48.8813C614.09 47.1489 613.728 45.0711 613.728 42.6423C613.728 40.2135 614.09 38.0735 614.82 36.4034C615.551 34.7332 616.609 33.4594 618.008 32.5762C619.406 31.6987 621.105 31.2571 623.109 31.2571C625.113 31.2571 626.811 31.6987 628.21 32.5762C629.608 33.4594 630.656 34.7332 631.352 36.4034C632.048 38.0735 632.399 40.1513 632.399 42.6423C632.399 45.1334 632.048 47.1545 631.352 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M679.05 19.2264C676.922 19.2264 674.872 19.8039 672.902 20.9588C670.926 22.1138 669.318 23.6933 668.073 25.6975C667.959 25.8786 667.857 26.0655 667.756 26.2523V20.2285H653.908V65.1467H667.665V47.0187C667.665 44.4653 667.739 42.5404 667.891 41.2326C668.044 39.9248 668.271 38.8491 668.577 37.9999C669.001 36.845 669.562 35.8882 670.264 35.1295C670.96 34.3709 671.781 33.8104 672.721 33.4424C673.661 33.0801 674.646 32.8932 675.682 32.8932C676.774 32.8932 677.867 33.0631 678.96 33.3971C680.052 33.7311 680.902 34.1105 681.513 34.5351L688.075 22.1421C686.495 21.1117 684.989 20.3644 683.562 19.9114C682.136 19.4585 680.63 19.2264 679.05 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M711.207 3.46483L697.359 10.8474V20.2342H689.886V30.8947H697.359V50.8458C697.359 54.673 698.073 57.6962 699.499 59.9099C700.926 62.1292 702.794 63.7201 705.104 64.6938C707.414 65.662 709.843 66.1545 712.391 66.1545C713.846 66.1545 715.244 66.03 716.58 65.7922C717.916 65.5487 719.252 65.1864 720.589 64.6995V54.2201C719.739 54.4635 718.947 54.6447 718.222 54.7693C717.492 54.8938 716.824 54.9504 716.218 54.9504C714.214 54.9504 712.878 54.2824 712.209 52.9463C711.541 51.6101 711.207 49.606 711.207 46.9338V30.9004H720.594V20.2398H711.207V3.46483Z"
              fill="#8889E8"
            />
            <path
              d="M744.735 53.4785C743.942 53.4785 743.291 53.2973 742.776 52.9293C742.261 52.567 742 51.9272 742 51.0157V1.64185H728.334V53.7559C728.334 58.0699 729.455 61.212 731.702 63.1879C733.95 65.1638 736.713 66.1489 739.991 66.1489C741.021 66.1489 742.193 66.0413 743.501 65.8318C744.808 65.6167 746.156 65.2374 747.554 64.6939V53.0312C747.067 53.1557 746.598 53.2577 746.145 53.3482C745.692 53.4388 745.216 53.4841 744.735 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M790.7 25.9692C788.905 23.8462 786.675 22.1874 784.003 21.0041C781.33 19.8209 778.234 19.2264 774.712 19.2264C770.704 19.2264 767.284 19.9228 764.465 21.3211C761.64 22.7195 759.364 24.5708 757.631 26.8807C755.899 29.1906 754.625 31.7383 753.804 34.5351C752.983 37.3318 752.576 40.123 752.576 42.9197C752.576 45.7165 752.983 48.6775 753.804 51.4403C754.625 54.2031 755.944 56.6941 757.767 58.9134C759.59 61.1328 761.957 62.8935 764.872 64.1956C767.788 65.5034 771.31 66.1545 775.442 66.1545C778.115 66.1545 780.47 65.9563 782.502 65.56C784.535 65.1637 786.528 64.4957 788.469 63.5559C790.411 62.6161 792.568 61.3535 794.941 59.774L789.109 50.1155C787.411 51.4516 785.407 52.499 783.097 53.2576C780.787 54.0163 778.539 54.3956 776.354 54.3956C773.863 54.3956 771.66 53.8464 769.747 52.7537C767.833 51.6611 766.695 49.9004 766.333 47.4716H795.852C795.971 46.5035 796.05 45.6825 796.079 45.0145C796.107 44.3464 796.124 43.7067 796.124 43.1009C796.124 39.6361 795.671 36.4487 794.759 33.533C793.848 30.6173 792.495 28.098 790.706 25.9692H790.7ZM766.146 37.3602C766.327 36.392 766.724 35.3843 767.329 34.3539C767.935 33.3235 768.864 32.4403 770.109 31.71C771.355 30.9797 772.98 30.6173 774.984 30.6173C776.076 30.6173 777.107 30.7872 778.081 31.1155C779.049 31.4496 779.915 31.9195 780.679 32.5252C781.438 33.131 782.032 33.8783 782.457 34.7559C782.825 35.5202 783.023 36.3864 783.074 37.3545H766.152L766.146 37.3602Z"
              fill="#8889E8"
            />
            <path
              d="M833.428 39.4945C831.695 38.4302 829.861 37.5979 827.913 36.9921C825.966 36.3864 824.115 35.8712 822.354 35.4409C820.531 34.954 819.104 34.5464 818.074 34.2124C817.038 33.8783 816.324 33.5273 815.934 33.165C815.537 32.8026 815.339 32.378 815.339 31.8911C815.339 31.1608 815.764 30.555 816.613 30.0681C817.462 29.5813 818.555 29.3378 819.891 29.3378C821.652 29.3378 823.356 29.7624 824.992 30.6116C826.634 31.4609 827.998 32.6158 829.091 34.0708L837.476 27.328C835.715 24.718 833.332 22.7139 830.325 21.3155C827.319 19.9171 823.962 19.2207 820.259 19.2207C814.915 19.2207 810.618 20.4946 807.368 23.0479C804.118 25.6012 802.493 28.9698 802.493 33.1593C802.493 35.6504 802.997 37.7168 803.999 39.353C805.001 40.9948 806.309 42.3479 807.917 43.4066C809.525 44.471 811.257 45.3202 813.109 45.9599C814.96 46.5997 816.738 47.1602 818.436 47.6471C819.835 48.0094 821.08 48.3887 822.173 48.785C823.265 49.1813 824.143 49.6229 824.817 50.1042C825.485 50.591 825.819 51.1968 825.819 51.9272C825.819 52.7198 825.332 53.4614 824.358 54.1578C823.384 54.8541 821.992 55.2052 820.169 55.2052C817.559 55.2052 815.413 54.5088 813.743 53.1104C812.073 51.712 810.72 50.3759 809.689 49.1021L800.399 57.2999C801.491 58.3926 802.89 59.6381 804.588 61.0365C806.287 62.4349 808.415 63.6351 810.963 64.6372C813.516 65.6393 816.551 66.1432 820.072 66.1432C823.9 66.1432 827.257 65.4751 830.138 64.139C833.02 62.8029 835.268 60.9516 836.881 58.5794C838.489 56.2129 839.293 53.4784 839.293 50.3816C839.293 47.7094 838.744 45.4957 837.651 43.7293C836.559 41.9686 835.143 40.5589 833.416 39.4945H833.428Z"
              fill="#8889E8"
            />
            <path
              d="M876.523 39.4945C874.791 38.4302 872.956 37.5979 871.009 36.9921C869.061 36.3864 867.21 35.8712 865.449 35.4409C863.626 34.954 862.2 34.5464 861.169 34.2124C860.133 33.8783 859.42 33.5273 859.029 33.165C858.633 32.8026 858.435 32.378 858.435 31.8911C858.435 31.1608 858.859 30.555 859.708 30.0681C860.558 29.5813 861.65 29.3378 862.987 29.3378C864.747 29.3378 866.451 29.7624 868.087 30.6116C869.729 31.4609 871.094 32.6158 872.186 34.0708L880.571 27.328C878.81 24.718 876.427 22.7139 873.421 21.3155C870.414 19.9171 867.057 19.2207 863.354 19.2207C858.01 19.2207 853.713 20.4946 850.463 23.0479C847.214 25.6012 845.589 28.9698 845.589 33.1593C845.589 35.6504 846.093 37.7168 847.095 39.353C848.097 40.9948 849.405 42.3479 851.012 43.4066C852.62 44.471 854.353 45.3202 856.204 45.9599C858.055 46.5997 859.833 47.1602 861.531 47.6471C862.93 48.0094 864.175 48.3887 865.268 48.785C866.361 49.1813 867.238 49.6229 867.912 50.1042C868.58 50.591 868.914 51.1968 868.914 51.9272C868.914 52.7198 868.427 53.4614 867.453 54.1578C866.48 54.8541 865.087 55.2052 863.264 55.2052C860.654 55.2052 858.508 54.5088 856.838 53.1104C855.168 51.712 853.815 50.3759 852.785 49.1021L843.494 57.2999C844.587 58.3926 845.985 59.6381 847.684 61.0365C849.382 62.4349 851.511 63.6351 854.058 64.6372C856.612 65.6393 859.646 66.1432 863.168 66.1432C866.995 66.1432 870.352 65.4751 873.234 64.139C876.116 62.8029 878.363 60.9516 879.977 58.5794C881.584 56.2129 882.388 53.4784 882.388 50.3816C882.388 47.7094 881.839 45.4957 880.747 43.7293C879.654 41.9686 878.239 40.5589 876.512 39.4945H876.523Z"
              fill="#8889E8"
            />
            <path
              d="M934.1 16.0333C936.62 14.4538 939.58 13.6668 942.983 13.6668C946.748 13.6668 949.952 14.6236 952.596 16.5372C955.24 18.4508 957.165 21.106 958.382 24.5086L971.868 19.6793C969.683 13.5479 966.065 8.73002 961.026 5.23688C955.982 1.74374 949.969 0 942.989 0C936.852 0 931.451 1.39839 926.768 4.1895C922.092 6.98628 918.446 10.8701 915.836 15.8522C913.226 20.8343 911.918 26.6656 911.918 33.3462C911.918 40.0267 913.226 45.8751 915.836 50.8855C918.446 55.8959 922.092 59.8023 926.768 62.5934C931.445 65.3845 936.852 66.7829 942.989 66.7829C949.975 66.7829 955.987 65.0222 961.026 61.5008C966.065 57.9793 969.683 53.1784 971.868 47.1036L958.382 42.2743C957.165 45.6769 955.24 48.3321 952.596 50.2457C949.952 52.1593 946.748 53.1161 942.983 53.1161C939.58 53.1161 936.62 52.3292 934.1 50.7496C931.581 49.17 929.633 46.9111 928.269 43.9615C926.904 41.0175 926.219 37.4791 926.219 33.3462C926.219 29.2133 926.904 25.6918 928.269 22.7762C929.633 19.8605 931.581 17.6129 934.1 16.0333Z"
              fill="#8889E8"
            />
            <path
              d="M1011.77 22.2327C1008.31 20.2285 1004.36 19.2264 999.926 19.2264C995.493 19.2264 991.53 20.2285 988.037 22.2327C984.544 24.2368 981.798 26.9996 979.794 30.5211C977.79 34.0425 976.788 38.0848 976.788 42.6367C976.788 47.1885 977.79 51.1572 979.794 54.707C981.798 58.2624 984.544 61.0535 988.037 63.086C991.53 65.1241 995.493 66.1375 999.926 66.1375C1004.36 66.1375 1008.31 65.1184 1011.77 63.086C1015.24 61.0535 1017.96 58.2567 1019.97 54.707C1021.97 51.1515 1022.97 47.1319 1022.97 42.6367C1022.97 38.1414 1021.97 34.0425 1019.97 30.5211C1017.96 26.9996 1015.23 24.2368 1011.77 22.2327ZM1008.17 48.8813C1007.47 50.6137 1006.43 51.9215 1005.03 52.799C1003.63 53.6822 1001.93 54.1182 999.926 54.1182C997.922 54.1182 996.218 53.6766 994.825 52.799C993.427 51.9215 992.363 50.6137 991.638 48.8813C990.908 47.1489 990.545 45.0711 990.545 42.6423C990.545 40.2135 990.908 38.0735 991.638 36.4034C992.368 34.7332 993.427 33.4594 994.825 32.5762C996.224 31.6987 997.922 31.2571 999.926 31.2571C1001.93 31.2571 1003.63 31.6987 1005.03 32.5762C1006.43 33.4594 1007.47 34.7332 1008.17 36.4034C1008.87 38.0735 1009.22 40.1513 1009.22 42.6423C1009.22 45.1334 1008.87 47.1545 1008.17 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M1091.81 21.7741C1089.35 20.0756 1086.15 19.2207 1082.19 19.2207C1079.95 19.2207 1077.62 19.7359 1075.22 20.772C1073.14 21.6722 1071.2 23.1951 1069.44 25.3351C1069.3 25.1483 1069.17 24.9558 1069.02 24.7803C1067.29 22.6573 1065.33 21.1966 1063.15 20.4096C1060.96 19.6227 1058.83 19.2264 1056.77 19.2264C1055.31 19.2264 1053.78 19.4415 1052.17 19.8661C1050.56 20.2908 1049.01 21.0664 1047.52 22.1874C1046.39 23.0422 1045.37 24.1802 1044.47 25.5956V20.2285H1030.71V65.1467H1044.47V43.3727C1044.47 40.5815 1044.74 38.2886 1045.29 36.4939C1045.84 34.7049 1046.67 33.3688 1047.8 32.4856C1048.92 31.6081 1050.34 31.1665 1052.03 31.1665C1053.91 31.1665 1055.39 31.8628 1056.45 33.2612C1057.52 34.6596 1058.05 36.7544 1058.05 39.5455V65.1467H1071.71V43.3727C1071.71 40.5815 1071.97 38.2886 1072.49 36.4939C1073 34.7049 1073.8 33.3688 1074.86 32.4856C1075.92 31.6081 1077.27 31.1665 1078.91 31.1665C1080.25 31.1665 1081.38 31.4552 1082.32 32.0327C1083.26 32.6102 1083.99 33.5217 1084.51 34.7672C1085.02 36.0127 1085.28 37.6093 1085.28 39.5511V65.1524H1099.04V36.1826C1099.04 33.4481 1098.45 30.7928 1097.26 28.2112C1096.08 25.6295 1094.26 23.4895 1091.8 21.7854L1091.81 21.7741Z"
              fill="#8889E8"
            />
            <path
              d="M1143.88 22.2779C1141.14 20.2455 1137.83 19.2264 1133.95 19.2264C1132.12 19.2264 1130.23 19.5887 1128.25 20.3191C1126.27 21.0494 1124.48 22.4591 1122.88 24.5539C1122.61 24.9049 1122.35 25.3012 1122.1 25.7145V20.2228H1108.43V83.7278H1122.1V59.5532C1122.25 59.7966 1122.4 60.0401 1122.55 60.2665C1124.01 62.3613 1125.76 63.8672 1127.79 64.7787C1129.82 65.6902 1131.87 66.1432 1133.94 66.1432C1137.34 66.1432 1140.42 65.2317 1143.18 63.4087C1145.95 61.5857 1148.15 58.9304 1149.79 55.4373C1151.43 51.9442 1152.25 47.7094 1152.25 42.7272C1152.25 38.232 1151.54 34.2067 1150.11 30.6569C1148.69 27.1015 1146.6 24.3104 1143.88 22.2723V22.2779ZM1137.45 48.5642C1136.75 50.2061 1135.75 51.4516 1134.44 52.3008C1133.14 53.15 1131.54 53.5747 1129.66 53.5747C1127.77 53.5747 1126.26 53.1331 1124.92 52.2555C1123.58 51.378 1122.57 50.1325 1121.87 48.5189C1121.17 46.9111 1120.82 44.9805 1120.82 42.7329C1120.82 40.4853 1121.17 38.4641 1121.87 36.8563C1122.57 35.2484 1123.58 34.0029 1124.92 33.1197C1126.26 32.2422 1127.84 31.8006 1129.66 31.8006C1131.48 31.8006 1133.14 32.2252 1134.44 33.0744C1135.75 33.9236 1136.75 35.1692 1137.45 36.811C1138.15 38.4528 1138.5 40.423 1138.5 42.7329C1138.5 45.0428 1138.15 46.9224 1137.45 48.5642Z"
              fill="#8889E8"
            />
            <path
              d="M1176.12 53.4785C1175.33 53.4785 1174.68 53.2973 1174.16 52.9293C1173.65 52.567 1173.39 51.9272 1173.39 51.0157V1.64185H1159.72V53.7559C1159.72 58.0699 1160.84 61.212 1163.09 63.1879C1165.34 65.1638 1168.1 66.1489 1171.38 66.1489C1172.41 66.1489 1173.58 66.0413 1174.89 65.8318C1176.2 65.6167 1177.54 65.2374 1178.94 64.6939V53.0312C1178.46 53.1557 1177.99 53.2577 1177.53 53.3482C1177.08 53.4388 1176.6 53.4841 1176.12 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M1199.72 20.2285H1185.96V65.1468H1199.72V20.2285Z"
              fill="#8889E8"
            />
            <path
              d="M1199.9 1.64185H1185.87V14.0348H1199.9V1.64185Z"
              fill="#8889E8"
            />
            <path
              d="M1244.96 23.9141C1241.34 20.789 1236.34 19.2207 1229.97 19.2207C1226.08 19.2207 1222.45 19.6906 1219.08 20.6304C1215.71 21.5759 1212.23 23.2291 1208.65 25.5956L1214.48 35.2541C1216.3 33.9802 1218.55 32.8989 1221.22 32.0214C1223.89 31.1438 1226.41 30.7022 1228.79 30.7022C1231.03 30.7022 1232.91 31.1552 1234.44 32.0667C1235.95 32.9781 1236.71 34.4954 1236.71 36.6241V37.3545H1224.32C1222.32 37.3545 1220.43 37.5357 1218.67 37.9036C1216.91 38.266 1215.33 38.8435 1213.93 39.6361C1212.05 40.6721 1210.55 42.1554 1209.42 44.103C1208.29 46.0449 1207.73 48.3265 1207.73 50.9364C1207.73 53.9766 1208.51 56.6488 1210.05 58.9531C1211.6 61.263 1213.7 63.0407 1216.34 64.2862C1218.98 65.5317 1221.91 66.1545 1225.13 66.1545C1226.83 66.1545 1228.58 65.8488 1230.37 65.243C1232.15 64.6372 1233.86 63.5106 1235.47 61.8744C1235.93 61.4102 1236.36 60.8723 1236.79 60.2892L1237.79 65.1524H1250.37V36.6355C1250.37 31.291 1248.56 27.0562 1244.94 23.9254L1244.96 23.9141ZM1235.43 51.2025C1234.58 52.4197 1233.44 53.3765 1232.02 54.0729C1230.59 54.7749 1229.02 55.1202 1227.33 55.1202C1225.63 55.1202 1224.15 54.7409 1223.09 53.9823C1222.03 53.2236 1221.49 52.2046 1221.49 50.9307C1221.49 50.3816 1221.61 49.8834 1221.86 49.4248C1222.1 48.9719 1222.43 48.5925 1222.86 48.2868C1223.28 47.9811 1223.78 47.7716 1224.36 47.6471C1224.94 47.5282 1225.59 47.4659 1226.32 47.4659H1236.7C1236.62 48.8586 1236.2 50.1042 1235.43 51.2025Z"
              fill="#8889E8"
            />
            <path
              d="M1286.18 19.2264C1284.3 19.2264 1282.37 19.5604 1280.4 20.2285C1278.42 20.8965 1276.64 22.0685 1275.07 23.7386C1274.46 24.384 1273.91 25.137 1273.43 25.9862V20.2341H1259.67V65.1524H1273.43V44.1992C1273.43 41.1647 1273.73 38.6849 1274.34 36.7714C1274.94 34.8578 1275.87 33.4481 1277.12 32.5366C1278.36 31.6251 1279.96 31.1721 1281.9 31.1721C1284.39 31.1721 1286.2 31.9025 1287.33 33.3575C1288.45 34.8181 1289.01 37.1563 1289.01 40.3721V65.1524H1302.77V35.2711C1302.77 30.3512 1301.32 26.4505 1298.4 23.5631C1295.48 20.6814 1291.41 19.2377 1286.19 19.2377L1286.18 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M1327.5 32.8479C1328.81 31.727 1330.52 31.1608 1332.65 31.1608C1334.96 31.1608 1336.73 31.7666 1337.97 32.9838C1339.22 34.201 1340.14 35.7466 1340.75 37.6319L1352.69 32.8932C1350.99 28.2791 1348.41 24.8483 1344.94 22.6007C1341.48 20.353 1337.38 19.2321 1332.64 19.2321C1328.81 19.2321 1325.49 19.9001 1322.67 21.2362C1319.84 22.5723 1317.49 24.3501 1315.61 26.5637C1313.72 28.783 1312.33 31.2854 1311.42 34.0822C1310.5 36.8789 1310.05 39.7323 1310.05 42.648C1310.05 45.5637 1310.5 48.417 1311.42 51.2138C1312.33 54.0106 1313.73 56.53 1315.61 58.7776C1317.49 61.0252 1319.84 62.8199 1322.67 64.1503C1325.49 65.4864 1328.81 66.1545 1332.64 66.1545C1337.38 66.1545 1341.48 65.0335 1344.94 62.7859C1348.41 60.5383 1350.98 57.1074 1352.69 52.4933L1340.75 47.7547C1340.15 49.6399 1339.22 51.1855 1337.97 52.4027C1336.73 53.62 1334.95 54.2257 1332.65 54.2257C1330.52 54.2257 1328.8 53.6652 1327.5 52.5386C1326.19 51.4176 1325.25 49.957 1324.68 48.1679C1324.1 46.3789 1323.81 44.5389 1323.81 42.6536C1323.81 40.7684 1324.1 38.951 1324.68 37.1847C1325.25 35.4239 1326.19 33.9802 1327.5 32.8593V32.8479Z"
              fill="#8889E8"
            />
            <path
              d="M1400.06 33.5273C1399.15 30.6117 1397.8 28.0923 1396.01 25.9636C1394.22 23.8405 1391.98 22.1817 1389.31 20.9984C1386.64 19.8152 1383.54 19.2207 1380.02 19.2207C1376.01 19.2207 1372.59 19.9171 1369.77 21.3155C1366.95 22.7139 1364.67 24.5652 1362.94 26.8751C1361.21 29.185 1359.93 31.7326 1359.11 34.5294C1358.29 37.3262 1357.89 40.1173 1357.89 42.9141C1357.89 45.7108 1358.29 48.6718 1359.11 51.4346C1359.93 54.1974 1361.25 56.6885 1363.08 58.9078C1364.9 61.1271 1367.27 62.8878 1370.18 64.1899C1373.1 65.4978 1376.62 66.1488 1380.75 66.1488C1383.42 66.1488 1385.78 65.9507 1387.81 65.5544C1389.84 65.1581 1391.84 64.49 1393.78 63.5502C1395.72 62.6104 1397.88 61.3479 1400.25 59.7683L1394.42 50.1098C1392.72 51.4459 1390.72 52.4933 1388.41 53.2519C1386.1 54.0106 1383.85 54.3899 1381.66 54.3899C1379.17 54.3899 1376.97 53.8407 1375.06 52.7481C1373.14 51.6554 1372.01 49.8947 1371.64 47.4659H1401.16C1401.28 46.4978 1401.36 45.6769 1401.39 45.0088C1401.42 44.3408 1401.43 43.701 1401.43 43.0952C1401.43 39.6304 1400.98 36.443 1400.07 33.5273H1400.06ZM1371.46 37.3545C1371.64 36.3864 1372.03 35.3786 1372.64 34.3482C1373.24 33.3178 1374.17 32.4347 1375.42 31.7043C1376.66 30.974 1378.29 30.6116 1380.29 30.6116C1381.39 30.6116 1382.42 30.7815 1383.39 31.1099C1384.36 31.4439 1385.22 31.9138 1385.99 32.5196C1386.75 33.1254 1387.34 33.8727 1387.77 34.7502C1388.13 35.5145 1388.33 36.3807 1388.38 37.3488H1371.46L1371.46 37.3545Z"
              fill="#8889E8"
            />
            <path
              d="M1431.39 50.4777C1436.15 50.4777 1440.01 46.6224 1440.01 41.8666C1440.01 37.1108 1436.15 33.2555 1431.39 33.2555C1426.64 33.2555 1422.78 37.1108 1422.78 41.8666C1422.78 46.6224 1426.64 50.4777 1431.39 50.4777Z"
              fill="#8889E8"
            />
          </svg>
          <svg
            width="1440"
            height="84"
            viewBox="0 0 1440 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.848633 64.3257H43.4853V51.5704H15.1552V37.9941H42.1209V25.2388H15.1552V13.5761H43.4853V0.820801H0.848633V64.3257Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M80.9303 19.4074L73.0043 32.7969L65.0782 19.4074H49.0448L63.8043 41.6345L49.0448 64.3257H64.9876L73.0043 50.5683L80.9303 64.3257H96.9693L82.023 41.6345L96.9693 19.4074H80.9303Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M138.695 21.457C135.96 19.4245 132.648 18.4054 128.764 18.4054C126.941 18.4054 125.045 18.7678 123.069 19.4981C121.093 20.2284 119.304 21.6381 117.696 23.7329C117.424 24.0839 117.17 24.4802 116.92 24.8935V19.4018H103.254V82.9068H116.92V58.7322C117.068 58.9757 117.215 59.2191 117.373 59.4456C118.828 61.5403 120.578 63.0463 122.61 63.9578C124.643 64.8693 126.692 65.3222 128.759 65.3222C132.161 65.3222 135.241 64.4107 138.004 62.5877C140.767 60.7647 142.969 58.1094 144.611 54.6163C146.253 51.1232 147.074 46.8884 147.074 41.9063C147.074 37.411 146.36 33.3857 144.933 29.836C143.507 26.2806 141.423 23.4894 138.695 21.4513V21.457ZM132.269 47.7433C131.572 49.3851 130.57 50.6306 129.262 51.4798C127.955 52.3291 126.364 52.7537 124.479 52.7537C122.593 52.7537 121.076 52.3121 119.74 51.4346C118.404 50.557 117.385 49.3115 116.688 47.698C115.992 46.0901 115.641 44.1595 115.641 41.9119C115.641 39.6643 115.992 37.6432 116.688 36.0353C117.385 34.4274 118.404 33.1819 119.74 32.2987C121.076 31.4212 122.656 30.9796 124.479 30.9796C126.302 30.9796 127.955 31.4042 129.262 32.2534C130.57 33.1026 131.572 34.3482 132.269 35.99C132.965 37.6318 133.316 39.602 133.316 41.9119C133.316 44.2218 132.965 46.1014 132.269 47.7433Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M190.854 25.1483C189.059 23.0252 186.828 21.3664 184.156 20.1831C181.484 18.9999 178.387 18.4054 174.866 18.4054C170.857 18.4054 167.438 19.1018 164.618 20.5002C161.793 21.8986 159.517 23.7499 157.785 26.0598C156.052 28.3696 154.779 30.9173 153.958 33.7141C153.137 36.5109 152.729 39.302 152.729 42.0988C152.729 44.8955 153.137 47.8565 153.958 50.6193C154.779 53.3821 156.098 55.8732 157.921 58.0925C159.744 60.3118 162.11 62.0725 165.026 63.3746C167.942 64.6824 171.463 65.3335 175.596 65.3335C178.268 65.3335 180.623 65.1354 182.656 64.7391C184.688 64.3428 186.681 63.6747 188.623 62.7349C190.565 61.7951 192.722 60.5326 195.094 58.953L189.263 49.2945C187.564 50.6306 185.56 51.678 183.25 52.4366C180.94 53.1953 178.693 53.5746 176.507 53.5746C174.016 53.5746 171.814 53.0254 169.9 51.9328C167.987 50.8401 166.849 49.0794 166.487 46.6506H196.006C196.124 45.6825 196.204 44.8616 196.232 44.1935C196.26 43.5255 196.277 42.8857 196.277 42.2799C196.277 38.8151 195.824 35.6277 194.913 32.712C194.001 29.7963 192.648 27.277 190.859 25.1483H190.854ZM166.3 36.5392C166.481 35.5711 166.877 34.5633 167.483 33.5329C168.089 32.5025 169.017 31.6193 170.263 30.889C171.508 30.1587 173.133 29.7963 175.137 29.7963C176.23 29.7963 177.26 29.9662 178.234 30.2946C179.202 30.6286 180.069 31.0985 180.833 31.7043C181.591 32.31 182.186 33.0574 182.611 33.9349C182.979 34.6992 183.177 35.5654 183.228 36.5335H166.305L166.3 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M229.347 18.4054C227.218 18.4054 225.168 18.9829 223.198 20.1378C221.222 21.2928 219.615 22.8723 218.369 24.8765C218.256 25.0577 218.154 25.2445 218.052 25.4313V19.4075H204.204V64.3258H217.961V46.1977C217.961 43.6443 218.035 41.7194 218.188 40.4116C218.341 39.1038 218.567 38.0281 218.873 37.1789C219.297 36.024 219.858 35.0672 220.56 34.3085C221.256 33.5499 222.077 32.9894 223.017 32.6214C223.957 32.2591 224.942 32.0723 225.978 32.0723C227.071 32.0723 228.163 32.2421 229.256 32.5761C230.349 32.9102 231.198 33.2895 231.809 33.7141L238.371 21.3211C236.791 20.2907 235.286 19.5434 233.859 19.0905C232.432 18.6375 230.926 18.4054 229.347 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M258.044 0.820801H244.015V13.2138H258.044V0.820801Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M257.863 19.4074H244.106V64.3257H257.863V19.4074Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M303.733 25.1483C301.938 23.0252 299.708 21.3664 297.035 20.1831C294.363 18.9999 291.266 18.4054 287.745 18.4054C283.737 18.4054 280.317 19.1018 277.498 20.5002C274.673 21.8986 272.397 23.7499 270.664 26.0598C268.932 28.3696 267.658 30.9173 266.837 33.7141C266.016 36.5109 265.608 39.302 265.608 42.0988C265.608 44.8955 266.016 47.8565 266.837 50.6193C267.658 53.3821 268.977 55.8732 270.8 58.0925C272.623 60.3118 274.99 62.0725 277.905 63.3746C280.821 64.6824 284.342 65.3335 288.475 65.3335C291.147 65.3335 293.503 65.1354 295.535 64.7391C297.568 64.3428 299.56 63.6747 301.502 62.7349C303.444 61.7951 305.601 60.5326 307.973 58.953L302.142 49.2945C300.444 50.6306 298.439 51.678 296.13 52.4366C293.82 53.1953 291.572 53.5746 289.387 53.5746C286.896 53.5746 284.693 53.0254 282.78 51.9328C280.866 50.8401 279.728 49.0794 279.366 46.6506H308.885C309.004 45.6825 309.083 44.8616 309.111 44.1935C309.14 43.5255 309.157 42.8857 309.157 42.2799C309.157 38.8151 308.704 35.6277 307.792 32.712C306.881 29.7963 305.528 27.277 303.739 25.1483H303.733ZM279.179 36.5392C279.36 35.5711 279.757 34.5633 280.362 33.5329C280.968 32.5025 281.897 31.6193 283.142 30.889C284.388 30.1587 286.012 29.7963 288.017 29.7963C289.109 29.7963 290.14 29.9662 291.113 30.2946C292.082 30.6286 292.948 31.0985 293.712 31.7043C294.471 32.31 295.065 33.0574 295.49 33.9349C295.858 34.6992 296.056 35.5654 296.107 36.5335H279.185L279.179 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M343.595 18.4054C341.71 18.4054 339.785 18.7395 337.809 19.4075C335.834 20.0756 334.056 21.2475 332.482 22.9176C331.87 23.563 331.327 24.316 330.84 25.1652V19.4132H317.083V64.3314H330.84V43.3783C330.84 40.3437 331.146 37.864 331.752 35.9504C332.357 34.0368 333.286 32.6271 334.531 31.7156C335.777 30.8041 337.368 30.3512 339.315 30.3512C341.806 30.3512 343.612 31.0815 344.739 32.5365C345.86 33.9972 346.426 36.3354 346.426 39.5511V64.3314H360.184V34.4501C360.184 29.5302 358.729 25.6295 355.813 22.7421C352.897 19.8604 348.827 18.4167 343.607 18.4167L343.595 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M384.907 32.0269C386.215 30.9059 387.93 30.3398 390.053 30.3398C392.363 30.3398 394.135 30.9456 395.381 32.1628C396.626 33.38 397.549 34.9256 398.161 36.8109L410.095 32.0722C408.397 27.4581 405.815 24.0272 402.35 21.7796C398.885 19.532 394.786 18.411 390.048 18.411C386.221 18.411 382.897 19.0791 380.072 20.4152C377.247 21.7513 374.892 23.529 373.012 25.7427C371.127 27.962 369.734 30.4643 368.823 33.2611C367.911 36.0579 367.458 38.9113 367.458 41.8269C367.458 44.7426 367.911 47.596 368.823 50.3928C369.734 53.1896 371.133 55.7089 373.012 57.9565C374.892 60.2041 377.247 61.9988 380.072 63.3293C382.897 64.6654 386.221 65.3335 390.048 65.3335C394.786 65.3335 398.885 64.2125 402.35 61.9649C405.815 59.7173 408.391 56.2864 410.095 51.6723L398.161 46.9336C397.555 48.8189 396.626 50.3645 395.381 51.5817C394.135 52.7989 392.358 53.4047 390.053 53.4047C387.925 53.4047 386.209 52.8442 384.907 51.7176C383.599 50.5966 382.659 49.1359 382.082 47.3469C381.504 45.5579 381.216 43.7179 381.216 41.8326C381.216 39.9473 381.504 38.13 382.082 36.3636C382.659 34.6029 383.599 33.1592 384.907 32.0382V32.0269Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M457.476 32.7063C456.565 29.7907 455.211 27.2713 453.422 25.1426C451.628 23.0195 449.397 21.3607 446.725 20.1775C444.053 18.9942 440.956 18.3998 437.434 18.3998C433.426 18.3998 430.006 19.0961 427.187 20.4945C424.362 21.8929 422.086 23.7442 420.354 26.0541C418.621 28.364 417.347 30.9116 416.526 33.7084C415.706 36.5052 415.298 39.2963 415.298 42.0931C415.298 44.8899 415.706 47.8508 416.526 50.6136C417.347 53.3764 418.667 55.8675 420.49 58.0868C422.313 60.3061 424.679 62.0668 427.595 63.369C430.51 64.6768 434.032 65.3278 438.165 65.3278C440.837 65.3278 443.192 65.1297 445.225 64.7334C447.257 64.3371 449.25 63.669 451.192 62.7292C453.134 61.7894 455.291 60.5269 457.663 58.9473L451.832 49.2888C450.133 50.625 448.129 51.6723 445.819 52.431C443.509 53.1896 441.262 53.5689 439.076 53.5689C436.585 53.5689 434.383 53.0198 432.469 51.9271C430.556 50.8344 429.418 49.0737 429.055 46.6449H458.574C458.693 45.6768 458.773 44.8559 458.801 44.1878C458.829 43.5198 458.846 42.88 458.846 42.2743C458.846 38.8094 458.393 35.622 457.482 32.7063H457.476ZM428.869 36.5335C429.05 35.5654 429.446 34.5576 430.052 33.5273C430.658 32.4969 431.586 31.6137 432.832 30.8833C434.077 30.153 435.702 29.7907 437.706 29.7907C438.799 29.7907 439.829 29.9605 440.803 30.2889C441.771 30.6229 442.637 31.0928 443.402 31.6986C444.16 32.3044 444.755 33.0517 445.179 33.9292C445.547 34.6935 445.745 35.5597 445.796 36.5278H428.874L428.869 36.5335Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M490.913 65.1411H533.55V52.3858H505.214V38.8152H532.185V26.0599H505.214V14.3972H533.55V1.64185H490.913V65.1411Z"
              fill="#8889E8"
            />
            <path
              d="M587.668 13.2592C588.03 12.4383 588.789 12.0307 589.944 12.0307C590.549 12.0307 591.144 12.1382 591.721 12.3477C592.299 12.5628 592.859 12.8176 593.408 13.1233L597.779 2.64391C596.443 2.03813 595.045 1.55125 593.59 1.18325C592.129 0.820913 590.402 0.634084 588.398 0.634084C586.513 0.634084 584.69 0.849221 582.929 1.27383C581.168 1.69844 579.589 2.45708 578.19 3.54975C576.367 2.51936 574.425 1.77204 572.359 1.31912C570.293 0.866205 568.226 0.634084 566.165 0.634084C562.706 0.634084 559.44 1.28516 556.371 2.59296C553.302 3.90076 550.828 5.81435 548.943 8.33371C547.058 10.8531 546.118 13.9386 546.118 17.5789V20.2228H538.645V30.8834H546.118V65.1411H559.785V30.8834H573.361V65.1411H587.119V30.8834H596.047V20.2228H587.119V16.3051C587.119 15.0935 587.3 14.0745 587.668 13.2535V13.2592ZM573.457 14.0348V20.2285H559.791V17.862C559.791 16.039 560.306 14.55 561.342 13.4007C562.372 12.2458 564.195 11.6683 566.811 11.6683C568.209 11.6683 569.421 11.8212 570.457 12.1212C571.487 12.427 572.489 12.8176 573.463 13.3045V14.0348H573.457Z"
              fill="#8889E8"
            />
            <path
              d="M634.953 22.2327C631.488 20.2285 627.542 19.2264 623.109 19.2264C618.676 19.2264 614.713 20.2285 611.22 22.2327C607.727 24.2368 604.981 26.9996 602.977 30.5211C600.972 34.0425 599.97 38.0848 599.97 42.6367C599.97 47.1885 600.972 51.1572 602.977 54.707C604.981 58.2624 607.727 61.0535 611.22 63.086C614.713 65.1241 618.676 66.1375 623.109 66.1375C627.542 66.1375 631.493 65.1184 634.953 63.086C638.417 61.0535 641.146 58.2567 643.15 54.707C645.155 51.1515 646.157 47.1319 646.157 42.6367C646.157 38.1414 645.155 34.0425 643.15 30.5211C641.146 26.9996 638.412 24.2368 634.953 22.2327ZM631.352 48.8813C630.656 50.6137 629.608 51.9215 628.21 52.799C626.811 53.6822 625.113 54.1182 623.109 54.1182C621.105 54.1182 619.401 53.6766 618.008 52.799C616.609 51.9215 615.545 50.6137 614.82 48.8813C614.09 47.1489 613.728 45.0711 613.728 42.6423C613.728 40.2135 614.09 38.0735 614.82 36.4034C615.551 34.7332 616.609 33.4594 618.008 32.5762C619.406 31.6987 621.105 31.2571 623.109 31.2571C625.113 31.2571 626.811 31.6987 628.21 32.5762C629.608 33.4594 630.656 34.7332 631.352 36.4034C632.048 38.0735 632.399 40.1513 632.399 42.6423C632.399 45.1334 632.048 47.1545 631.352 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M679.05 19.2264C676.922 19.2264 674.872 19.8039 672.902 20.9588C670.926 22.1138 669.318 23.6933 668.073 25.6975C667.959 25.8786 667.857 26.0655 667.756 26.2523V20.2285H653.908V65.1467H667.665V47.0187C667.665 44.4653 667.739 42.5404 667.891 41.2326C668.044 39.9248 668.271 38.8491 668.577 37.9999C669.001 36.845 669.562 35.8882 670.264 35.1295C670.96 34.3709 671.781 33.8104 672.721 33.4424C673.661 33.0801 674.646 32.8932 675.682 32.8932C676.774 32.8932 677.867 33.0631 678.96 33.3971C680.052 33.7311 680.902 34.1105 681.513 34.5351L688.075 22.1421C686.495 21.1117 684.989 20.3644 683.562 19.9114C682.136 19.4585 680.63 19.2264 679.05 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M711.207 3.46483L697.359 10.8474V20.2342H689.886V30.8947H697.359V50.8458C697.359 54.673 698.073 57.6962 699.499 59.9099C700.926 62.1292 702.794 63.7201 705.104 64.6938C707.414 65.662 709.843 66.1545 712.391 66.1545C713.846 66.1545 715.244 66.03 716.58 65.7922C717.916 65.5487 719.252 65.1864 720.589 64.6995V54.2201C719.739 54.4635 718.947 54.6447 718.222 54.7693C717.492 54.8938 716.824 54.9504 716.218 54.9504C714.214 54.9504 712.878 54.2824 712.209 52.9463C711.541 51.6101 711.207 49.606 711.207 46.9338V30.9004H720.594V20.2398H711.207V3.46483Z"
              fill="#8889E8"
            />
            <path
              d="M744.735 53.4785C743.942 53.4785 743.291 53.2973 742.776 52.9293C742.261 52.567 742 51.9272 742 51.0157V1.64185H728.334V53.7559C728.334 58.0699 729.455 61.212 731.702 63.1879C733.95 65.1638 736.713 66.1489 739.991 66.1489C741.021 66.1489 742.193 66.0413 743.501 65.8318C744.808 65.6167 746.156 65.2374 747.554 64.6939V53.0312C747.067 53.1557 746.598 53.2577 746.145 53.3482C745.692 53.4388 745.216 53.4841 744.735 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M790.7 25.9692C788.905 23.8462 786.675 22.1874 784.003 21.0041C781.33 19.8209 778.234 19.2264 774.712 19.2264C770.704 19.2264 767.284 19.9228 764.465 21.3211C761.64 22.7195 759.364 24.5708 757.631 26.8807C755.899 29.1906 754.625 31.7383 753.804 34.5351C752.983 37.3318 752.576 40.123 752.576 42.9197C752.576 45.7165 752.983 48.6775 753.804 51.4403C754.625 54.2031 755.944 56.6941 757.767 58.9134C759.59 61.1328 761.957 62.8935 764.872 64.1956C767.788 65.5034 771.31 66.1545 775.442 66.1545C778.115 66.1545 780.47 65.9563 782.502 65.56C784.535 65.1637 786.528 64.4957 788.469 63.5559C790.411 62.6161 792.568 61.3535 794.941 59.774L789.109 50.1155C787.411 51.4516 785.407 52.499 783.097 53.2576C780.787 54.0163 778.539 54.3956 776.354 54.3956C773.863 54.3956 771.66 53.8464 769.747 52.7537C767.833 51.6611 766.695 49.9004 766.333 47.4716H795.852C795.971 46.5035 796.05 45.6825 796.079 45.0145C796.107 44.3464 796.124 43.7067 796.124 43.1009C796.124 39.6361 795.671 36.4487 794.759 33.533C793.848 30.6173 792.495 28.098 790.706 25.9692H790.7ZM766.146 37.3602C766.327 36.392 766.724 35.3843 767.329 34.3539C767.935 33.3235 768.864 32.4403 770.109 31.71C771.355 30.9797 772.98 30.6173 774.984 30.6173C776.076 30.6173 777.107 30.7872 778.081 31.1155C779.049 31.4496 779.915 31.9195 780.679 32.5252C781.438 33.131 782.032 33.8783 782.457 34.7559C782.825 35.5202 783.023 36.3864 783.074 37.3545H766.152L766.146 37.3602Z"
              fill="#8889E8"
            />
            <path
              d="M833.428 39.4945C831.695 38.4302 829.861 37.5979 827.913 36.9921C825.966 36.3864 824.115 35.8712 822.354 35.4409C820.531 34.954 819.104 34.5464 818.074 34.2124C817.038 33.8783 816.324 33.5273 815.934 33.165C815.537 32.8026 815.339 32.378 815.339 31.8911C815.339 31.1608 815.764 30.555 816.613 30.0681C817.462 29.5813 818.555 29.3378 819.891 29.3378C821.652 29.3378 823.356 29.7624 824.992 30.6116C826.634 31.4609 827.998 32.6158 829.091 34.0708L837.476 27.328C835.715 24.718 833.332 22.7139 830.325 21.3155C827.319 19.9171 823.962 19.2207 820.259 19.2207C814.915 19.2207 810.618 20.4946 807.368 23.0479C804.118 25.6012 802.493 28.9698 802.493 33.1593C802.493 35.6504 802.997 37.7168 803.999 39.353C805.001 40.9948 806.309 42.3479 807.917 43.4066C809.525 44.471 811.257 45.3202 813.109 45.9599C814.96 46.5997 816.738 47.1602 818.436 47.6471C819.835 48.0094 821.08 48.3887 822.173 48.785C823.265 49.1813 824.143 49.6229 824.817 50.1042C825.485 50.591 825.819 51.1968 825.819 51.9272C825.819 52.7198 825.332 53.4614 824.358 54.1578C823.384 54.8541 821.992 55.2052 820.169 55.2052C817.559 55.2052 815.413 54.5088 813.743 53.1104C812.073 51.712 810.72 50.3759 809.689 49.1021L800.399 57.2999C801.491 58.3926 802.89 59.6381 804.588 61.0365C806.287 62.4349 808.415 63.6351 810.963 64.6372C813.516 65.6393 816.551 66.1432 820.072 66.1432C823.9 66.1432 827.257 65.4751 830.138 64.139C833.02 62.8029 835.268 60.9516 836.881 58.5794C838.489 56.2129 839.293 53.4784 839.293 50.3816C839.293 47.7094 838.744 45.4957 837.651 43.7293C836.559 41.9686 835.143 40.5589 833.416 39.4945H833.428Z"
              fill="#8889E8"
            />
            <path
              d="M876.523 39.4945C874.791 38.4302 872.956 37.5979 871.009 36.9921C869.061 36.3864 867.21 35.8712 865.449 35.4409C863.626 34.954 862.2 34.5464 861.169 34.2124C860.133 33.8783 859.42 33.5273 859.029 33.165C858.633 32.8026 858.435 32.378 858.435 31.8911C858.435 31.1608 858.859 30.555 859.708 30.0681C860.558 29.5813 861.65 29.3378 862.987 29.3378C864.747 29.3378 866.451 29.7624 868.087 30.6116C869.729 31.4609 871.094 32.6158 872.186 34.0708L880.571 27.328C878.81 24.718 876.427 22.7139 873.421 21.3155C870.414 19.9171 867.057 19.2207 863.354 19.2207C858.01 19.2207 853.713 20.4946 850.463 23.0479C847.214 25.6012 845.589 28.9698 845.589 33.1593C845.589 35.6504 846.093 37.7168 847.095 39.353C848.097 40.9948 849.405 42.3479 851.012 43.4066C852.62 44.471 854.353 45.3202 856.204 45.9599C858.055 46.5997 859.833 47.1602 861.531 47.6471C862.93 48.0094 864.175 48.3887 865.268 48.785C866.361 49.1813 867.238 49.6229 867.912 50.1042C868.58 50.591 868.914 51.1968 868.914 51.9272C868.914 52.7198 868.427 53.4614 867.453 54.1578C866.48 54.8541 865.087 55.2052 863.264 55.2052C860.654 55.2052 858.508 54.5088 856.838 53.1104C855.168 51.712 853.815 50.3759 852.785 49.1021L843.494 57.2999C844.587 58.3926 845.985 59.6381 847.684 61.0365C849.382 62.4349 851.511 63.6351 854.058 64.6372C856.612 65.6393 859.646 66.1432 863.168 66.1432C866.995 66.1432 870.352 65.4751 873.234 64.139C876.116 62.8029 878.363 60.9516 879.977 58.5794C881.584 56.2129 882.388 53.4784 882.388 50.3816C882.388 47.7094 881.839 45.4957 880.747 43.7293C879.654 41.9686 878.239 40.5589 876.512 39.4945H876.523Z"
              fill="#8889E8"
            />
            <path
              d="M934.1 16.0333C936.62 14.4538 939.58 13.6668 942.983 13.6668C946.748 13.6668 949.952 14.6236 952.596 16.5372C955.24 18.4508 957.165 21.106 958.382 24.5086L971.868 19.6793C969.683 13.5479 966.065 8.73002 961.026 5.23688C955.982 1.74374 949.969 0 942.989 0C936.852 0 931.451 1.39839 926.768 4.1895C922.092 6.98628 918.446 10.8701 915.836 15.8522C913.226 20.8343 911.918 26.6656 911.918 33.3462C911.918 40.0267 913.226 45.8751 915.836 50.8855C918.446 55.8959 922.092 59.8023 926.768 62.5934C931.445 65.3845 936.852 66.7829 942.989 66.7829C949.975 66.7829 955.987 65.0222 961.026 61.5008C966.065 57.9793 969.683 53.1784 971.868 47.1036L958.382 42.2743C957.165 45.6769 955.24 48.3321 952.596 50.2457C949.952 52.1593 946.748 53.1161 942.983 53.1161C939.58 53.1161 936.62 52.3292 934.1 50.7496C931.581 49.17 929.633 46.9111 928.269 43.9615C926.904 41.0175 926.219 37.4791 926.219 33.3462C926.219 29.2133 926.904 25.6918 928.269 22.7762C929.633 19.8605 931.581 17.6129 934.1 16.0333Z"
              fill="#8889E8"
            />
            <path
              d="M1011.77 22.2327C1008.31 20.2285 1004.36 19.2264 999.926 19.2264C995.493 19.2264 991.53 20.2285 988.037 22.2327C984.544 24.2368 981.798 26.9996 979.794 30.5211C977.79 34.0425 976.788 38.0848 976.788 42.6367C976.788 47.1885 977.79 51.1572 979.794 54.707C981.798 58.2624 984.544 61.0535 988.037 63.086C991.53 65.1241 995.493 66.1375 999.926 66.1375C1004.36 66.1375 1008.31 65.1184 1011.77 63.086C1015.24 61.0535 1017.96 58.2567 1019.97 54.707C1021.97 51.1515 1022.97 47.1319 1022.97 42.6367C1022.97 38.1414 1021.97 34.0425 1019.97 30.5211C1017.96 26.9996 1015.23 24.2368 1011.77 22.2327ZM1008.17 48.8813C1007.47 50.6137 1006.43 51.9215 1005.03 52.799C1003.63 53.6822 1001.93 54.1182 999.926 54.1182C997.922 54.1182 996.218 53.6766 994.825 52.799C993.427 51.9215 992.363 50.6137 991.638 48.8813C990.908 47.1489 990.545 45.0711 990.545 42.6423C990.545 40.2135 990.908 38.0735 991.638 36.4034C992.368 34.7332 993.427 33.4594 994.825 32.5762C996.224 31.6987 997.922 31.2571 999.926 31.2571C1001.93 31.2571 1003.63 31.6987 1005.03 32.5762C1006.43 33.4594 1007.47 34.7332 1008.17 36.4034C1008.87 38.0735 1009.22 40.1513 1009.22 42.6423C1009.22 45.1334 1008.87 47.1545 1008.17 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M1091.81 21.7741C1089.35 20.0756 1086.15 19.2207 1082.19 19.2207C1079.95 19.2207 1077.62 19.7359 1075.22 20.772C1073.14 21.6722 1071.2 23.1951 1069.44 25.3351C1069.3 25.1483 1069.17 24.9558 1069.02 24.7803C1067.29 22.6573 1065.33 21.1966 1063.15 20.4096C1060.96 19.6227 1058.83 19.2264 1056.77 19.2264C1055.31 19.2264 1053.78 19.4415 1052.17 19.8661C1050.56 20.2908 1049.01 21.0664 1047.52 22.1874C1046.39 23.0422 1045.37 24.1802 1044.47 25.5956V20.2285H1030.71V65.1467H1044.47V43.3727C1044.47 40.5815 1044.74 38.2886 1045.29 36.4939C1045.84 34.7049 1046.67 33.3688 1047.8 32.4856C1048.92 31.6081 1050.34 31.1665 1052.03 31.1665C1053.91 31.1665 1055.39 31.8628 1056.45 33.2612C1057.52 34.6596 1058.05 36.7544 1058.05 39.5455V65.1467H1071.71V43.3727C1071.71 40.5815 1071.97 38.2886 1072.49 36.4939C1073 34.7049 1073.8 33.3688 1074.86 32.4856C1075.92 31.6081 1077.27 31.1665 1078.91 31.1665C1080.25 31.1665 1081.38 31.4552 1082.32 32.0327C1083.26 32.6102 1083.99 33.5217 1084.51 34.7672C1085.02 36.0127 1085.28 37.6093 1085.28 39.5511V65.1524H1099.04V36.1826C1099.04 33.4481 1098.45 30.7928 1097.26 28.2112C1096.08 25.6295 1094.26 23.4895 1091.8 21.7854L1091.81 21.7741Z"
              fill="#8889E8"
            />
            <path
              d="M1143.88 22.2779C1141.14 20.2455 1137.83 19.2264 1133.95 19.2264C1132.12 19.2264 1130.23 19.5887 1128.25 20.3191C1126.27 21.0494 1124.48 22.4591 1122.88 24.5539C1122.61 24.9049 1122.35 25.3012 1122.1 25.7145V20.2228H1108.43V83.7278H1122.1V59.5532C1122.25 59.7966 1122.4 60.0401 1122.55 60.2665C1124.01 62.3613 1125.76 63.8672 1127.79 64.7787C1129.82 65.6902 1131.87 66.1432 1133.94 66.1432C1137.34 66.1432 1140.42 65.2317 1143.18 63.4087C1145.95 61.5857 1148.15 58.9304 1149.79 55.4373C1151.43 51.9442 1152.25 47.7094 1152.25 42.7272C1152.25 38.232 1151.54 34.2067 1150.11 30.6569C1148.69 27.1015 1146.6 24.3104 1143.88 22.2723V22.2779ZM1137.45 48.5642C1136.75 50.2061 1135.75 51.4516 1134.44 52.3008C1133.14 53.15 1131.54 53.5747 1129.66 53.5747C1127.77 53.5747 1126.26 53.1331 1124.92 52.2555C1123.58 51.378 1122.57 50.1325 1121.87 48.5189C1121.17 46.9111 1120.82 44.9805 1120.82 42.7329C1120.82 40.4853 1121.17 38.4641 1121.87 36.8563C1122.57 35.2484 1123.58 34.0029 1124.92 33.1197C1126.26 32.2422 1127.84 31.8006 1129.66 31.8006C1131.48 31.8006 1133.14 32.2252 1134.44 33.0744C1135.75 33.9236 1136.75 35.1692 1137.45 36.811C1138.15 38.4528 1138.5 40.423 1138.5 42.7329C1138.5 45.0428 1138.15 46.9224 1137.45 48.5642Z"
              fill="#8889E8"
            />
            <path
              d="M1176.12 53.4785C1175.33 53.4785 1174.68 53.2973 1174.16 52.9293C1173.65 52.567 1173.39 51.9272 1173.39 51.0157V1.64185H1159.72V53.7559C1159.72 58.0699 1160.84 61.212 1163.09 63.1879C1165.34 65.1638 1168.1 66.1489 1171.38 66.1489C1172.41 66.1489 1173.58 66.0413 1174.89 65.8318C1176.2 65.6167 1177.54 65.2374 1178.94 64.6939V53.0312C1178.46 53.1557 1177.99 53.2577 1177.53 53.3482C1177.08 53.4388 1176.6 53.4841 1176.12 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M1199.72 20.2285H1185.96V65.1468H1199.72V20.2285Z"
              fill="#8889E8"
            />
            <path
              d="M1199.9 1.64185H1185.87V14.0348H1199.9V1.64185Z"
              fill="#8889E8"
            />
            <path
              d="M1244.96 23.9141C1241.34 20.789 1236.34 19.2207 1229.97 19.2207C1226.08 19.2207 1222.45 19.6906 1219.08 20.6304C1215.71 21.5759 1212.23 23.2291 1208.65 25.5956L1214.48 35.2541C1216.3 33.9802 1218.55 32.8989 1221.22 32.0214C1223.89 31.1438 1226.41 30.7022 1228.79 30.7022C1231.03 30.7022 1232.91 31.1552 1234.44 32.0667C1235.95 32.9781 1236.71 34.4954 1236.71 36.6241V37.3545H1224.32C1222.32 37.3545 1220.43 37.5357 1218.67 37.9036C1216.91 38.266 1215.33 38.8435 1213.93 39.6361C1212.05 40.6721 1210.55 42.1554 1209.42 44.103C1208.29 46.0449 1207.73 48.3265 1207.73 50.9364C1207.73 53.9766 1208.51 56.6488 1210.05 58.9531C1211.6 61.263 1213.7 63.0407 1216.34 64.2862C1218.98 65.5317 1221.91 66.1545 1225.13 66.1545C1226.83 66.1545 1228.58 65.8488 1230.37 65.243C1232.15 64.6372 1233.86 63.5106 1235.47 61.8744C1235.93 61.4102 1236.36 60.8723 1236.79 60.2892L1237.79 65.1524H1250.37V36.6355C1250.37 31.291 1248.56 27.0562 1244.94 23.9254L1244.96 23.9141ZM1235.43 51.2025C1234.58 52.4197 1233.44 53.3765 1232.02 54.0729C1230.59 54.7749 1229.02 55.1202 1227.33 55.1202C1225.63 55.1202 1224.15 54.7409 1223.09 53.9823C1222.03 53.2236 1221.49 52.2046 1221.49 50.9307C1221.49 50.3816 1221.61 49.8834 1221.86 49.4248C1222.1 48.9719 1222.43 48.5925 1222.86 48.2868C1223.28 47.9811 1223.78 47.7716 1224.36 47.6471C1224.94 47.5282 1225.59 47.4659 1226.32 47.4659H1236.7C1236.62 48.8586 1236.2 50.1042 1235.43 51.2025Z"
              fill="#8889E8"
            />
            <path
              d="M1286.18 19.2264C1284.3 19.2264 1282.37 19.5604 1280.4 20.2285C1278.42 20.8965 1276.64 22.0685 1275.07 23.7386C1274.46 24.384 1273.91 25.137 1273.43 25.9862V20.2341H1259.67V65.1524H1273.43V44.1992C1273.43 41.1647 1273.73 38.6849 1274.34 36.7714C1274.94 34.8578 1275.87 33.4481 1277.12 32.5366C1278.36 31.6251 1279.96 31.1721 1281.9 31.1721C1284.39 31.1721 1286.2 31.9025 1287.33 33.3575C1288.45 34.8181 1289.01 37.1563 1289.01 40.3721V65.1524H1302.77V35.2711C1302.77 30.3512 1301.32 26.4505 1298.4 23.5631C1295.48 20.6814 1291.41 19.2377 1286.19 19.2377L1286.18 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M1327.5 32.8479C1328.81 31.727 1330.52 31.1608 1332.65 31.1608C1334.96 31.1608 1336.73 31.7666 1337.97 32.9838C1339.22 34.201 1340.14 35.7466 1340.75 37.6319L1352.69 32.8932C1350.99 28.2791 1348.41 24.8483 1344.94 22.6007C1341.48 20.353 1337.38 19.2321 1332.64 19.2321C1328.81 19.2321 1325.49 19.9001 1322.67 21.2362C1319.84 22.5723 1317.49 24.3501 1315.61 26.5637C1313.72 28.783 1312.33 31.2854 1311.42 34.0822C1310.5 36.8789 1310.05 39.7323 1310.05 42.648C1310.05 45.5637 1310.5 48.417 1311.42 51.2138C1312.33 54.0106 1313.73 56.53 1315.61 58.7776C1317.49 61.0252 1319.84 62.8199 1322.67 64.1503C1325.49 65.4864 1328.81 66.1545 1332.64 66.1545C1337.38 66.1545 1341.48 65.0335 1344.94 62.7859C1348.41 60.5383 1350.98 57.1074 1352.69 52.4933L1340.75 47.7547C1340.15 49.6399 1339.22 51.1855 1337.97 52.4027C1336.73 53.62 1334.95 54.2257 1332.65 54.2257C1330.52 54.2257 1328.8 53.6652 1327.5 52.5386C1326.19 51.4176 1325.25 49.957 1324.68 48.1679C1324.1 46.3789 1323.81 44.5389 1323.81 42.6536C1323.81 40.7684 1324.1 38.951 1324.68 37.1847C1325.25 35.4239 1326.19 33.9802 1327.5 32.8593V32.8479Z"
              fill="#8889E8"
            />
            <path
              d="M1400.06 33.5273C1399.15 30.6117 1397.8 28.0923 1396.01 25.9636C1394.22 23.8405 1391.98 22.1817 1389.31 20.9984C1386.64 19.8152 1383.54 19.2207 1380.02 19.2207C1376.01 19.2207 1372.59 19.9171 1369.77 21.3155C1366.95 22.7139 1364.67 24.5652 1362.94 26.8751C1361.21 29.185 1359.93 31.7326 1359.11 34.5294C1358.29 37.3262 1357.89 40.1173 1357.89 42.9141C1357.89 45.7108 1358.29 48.6718 1359.11 51.4346C1359.93 54.1974 1361.25 56.6885 1363.08 58.9078C1364.9 61.1271 1367.27 62.8878 1370.18 64.1899C1373.1 65.4978 1376.62 66.1488 1380.75 66.1488C1383.42 66.1488 1385.78 65.9507 1387.81 65.5544C1389.84 65.1581 1391.84 64.49 1393.78 63.5502C1395.72 62.6104 1397.88 61.3479 1400.25 59.7683L1394.42 50.1098C1392.72 51.4459 1390.72 52.4933 1388.41 53.2519C1386.1 54.0106 1383.85 54.3899 1381.66 54.3899C1379.17 54.3899 1376.97 53.8407 1375.06 52.7481C1373.14 51.6554 1372.01 49.8947 1371.64 47.4659H1401.16C1401.28 46.4978 1401.36 45.6769 1401.39 45.0088C1401.42 44.3408 1401.43 43.701 1401.43 43.0952C1401.43 39.6304 1400.98 36.443 1400.07 33.5273H1400.06ZM1371.46 37.3545C1371.64 36.3864 1372.03 35.3786 1372.64 34.3482C1373.24 33.3178 1374.17 32.4347 1375.42 31.7043C1376.66 30.974 1378.29 30.6116 1380.29 30.6116C1381.39 30.6116 1382.42 30.7815 1383.39 31.1099C1384.36 31.4439 1385.22 31.9138 1385.99 32.5196C1386.75 33.1254 1387.34 33.8727 1387.77 34.7502C1388.13 35.5145 1388.33 36.3807 1388.38 37.3488H1371.46L1371.46 37.3545Z"
              fill="#8889E8"
            />
            <path
              d="M1431.39 50.4777C1436.15 50.4777 1440.01 46.6224 1440.01 41.8666C1440.01 37.1108 1436.15 33.2555 1431.39 33.2555C1426.64 33.2555 1422.78 37.1108 1422.78 41.8666C1422.78 46.6224 1426.64 50.4777 1431.39 50.4777Z"
              fill="#8889E8"
            />
          </svg>
          <svg
            width="1440"
            height="84"
            viewBox="0 0 1440 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.848633 64.3257H43.4853V51.5704H15.1552V37.9941H42.1209V25.2388H15.1552V13.5761H43.4853V0.820801H0.848633V64.3257Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M80.9303 19.4074L73.0043 32.7969L65.0782 19.4074H49.0448L63.8043 41.6345L49.0448 64.3257H64.9876L73.0043 50.5683L80.9303 64.3257H96.9693L82.023 41.6345L96.9693 19.4074H80.9303Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M138.695 21.457C135.96 19.4245 132.648 18.4054 128.764 18.4054C126.941 18.4054 125.045 18.7678 123.069 19.4981C121.093 20.2284 119.304 21.6381 117.696 23.7329C117.424 24.0839 117.17 24.4802 116.92 24.8935V19.4018H103.254V82.9068H116.92V58.7322C117.068 58.9757 117.215 59.2191 117.373 59.4456C118.828 61.5403 120.578 63.0463 122.61 63.9578C124.643 64.8693 126.692 65.3222 128.759 65.3222C132.161 65.3222 135.241 64.4107 138.004 62.5877C140.767 60.7647 142.969 58.1094 144.611 54.6163C146.253 51.1232 147.074 46.8884 147.074 41.9063C147.074 37.411 146.36 33.3857 144.933 29.836C143.507 26.2806 141.423 23.4894 138.695 21.4513V21.457ZM132.269 47.7433C131.572 49.3851 130.57 50.6306 129.262 51.4798C127.955 52.3291 126.364 52.7537 124.479 52.7537C122.593 52.7537 121.076 52.3121 119.74 51.4346C118.404 50.557 117.385 49.3115 116.688 47.698C115.992 46.0901 115.641 44.1595 115.641 41.9119C115.641 39.6643 115.992 37.6432 116.688 36.0353C117.385 34.4274 118.404 33.1819 119.74 32.2987C121.076 31.4212 122.656 30.9796 124.479 30.9796C126.302 30.9796 127.955 31.4042 129.262 32.2534C130.57 33.1026 131.572 34.3482 132.269 35.99C132.965 37.6318 133.316 39.602 133.316 41.9119C133.316 44.2218 132.965 46.1014 132.269 47.7433Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M190.854 25.1483C189.059 23.0252 186.828 21.3664 184.156 20.1831C181.484 18.9999 178.387 18.4054 174.866 18.4054C170.857 18.4054 167.438 19.1018 164.618 20.5002C161.793 21.8986 159.517 23.7499 157.785 26.0598C156.052 28.3696 154.779 30.9173 153.958 33.7141C153.137 36.5109 152.729 39.302 152.729 42.0988C152.729 44.8955 153.137 47.8565 153.958 50.6193C154.779 53.3821 156.098 55.8732 157.921 58.0925C159.744 60.3118 162.11 62.0725 165.026 63.3746C167.942 64.6824 171.463 65.3335 175.596 65.3335C178.268 65.3335 180.623 65.1354 182.656 64.7391C184.688 64.3428 186.681 63.6747 188.623 62.7349C190.565 61.7951 192.722 60.5326 195.094 58.953L189.263 49.2945C187.564 50.6306 185.56 51.678 183.25 52.4366C180.94 53.1953 178.693 53.5746 176.507 53.5746C174.016 53.5746 171.814 53.0254 169.9 51.9328C167.987 50.8401 166.849 49.0794 166.487 46.6506H196.006C196.124 45.6825 196.204 44.8616 196.232 44.1935C196.26 43.5255 196.277 42.8857 196.277 42.2799C196.277 38.8151 195.824 35.6277 194.913 32.712C194.001 29.7963 192.648 27.277 190.859 25.1483H190.854ZM166.3 36.5392C166.481 35.5711 166.877 34.5633 167.483 33.5329C168.089 32.5025 169.017 31.6193 170.263 30.889C171.508 30.1587 173.133 29.7963 175.137 29.7963C176.23 29.7963 177.26 29.9662 178.234 30.2946C179.202 30.6286 180.069 31.0985 180.833 31.7043C181.591 32.31 182.186 33.0574 182.611 33.9349C182.979 34.6992 183.177 35.5654 183.228 36.5335H166.305L166.3 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M229.347 18.4054C227.218 18.4054 225.168 18.9829 223.198 20.1378C221.222 21.2928 219.615 22.8723 218.369 24.8765C218.256 25.0577 218.154 25.2445 218.052 25.4313V19.4075H204.204V64.3258H217.961V46.1977C217.961 43.6443 218.035 41.7194 218.188 40.4116C218.341 39.1038 218.567 38.0281 218.873 37.1789C219.297 36.024 219.858 35.0672 220.56 34.3085C221.256 33.5499 222.077 32.9894 223.017 32.6214C223.957 32.2591 224.942 32.0723 225.978 32.0723C227.071 32.0723 228.163 32.2421 229.256 32.5761C230.349 32.9102 231.198 33.2895 231.809 33.7141L238.371 21.3211C236.791 20.2907 235.286 19.5434 233.859 19.0905C232.432 18.6375 230.926 18.4054 229.347 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M258.044 0.820801H244.015V13.2138H258.044V0.820801Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M257.863 19.4074H244.106V64.3257H257.863V19.4074Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M303.733 25.1483C301.938 23.0252 299.708 21.3664 297.035 20.1831C294.363 18.9999 291.266 18.4054 287.745 18.4054C283.737 18.4054 280.317 19.1018 277.498 20.5002C274.673 21.8986 272.397 23.7499 270.664 26.0598C268.932 28.3696 267.658 30.9173 266.837 33.7141C266.016 36.5109 265.608 39.302 265.608 42.0988C265.608 44.8955 266.016 47.8565 266.837 50.6193C267.658 53.3821 268.977 55.8732 270.8 58.0925C272.623 60.3118 274.99 62.0725 277.905 63.3746C280.821 64.6824 284.342 65.3335 288.475 65.3335C291.147 65.3335 293.503 65.1354 295.535 64.7391C297.568 64.3428 299.56 63.6747 301.502 62.7349C303.444 61.7951 305.601 60.5326 307.973 58.953L302.142 49.2945C300.444 50.6306 298.439 51.678 296.13 52.4366C293.82 53.1953 291.572 53.5746 289.387 53.5746C286.896 53.5746 284.693 53.0254 282.78 51.9328C280.866 50.8401 279.728 49.0794 279.366 46.6506H308.885C309.004 45.6825 309.083 44.8616 309.111 44.1935C309.14 43.5255 309.157 42.8857 309.157 42.2799C309.157 38.8151 308.704 35.6277 307.792 32.712C306.881 29.7963 305.528 27.277 303.739 25.1483H303.733ZM279.179 36.5392C279.36 35.5711 279.757 34.5633 280.362 33.5329C280.968 32.5025 281.897 31.6193 283.142 30.889C284.388 30.1587 286.012 29.7963 288.017 29.7963C289.109 29.7963 290.14 29.9662 291.113 30.2946C292.082 30.6286 292.948 31.0985 293.712 31.7043C294.471 32.31 295.065 33.0574 295.49 33.9349C295.858 34.6992 296.056 35.5654 296.107 36.5335H279.185L279.179 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M343.595 18.4054C341.71 18.4054 339.785 18.7395 337.809 19.4075C335.834 20.0756 334.056 21.2475 332.482 22.9176C331.87 23.563 331.327 24.316 330.84 25.1652V19.4132H317.083V64.3314H330.84V43.3783C330.84 40.3437 331.146 37.864 331.752 35.9504C332.357 34.0368 333.286 32.6271 334.531 31.7156C335.777 30.8041 337.368 30.3512 339.315 30.3512C341.806 30.3512 343.612 31.0815 344.739 32.5365C345.86 33.9972 346.426 36.3354 346.426 39.5511V64.3314H360.184V34.4501C360.184 29.5302 358.729 25.6295 355.813 22.7421C352.897 19.8604 348.827 18.4167 343.607 18.4167L343.595 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M384.907 32.0269C386.215 30.9059 387.93 30.3398 390.053 30.3398C392.363 30.3398 394.135 30.9456 395.381 32.1628C396.626 33.38 397.549 34.9256 398.161 36.8109L410.095 32.0722C408.397 27.4581 405.815 24.0272 402.35 21.7796C398.885 19.532 394.786 18.411 390.048 18.411C386.221 18.411 382.897 19.0791 380.072 20.4152C377.247 21.7513 374.892 23.529 373.012 25.7427C371.127 27.962 369.734 30.4643 368.823 33.2611C367.911 36.0579 367.458 38.9113 367.458 41.8269C367.458 44.7426 367.911 47.596 368.823 50.3928C369.734 53.1896 371.133 55.7089 373.012 57.9565C374.892 60.2041 377.247 61.9988 380.072 63.3293C382.897 64.6654 386.221 65.3335 390.048 65.3335C394.786 65.3335 398.885 64.2125 402.35 61.9649C405.815 59.7173 408.391 56.2864 410.095 51.6723L398.161 46.9336C397.555 48.8189 396.626 50.3645 395.381 51.5817C394.135 52.7989 392.358 53.4047 390.053 53.4047C387.925 53.4047 386.209 52.8442 384.907 51.7176C383.599 50.5966 382.659 49.1359 382.082 47.3469C381.504 45.5579 381.216 43.7179 381.216 41.8326C381.216 39.9473 381.504 38.13 382.082 36.3636C382.659 34.6029 383.599 33.1592 384.907 32.0382V32.0269Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M457.476 32.7063C456.565 29.7907 455.211 27.2713 453.422 25.1426C451.628 23.0195 449.397 21.3607 446.725 20.1775C444.053 18.9942 440.956 18.3998 437.434 18.3998C433.426 18.3998 430.006 19.0961 427.187 20.4945C424.362 21.8929 422.086 23.7442 420.354 26.0541C418.621 28.364 417.347 30.9116 416.526 33.7084C415.706 36.5052 415.298 39.2963 415.298 42.0931C415.298 44.8899 415.706 47.8508 416.526 50.6136C417.347 53.3764 418.667 55.8675 420.49 58.0868C422.313 60.3061 424.679 62.0668 427.595 63.369C430.51 64.6768 434.032 65.3278 438.165 65.3278C440.837 65.3278 443.192 65.1297 445.225 64.7334C447.257 64.3371 449.25 63.669 451.192 62.7292C453.134 61.7894 455.291 60.5269 457.663 58.9473L451.832 49.2888C450.133 50.625 448.129 51.6723 445.819 52.431C443.509 53.1896 441.262 53.5689 439.076 53.5689C436.585 53.5689 434.383 53.0198 432.469 51.9271C430.556 50.8344 429.418 49.0737 429.055 46.6449H458.574C458.693 45.6768 458.773 44.8559 458.801 44.1878C458.829 43.5198 458.846 42.88 458.846 42.2743C458.846 38.8094 458.393 35.622 457.482 32.7063H457.476ZM428.869 36.5335C429.05 35.5654 429.446 34.5576 430.052 33.5273C430.658 32.4969 431.586 31.6137 432.832 30.8833C434.077 30.153 435.702 29.7907 437.706 29.7907C438.799 29.7907 439.829 29.9605 440.803 30.2889C441.771 30.6229 442.637 31.0928 443.402 31.6986C444.16 32.3044 444.755 33.0517 445.179 33.9292C445.547 34.6935 445.745 35.5597 445.796 36.5278H428.874L428.869 36.5335Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M490.913 65.1411H533.55V52.3858H505.214V38.8152H532.185V26.0599H505.214V14.3972H533.55V1.64185H490.913V65.1411Z"
              fill="#8889E8"
            />
            <path
              d="M587.668 13.2592C588.03 12.4383 588.789 12.0307 589.944 12.0307C590.549 12.0307 591.144 12.1382 591.721 12.3477C592.299 12.5628 592.859 12.8176 593.408 13.1233L597.779 2.64391C596.443 2.03813 595.045 1.55125 593.59 1.18325C592.129 0.820913 590.402 0.634084 588.398 0.634084C586.513 0.634084 584.69 0.849221 582.929 1.27383C581.168 1.69844 579.589 2.45708 578.19 3.54975C576.367 2.51936 574.425 1.77204 572.359 1.31912C570.293 0.866205 568.226 0.634084 566.165 0.634084C562.706 0.634084 559.44 1.28516 556.371 2.59296C553.302 3.90076 550.828 5.81435 548.943 8.33371C547.058 10.8531 546.118 13.9386 546.118 17.5789V20.2228H538.645V30.8834H546.118V65.1411H559.785V30.8834H573.361V65.1411H587.119V30.8834H596.047V20.2228H587.119V16.3051C587.119 15.0935 587.3 14.0745 587.668 13.2535V13.2592ZM573.457 14.0348V20.2285H559.791V17.862C559.791 16.039 560.306 14.55 561.342 13.4007C562.372 12.2458 564.195 11.6683 566.811 11.6683C568.209 11.6683 569.421 11.8212 570.457 12.1212C571.487 12.427 572.489 12.8176 573.463 13.3045V14.0348H573.457Z"
              fill="#8889E8"
            />
            <path
              d="M634.953 22.2327C631.488 20.2285 627.542 19.2264 623.109 19.2264C618.676 19.2264 614.713 20.2285 611.22 22.2327C607.727 24.2368 604.981 26.9996 602.977 30.5211C600.972 34.0425 599.97 38.0848 599.97 42.6367C599.97 47.1885 600.972 51.1572 602.977 54.707C604.981 58.2624 607.727 61.0535 611.22 63.086C614.713 65.1241 618.676 66.1375 623.109 66.1375C627.542 66.1375 631.493 65.1184 634.953 63.086C638.417 61.0535 641.146 58.2567 643.15 54.707C645.155 51.1515 646.157 47.1319 646.157 42.6367C646.157 38.1414 645.155 34.0425 643.15 30.5211C641.146 26.9996 638.412 24.2368 634.953 22.2327ZM631.352 48.8813C630.656 50.6137 629.608 51.9215 628.21 52.799C626.811 53.6822 625.113 54.1182 623.109 54.1182C621.105 54.1182 619.401 53.6766 618.008 52.799C616.609 51.9215 615.545 50.6137 614.82 48.8813C614.09 47.1489 613.728 45.0711 613.728 42.6423C613.728 40.2135 614.09 38.0735 614.82 36.4034C615.551 34.7332 616.609 33.4594 618.008 32.5762C619.406 31.6987 621.105 31.2571 623.109 31.2571C625.113 31.2571 626.811 31.6987 628.21 32.5762C629.608 33.4594 630.656 34.7332 631.352 36.4034C632.048 38.0735 632.399 40.1513 632.399 42.6423C632.399 45.1334 632.048 47.1545 631.352 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M679.05 19.2264C676.922 19.2264 674.872 19.8039 672.902 20.9588C670.926 22.1138 669.318 23.6933 668.073 25.6975C667.959 25.8786 667.857 26.0655 667.756 26.2523V20.2285H653.908V65.1467H667.665V47.0187C667.665 44.4653 667.739 42.5404 667.891 41.2326C668.044 39.9248 668.271 38.8491 668.577 37.9999C669.001 36.845 669.562 35.8882 670.264 35.1295C670.96 34.3709 671.781 33.8104 672.721 33.4424C673.661 33.0801 674.646 32.8932 675.682 32.8932C676.774 32.8932 677.867 33.0631 678.96 33.3971C680.052 33.7311 680.902 34.1105 681.513 34.5351L688.075 22.1421C686.495 21.1117 684.989 20.3644 683.562 19.9114C682.136 19.4585 680.63 19.2264 679.05 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M711.207 3.46483L697.359 10.8474V20.2342H689.886V30.8947H697.359V50.8458C697.359 54.673 698.073 57.6962 699.499 59.9099C700.926 62.1292 702.794 63.7201 705.104 64.6938C707.414 65.662 709.843 66.1545 712.391 66.1545C713.846 66.1545 715.244 66.03 716.58 65.7922C717.916 65.5487 719.252 65.1864 720.589 64.6995V54.2201C719.739 54.4635 718.947 54.6447 718.222 54.7693C717.492 54.8938 716.824 54.9504 716.218 54.9504C714.214 54.9504 712.878 54.2824 712.209 52.9463C711.541 51.6101 711.207 49.606 711.207 46.9338V30.9004H720.594V20.2398H711.207V3.46483Z"
              fill="#8889E8"
            />
            <path
              d="M744.735 53.4785C743.942 53.4785 743.291 53.2973 742.776 52.9293C742.261 52.567 742 51.9272 742 51.0157V1.64185H728.334V53.7559C728.334 58.0699 729.455 61.212 731.702 63.1879C733.95 65.1638 736.713 66.1489 739.991 66.1489C741.021 66.1489 742.193 66.0413 743.501 65.8318C744.808 65.6167 746.156 65.2374 747.554 64.6939V53.0312C747.067 53.1557 746.598 53.2577 746.145 53.3482C745.692 53.4388 745.216 53.4841 744.735 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M790.7 25.9692C788.905 23.8462 786.675 22.1874 784.003 21.0041C781.33 19.8209 778.234 19.2264 774.712 19.2264C770.704 19.2264 767.284 19.9228 764.465 21.3211C761.64 22.7195 759.364 24.5708 757.631 26.8807C755.899 29.1906 754.625 31.7383 753.804 34.5351C752.983 37.3318 752.576 40.123 752.576 42.9197C752.576 45.7165 752.983 48.6775 753.804 51.4403C754.625 54.2031 755.944 56.6941 757.767 58.9134C759.59 61.1328 761.957 62.8935 764.872 64.1956C767.788 65.5034 771.31 66.1545 775.442 66.1545C778.115 66.1545 780.47 65.9563 782.502 65.56C784.535 65.1637 786.528 64.4957 788.469 63.5559C790.411 62.6161 792.568 61.3535 794.941 59.774L789.109 50.1155C787.411 51.4516 785.407 52.499 783.097 53.2576C780.787 54.0163 778.539 54.3956 776.354 54.3956C773.863 54.3956 771.66 53.8464 769.747 52.7537C767.833 51.6611 766.695 49.9004 766.333 47.4716H795.852C795.971 46.5035 796.05 45.6825 796.079 45.0145C796.107 44.3464 796.124 43.7067 796.124 43.1009C796.124 39.6361 795.671 36.4487 794.759 33.533C793.848 30.6173 792.495 28.098 790.706 25.9692H790.7ZM766.146 37.3602C766.327 36.392 766.724 35.3843 767.329 34.3539C767.935 33.3235 768.864 32.4403 770.109 31.71C771.355 30.9797 772.98 30.6173 774.984 30.6173C776.076 30.6173 777.107 30.7872 778.081 31.1155C779.049 31.4496 779.915 31.9195 780.679 32.5252C781.438 33.131 782.032 33.8783 782.457 34.7559C782.825 35.5202 783.023 36.3864 783.074 37.3545H766.152L766.146 37.3602Z"
              fill="#8889E8"
            />
            <path
              d="M833.428 39.4945C831.695 38.4302 829.861 37.5979 827.913 36.9921C825.966 36.3864 824.115 35.8712 822.354 35.4409C820.531 34.954 819.104 34.5464 818.074 34.2124C817.038 33.8783 816.324 33.5273 815.934 33.165C815.537 32.8026 815.339 32.378 815.339 31.8911C815.339 31.1608 815.764 30.555 816.613 30.0681C817.462 29.5813 818.555 29.3378 819.891 29.3378C821.652 29.3378 823.356 29.7624 824.992 30.6116C826.634 31.4609 827.998 32.6158 829.091 34.0708L837.476 27.328C835.715 24.718 833.332 22.7139 830.325 21.3155C827.319 19.9171 823.962 19.2207 820.259 19.2207C814.915 19.2207 810.618 20.4946 807.368 23.0479C804.118 25.6012 802.493 28.9698 802.493 33.1593C802.493 35.6504 802.997 37.7168 803.999 39.353C805.001 40.9948 806.309 42.3479 807.917 43.4066C809.525 44.471 811.257 45.3202 813.109 45.9599C814.96 46.5997 816.738 47.1602 818.436 47.6471C819.835 48.0094 821.08 48.3887 822.173 48.785C823.265 49.1813 824.143 49.6229 824.817 50.1042C825.485 50.591 825.819 51.1968 825.819 51.9272C825.819 52.7198 825.332 53.4614 824.358 54.1578C823.384 54.8541 821.992 55.2052 820.169 55.2052C817.559 55.2052 815.413 54.5088 813.743 53.1104C812.073 51.712 810.72 50.3759 809.689 49.1021L800.399 57.2999C801.491 58.3926 802.89 59.6381 804.588 61.0365C806.287 62.4349 808.415 63.6351 810.963 64.6372C813.516 65.6393 816.551 66.1432 820.072 66.1432C823.9 66.1432 827.257 65.4751 830.138 64.139C833.02 62.8029 835.268 60.9516 836.881 58.5794C838.489 56.2129 839.293 53.4784 839.293 50.3816C839.293 47.7094 838.744 45.4957 837.651 43.7293C836.559 41.9686 835.143 40.5589 833.416 39.4945H833.428Z"
              fill="#8889E8"
            />
            <path
              d="M876.523 39.4945C874.791 38.4302 872.956 37.5979 871.009 36.9921C869.061 36.3864 867.21 35.8712 865.449 35.4409C863.626 34.954 862.2 34.5464 861.169 34.2124C860.133 33.8783 859.42 33.5273 859.029 33.165C858.633 32.8026 858.435 32.378 858.435 31.8911C858.435 31.1608 858.859 30.555 859.708 30.0681C860.558 29.5813 861.65 29.3378 862.987 29.3378C864.747 29.3378 866.451 29.7624 868.087 30.6116C869.729 31.4609 871.094 32.6158 872.186 34.0708L880.571 27.328C878.81 24.718 876.427 22.7139 873.421 21.3155C870.414 19.9171 867.057 19.2207 863.354 19.2207C858.01 19.2207 853.713 20.4946 850.463 23.0479C847.214 25.6012 845.589 28.9698 845.589 33.1593C845.589 35.6504 846.093 37.7168 847.095 39.353C848.097 40.9948 849.405 42.3479 851.012 43.4066C852.62 44.471 854.353 45.3202 856.204 45.9599C858.055 46.5997 859.833 47.1602 861.531 47.6471C862.93 48.0094 864.175 48.3887 865.268 48.785C866.361 49.1813 867.238 49.6229 867.912 50.1042C868.58 50.591 868.914 51.1968 868.914 51.9272C868.914 52.7198 868.427 53.4614 867.453 54.1578C866.48 54.8541 865.087 55.2052 863.264 55.2052C860.654 55.2052 858.508 54.5088 856.838 53.1104C855.168 51.712 853.815 50.3759 852.785 49.1021L843.494 57.2999C844.587 58.3926 845.985 59.6381 847.684 61.0365C849.382 62.4349 851.511 63.6351 854.058 64.6372C856.612 65.6393 859.646 66.1432 863.168 66.1432C866.995 66.1432 870.352 65.4751 873.234 64.139C876.116 62.8029 878.363 60.9516 879.977 58.5794C881.584 56.2129 882.388 53.4784 882.388 50.3816C882.388 47.7094 881.839 45.4957 880.747 43.7293C879.654 41.9686 878.239 40.5589 876.512 39.4945H876.523Z"
              fill="#8889E8"
            />
            <path
              d="M934.1 16.0333C936.62 14.4538 939.58 13.6668 942.983 13.6668C946.748 13.6668 949.952 14.6236 952.596 16.5372C955.24 18.4508 957.165 21.106 958.382 24.5086L971.868 19.6793C969.683 13.5479 966.065 8.73002 961.026 5.23688C955.982 1.74374 949.969 0 942.989 0C936.852 0 931.451 1.39839 926.768 4.1895C922.092 6.98628 918.446 10.8701 915.836 15.8522C913.226 20.8343 911.918 26.6656 911.918 33.3462C911.918 40.0267 913.226 45.8751 915.836 50.8855C918.446 55.8959 922.092 59.8023 926.768 62.5934C931.445 65.3845 936.852 66.7829 942.989 66.7829C949.975 66.7829 955.987 65.0222 961.026 61.5008C966.065 57.9793 969.683 53.1784 971.868 47.1036L958.382 42.2743C957.165 45.6769 955.24 48.3321 952.596 50.2457C949.952 52.1593 946.748 53.1161 942.983 53.1161C939.58 53.1161 936.62 52.3292 934.1 50.7496C931.581 49.17 929.633 46.9111 928.269 43.9615C926.904 41.0175 926.219 37.4791 926.219 33.3462C926.219 29.2133 926.904 25.6918 928.269 22.7762C929.633 19.8605 931.581 17.6129 934.1 16.0333Z"
              fill="#8889E8"
            />
            <path
              d="M1011.77 22.2327C1008.31 20.2285 1004.36 19.2264 999.926 19.2264C995.493 19.2264 991.53 20.2285 988.037 22.2327C984.544 24.2368 981.798 26.9996 979.794 30.5211C977.79 34.0425 976.788 38.0848 976.788 42.6367C976.788 47.1885 977.79 51.1572 979.794 54.707C981.798 58.2624 984.544 61.0535 988.037 63.086C991.53 65.1241 995.493 66.1375 999.926 66.1375C1004.36 66.1375 1008.31 65.1184 1011.77 63.086C1015.24 61.0535 1017.96 58.2567 1019.97 54.707C1021.97 51.1515 1022.97 47.1319 1022.97 42.6367C1022.97 38.1414 1021.97 34.0425 1019.97 30.5211C1017.96 26.9996 1015.23 24.2368 1011.77 22.2327ZM1008.17 48.8813C1007.47 50.6137 1006.43 51.9215 1005.03 52.799C1003.63 53.6822 1001.93 54.1182 999.926 54.1182C997.922 54.1182 996.218 53.6766 994.825 52.799C993.427 51.9215 992.363 50.6137 991.638 48.8813C990.908 47.1489 990.545 45.0711 990.545 42.6423C990.545 40.2135 990.908 38.0735 991.638 36.4034C992.368 34.7332 993.427 33.4594 994.825 32.5762C996.224 31.6987 997.922 31.2571 999.926 31.2571C1001.93 31.2571 1003.63 31.6987 1005.03 32.5762C1006.43 33.4594 1007.47 34.7332 1008.17 36.4034C1008.87 38.0735 1009.22 40.1513 1009.22 42.6423C1009.22 45.1334 1008.87 47.1545 1008.17 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M1091.81 21.7741C1089.35 20.0756 1086.15 19.2207 1082.19 19.2207C1079.95 19.2207 1077.62 19.7359 1075.22 20.772C1073.14 21.6722 1071.2 23.1951 1069.44 25.3351C1069.3 25.1483 1069.17 24.9558 1069.02 24.7803C1067.29 22.6573 1065.33 21.1966 1063.15 20.4096C1060.96 19.6227 1058.83 19.2264 1056.77 19.2264C1055.31 19.2264 1053.78 19.4415 1052.17 19.8661C1050.56 20.2908 1049.01 21.0664 1047.52 22.1874C1046.39 23.0422 1045.37 24.1802 1044.47 25.5956V20.2285H1030.71V65.1467H1044.47V43.3727C1044.47 40.5815 1044.74 38.2886 1045.29 36.4939C1045.84 34.7049 1046.67 33.3688 1047.8 32.4856C1048.92 31.6081 1050.34 31.1665 1052.03 31.1665C1053.91 31.1665 1055.39 31.8628 1056.45 33.2612C1057.52 34.6596 1058.05 36.7544 1058.05 39.5455V65.1467H1071.71V43.3727C1071.71 40.5815 1071.97 38.2886 1072.49 36.4939C1073 34.7049 1073.8 33.3688 1074.86 32.4856C1075.92 31.6081 1077.27 31.1665 1078.91 31.1665C1080.25 31.1665 1081.38 31.4552 1082.32 32.0327C1083.26 32.6102 1083.99 33.5217 1084.51 34.7672C1085.02 36.0127 1085.28 37.6093 1085.28 39.5511V65.1524H1099.04V36.1826C1099.04 33.4481 1098.45 30.7928 1097.26 28.2112C1096.08 25.6295 1094.26 23.4895 1091.8 21.7854L1091.81 21.7741Z"
              fill="#8889E8"
            />
            <path
              d="M1143.88 22.2779C1141.14 20.2455 1137.83 19.2264 1133.95 19.2264C1132.12 19.2264 1130.23 19.5887 1128.25 20.3191C1126.27 21.0494 1124.48 22.4591 1122.88 24.5539C1122.61 24.9049 1122.35 25.3012 1122.1 25.7145V20.2228H1108.43V83.7278H1122.1V59.5532C1122.25 59.7966 1122.4 60.0401 1122.55 60.2665C1124.01 62.3613 1125.76 63.8672 1127.79 64.7787C1129.82 65.6902 1131.87 66.1432 1133.94 66.1432C1137.34 66.1432 1140.42 65.2317 1143.18 63.4087C1145.95 61.5857 1148.15 58.9304 1149.79 55.4373C1151.43 51.9442 1152.25 47.7094 1152.25 42.7272C1152.25 38.232 1151.54 34.2067 1150.11 30.6569C1148.69 27.1015 1146.6 24.3104 1143.88 22.2723V22.2779ZM1137.45 48.5642C1136.75 50.2061 1135.75 51.4516 1134.44 52.3008C1133.14 53.15 1131.54 53.5747 1129.66 53.5747C1127.77 53.5747 1126.26 53.1331 1124.92 52.2555C1123.58 51.378 1122.57 50.1325 1121.87 48.5189C1121.17 46.9111 1120.82 44.9805 1120.82 42.7329C1120.82 40.4853 1121.17 38.4641 1121.87 36.8563C1122.57 35.2484 1123.58 34.0029 1124.92 33.1197C1126.26 32.2422 1127.84 31.8006 1129.66 31.8006C1131.48 31.8006 1133.14 32.2252 1134.44 33.0744C1135.75 33.9236 1136.75 35.1692 1137.45 36.811C1138.15 38.4528 1138.5 40.423 1138.5 42.7329C1138.5 45.0428 1138.15 46.9224 1137.45 48.5642Z"
              fill="#8889E8"
            />
            <path
              d="M1176.12 53.4785C1175.33 53.4785 1174.68 53.2973 1174.16 52.9293C1173.65 52.567 1173.39 51.9272 1173.39 51.0157V1.64185H1159.72V53.7559C1159.72 58.0699 1160.84 61.212 1163.09 63.1879C1165.34 65.1638 1168.1 66.1489 1171.38 66.1489C1172.41 66.1489 1173.58 66.0413 1174.89 65.8318C1176.2 65.6167 1177.54 65.2374 1178.94 64.6939V53.0312C1178.46 53.1557 1177.99 53.2577 1177.53 53.3482C1177.08 53.4388 1176.6 53.4841 1176.12 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M1199.72 20.2285H1185.96V65.1468H1199.72V20.2285Z"
              fill="#8889E8"
            />
            <path
              d="M1199.9 1.64185H1185.87V14.0348H1199.9V1.64185Z"
              fill="#8889E8"
            />
            <path
              d="M1244.96 23.9141C1241.34 20.789 1236.34 19.2207 1229.97 19.2207C1226.08 19.2207 1222.45 19.6906 1219.08 20.6304C1215.71 21.5759 1212.23 23.2291 1208.65 25.5956L1214.48 35.2541C1216.3 33.9802 1218.55 32.8989 1221.22 32.0214C1223.89 31.1438 1226.41 30.7022 1228.79 30.7022C1231.03 30.7022 1232.91 31.1552 1234.44 32.0667C1235.95 32.9781 1236.71 34.4954 1236.71 36.6241V37.3545H1224.32C1222.32 37.3545 1220.43 37.5357 1218.67 37.9036C1216.91 38.266 1215.33 38.8435 1213.93 39.6361C1212.05 40.6721 1210.55 42.1554 1209.42 44.103C1208.29 46.0449 1207.73 48.3265 1207.73 50.9364C1207.73 53.9766 1208.51 56.6488 1210.05 58.9531C1211.6 61.263 1213.7 63.0407 1216.34 64.2862C1218.98 65.5317 1221.91 66.1545 1225.13 66.1545C1226.83 66.1545 1228.58 65.8488 1230.37 65.243C1232.15 64.6372 1233.86 63.5106 1235.47 61.8744C1235.93 61.4102 1236.36 60.8723 1236.79 60.2892L1237.79 65.1524H1250.37V36.6355C1250.37 31.291 1248.56 27.0562 1244.94 23.9254L1244.96 23.9141ZM1235.43 51.2025C1234.58 52.4197 1233.44 53.3765 1232.02 54.0729C1230.59 54.7749 1229.02 55.1202 1227.33 55.1202C1225.63 55.1202 1224.15 54.7409 1223.09 53.9823C1222.03 53.2236 1221.49 52.2046 1221.49 50.9307C1221.49 50.3816 1221.61 49.8834 1221.86 49.4248C1222.1 48.9719 1222.43 48.5925 1222.86 48.2868C1223.28 47.9811 1223.78 47.7716 1224.36 47.6471C1224.94 47.5282 1225.59 47.4659 1226.32 47.4659H1236.7C1236.62 48.8586 1236.2 50.1042 1235.43 51.2025Z"
              fill="#8889E8"
            />
            <path
              d="M1286.18 19.2264C1284.3 19.2264 1282.37 19.5604 1280.4 20.2285C1278.42 20.8965 1276.64 22.0685 1275.07 23.7386C1274.46 24.384 1273.91 25.137 1273.43 25.9862V20.2341H1259.67V65.1524H1273.43V44.1992C1273.43 41.1647 1273.73 38.6849 1274.34 36.7714C1274.94 34.8578 1275.87 33.4481 1277.12 32.5366C1278.36 31.6251 1279.96 31.1721 1281.9 31.1721C1284.39 31.1721 1286.2 31.9025 1287.33 33.3575C1288.45 34.8181 1289.01 37.1563 1289.01 40.3721V65.1524H1302.77V35.2711C1302.77 30.3512 1301.32 26.4505 1298.4 23.5631C1295.48 20.6814 1291.41 19.2377 1286.19 19.2377L1286.18 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M1327.5 32.8479C1328.81 31.727 1330.52 31.1608 1332.65 31.1608C1334.96 31.1608 1336.73 31.7666 1337.97 32.9838C1339.22 34.201 1340.14 35.7466 1340.75 37.6319L1352.69 32.8932C1350.99 28.2791 1348.41 24.8483 1344.94 22.6007C1341.48 20.353 1337.38 19.2321 1332.64 19.2321C1328.81 19.2321 1325.49 19.9001 1322.67 21.2362C1319.84 22.5723 1317.49 24.3501 1315.61 26.5637C1313.72 28.783 1312.33 31.2854 1311.42 34.0822C1310.5 36.8789 1310.05 39.7323 1310.05 42.648C1310.05 45.5637 1310.5 48.417 1311.42 51.2138C1312.33 54.0106 1313.73 56.53 1315.61 58.7776C1317.49 61.0252 1319.84 62.8199 1322.67 64.1503C1325.49 65.4864 1328.81 66.1545 1332.64 66.1545C1337.38 66.1545 1341.48 65.0335 1344.94 62.7859C1348.41 60.5383 1350.98 57.1074 1352.69 52.4933L1340.75 47.7547C1340.15 49.6399 1339.22 51.1855 1337.97 52.4027C1336.73 53.62 1334.95 54.2257 1332.65 54.2257C1330.52 54.2257 1328.8 53.6652 1327.5 52.5386C1326.19 51.4176 1325.25 49.957 1324.68 48.1679C1324.1 46.3789 1323.81 44.5389 1323.81 42.6536C1323.81 40.7684 1324.1 38.951 1324.68 37.1847C1325.25 35.4239 1326.19 33.9802 1327.5 32.8593V32.8479Z"
              fill="#8889E8"
            />
            <path
              d="M1400.06 33.5273C1399.15 30.6117 1397.8 28.0923 1396.01 25.9636C1394.22 23.8405 1391.98 22.1817 1389.31 20.9984C1386.64 19.8152 1383.54 19.2207 1380.02 19.2207C1376.01 19.2207 1372.59 19.9171 1369.77 21.3155C1366.95 22.7139 1364.67 24.5652 1362.94 26.8751C1361.21 29.185 1359.93 31.7326 1359.11 34.5294C1358.29 37.3262 1357.89 40.1173 1357.89 42.9141C1357.89 45.7108 1358.29 48.6718 1359.11 51.4346C1359.93 54.1974 1361.25 56.6885 1363.08 58.9078C1364.9 61.1271 1367.27 62.8878 1370.18 64.1899C1373.1 65.4978 1376.62 66.1488 1380.75 66.1488C1383.42 66.1488 1385.78 65.9507 1387.81 65.5544C1389.84 65.1581 1391.84 64.49 1393.78 63.5502C1395.72 62.6104 1397.88 61.3479 1400.25 59.7683L1394.42 50.1098C1392.72 51.4459 1390.72 52.4933 1388.41 53.2519C1386.1 54.0106 1383.85 54.3899 1381.66 54.3899C1379.17 54.3899 1376.97 53.8407 1375.06 52.7481C1373.14 51.6554 1372.01 49.8947 1371.64 47.4659H1401.16C1401.28 46.4978 1401.36 45.6769 1401.39 45.0088C1401.42 44.3408 1401.43 43.701 1401.43 43.0952C1401.43 39.6304 1400.98 36.443 1400.07 33.5273H1400.06ZM1371.46 37.3545C1371.64 36.3864 1372.03 35.3786 1372.64 34.3482C1373.24 33.3178 1374.17 32.4347 1375.42 31.7043C1376.66 30.974 1378.29 30.6116 1380.29 30.6116C1381.39 30.6116 1382.42 30.7815 1383.39 31.1099C1384.36 31.4439 1385.22 31.9138 1385.99 32.5196C1386.75 33.1254 1387.34 33.8727 1387.77 34.7502C1388.13 35.5145 1388.33 36.3807 1388.38 37.3488H1371.46L1371.46 37.3545Z"
              fill="#8889E8"
            />
            <path
              d="M1431.39 50.4777C1436.15 50.4777 1440.01 46.6224 1440.01 41.8666C1440.01 37.1108 1436.15 33.2555 1431.39 33.2555C1426.64 33.2555 1422.78 37.1108 1422.78 41.8666C1422.78 46.6224 1426.64 50.4777 1431.39 50.4777Z"
              fill="#8889E8"
            />
          </svg>
          <svg
            width="1440"
            height="84"
            viewBox="0 0 1440 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.848633 64.3257H43.4853V51.5704H15.1552V37.9941H42.1209V25.2388H15.1552V13.5761H43.4853V0.820801H0.848633V64.3257Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M80.9303 19.4074L73.0043 32.7969L65.0782 19.4074H49.0448L63.8043 41.6345L49.0448 64.3257H64.9876L73.0043 50.5683L80.9303 64.3257H96.9693L82.023 41.6345L96.9693 19.4074H80.9303Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M138.695 21.457C135.96 19.4245 132.648 18.4054 128.764 18.4054C126.941 18.4054 125.045 18.7678 123.069 19.4981C121.093 20.2284 119.304 21.6381 117.696 23.7329C117.424 24.0839 117.17 24.4802 116.92 24.8935V19.4018H103.254V82.9068H116.92V58.7322C117.068 58.9757 117.215 59.2191 117.373 59.4456C118.828 61.5403 120.578 63.0463 122.61 63.9578C124.643 64.8693 126.692 65.3222 128.759 65.3222C132.161 65.3222 135.241 64.4107 138.004 62.5877C140.767 60.7647 142.969 58.1094 144.611 54.6163C146.253 51.1232 147.074 46.8884 147.074 41.9063C147.074 37.411 146.36 33.3857 144.933 29.836C143.507 26.2806 141.423 23.4894 138.695 21.4513V21.457ZM132.269 47.7433C131.572 49.3851 130.57 50.6306 129.262 51.4798C127.955 52.3291 126.364 52.7537 124.479 52.7537C122.593 52.7537 121.076 52.3121 119.74 51.4346C118.404 50.557 117.385 49.3115 116.688 47.698C115.992 46.0901 115.641 44.1595 115.641 41.9119C115.641 39.6643 115.992 37.6432 116.688 36.0353C117.385 34.4274 118.404 33.1819 119.74 32.2987C121.076 31.4212 122.656 30.9796 124.479 30.9796C126.302 30.9796 127.955 31.4042 129.262 32.2534C130.57 33.1026 131.572 34.3482 132.269 35.99C132.965 37.6318 133.316 39.602 133.316 41.9119C133.316 44.2218 132.965 46.1014 132.269 47.7433Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M190.854 25.1483C189.059 23.0252 186.828 21.3664 184.156 20.1831C181.484 18.9999 178.387 18.4054 174.866 18.4054C170.857 18.4054 167.438 19.1018 164.618 20.5002C161.793 21.8986 159.517 23.7499 157.785 26.0598C156.052 28.3696 154.779 30.9173 153.958 33.7141C153.137 36.5109 152.729 39.302 152.729 42.0988C152.729 44.8955 153.137 47.8565 153.958 50.6193C154.779 53.3821 156.098 55.8732 157.921 58.0925C159.744 60.3118 162.11 62.0725 165.026 63.3746C167.942 64.6824 171.463 65.3335 175.596 65.3335C178.268 65.3335 180.623 65.1354 182.656 64.7391C184.688 64.3428 186.681 63.6747 188.623 62.7349C190.565 61.7951 192.722 60.5326 195.094 58.953L189.263 49.2945C187.564 50.6306 185.56 51.678 183.25 52.4366C180.94 53.1953 178.693 53.5746 176.507 53.5746C174.016 53.5746 171.814 53.0254 169.9 51.9328C167.987 50.8401 166.849 49.0794 166.487 46.6506H196.006C196.124 45.6825 196.204 44.8616 196.232 44.1935C196.26 43.5255 196.277 42.8857 196.277 42.2799C196.277 38.8151 195.824 35.6277 194.913 32.712C194.001 29.7963 192.648 27.277 190.859 25.1483H190.854ZM166.3 36.5392C166.481 35.5711 166.877 34.5633 167.483 33.5329C168.089 32.5025 169.017 31.6193 170.263 30.889C171.508 30.1587 173.133 29.7963 175.137 29.7963C176.23 29.7963 177.26 29.9662 178.234 30.2946C179.202 30.6286 180.069 31.0985 180.833 31.7043C181.591 32.31 182.186 33.0574 182.611 33.9349C182.979 34.6992 183.177 35.5654 183.228 36.5335H166.305L166.3 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M229.347 18.4054C227.218 18.4054 225.168 18.9829 223.198 20.1378C221.222 21.2928 219.615 22.8723 218.369 24.8765C218.256 25.0577 218.154 25.2445 218.052 25.4313V19.4075H204.204V64.3258H217.961V46.1977C217.961 43.6443 218.035 41.7194 218.188 40.4116C218.341 39.1038 218.567 38.0281 218.873 37.1789C219.297 36.024 219.858 35.0672 220.56 34.3085C221.256 33.5499 222.077 32.9894 223.017 32.6214C223.957 32.2591 224.942 32.0723 225.978 32.0723C227.071 32.0723 228.163 32.2421 229.256 32.5761C230.349 32.9102 231.198 33.2895 231.809 33.7141L238.371 21.3211C236.791 20.2907 235.286 19.5434 233.859 19.0905C232.432 18.6375 230.926 18.4054 229.347 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M258.044 0.820801H244.015V13.2138H258.044V0.820801Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M257.863 19.4074H244.106V64.3257H257.863V19.4074Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M303.733 25.1483C301.938 23.0252 299.708 21.3664 297.035 20.1831C294.363 18.9999 291.266 18.4054 287.745 18.4054C283.737 18.4054 280.317 19.1018 277.498 20.5002C274.673 21.8986 272.397 23.7499 270.664 26.0598C268.932 28.3696 267.658 30.9173 266.837 33.7141C266.016 36.5109 265.608 39.302 265.608 42.0988C265.608 44.8955 266.016 47.8565 266.837 50.6193C267.658 53.3821 268.977 55.8732 270.8 58.0925C272.623 60.3118 274.99 62.0725 277.905 63.3746C280.821 64.6824 284.342 65.3335 288.475 65.3335C291.147 65.3335 293.503 65.1354 295.535 64.7391C297.568 64.3428 299.56 63.6747 301.502 62.7349C303.444 61.7951 305.601 60.5326 307.973 58.953L302.142 49.2945C300.444 50.6306 298.439 51.678 296.13 52.4366C293.82 53.1953 291.572 53.5746 289.387 53.5746C286.896 53.5746 284.693 53.0254 282.78 51.9328C280.866 50.8401 279.728 49.0794 279.366 46.6506H308.885C309.004 45.6825 309.083 44.8616 309.111 44.1935C309.14 43.5255 309.157 42.8857 309.157 42.2799C309.157 38.8151 308.704 35.6277 307.792 32.712C306.881 29.7963 305.528 27.277 303.739 25.1483H303.733ZM279.179 36.5392C279.36 35.5711 279.757 34.5633 280.362 33.5329C280.968 32.5025 281.897 31.6193 283.142 30.889C284.388 30.1587 286.012 29.7963 288.017 29.7963C289.109 29.7963 290.14 29.9662 291.113 30.2946C292.082 30.6286 292.948 31.0985 293.712 31.7043C294.471 32.31 295.065 33.0574 295.49 33.9349C295.858 34.6992 296.056 35.5654 296.107 36.5335H279.185L279.179 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M343.595 18.4054C341.71 18.4054 339.785 18.7395 337.809 19.4075C335.834 20.0756 334.056 21.2475 332.482 22.9176C331.87 23.563 331.327 24.316 330.84 25.1652V19.4132H317.083V64.3314H330.84V43.3783C330.84 40.3437 331.146 37.864 331.752 35.9504C332.357 34.0368 333.286 32.6271 334.531 31.7156C335.777 30.8041 337.368 30.3512 339.315 30.3512C341.806 30.3512 343.612 31.0815 344.739 32.5365C345.86 33.9972 346.426 36.3354 346.426 39.5511V64.3314H360.184V34.4501C360.184 29.5302 358.729 25.6295 355.813 22.7421C352.897 19.8604 348.827 18.4167 343.607 18.4167L343.595 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M384.907 32.0269C386.215 30.9059 387.93 30.3398 390.053 30.3398C392.363 30.3398 394.135 30.9456 395.381 32.1628C396.626 33.38 397.549 34.9256 398.161 36.8109L410.095 32.0722C408.397 27.4581 405.815 24.0272 402.35 21.7796C398.885 19.532 394.786 18.411 390.048 18.411C386.221 18.411 382.897 19.0791 380.072 20.4152C377.247 21.7513 374.892 23.529 373.012 25.7427C371.127 27.962 369.734 30.4643 368.823 33.2611C367.911 36.0579 367.458 38.9113 367.458 41.8269C367.458 44.7426 367.911 47.596 368.823 50.3928C369.734 53.1896 371.133 55.7089 373.012 57.9565C374.892 60.2041 377.247 61.9988 380.072 63.3293C382.897 64.6654 386.221 65.3335 390.048 65.3335C394.786 65.3335 398.885 64.2125 402.35 61.9649C405.815 59.7173 408.391 56.2864 410.095 51.6723L398.161 46.9336C397.555 48.8189 396.626 50.3645 395.381 51.5817C394.135 52.7989 392.358 53.4047 390.053 53.4047C387.925 53.4047 386.209 52.8442 384.907 51.7176C383.599 50.5966 382.659 49.1359 382.082 47.3469C381.504 45.5579 381.216 43.7179 381.216 41.8326C381.216 39.9473 381.504 38.13 382.082 36.3636C382.659 34.6029 383.599 33.1592 384.907 32.0382V32.0269Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M457.476 32.7063C456.565 29.7907 455.211 27.2713 453.422 25.1426C451.628 23.0195 449.397 21.3607 446.725 20.1775C444.053 18.9942 440.956 18.3998 437.434 18.3998C433.426 18.3998 430.006 19.0961 427.187 20.4945C424.362 21.8929 422.086 23.7442 420.354 26.0541C418.621 28.364 417.347 30.9116 416.526 33.7084C415.706 36.5052 415.298 39.2963 415.298 42.0931C415.298 44.8899 415.706 47.8508 416.526 50.6136C417.347 53.3764 418.667 55.8675 420.49 58.0868C422.313 60.3061 424.679 62.0668 427.595 63.369C430.51 64.6768 434.032 65.3278 438.165 65.3278C440.837 65.3278 443.192 65.1297 445.225 64.7334C447.257 64.3371 449.25 63.669 451.192 62.7292C453.134 61.7894 455.291 60.5269 457.663 58.9473L451.832 49.2888C450.133 50.625 448.129 51.6723 445.819 52.431C443.509 53.1896 441.262 53.5689 439.076 53.5689C436.585 53.5689 434.383 53.0198 432.469 51.9271C430.556 50.8344 429.418 49.0737 429.055 46.6449H458.574C458.693 45.6768 458.773 44.8559 458.801 44.1878C458.829 43.5198 458.846 42.88 458.846 42.2743C458.846 38.8094 458.393 35.622 457.482 32.7063H457.476ZM428.869 36.5335C429.05 35.5654 429.446 34.5576 430.052 33.5273C430.658 32.4969 431.586 31.6137 432.832 30.8833C434.077 30.153 435.702 29.7907 437.706 29.7907C438.799 29.7907 439.829 29.9605 440.803 30.2889C441.771 30.6229 442.637 31.0928 443.402 31.6986C444.16 32.3044 444.755 33.0517 445.179 33.9292C445.547 34.6935 445.745 35.5597 445.796 36.5278H428.874L428.869 36.5335Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M490.913 65.1411H533.55V52.3858H505.214V38.8152H532.185V26.0599H505.214V14.3972H533.55V1.64185H490.913V65.1411Z"
              fill="#8889E8"
            />
            <path
              d="M587.668 13.2592C588.03 12.4383 588.789 12.0307 589.944 12.0307C590.549 12.0307 591.144 12.1382 591.721 12.3477C592.299 12.5628 592.859 12.8176 593.408 13.1233L597.779 2.64391C596.443 2.03813 595.045 1.55125 593.59 1.18325C592.129 0.820913 590.402 0.634084 588.398 0.634084C586.513 0.634084 584.69 0.849221 582.929 1.27383C581.168 1.69844 579.589 2.45708 578.19 3.54975C576.367 2.51936 574.425 1.77204 572.359 1.31912C570.293 0.866205 568.226 0.634084 566.165 0.634084C562.706 0.634084 559.44 1.28516 556.371 2.59296C553.302 3.90076 550.828 5.81435 548.943 8.33371C547.058 10.8531 546.118 13.9386 546.118 17.5789V20.2228H538.645V30.8834H546.118V65.1411H559.785V30.8834H573.361V65.1411H587.119V30.8834H596.047V20.2228H587.119V16.3051C587.119 15.0935 587.3 14.0745 587.668 13.2535V13.2592ZM573.457 14.0348V20.2285H559.791V17.862C559.791 16.039 560.306 14.55 561.342 13.4007C562.372 12.2458 564.195 11.6683 566.811 11.6683C568.209 11.6683 569.421 11.8212 570.457 12.1212C571.487 12.427 572.489 12.8176 573.463 13.3045V14.0348H573.457Z"
              fill="#8889E8"
            />
            <path
              d="M634.953 22.2327C631.488 20.2285 627.542 19.2264 623.109 19.2264C618.676 19.2264 614.713 20.2285 611.22 22.2327C607.727 24.2368 604.981 26.9996 602.977 30.5211C600.972 34.0425 599.97 38.0848 599.97 42.6367C599.97 47.1885 600.972 51.1572 602.977 54.707C604.981 58.2624 607.727 61.0535 611.22 63.086C614.713 65.1241 618.676 66.1375 623.109 66.1375C627.542 66.1375 631.493 65.1184 634.953 63.086C638.417 61.0535 641.146 58.2567 643.15 54.707C645.155 51.1515 646.157 47.1319 646.157 42.6367C646.157 38.1414 645.155 34.0425 643.15 30.5211C641.146 26.9996 638.412 24.2368 634.953 22.2327ZM631.352 48.8813C630.656 50.6137 629.608 51.9215 628.21 52.799C626.811 53.6822 625.113 54.1182 623.109 54.1182C621.105 54.1182 619.401 53.6766 618.008 52.799C616.609 51.9215 615.545 50.6137 614.82 48.8813C614.09 47.1489 613.728 45.0711 613.728 42.6423C613.728 40.2135 614.09 38.0735 614.82 36.4034C615.551 34.7332 616.609 33.4594 618.008 32.5762C619.406 31.6987 621.105 31.2571 623.109 31.2571C625.113 31.2571 626.811 31.6987 628.21 32.5762C629.608 33.4594 630.656 34.7332 631.352 36.4034C632.048 38.0735 632.399 40.1513 632.399 42.6423C632.399 45.1334 632.048 47.1545 631.352 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M679.05 19.2264C676.922 19.2264 674.872 19.8039 672.902 20.9588C670.926 22.1138 669.318 23.6933 668.073 25.6975C667.959 25.8786 667.857 26.0655 667.756 26.2523V20.2285H653.908V65.1467H667.665V47.0187C667.665 44.4653 667.739 42.5404 667.891 41.2326C668.044 39.9248 668.271 38.8491 668.577 37.9999C669.001 36.845 669.562 35.8882 670.264 35.1295C670.96 34.3709 671.781 33.8104 672.721 33.4424C673.661 33.0801 674.646 32.8932 675.682 32.8932C676.774 32.8932 677.867 33.0631 678.96 33.3971C680.052 33.7311 680.902 34.1105 681.513 34.5351L688.075 22.1421C686.495 21.1117 684.989 20.3644 683.562 19.9114C682.136 19.4585 680.63 19.2264 679.05 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M711.207 3.46483L697.359 10.8474V20.2342H689.886V30.8947H697.359V50.8458C697.359 54.673 698.073 57.6962 699.499 59.9099C700.926 62.1292 702.794 63.7201 705.104 64.6938C707.414 65.662 709.843 66.1545 712.391 66.1545C713.846 66.1545 715.244 66.03 716.58 65.7922C717.916 65.5487 719.252 65.1864 720.589 64.6995V54.2201C719.739 54.4635 718.947 54.6447 718.222 54.7693C717.492 54.8938 716.824 54.9504 716.218 54.9504C714.214 54.9504 712.878 54.2824 712.209 52.9463C711.541 51.6101 711.207 49.606 711.207 46.9338V30.9004H720.594V20.2398H711.207V3.46483Z"
              fill="#8889E8"
            />
            <path
              d="M744.735 53.4785C743.942 53.4785 743.291 53.2973 742.776 52.9293C742.261 52.567 742 51.9272 742 51.0157V1.64185H728.334V53.7559C728.334 58.0699 729.455 61.212 731.702 63.1879C733.95 65.1638 736.713 66.1489 739.991 66.1489C741.021 66.1489 742.193 66.0413 743.501 65.8318C744.808 65.6167 746.156 65.2374 747.554 64.6939V53.0312C747.067 53.1557 746.598 53.2577 746.145 53.3482C745.692 53.4388 745.216 53.4841 744.735 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M790.7 25.9692C788.905 23.8462 786.675 22.1874 784.003 21.0041C781.33 19.8209 778.234 19.2264 774.712 19.2264C770.704 19.2264 767.284 19.9228 764.465 21.3211C761.64 22.7195 759.364 24.5708 757.631 26.8807C755.899 29.1906 754.625 31.7383 753.804 34.5351C752.983 37.3318 752.576 40.123 752.576 42.9197C752.576 45.7165 752.983 48.6775 753.804 51.4403C754.625 54.2031 755.944 56.6941 757.767 58.9134C759.59 61.1328 761.957 62.8935 764.872 64.1956C767.788 65.5034 771.31 66.1545 775.442 66.1545C778.115 66.1545 780.47 65.9563 782.502 65.56C784.535 65.1637 786.528 64.4957 788.469 63.5559C790.411 62.6161 792.568 61.3535 794.941 59.774L789.109 50.1155C787.411 51.4516 785.407 52.499 783.097 53.2576C780.787 54.0163 778.539 54.3956 776.354 54.3956C773.863 54.3956 771.66 53.8464 769.747 52.7537C767.833 51.6611 766.695 49.9004 766.333 47.4716H795.852C795.971 46.5035 796.05 45.6825 796.079 45.0145C796.107 44.3464 796.124 43.7067 796.124 43.1009C796.124 39.6361 795.671 36.4487 794.759 33.533C793.848 30.6173 792.495 28.098 790.706 25.9692H790.7ZM766.146 37.3602C766.327 36.392 766.724 35.3843 767.329 34.3539C767.935 33.3235 768.864 32.4403 770.109 31.71C771.355 30.9797 772.98 30.6173 774.984 30.6173C776.076 30.6173 777.107 30.7872 778.081 31.1155C779.049 31.4496 779.915 31.9195 780.679 32.5252C781.438 33.131 782.032 33.8783 782.457 34.7559C782.825 35.5202 783.023 36.3864 783.074 37.3545H766.152L766.146 37.3602Z"
              fill="#8889E8"
            />
            <path
              d="M833.428 39.4945C831.695 38.4302 829.861 37.5979 827.913 36.9921C825.966 36.3864 824.115 35.8712 822.354 35.4409C820.531 34.954 819.104 34.5464 818.074 34.2124C817.038 33.8783 816.324 33.5273 815.934 33.165C815.537 32.8026 815.339 32.378 815.339 31.8911C815.339 31.1608 815.764 30.555 816.613 30.0681C817.462 29.5813 818.555 29.3378 819.891 29.3378C821.652 29.3378 823.356 29.7624 824.992 30.6116C826.634 31.4609 827.998 32.6158 829.091 34.0708L837.476 27.328C835.715 24.718 833.332 22.7139 830.325 21.3155C827.319 19.9171 823.962 19.2207 820.259 19.2207C814.915 19.2207 810.618 20.4946 807.368 23.0479C804.118 25.6012 802.493 28.9698 802.493 33.1593C802.493 35.6504 802.997 37.7168 803.999 39.353C805.001 40.9948 806.309 42.3479 807.917 43.4066C809.525 44.471 811.257 45.3202 813.109 45.9599C814.96 46.5997 816.738 47.1602 818.436 47.6471C819.835 48.0094 821.08 48.3887 822.173 48.785C823.265 49.1813 824.143 49.6229 824.817 50.1042C825.485 50.591 825.819 51.1968 825.819 51.9272C825.819 52.7198 825.332 53.4614 824.358 54.1578C823.384 54.8541 821.992 55.2052 820.169 55.2052C817.559 55.2052 815.413 54.5088 813.743 53.1104C812.073 51.712 810.72 50.3759 809.689 49.1021L800.399 57.2999C801.491 58.3926 802.89 59.6381 804.588 61.0365C806.287 62.4349 808.415 63.6351 810.963 64.6372C813.516 65.6393 816.551 66.1432 820.072 66.1432C823.9 66.1432 827.257 65.4751 830.138 64.139C833.02 62.8029 835.268 60.9516 836.881 58.5794C838.489 56.2129 839.293 53.4784 839.293 50.3816C839.293 47.7094 838.744 45.4957 837.651 43.7293C836.559 41.9686 835.143 40.5589 833.416 39.4945H833.428Z"
              fill="#8889E8"
            />
            <path
              d="M876.523 39.4945C874.791 38.4302 872.956 37.5979 871.009 36.9921C869.061 36.3864 867.21 35.8712 865.449 35.4409C863.626 34.954 862.2 34.5464 861.169 34.2124C860.133 33.8783 859.42 33.5273 859.029 33.165C858.633 32.8026 858.435 32.378 858.435 31.8911C858.435 31.1608 858.859 30.555 859.708 30.0681C860.558 29.5813 861.65 29.3378 862.987 29.3378C864.747 29.3378 866.451 29.7624 868.087 30.6116C869.729 31.4609 871.094 32.6158 872.186 34.0708L880.571 27.328C878.81 24.718 876.427 22.7139 873.421 21.3155C870.414 19.9171 867.057 19.2207 863.354 19.2207C858.01 19.2207 853.713 20.4946 850.463 23.0479C847.214 25.6012 845.589 28.9698 845.589 33.1593C845.589 35.6504 846.093 37.7168 847.095 39.353C848.097 40.9948 849.405 42.3479 851.012 43.4066C852.62 44.471 854.353 45.3202 856.204 45.9599C858.055 46.5997 859.833 47.1602 861.531 47.6471C862.93 48.0094 864.175 48.3887 865.268 48.785C866.361 49.1813 867.238 49.6229 867.912 50.1042C868.58 50.591 868.914 51.1968 868.914 51.9272C868.914 52.7198 868.427 53.4614 867.453 54.1578C866.48 54.8541 865.087 55.2052 863.264 55.2052C860.654 55.2052 858.508 54.5088 856.838 53.1104C855.168 51.712 853.815 50.3759 852.785 49.1021L843.494 57.2999C844.587 58.3926 845.985 59.6381 847.684 61.0365C849.382 62.4349 851.511 63.6351 854.058 64.6372C856.612 65.6393 859.646 66.1432 863.168 66.1432C866.995 66.1432 870.352 65.4751 873.234 64.139C876.116 62.8029 878.363 60.9516 879.977 58.5794C881.584 56.2129 882.388 53.4784 882.388 50.3816C882.388 47.7094 881.839 45.4957 880.747 43.7293C879.654 41.9686 878.239 40.5589 876.512 39.4945H876.523Z"
              fill="#8889E8"
            />
            <path
              d="M934.1 16.0333C936.62 14.4538 939.58 13.6668 942.983 13.6668C946.748 13.6668 949.952 14.6236 952.596 16.5372C955.24 18.4508 957.165 21.106 958.382 24.5086L971.868 19.6793C969.683 13.5479 966.065 8.73002 961.026 5.23688C955.982 1.74374 949.969 0 942.989 0C936.852 0 931.451 1.39839 926.768 4.1895C922.092 6.98628 918.446 10.8701 915.836 15.8522C913.226 20.8343 911.918 26.6656 911.918 33.3462C911.918 40.0267 913.226 45.8751 915.836 50.8855C918.446 55.8959 922.092 59.8023 926.768 62.5934C931.445 65.3845 936.852 66.7829 942.989 66.7829C949.975 66.7829 955.987 65.0222 961.026 61.5008C966.065 57.9793 969.683 53.1784 971.868 47.1036L958.382 42.2743C957.165 45.6769 955.24 48.3321 952.596 50.2457C949.952 52.1593 946.748 53.1161 942.983 53.1161C939.58 53.1161 936.62 52.3292 934.1 50.7496C931.581 49.17 929.633 46.9111 928.269 43.9615C926.904 41.0175 926.219 37.4791 926.219 33.3462C926.219 29.2133 926.904 25.6918 928.269 22.7762C929.633 19.8605 931.581 17.6129 934.1 16.0333Z"
              fill="#8889E8"
            />
            <path
              d="M1011.77 22.2327C1008.31 20.2285 1004.36 19.2264 999.926 19.2264C995.493 19.2264 991.53 20.2285 988.037 22.2327C984.544 24.2368 981.798 26.9996 979.794 30.5211C977.79 34.0425 976.788 38.0848 976.788 42.6367C976.788 47.1885 977.79 51.1572 979.794 54.707C981.798 58.2624 984.544 61.0535 988.037 63.086C991.53 65.1241 995.493 66.1375 999.926 66.1375C1004.36 66.1375 1008.31 65.1184 1011.77 63.086C1015.24 61.0535 1017.96 58.2567 1019.97 54.707C1021.97 51.1515 1022.97 47.1319 1022.97 42.6367C1022.97 38.1414 1021.97 34.0425 1019.97 30.5211C1017.96 26.9996 1015.23 24.2368 1011.77 22.2327ZM1008.17 48.8813C1007.47 50.6137 1006.43 51.9215 1005.03 52.799C1003.63 53.6822 1001.93 54.1182 999.926 54.1182C997.922 54.1182 996.218 53.6766 994.825 52.799C993.427 51.9215 992.363 50.6137 991.638 48.8813C990.908 47.1489 990.545 45.0711 990.545 42.6423C990.545 40.2135 990.908 38.0735 991.638 36.4034C992.368 34.7332 993.427 33.4594 994.825 32.5762C996.224 31.6987 997.922 31.2571 999.926 31.2571C1001.93 31.2571 1003.63 31.6987 1005.03 32.5762C1006.43 33.4594 1007.47 34.7332 1008.17 36.4034C1008.87 38.0735 1009.22 40.1513 1009.22 42.6423C1009.22 45.1334 1008.87 47.1545 1008.17 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M1091.81 21.7741C1089.35 20.0756 1086.15 19.2207 1082.19 19.2207C1079.95 19.2207 1077.62 19.7359 1075.22 20.772C1073.14 21.6722 1071.2 23.1951 1069.44 25.3351C1069.3 25.1483 1069.17 24.9558 1069.02 24.7803C1067.29 22.6573 1065.33 21.1966 1063.15 20.4096C1060.96 19.6227 1058.83 19.2264 1056.77 19.2264C1055.31 19.2264 1053.78 19.4415 1052.17 19.8661C1050.56 20.2908 1049.01 21.0664 1047.52 22.1874C1046.39 23.0422 1045.37 24.1802 1044.47 25.5956V20.2285H1030.71V65.1467H1044.47V43.3727C1044.47 40.5815 1044.74 38.2886 1045.29 36.4939C1045.84 34.7049 1046.67 33.3688 1047.8 32.4856C1048.92 31.6081 1050.34 31.1665 1052.03 31.1665C1053.91 31.1665 1055.39 31.8628 1056.45 33.2612C1057.52 34.6596 1058.05 36.7544 1058.05 39.5455V65.1467H1071.71V43.3727C1071.71 40.5815 1071.97 38.2886 1072.49 36.4939C1073 34.7049 1073.8 33.3688 1074.86 32.4856C1075.92 31.6081 1077.27 31.1665 1078.91 31.1665C1080.25 31.1665 1081.38 31.4552 1082.32 32.0327C1083.26 32.6102 1083.99 33.5217 1084.51 34.7672C1085.02 36.0127 1085.28 37.6093 1085.28 39.5511V65.1524H1099.04V36.1826C1099.04 33.4481 1098.45 30.7928 1097.26 28.2112C1096.08 25.6295 1094.26 23.4895 1091.8 21.7854L1091.81 21.7741Z"
              fill="#8889E8"
            />
            <path
              d="M1143.88 22.2779C1141.14 20.2455 1137.83 19.2264 1133.95 19.2264C1132.12 19.2264 1130.23 19.5887 1128.25 20.3191C1126.27 21.0494 1124.48 22.4591 1122.88 24.5539C1122.61 24.9049 1122.35 25.3012 1122.1 25.7145V20.2228H1108.43V83.7278H1122.1V59.5532C1122.25 59.7966 1122.4 60.0401 1122.55 60.2665C1124.01 62.3613 1125.76 63.8672 1127.79 64.7787C1129.82 65.6902 1131.87 66.1432 1133.94 66.1432C1137.34 66.1432 1140.42 65.2317 1143.18 63.4087C1145.95 61.5857 1148.15 58.9304 1149.79 55.4373C1151.43 51.9442 1152.25 47.7094 1152.25 42.7272C1152.25 38.232 1151.54 34.2067 1150.11 30.6569C1148.69 27.1015 1146.6 24.3104 1143.88 22.2723V22.2779ZM1137.45 48.5642C1136.75 50.2061 1135.75 51.4516 1134.44 52.3008C1133.14 53.15 1131.54 53.5747 1129.66 53.5747C1127.77 53.5747 1126.26 53.1331 1124.92 52.2555C1123.58 51.378 1122.57 50.1325 1121.87 48.5189C1121.17 46.9111 1120.82 44.9805 1120.82 42.7329C1120.82 40.4853 1121.17 38.4641 1121.87 36.8563C1122.57 35.2484 1123.58 34.0029 1124.92 33.1197C1126.26 32.2422 1127.84 31.8006 1129.66 31.8006C1131.48 31.8006 1133.14 32.2252 1134.44 33.0744C1135.75 33.9236 1136.75 35.1692 1137.45 36.811C1138.15 38.4528 1138.5 40.423 1138.5 42.7329C1138.5 45.0428 1138.15 46.9224 1137.45 48.5642Z"
              fill="#8889E8"
            />
            <path
              d="M1176.12 53.4785C1175.33 53.4785 1174.68 53.2973 1174.16 52.9293C1173.65 52.567 1173.39 51.9272 1173.39 51.0157V1.64185H1159.72V53.7559C1159.72 58.0699 1160.84 61.212 1163.09 63.1879C1165.34 65.1638 1168.1 66.1489 1171.38 66.1489C1172.41 66.1489 1173.58 66.0413 1174.89 65.8318C1176.2 65.6167 1177.54 65.2374 1178.94 64.6939V53.0312C1178.46 53.1557 1177.99 53.2577 1177.53 53.3482C1177.08 53.4388 1176.6 53.4841 1176.12 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M1199.72 20.2285H1185.96V65.1468H1199.72V20.2285Z"
              fill="#8889E8"
            />
            <path
              d="M1199.9 1.64185H1185.87V14.0348H1199.9V1.64185Z"
              fill="#8889E8"
            />
            <path
              d="M1244.96 23.9141C1241.34 20.789 1236.34 19.2207 1229.97 19.2207C1226.08 19.2207 1222.45 19.6906 1219.08 20.6304C1215.71 21.5759 1212.23 23.2291 1208.65 25.5956L1214.48 35.2541C1216.3 33.9802 1218.55 32.8989 1221.22 32.0214C1223.89 31.1438 1226.41 30.7022 1228.79 30.7022C1231.03 30.7022 1232.91 31.1552 1234.44 32.0667C1235.95 32.9781 1236.71 34.4954 1236.71 36.6241V37.3545H1224.32C1222.32 37.3545 1220.43 37.5357 1218.67 37.9036C1216.91 38.266 1215.33 38.8435 1213.93 39.6361C1212.05 40.6721 1210.55 42.1554 1209.42 44.103C1208.29 46.0449 1207.73 48.3265 1207.73 50.9364C1207.73 53.9766 1208.51 56.6488 1210.05 58.9531C1211.6 61.263 1213.7 63.0407 1216.34 64.2862C1218.98 65.5317 1221.91 66.1545 1225.13 66.1545C1226.83 66.1545 1228.58 65.8488 1230.37 65.243C1232.15 64.6372 1233.86 63.5106 1235.47 61.8744C1235.93 61.4102 1236.36 60.8723 1236.79 60.2892L1237.79 65.1524H1250.37V36.6355C1250.37 31.291 1248.56 27.0562 1244.94 23.9254L1244.96 23.9141ZM1235.43 51.2025C1234.58 52.4197 1233.44 53.3765 1232.02 54.0729C1230.59 54.7749 1229.02 55.1202 1227.33 55.1202C1225.63 55.1202 1224.15 54.7409 1223.09 53.9823C1222.03 53.2236 1221.49 52.2046 1221.49 50.9307C1221.49 50.3816 1221.61 49.8834 1221.86 49.4248C1222.1 48.9719 1222.43 48.5925 1222.86 48.2868C1223.28 47.9811 1223.78 47.7716 1224.36 47.6471C1224.94 47.5282 1225.59 47.4659 1226.32 47.4659H1236.7C1236.62 48.8586 1236.2 50.1042 1235.43 51.2025Z"
              fill="#8889E8"
            />
            <path
              d="M1286.18 19.2264C1284.3 19.2264 1282.37 19.5604 1280.4 20.2285C1278.42 20.8965 1276.64 22.0685 1275.07 23.7386C1274.46 24.384 1273.91 25.137 1273.43 25.9862V20.2341H1259.67V65.1524H1273.43V44.1992C1273.43 41.1647 1273.73 38.6849 1274.34 36.7714C1274.94 34.8578 1275.87 33.4481 1277.12 32.5366C1278.36 31.6251 1279.96 31.1721 1281.9 31.1721C1284.39 31.1721 1286.2 31.9025 1287.33 33.3575C1288.45 34.8181 1289.01 37.1563 1289.01 40.3721V65.1524H1302.77V35.2711C1302.77 30.3512 1301.32 26.4505 1298.4 23.5631C1295.48 20.6814 1291.41 19.2377 1286.19 19.2377L1286.18 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M1327.5 32.8479C1328.81 31.727 1330.52 31.1608 1332.65 31.1608C1334.96 31.1608 1336.73 31.7666 1337.97 32.9838C1339.22 34.201 1340.14 35.7466 1340.75 37.6319L1352.69 32.8932C1350.99 28.2791 1348.41 24.8483 1344.94 22.6007C1341.48 20.353 1337.38 19.2321 1332.64 19.2321C1328.81 19.2321 1325.49 19.9001 1322.67 21.2362C1319.84 22.5723 1317.49 24.3501 1315.61 26.5637C1313.72 28.783 1312.33 31.2854 1311.42 34.0822C1310.5 36.8789 1310.05 39.7323 1310.05 42.648C1310.05 45.5637 1310.5 48.417 1311.42 51.2138C1312.33 54.0106 1313.73 56.53 1315.61 58.7776C1317.49 61.0252 1319.84 62.8199 1322.67 64.1503C1325.49 65.4864 1328.81 66.1545 1332.64 66.1545C1337.38 66.1545 1341.48 65.0335 1344.94 62.7859C1348.41 60.5383 1350.98 57.1074 1352.69 52.4933L1340.75 47.7547C1340.15 49.6399 1339.22 51.1855 1337.97 52.4027C1336.73 53.62 1334.95 54.2257 1332.65 54.2257C1330.52 54.2257 1328.8 53.6652 1327.5 52.5386C1326.19 51.4176 1325.25 49.957 1324.68 48.1679C1324.1 46.3789 1323.81 44.5389 1323.81 42.6536C1323.81 40.7684 1324.1 38.951 1324.68 37.1847C1325.25 35.4239 1326.19 33.9802 1327.5 32.8593V32.8479Z"
              fill="#8889E8"
            />
            <path
              d="M1400.06 33.5273C1399.15 30.6117 1397.8 28.0923 1396.01 25.9636C1394.22 23.8405 1391.98 22.1817 1389.31 20.9984C1386.64 19.8152 1383.54 19.2207 1380.02 19.2207C1376.01 19.2207 1372.59 19.9171 1369.77 21.3155C1366.95 22.7139 1364.67 24.5652 1362.94 26.8751C1361.21 29.185 1359.93 31.7326 1359.11 34.5294C1358.29 37.3262 1357.89 40.1173 1357.89 42.9141C1357.89 45.7108 1358.29 48.6718 1359.11 51.4346C1359.93 54.1974 1361.25 56.6885 1363.08 58.9078C1364.9 61.1271 1367.27 62.8878 1370.18 64.1899C1373.1 65.4978 1376.62 66.1488 1380.75 66.1488C1383.42 66.1488 1385.78 65.9507 1387.81 65.5544C1389.84 65.1581 1391.84 64.49 1393.78 63.5502C1395.72 62.6104 1397.88 61.3479 1400.25 59.7683L1394.42 50.1098C1392.72 51.4459 1390.72 52.4933 1388.41 53.2519C1386.1 54.0106 1383.85 54.3899 1381.66 54.3899C1379.17 54.3899 1376.97 53.8407 1375.06 52.7481C1373.14 51.6554 1372.01 49.8947 1371.64 47.4659H1401.16C1401.28 46.4978 1401.36 45.6769 1401.39 45.0088C1401.42 44.3408 1401.43 43.701 1401.43 43.0952C1401.43 39.6304 1400.98 36.443 1400.07 33.5273H1400.06ZM1371.46 37.3545C1371.64 36.3864 1372.03 35.3786 1372.64 34.3482C1373.24 33.3178 1374.17 32.4347 1375.42 31.7043C1376.66 30.974 1378.29 30.6116 1380.29 30.6116C1381.39 30.6116 1382.42 30.7815 1383.39 31.1099C1384.36 31.4439 1385.22 31.9138 1385.99 32.5196C1386.75 33.1254 1387.34 33.8727 1387.77 34.7502C1388.13 35.5145 1388.33 36.3807 1388.38 37.3488H1371.46L1371.46 37.3545Z"
              fill="#8889E8"
            />
            <path
              d="M1431.39 50.4777C1436.15 50.4777 1440.01 46.6224 1440.01 41.8666C1440.01 37.1108 1436.15 33.2555 1431.39 33.2555C1426.64 33.2555 1422.78 37.1108 1422.78 41.8666C1422.78 46.6224 1426.64 50.4777 1431.39 50.4777Z"
              fill="#8889E8"
            />
          </svg>
          <svg
            width="1440"
            height="84"
            viewBox="0 0 1440 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.848633 64.3257H43.4853V51.5704H15.1552V37.9941H42.1209V25.2388H15.1552V13.5761H43.4853V0.820801H0.848633V64.3257Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M80.9303 19.4074L73.0043 32.7969L65.0782 19.4074H49.0448L63.8043 41.6345L49.0448 64.3257H64.9876L73.0043 50.5683L80.9303 64.3257H96.9693L82.023 41.6345L96.9693 19.4074H80.9303Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M138.695 21.457C135.96 19.4245 132.648 18.4054 128.764 18.4054C126.941 18.4054 125.045 18.7678 123.069 19.4981C121.093 20.2284 119.304 21.6381 117.696 23.7329C117.424 24.0839 117.17 24.4802 116.92 24.8935V19.4018H103.254V82.9068H116.92V58.7322C117.068 58.9757 117.215 59.2191 117.373 59.4456C118.828 61.5403 120.578 63.0463 122.61 63.9578C124.643 64.8693 126.692 65.3222 128.759 65.3222C132.161 65.3222 135.241 64.4107 138.004 62.5877C140.767 60.7647 142.969 58.1094 144.611 54.6163C146.253 51.1232 147.074 46.8884 147.074 41.9063C147.074 37.411 146.36 33.3857 144.933 29.836C143.507 26.2806 141.423 23.4894 138.695 21.4513V21.457ZM132.269 47.7433C131.572 49.3851 130.57 50.6306 129.262 51.4798C127.955 52.3291 126.364 52.7537 124.479 52.7537C122.593 52.7537 121.076 52.3121 119.74 51.4346C118.404 50.557 117.385 49.3115 116.688 47.698C115.992 46.0901 115.641 44.1595 115.641 41.9119C115.641 39.6643 115.992 37.6432 116.688 36.0353C117.385 34.4274 118.404 33.1819 119.74 32.2987C121.076 31.4212 122.656 30.9796 124.479 30.9796C126.302 30.9796 127.955 31.4042 129.262 32.2534C130.57 33.1026 131.572 34.3482 132.269 35.99C132.965 37.6318 133.316 39.602 133.316 41.9119C133.316 44.2218 132.965 46.1014 132.269 47.7433Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M190.854 25.1483C189.059 23.0252 186.828 21.3664 184.156 20.1831C181.484 18.9999 178.387 18.4054 174.866 18.4054C170.857 18.4054 167.438 19.1018 164.618 20.5002C161.793 21.8986 159.517 23.7499 157.785 26.0598C156.052 28.3696 154.779 30.9173 153.958 33.7141C153.137 36.5109 152.729 39.302 152.729 42.0988C152.729 44.8955 153.137 47.8565 153.958 50.6193C154.779 53.3821 156.098 55.8732 157.921 58.0925C159.744 60.3118 162.11 62.0725 165.026 63.3746C167.942 64.6824 171.463 65.3335 175.596 65.3335C178.268 65.3335 180.623 65.1354 182.656 64.7391C184.688 64.3428 186.681 63.6747 188.623 62.7349C190.565 61.7951 192.722 60.5326 195.094 58.953L189.263 49.2945C187.564 50.6306 185.56 51.678 183.25 52.4366C180.94 53.1953 178.693 53.5746 176.507 53.5746C174.016 53.5746 171.814 53.0254 169.9 51.9328C167.987 50.8401 166.849 49.0794 166.487 46.6506H196.006C196.124 45.6825 196.204 44.8616 196.232 44.1935C196.26 43.5255 196.277 42.8857 196.277 42.2799C196.277 38.8151 195.824 35.6277 194.913 32.712C194.001 29.7963 192.648 27.277 190.859 25.1483H190.854ZM166.3 36.5392C166.481 35.5711 166.877 34.5633 167.483 33.5329C168.089 32.5025 169.017 31.6193 170.263 30.889C171.508 30.1587 173.133 29.7963 175.137 29.7963C176.23 29.7963 177.26 29.9662 178.234 30.2946C179.202 30.6286 180.069 31.0985 180.833 31.7043C181.591 32.31 182.186 33.0574 182.611 33.9349C182.979 34.6992 183.177 35.5654 183.228 36.5335H166.305L166.3 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M229.347 18.4054C227.218 18.4054 225.168 18.9829 223.198 20.1378C221.222 21.2928 219.615 22.8723 218.369 24.8765C218.256 25.0577 218.154 25.2445 218.052 25.4313V19.4075H204.204V64.3258H217.961V46.1977C217.961 43.6443 218.035 41.7194 218.188 40.4116C218.341 39.1038 218.567 38.0281 218.873 37.1789C219.297 36.024 219.858 35.0672 220.56 34.3085C221.256 33.5499 222.077 32.9894 223.017 32.6214C223.957 32.2591 224.942 32.0723 225.978 32.0723C227.071 32.0723 228.163 32.2421 229.256 32.5761C230.349 32.9102 231.198 33.2895 231.809 33.7141L238.371 21.3211C236.791 20.2907 235.286 19.5434 233.859 19.0905C232.432 18.6375 230.926 18.4054 229.347 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M258.044 0.820801H244.015V13.2138H258.044V0.820801Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M257.863 19.4074H244.106V64.3257H257.863V19.4074Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M303.733 25.1483C301.938 23.0252 299.708 21.3664 297.035 20.1831C294.363 18.9999 291.266 18.4054 287.745 18.4054C283.737 18.4054 280.317 19.1018 277.498 20.5002C274.673 21.8986 272.397 23.7499 270.664 26.0598C268.932 28.3696 267.658 30.9173 266.837 33.7141C266.016 36.5109 265.608 39.302 265.608 42.0988C265.608 44.8955 266.016 47.8565 266.837 50.6193C267.658 53.3821 268.977 55.8732 270.8 58.0925C272.623 60.3118 274.99 62.0725 277.905 63.3746C280.821 64.6824 284.342 65.3335 288.475 65.3335C291.147 65.3335 293.503 65.1354 295.535 64.7391C297.568 64.3428 299.56 63.6747 301.502 62.7349C303.444 61.7951 305.601 60.5326 307.973 58.953L302.142 49.2945C300.444 50.6306 298.439 51.678 296.13 52.4366C293.82 53.1953 291.572 53.5746 289.387 53.5746C286.896 53.5746 284.693 53.0254 282.78 51.9328C280.866 50.8401 279.728 49.0794 279.366 46.6506H308.885C309.004 45.6825 309.083 44.8616 309.111 44.1935C309.14 43.5255 309.157 42.8857 309.157 42.2799C309.157 38.8151 308.704 35.6277 307.792 32.712C306.881 29.7963 305.528 27.277 303.739 25.1483H303.733ZM279.179 36.5392C279.36 35.5711 279.757 34.5633 280.362 33.5329C280.968 32.5025 281.897 31.6193 283.142 30.889C284.388 30.1587 286.012 29.7963 288.017 29.7963C289.109 29.7963 290.14 29.9662 291.113 30.2946C292.082 30.6286 292.948 31.0985 293.712 31.7043C294.471 32.31 295.065 33.0574 295.49 33.9349C295.858 34.6992 296.056 35.5654 296.107 36.5335H279.185L279.179 36.5392Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M343.595 18.4054C341.71 18.4054 339.785 18.7395 337.809 19.4075C335.834 20.0756 334.056 21.2475 332.482 22.9176C331.87 23.563 331.327 24.316 330.84 25.1652V19.4132H317.083V64.3314H330.84V43.3783C330.84 40.3437 331.146 37.864 331.752 35.9504C332.357 34.0368 333.286 32.6271 334.531 31.7156C335.777 30.8041 337.368 30.3512 339.315 30.3512C341.806 30.3512 343.612 31.0815 344.739 32.5365C345.86 33.9972 346.426 36.3354 346.426 39.5511V64.3314H360.184V34.4501C360.184 29.5302 358.729 25.6295 355.813 22.7421C352.897 19.8604 348.827 18.4167 343.607 18.4167L343.595 18.4054Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M384.907 32.0269C386.215 30.9059 387.93 30.3398 390.053 30.3398C392.363 30.3398 394.135 30.9456 395.381 32.1628C396.626 33.38 397.549 34.9256 398.161 36.8109L410.095 32.0722C408.397 27.4581 405.815 24.0272 402.35 21.7796C398.885 19.532 394.786 18.411 390.048 18.411C386.221 18.411 382.897 19.0791 380.072 20.4152C377.247 21.7513 374.892 23.529 373.012 25.7427C371.127 27.962 369.734 30.4643 368.823 33.2611C367.911 36.0579 367.458 38.9113 367.458 41.8269C367.458 44.7426 367.911 47.596 368.823 50.3928C369.734 53.1896 371.133 55.7089 373.012 57.9565C374.892 60.2041 377.247 61.9988 380.072 63.3293C382.897 64.6654 386.221 65.3335 390.048 65.3335C394.786 65.3335 398.885 64.2125 402.35 61.9649C405.815 59.7173 408.391 56.2864 410.095 51.6723L398.161 46.9336C397.555 48.8189 396.626 50.3645 395.381 51.5817C394.135 52.7989 392.358 53.4047 390.053 53.4047C387.925 53.4047 386.209 52.8442 384.907 51.7176C383.599 50.5966 382.659 49.1359 382.082 47.3469C381.504 45.5579 381.216 43.7179 381.216 41.8326C381.216 39.9473 381.504 38.13 382.082 36.3636C382.659 34.6029 383.599 33.1592 384.907 32.0382V32.0269Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M457.476 32.7063C456.565 29.7907 455.211 27.2713 453.422 25.1426C451.628 23.0195 449.397 21.3607 446.725 20.1775C444.053 18.9942 440.956 18.3998 437.434 18.3998C433.426 18.3998 430.006 19.0961 427.187 20.4945C424.362 21.8929 422.086 23.7442 420.354 26.0541C418.621 28.364 417.347 30.9116 416.526 33.7084C415.706 36.5052 415.298 39.2963 415.298 42.0931C415.298 44.8899 415.706 47.8508 416.526 50.6136C417.347 53.3764 418.667 55.8675 420.49 58.0868C422.313 60.3061 424.679 62.0668 427.595 63.369C430.51 64.6768 434.032 65.3278 438.165 65.3278C440.837 65.3278 443.192 65.1297 445.225 64.7334C447.257 64.3371 449.25 63.669 451.192 62.7292C453.134 61.7894 455.291 60.5269 457.663 58.9473L451.832 49.2888C450.133 50.625 448.129 51.6723 445.819 52.431C443.509 53.1896 441.262 53.5689 439.076 53.5689C436.585 53.5689 434.383 53.0198 432.469 51.9271C430.556 50.8344 429.418 49.0737 429.055 46.6449H458.574C458.693 45.6768 458.773 44.8559 458.801 44.1878C458.829 43.5198 458.846 42.88 458.846 42.2743C458.846 38.8094 458.393 35.622 457.482 32.7063H457.476ZM428.869 36.5335C429.05 35.5654 429.446 34.5576 430.052 33.5273C430.658 32.4969 431.586 31.6137 432.832 30.8833C434.077 30.153 435.702 29.7907 437.706 29.7907C438.799 29.7907 439.829 29.9605 440.803 30.2889C441.771 30.6229 442.637 31.0928 443.402 31.6986C444.16 32.3044 444.755 33.0517 445.179 33.9292C445.547 34.6935 445.745 35.5597 445.796 36.5278H428.874L428.869 36.5335Z"
              stroke="#8889E8"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M490.913 65.1411H533.55V52.3858H505.214V38.8152H532.185V26.0599H505.214V14.3972H533.55V1.64185H490.913V65.1411Z"
              fill="#8889E8"
            />
            <path
              d="M587.668 13.2592C588.03 12.4383 588.789 12.0307 589.944 12.0307C590.549 12.0307 591.144 12.1382 591.721 12.3477C592.299 12.5628 592.859 12.8176 593.408 13.1233L597.779 2.64391C596.443 2.03813 595.045 1.55125 593.59 1.18325C592.129 0.820913 590.402 0.634084 588.398 0.634084C586.513 0.634084 584.69 0.849221 582.929 1.27383C581.168 1.69844 579.589 2.45708 578.19 3.54975C576.367 2.51936 574.425 1.77204 572.359 1.31912C570.293 0.866205 568.226 0.634084 566.165 0.634084C562.706 0.634084 559.44 1.28516 556.371 2.59296C553.302 3.90076 550.828 5.81435 548.943 8.33371C547.058 10.8531 546.118 13.9386 546.118 17.5789V20.2228H538.645V30.8834H546.118V65.1411H559.785V30.8834H573.361V65.1411H587.119V30.8834H596.047V20.2228H587.119V16.3051C587.119 15.0935 587.3 14.0745 587.668 13.2535V13.2592ZM573.457 14.0348V20.2285H559.791V17.862C559.791 16.039 560.306 14.55 561.342 13.4007C562.372 12.2458 564.195 11.6683 566.811 11.6683C568.209 11.6683 569.421 11.8212 570.457 12.1212C571.487 12.427 572.489 12.8176 573.463 13.3045V14.0348H573.457Z"
              fill="#8889E8"
            />
            <path
              d="M634.953 22.2327C631.488 20.2285 627.542 19.2264 623.109 19.2264C618.676 19.2264 614.713 20.2285 611.22 22.2327C607.727 24.2368 604.981 26.9996 602.977 30.5211C600.972 34.0425 599.97 38.0848 599.97 42.6367C599.97 47.1885 600.972 51.1572 602.977 54.707C604.981 58.2624 607.727 61.0535 611.22 63.086C614.713 65.1241 618.676 66.1375 623.109 66.1375C627.542 66.1375 631.493 65.1184 634.953 63.086C638.417 61.0535 641.146 58.2567 643.15 54.707C645.155 51.1515 646.157 47.1319 646.157 42.6367C646.157 38.1414 645.155 34.0425 643.15 30.5211C641.146 26.9996 638.412 24.2368 634.953 22.2327ZM631.352 48.8813C630.656 50.6137 629.608 51.9215 628.21 52.799C626.811 53.6822 625.113 54.1182 623.109 54.1182C621.105 54.1182 619.401 53.6766 618.008 52.799C616.609 51.9215 615.545 50.6137 614.82 48.8813C614.09 47.1489 613.728 45.0711 613.728 42.6423C613.728 40.2135 614.09 38.0735 614.82 36.4034C615.551 34.7332 616.609 33.4594 618.008 32.5762C619.406 31.6987 621.105 31.2571 623.109 31.2571C625.113 31.2571 626.811 31.6987 628.21 32.5762C629.608 33.4594 630.656 34.7332 631.352 36.4034C632.048 38.0735 632.399 40.1513 632.399 42.6423C632.399 45.1334 632.048 47.1545 631.352 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M679.05 19.2264C676.922 19.2264 674.872 19.8039 672.902 20.9588C670.926 22.1138 669.318 23.6933 668.073 25.6975C667.959 25.8786 667.857 26.0655 667.756 26.2523V20.2285H653.908V65.1467H667.665V47.0187C667.665 44.4653 667.739 42.5404 667.891 41.2326C668.044 39.9248 668.271 38.8491 668.577 37.9999C669.001 36.845 669.562 35.8882 670.264 35.1295C670.96 34.3709 671.781 33.8104 672.721 33.4424C673.661 33.0801 674.646 32.8932 675.682 32.8932C676.774 32.8932 677.867 33.0631 678.96 33.3971C680.052 33.7311 680.902 34.1105 681.513 34.5351L688.075 22.1421C686.495 21.1117 684.989 20.3644 683.562 19.9114C682.136 19.4585 680.63 19.2264 679.05 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M711.207 3.46483L697.359 10.8474V20.2342H689.886V30.8947H697.359V50.8458C697.359 54.673 698.073 57.6962 699.499 59.9099C700.926 62.1292 702.794 63.7201 705.104 64.6938C707.414 65.662 709.843 66.1545 712.391 66.1545C713.846 66.1545 715.244 66.03 716.58 65.7922C717.916 65.5487 719.252 65.1864 720.589 64.6995V54.2201C719.739 54.4635 718.947 54.6447 718.222 54.7693C717.492 54.8938 716.824 54.9504 716.218 54.9504C714.214 54.9504 712.878 54.2824 712.209 52.9463C711.541 51.6101 711.207 49.606 711.207 46.9338V30.9004H720.594V20.2398H711.207V3.46483Z"
              fill="#8889E8"
            />
            <path
              d="M744.735 53.4785C743.942 53.4785 743.291 53.2973 742.776 52.9293C742.261 52.567 742 51.9272 742 51.0157V1.64185H728.334V53.7559C728.334 58.0699 729.455 61.212 731.702 63.1879C733.95 65.1638 736.713 66.1489 739.991 66.1489C741.021 66.1489 742.193 66.0413 743.501 65.8318C744.808 65.6167 746.156 65.2374 747.554 64.6939V53.0312C747.067 53.1557 746.598 53.2577 746.145 53.3482C745.692 53.4388 745.216 53.4841 744.735 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M790.7 25.9692C788.905 23.8462 786.675 22.1874 784.003 21.0041C781.33 19.8209 778.234 19.2264 774.712 19.2264C770.704 19.2264 767.284 19.9228 764.465 21.3211C761.64 22.7195 759.364 24.5708 757.631 26.8807C755.899 29.1906 754.625 31.7383 753.804 34.5351C752.983 37.3318 752.576 40.123 752.576 42.9197C752.576 45.7165 752.983 48.6775 753.804 51.4403C754.625 54.2031 755.944 56.6941 757.767 58.9134C759.59 61.1328 761.957 62.8935 764.872 64.1956C767.788 65.5034 771.31 66.1545 775.442 66.1545C778.115 66.1545 780.47 65.9563 782.502 65.56C784.535 65.1637 786.528 64.4957 788.469 63.5559C790.411 62.6161 792.568 61.3535 794.941 59.774L789.109 50.1155C787.411 51.4516 785.407 52.499 783.097 53.2576C780.787 54.0163 778.539 54.3956 776.354 54.3956C773.863 54.3956 771.66 53.8464 769.747 52.7537C767.833 51.6611 766.695 49.9004 766.333 47.4716H795.852C795.971 46.5035 796.05 45.6825 796.079 45.0145C796.107 44.3464 796.124 43.7067 796.124 43.1009C796.124 39.6361 795.671 36.4487 794.759 33.533C793.848 30.6173 792.495 28.098 790.706 25.9692H790.7ZM766.146 37.3602C766.327 36.392 766.724 35.3843 767.329 34.3539C767.935 33.3235 768.864 32.4403 770.109 31.71C771.355 30.9797 772.98 30.6173 774.984 30.6173C776.076 30.6173 777.107 30.7872 778.081 31.1155C779.049 31.4496 779.915 31.9195 780.679 32.5252C781.438 33.131 782.032 33.8783 782.457 34.7559C782.825 35.5202 783.023 36.3864 783.074 37.3545H766.152L766.146 37.3602Z"
              fill="#8889E8"
            />
            <path
              d="M833.428 39.4945C831.695 38.4302 829.861 37.5979 827.913 36.9921C825.966 36.3864 824.115 35.8712 822.354 35.4409C820.531 34.954 819.104 34.5464 818.074 34.2124C817.038 33.8783 816.324 33.5273 815.934 33.165C815.537 32.8026 815.339 32.378 815.339 31.8911C815.339 31.1608 815.764 30.555 816.613 30.0681C817.462 29.5813 818.555 29.3378 819.891 29.3378C821.652 29.3378 823.356 29.7624 824.992 30.6116C826.634 31.4609 827.998 32.6158 829.091 34.0708L837.476 27.328C835.715 24.718 833.332 22.7139 830.325 21.3155C827.319 19.9171 823.962 19.2207 820.259 19.2207C814.915 19.2207 810.618 20.4946 807.368 23.0479C804.118 25.6012 802.493 28.9698 802.493 33.1593C802.493 35.6504 802.997 37.7168 803.999 39.353C805.001 40.9948 806.309 42.3479 807.917 43.4066C809.525 44.471 811.257 45.3202 813.109 45.9599C814.96 46.5997 816.738 47.1602 818.436 47.6471C819.835 48.0094 821.08 48.3887 822.173 48.785C823.265 49.1813 824.143 49.6229 824.817 50.1042C825.485 50.591 825.819 51.1968 825.819 51.9272C825.819 52.7198 825.332 53.4614 824.358 54.1578C823.384 54.8541 821.992 55.2052 820.169 55.2052C817.559 55.2052 815.413 54.5088 813.743 53.1104C812.073 51.712 810.72 50.3759 809.689 49.1021L800.399 57.2999C801.491 58.3926 802.89 59.6381 804.588 61.0365C806.287 62.4349 808.415 63.6351 810.963 64.6372C813.516 65.6393 816.551 66.1432 820.072 66.1432C823.9 66.1432 827.257 65.4751 830.138 64.139C833.02 62.8029 835.268 60.9516 836.881 58.5794C838.489 56.2129 839.293 53.4784 839.293 50.3816C839.293 47.7094 838.744 45.4957 837.651 43.7293C836.559 41.9686 835.143 40.5589 833.416 39.4945H833.428Z"
              fill="#8889E8"
            />
            <path
              d="M876.523 39.4945C874.791 38.4302 872.956 37.5979 871.009 36.9921C869.061 36.3864 867.21 35.8712 865.449 35.4409C863.626 34.954 862.2 34.5464 861.169 34.2124C860.133 33.8783 859.42 33.5273 859.029 33.165C858.633 32.8026 858.435 32.378 858.435 31.8911C858.435 31.1608 858.859 30.555 859.708 30.0681C860.558 29.5813 861.65 29.3378 862.987 29.3378C864.747 29.3378 866.451 29.7624 868.087 30.6116C869.729 31.4609 871.094 32.6158 872.186 34.0708L880.571 27.328C878.81 24.718 876.427 22.7139 873.421 21.3155C870.414 19.9171 867.057 19.2207 863.354 19.2207C858.01 19.2207 853.713 20.4946 850.463 23.0479C847.214 25.6012 845.589 28.9698 845.589 33.1593C845.589 35.6504 846.093 37.7168 847.095 39.353C848.097 40.9948 849.405 42.3479 851.012 43.4066C852.62 44.471 854.353 45.3202 856.204 45.9599C858.055 46.5997 859.833 47.1602 861.531 47.6471C862.93 48.0094 864.175 48.3887 865.268 48.785C866.361 49.1813 867.238 49.6229 867.912 50.1042C868.58 50.591 868.914 51.1968 868.914 51.9272C868.914 52.7198 868.427 53.4614 867.453 54.1578C866.48 54.8541 865.087 55.2052 863.264 55.2052C860.654 55.2052 858.508 54.5088 856.838 53.1104C855.168 51.712 853.815 50.3759 852.785 49.1021L843.494 57.2999C844.587 58.3926 845.985 59.6381 847.684 61.0365C849.382 62.4349 851.511 63.6351 854.058 64.6372C856.612 65.6393 859.646 66.1432 863.168 66.1432C866.995 66.1432 870.352 65.4751 873.234 64.139C876.116 62.8029 878.363 60.9516 879.977 58.5794C881.584 56.2129 882.388 53.4784 882.388 50.3816C882.388 47.7094 881.839 45.4957 880.747 43.7293C879.654 41.9686 878.239 40.5589 876.512 39.4945H876.523Z"
              fill="#8889E8"
            />
            <path
              d="M934.1 16.0333C936.62 14.4538 939.58 13.6668 942.983 13.6668C946.748 13.6668 949.952 14.6236 952.596 16.5372C955.24 18.4508 957.165 21.106 958.382 24.5086L971.868 19.6793C969.683 13.5479 966.065 8.73002 961.026 5.23688C955.982 1.74374 949.969 0 942.989 0C936.852 0 931.451 1.39839 926.768 4.1895C922.092 6.98628 918.446 10.8701 915.836 15.8522C913.226 20.8343 911.918 26.6656 911.918 33.3462C911.918 40.0267 913.226 45.8751 915.836 50.8855C918.446 55.8959 922.092 59.8023 926.768 62.5934C931.445 65.3845 936.852 66.7829 942.989 66.7829C949.975 66.7829 955.987 65.0222 961.026 61.5008C966.065 57.9793 969.683 53.1784 971.868 47.1036L958.382 42.2743C957.165 45.6769 955.24 48.3321 952.596 50.2457C949.952 52.1593 946.748 53.1161 942.983 53.1161C939.58 53.1161 936.62 52.3292 934.1 50.7496C931.581 49.17 929.633 46.9111 928.269 43.9615C926.904 41.0175 926.219 37.4791 926.219 33.3462C926.219 29.2133 926.904 25.6918 928.269 22.7762C929.633 19.8605 931.581 17.6129 934.1 16.0333Z"
              fill="#8889E8"
            />
            <path
              d="M1011.77 22.2327C1008.31 20.2285 1004.36 19.2264 999.926 19.2264C995.493 19.2264 991.53 20.2285 988.037 22.2327C984.544 24.2368 981.798 26.9996 979.794 30.5211C977.79 34.0425 976.788 38.0848 976.788 42.6367C976.788 47.1885 977.79 51.1572 979.794 54.707C981.798 58.2624 984.544 61.0535 988.037 63.086C991.53 65.1241 995.493 66.1375 999.926 66.1375C1004.36 66.1375 1008.31 65.1184 1011.77 63.086C1015.24 61.0535 1017.96 58.2567 1019.97 54.707C1021.97 51.1515 1022.97 47.1319 1022.97 42.6367C1022.97 38.1414 1021.97 34.0425 1019.97 30.5211C1017.96 26.9996 1015.23 24.2368 1011.77 22.2327ZM1008.17 48.8813C1007.47 50.6137 1006.43 51.9215 1005.03 52.799C1003.63 53.6822 1001.93 54.1182 999.926 54.1182C997.922 54.1182 996.218 53.6766 994.825 52.799C993.427 51.9215 992.363 50.6137 991.638 48.8813C990.908 47.1489 990.545 45.0711 990.545 42.6423C990.545 40.2135 990.908 38.0735 991.638 36.4034C992.368 34.7332 993.427 33.4594 994.825 32.5762C996.224 31.6987 997.922 31.2571 999.926 31.2571C1001.93 31.2571 1003.63 31.6987 1005.03 32.5762C1006.43 33.4594 1007.47 34.7332 1008.17 36.4034C1008.87 38.0735 1009.22 40.1513 1009.22 42.6423C1009.22 45.1334 1008.87 47.1545 1008.17 48.8813Z"
              fill="#8889E8"
            />
            <path
              d="M1091.81 21.7741C1089.35 20.0756 1086.15 19.2207 1082.19 19.2207C1079.95 19.2207 1077.62 19.7359 1075.22 20.772C1073.14 21.6722 1071.2 23.1951 1069.44 25.3351C1069.3 25.1483 1069.17 24.9558 1069.02 24.7803C1067.29 22.6573 1065.33 21.1966 1063.15 20.4096C1060.96 19.6227 1058.83 19.2264 1056.77 19.2264C1055.31 19.2264 1053.78 19.4415 1052.17 19.8661C1050.56 20.2908 1049.01 21.0664 1047.52 22.1874C1046.39 23.0422 1045.37 24.1802 1044.47 25.5956V20.2285H1030.71V65.1467H1044.47V43.3727C1044.47 40.5815 1044.74 38.2886 1045.29 36.4939C1045.84 34.7049 1046.67 33.3688 1047.8 32.4856C1048.92 31.6081 1050.34 31.1665 1052.03 31.1665C1053.91 31.1665 1055.39 31.8628 1056.45 33.2612C1057.52 34.6596 1058.05 36.7544 1058.05 39.5455V65.1467H1071.71V43.3727C1071.71 40.5815 1071.97 38.2886 1072.49 36.4939C1073 34.7049 1073.8 33.3688 1074.86 32.4856C1075.92 31.6081 1077.27 31.1665 1078.91 31.1665C1080.25 31.1665 1081.38 31.4552 1082.32 32.0327C1083.26 32.6102 1083.99 33.5217 1084.51 34.7672C1085.02 36.0127 1085.28 37.6093 1085.28 39.5511V65.1524H1099.04V36.1826C1099.04 33.4481 1098.45 30.7928 1097.26 28.2112C1096.08 25.6295 1094.26 23.4895 1091.8 21.7854L1091.81 21.7741Z"
              fill="#8889E8"
            />
            <path
              d="M1143.88 22.2779C1141.14 20.2455 1137.83 19.2264 1133.95 19.2264C1132.12 19.2264 1130.23 19.5887 1128.25 20.3191C1126.27 21.0494 1124.48 22.4591 1122.88 24.5539C1122.61 24.9049 1122.35 25.3012 1122.1 25.7145V20.2228H1108.43V83.7278H1122.1V59.5532C1122.25 59.7966 1122.4 60.0401 1122.55 60.2665C1124.01 62.3613 1125.76 63.8672 1127.79 64.7787C1129.82 65.6902 1131.87 66.1432 1133.94 66.1432C1137.34 66.1432 1140.42 65.2317 1143.18 63.4087C1145.95 61.5857 1148.15 58.9304 1149.79 55.4373C1151.43 51.9442 1152.25 47.7094 1152.25 42.7272C1152.25 38.232 1151.54 34.2067 1150.11 30.6569C1148.69 27.1015 1146.6 24.3104 1143.88 22.2723V22.2779ZM1137.45 48.5642C1136.75 50.2061 1135.75 51.4516 1134.44 52.3008C1133.14 53.15 1131.54 53.5747 1129.66 53.5747C1127.77 53.5747 1126.26 53.1331 1124.92 52.2555C1123.58 51.378 1122.57 50.1325 1121.87 48.5189C1121.17 46.9111 1120.82 44.9805 1120.82 42.7329C1120.82 40.4853 1121.17 38.4641 1121.87 36.8563C1122.57 35.2484 1123.58 34.0029 1124.92 33.1197C1126.26 32.2422 1127.84 31.8006 1129.66 31.8006C1131.48 31.8006 1133.14 32.2252 1134.44 33.0744C1135.75 33.9236 1136.75 35.1692 1137.45 36.811C1138.15 38.4528 1138.5 40.423 1138.5 42.7329C1138.5 45.0428 1138.15 46.9224 1137.45 48.5642Z"
              fill="#8889E8"
            />
            <path
              d="M1176.12 53.4785C1175.33 53.4785 1174.68 53.2973 1174.16 52.9293C1173.65 52.567 1173.39 51.9272 1173.39 51.0157V1.64185H1159.72V53.7559C1159.72 58.0699 1160.84 61.212 1163.09 63.1879C1165.34 65.1638 1168.1 66.1489 1171.38 66.1489C1172.41 66.1489 1173.58 66.0413 1174.89 65.8318C1176.2 65.6167 1177.54 65.2374 1178.94 64.6939V53.0312C1178.46 53.1557 1177.99 53.2577 1177.53 53.3482C1177.08 53.4388 1176.6 53.4841 1176.12 53.4841V53.4785Z"
              fill="#8889E8"
            />
            <path
              d="M1199.72 20.2285H1185.96V65.1468H1199.72V20.2285Z"
              fill="#8889E8"
            />
            <path
              d="M1199.9 1.64185H1185.87V14.0348H1199.9V1.64185Z"
              fill="#8889E8"
            />
            <path
              d="M1244.96 23.9141C1241.34 20.789 1236.34 19.2207 1229.97 19.2207C1226.08 19.2207 1222.45 19.6906 1219.08 20.6304C1215.71 21.5759 1212.23 23.2291 1208.65 25.5956L1214.48 35.2541C1216.3 33.9802 1218.55 32.8989 1221.22 32.0214C1223.89 31.1438 1226.41 30.7022 1228.79 30.7022C1231.03 30.7022 1232.91 31.1552 1234.44 32.0667C1235.95 32.9781 1236.71 34.4954 1236.71 36.6241V37.3545H1224.32C1222.32 37.3545 1220.43 37.5357 1218.67 37.9036C1216.91 38.266 1215.33 38.8435 1213.93 39.6361C1212.05 40.6721 1210.55 42.1554 1209.42 44.103C1208.29 46.0449 1207.73 48.3265 1207.73 50.9364C1207.73 53.9766 1208.51 56.6488 1210.05 58.9531C1211.6 61.263 1213.7 63.0407 1216.34 64.2862C1218.98 65.5317 1221.91 66.1545 1225.13 66.1545C1226.83 66.1545 1228.58 65.8488 1230.37 65.243C1232.15 64.6372 1233.86 63.5106 1235.47 61.8744C1235.93 61.4102 1236.36 60.8723 1236.79 60.2892L1237.79 65.1524H1250.37V36.6355C1250.37 31.291 1248.56 27.0562 1244.94 23.9254L1244.96 23.9141ZM1235.43 51.2025C1234.58 52.4197 1233.44 53.3765 1232.02 54.0729C1230.59 54.7749 1229.02 55.1202 1227.33 55.1202C1225.63 55.1202 1224.15 54.7409 1223.09 53.9823C1222.03 53.2236 1221.49 52.2046 1221.49 50.9307C1221.49 50.3816 1221.61 49.8834 1221.86 49.4248C1222.1 48.9719 1222.43 48.5925 1222.86 48.2868C1223.28 47.9811 1223.78 47.7716 1224.36 47.6471C1224.94 47.5282 1225.59 47.4659 1226.32 47.4659H1236.7C1236.62 48.8586 1236.2 50.1042 1235.43 51.2025Z"
              fill="#8889E8"
            />
            <path
              d="M1286.18 19.2264C1284.3 19.2264 1282.37 19.5604 1280.4 20.2285C1278.42 20.8965 1276.64 22.0685 1275.07 23.7386C1274.46 24.384 1273.91 25.137 1273.43 25.9862V20.2341H1259.67V65.1524H1273.43V44.1992C1273.43 41.1647 1273.73 38.6849 1274.34 36.7714C1274.94 34.8578 1275.87 33.4481 1277.12 32.5366C1278.36 31.6251 1279.96 31.1721 1281.9 31.1721C1284.39 31.1721 1286.2 31.9025 1287.33 33.3575C1288.45 34.8181 1289.01 37.1563 1289.01 40.3721V65.1524H1302.77V35.2711C1302.77 30.3512 1301.32 26.4505 1298.4 23.5631C1295.48 20.6814 1291.41 19.2377 1286.19 19.2377L1286.18 19.2264Z"
              fill="#8889E8"
            />
            <path
              d="M1327.5 32.8479C1328.81 31.727 1330.52 31.1608 1332.65 31.1608C1334.96 31.1608 1336.73 31.7666 1337.97 32.9838C1339.22 34.201 1340.14 35.7466 1340.75 37.6319L1352.69 32.8932C1350.99 28.2791 1348.41 24.8483 1344.94 22.6007C1341.48 20.353 1337.38 19.2321 1332.64 19.2321C1328.81 19.2321 1325.49 19.9001 1322.67 21.2362C1319.84 22.5723 1317.49 24.3501 1315.61 26.5637C1313.72 28.783 1312.33 31.2854 1311.42 34.0822C1310.5 36.8789 1310.05 39.7323 1310.05 42.648C1310.05 45.5637 1310.5 48.417 1311.42 51.2138C1312.33 54.0106 1313.73 56.53 1315.61 58.7776C1317.49 61.0252 1319.84 62.8199 1322.67 64.1503C1325.49 65.4864 1328.81 66.1545 1332.64 66.1545C1337.38 66.1545 1341.48 65.0335 1344.94 62.7859C1348.41 60.5383 1350.98 57.1074 1352.69 52.4933L1340.75 47.7547C1340.15 49.6399 1339.22 51.1855 1337.97 52.4027C1336.73 53.62 1334.95 54.2257 1332.65 54.2257C1330.52 54.2257 1328.8 53.6652 1327.5 52.5386C1326.19 51.4176 1325.25 49.957 1324.68 48.1679C1324.1 46.3789 1323.81 44.5389 1323.81 42.6536C1323.81 40.7684 1324.1 38.951 1324.68 37.1847C1325.25 35.4239 1326.19 33.9802 1327.5 32.8593V32.8479Z"
              fill="#8889E8"
            />
            <path
              d="M1400.06 33.5273C1399.15 30.6117 1397.8 28.0923 1396.01 25.9636C1394.22 23.8405 1391.98 22.1817 1389.31 20.9984C1386.64 19.8152 1383.54 19.2207 1380.02 19.2207C1376.01 19.2207 1372.59 19.9171 1369.77 21.3155C1366.95 22.7139 1364.67 24.5652 1362.94 26.8751C1361.21 29.185 1359.93 31.7326 1359.11 34.5294C1358.29 37.3262 1357.89 40.1173 1357.89 42.9141C1357.89 45.7108 1358.29 48.6718 1359.11 51.4346C1359.93 54.1974 1361.25 56.6885 1363.08 58.9078C1364.9 61.1271 1367.27 62.8878 1370.18 64.1899C1373.1 65.4978 1376.62 66.1488 1380.75 66.1488C1383.42 66.1488 1385.78 65.9507 1387.81 65.5544C1389.84 65.1581 1391.84 64.49 1393.78 63.5502C1395.72 62.6104 1397.88 61.3479 1400.25 59.7683L1394.42 50.1098C1392.72 51.4459 1390.72 52.4933 1388.41 53.2519C1386.1 54.0106 1383.85 54.3899 1381.66 54.3899C1379.17 54.3899 1376.97 53.8407 1375.06 52.7481C1373.14 51.6554 1372.01 49.8947 1371.64 47.4659H1401.16C1401.28 46.4978 1401.36 45.6769 1401.39 45.0088C1401.42 44.3408 1401.43 43.701 1401.43 43.0952C1401.43 39.6304 1400.98 36.443 1400.07 33.5273H1400.06ZM1371.46 37.3545C1371.64 36.3864 1372.03 35.3786 1372.64 34.3482C1373.24 33.3178 1374.17 32.4347 1375.42 31.7043C1376.66 30.974 1378.29 30.6116 1380.29 30.6116C1381.39 30.6116 1382.42 30.7815 1383.39 31.1099C1384.36 31.4439 1385.22 31.9138 1385.99 32.5196C1386.75 33.1254 1387.34 33.8727 1387.77 34.7502C1388.13 35.5145 1388.33 36.3807 1388.38 37.3488H1371.46L1371.46 37.3545Z"
              fill="#8889E8"
            />
            <path
              d="M1431.39 50.4777C1436.15 50.4777 1440.01 46.6224 1440.01 41.8666C1440.01 37.1108 1436.15 33.2555 1431.39 33.2555C1426.64 33.2555 1422.78 37.1108 1422.78 41.8666C1422.78 46.6224 1426.64 50.4777 1431.39 50.4777Z"
              fill="#8889E8"
            />
          </svg>
        </TextSlider>
      </div>

      {/* why complysimpli */}
      <div className="complysimpli-section">
        <div className="container">
          <div className="complysimpli-row">
            <img src={img} alt="" />
            <div>
              <h1>WHY Complysimpli?</h1>
              <ul>
                <li>
                  Unlock Over <b>50 Years</b> of Industry Expertise
                </li>
                <li>
                  Harness the Power of Our <b>Virtual IAM Architect</b>
                </li>
                <li>Embrace a Truly Passion-Driven Partnership</li>
                <li>
                  Seamlessly Navigate Compliance with Our{' '}
                  <b>3-Step Cyber Maturity Accelerator</b>
                </li>
                <li>
                  Reap the Benefits of Innovative{' '}
                  <b>Solutions Crafted Exclusively</b> for Your Business
                </li>
                <li>
                  Be Part of a <b>Collaborative</b> Community Committed to
                  Excellence
                </li>
                <li>Stay Ahead with Our Proactive, Round-the-Clock Support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form */}
      <div className="contact-section round-animation">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <div className="contact-row">
            <div className="contact-text">
              <h1>CONTACT</h1>
              <span>
                Reach out to us for inquiries, or any assistance you need on
                your path to compliance.
              </span>
              <hr />
              <span>
                6132 Joybrook Rd, Duluth, GA, 30097, USA <br />
                +1 404 502 3844
                <br />
                info@complysimpli.com
              </span>
            </div>
            <div className="form-box">
              <label htmlFor="">
                Please complete the form below to get in touch with our experts.
                Fields marked with * are required.
              </label>
              <form action="" ref={formRef}>
                <div className="formcol2">
                  <div className="input-group">
                    <label htmlFor="">Name *</label>
                    <input
                      name="name"
                      value={emailData.name}
                      onChange={(e) => handleFormChange(e)}
                      type="text"
                      placeholder="John Carter"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Email *</label>
                    <input
                      name="email"
                      value={emailData.email}
                      onChange={(e) => handleFormChange(e)}
                      type="email"
                      placeholder="example@email.com"
                    />
                  </div>
                </div>
                <div className="formcol2">
                  <div className="input-group">
                    <label htmlFor="">Phone *</label>
                    <input
                      name="phone"
                      value={emailData.phone}
                      onChange={(e) => handleFormChange(e)}
                      type="text"
                      placeholder="(123) 456 - 789"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Company *</label>
                    <input
                      name="company"
                      value={emailData.company}
                      onChange={(e) => handleFormChange(e)}
                      type="text"
                      placeholder="Facebook"
                    />
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="">Message *</label>
                  <br />
                  <textarea
                    name="message"
                    value={emailData.message}
                    onChange={(e) => handleFormChange(e)}
                    id=""
                    rows="5"
                    placeholder="Please type your message here..."
                  ></textarea>
                </div>
                <button onClick={sendEmail}>
                  Submit Details
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.4043 1.65436L14.7499 8.00001L8.4043 14.3457"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.7499 8L1.25 8"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Schedule a Call */}
      {/* <div className="call-section">
        <div className="griddot">
          <img src={griddots} alt="" />
        </div>
        <div className="container">
          <h1>We understand the value of direct communication</h1>
          <span>
            Schedule a call with our experts to discuss your compliance needs
            and <br /> discover how ComplySimpli can help your business succeed.
          </span>
          <button>
            Schedule a Call
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.4043 1.65436L14.7499 8.00001L8.4043 14.3457"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.7499 8L1.25 8"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="We understand the value of direct communication"
          bodyText="Schedule a call with our experts to discuss your compliance needs
          and discover how ComplySimpli can help your business succeed."
          linkSrc="/contact?focus=contact"
          btnText="Schedule a Call"
        />
      </div>
    </div>
  );
};
