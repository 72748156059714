import gsap from 'gsap';
import React, { useEffect } from 'react';

const ContactHeroAnimation = () => {
  useEffect(() => {
    gsap.set('svg g g *', { strokeWidth: 0 });
    const groups = gsap.utils.toArray("svg g[id^='group']");

    const tl = gsap.timeline({ repeat: -1, delay: 0.01 });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group.children,
        {
          // scale: 1
          strokeWidth: 3,
        },
        {
          //   scale: 0,
          strokeWidth: 0,

          duration: 0.001,
          ease: 'power2.out',
        }
      );
    });

    groups.reverse().forEach((group) => {
      tl.fromTo(
        group.children,
        {
          // scale: 0
          strokeWidth: 0,
        },
        {
          //   scale: 1,
          strokeWidth: 3,
          duration: 0.4,
          ease: 'power2.out',
        }
      );
    });
  }, []);
  return (
    <svg
      width="253"
      height="202"
      viewBox="0 0 253 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_664_9476)">
        <path
          d="M113.6 129.719H8.22284C4.32432 129.719 1.16699 126.562 1.16699 122.663V8.22868C1.16699 4.33016 4.32432 1.16699 8.22284 1.16699H243.926C247.824 1.16699 250.981 4.33016 250.981 8.22868V122.663C250.981 126.562 247.824 129.719 243.926 129.719H144.216"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M144.216 129.719L101.414 159.781L113.6 129.719"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g id="group1">
          <path
            d="M154.651 22.5156H36.2597C34.2162 22.5156 32.5596 24.1722 32.5596 26.2157V29.2972C32.5596 31.3407 34.2162 32.9973 36.2597 32.9973H154.651C156.694 32.9973 158.351 31.3407 158.351 29.2972V26.2157C158.351 24.1722 156.694 22.5156 154.651 22.5156Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M77.8711 43.8115H36.2597C34.2162 43.8115 32.5596 45.4681 32.5596 47.5116V50.5931C32.5596 52.6366 34.2162 54.2932 36.2597 54.2932H77.8711C79.9146 54.2932 81.5712 52.6366 81.5712 50.5931V47.5116C81.5712 45.4681 79.9146 43.8115 77.8711 43.8115Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group2">
          <path
            d="M32.5596 74.2935H218.299"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group3">
          <path
            d="M32.5596 88.7437H218.299"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group4">
          <path
            d="M32.5596 103.194H218.299"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group5">
          <path
            d="M82.5631 184.544V194.43C82.5631 197.873 79.7735 200.663 76.3302 200.663H8.38042C4.39436 200.663 1.16699 197.436 1.16699 193.449V156.641C1.16699 152.655 4.39436 149.428 8.38042 149.428H75.3497C79.3358 149.428 82.5631 152.655 82.5631 156.641V174.202"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M82.5635 174.202L99.0038 174.062L82.5635 184.544"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group6">
          <path
            d="M22.8012 180.47C25.5892 180.47 27.8494 178.21 27.8494 175.422C27.8494 172.634 25.5892 170.374 22.8012 170.374C20.0131 170.374 17.7529 172.634 17.7529 175.422C17.7529 178.21 20.0131 180.47 22.8012 180.47Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group7">
          <path
            d="M42.6566 180.47C45.4447 180.47 47.7049 178.21 47.7049 175.422C47.7049 172.634 45.4447 170.374 42.6566 170.374C39.8686 170.374 37.6084 172.634 37.6084 175.422C37.6084 178.21 39.8686 180.47 42.6566 180.47Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g id="group8">
          <path
            d="M62.5053 180.47C65.2933 180.47 67.5535 178.21 67.5535 175.422C67.5535 172.634 65.2933 170.374 62.5053 170.374C59.7172 170.374 57.457 172.634 57.457 175.422C57.457 178.21 59.7172 180.47 62.5053 180.47Z"
            stroke="#FFA900"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_664_9476">
          <rect width="252.149" height="201.83" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactHeroAnimation;
