import { ReactComponent as ExpertiseAndExperience } from '../images/services/implementation/ExpertiseAndExperience_card.svg';
import { ReactComponent as CustomizedStrategies } from '../images/services/implementation/CustomizedStrategies_card.svg';
import { ReactComponent as SeamlessDeployment } from '../images/services/implementation/SeamlessDeployment_card.svg';
import { ReactComponent as RobustIntegration } from '../images/services/implementation/RobustIntegration_card.svg';

export const implementationWhyUsData = [
  {
    title: 'Expertise and Experience',
    paragraphText:
      'Our team boasts extensive experience in deploying IAM solutions across various organizations, making us the experts you can rely on.',
    imgComponent: <ExpertiseAndExperience />,
  },
  {
    title: 'Customized Strategies',
    paragraphText:
      'We understand that each organization has unique needs. Our approach is tailored to your specific requirements, ensuring that the implementation and integration meet your exact expectations.',
    imgComponent: <CustomizedStrategies />,
  },
  {
    title: 'Seamless Deployment',
    paragraphText:
      'Minimizing disruption during deployment is our top priority. We carefully plan and execute the deployment to keep your operations running smoothly.',
    imgComponent: <SeamlessDeployment />,
  },
  {
    title: 'Robust Integration',
    paragraphText:
      'Our team takes the time to understand your existing IT infrastructure to ensure that the IAM system integrates seamlessly with your current systems, applications, and processes.',
    imgComponent: <RobustIntegration />,
  },
];
