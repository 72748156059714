import React from 'react';
import './EIAM.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import ImportanceSection from '../../components/sections/ImportanceSection/ImportanceSection.jsx';
import EIAMAnimation from '../../components/animations/EIAMAnimation/EIAMAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { focusData } from '../../assets/data/eiamFocusData.js';
import { enterpriseData } from '../../assets/data/eiamEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';

import vendor1 from '../../assets/images/ping Identity.png';
import vendor2 from '../../assets/images/Okta.png';
import vendor3 from '../../assets/images/ForgeRock.png';
import vendor4 from '../../assets/images/Okta2.png';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';

import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const EIAM = () => {
  useMoveToTopFeature();

  return (
    <div className="eiam-page">
      <div className="eiam-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="ENTERPRISE IDENTITY AND ACCESS MANAGEMENT (EIAM)"
          mainText="Experience Seamless and Secure Workforce Access"
          paragarphText="Simplified by Expertise, Crafted for Your Success"
          AnimationComponent={<EIAMAnimation></EIAMAnimation>}
        />
      </div>

      {/* Importance Section */}
      <ImportanceSection
        title="IMPORTANCE OF CENTRALIZED AUTHENTICATION AUTHORITY"
        paragraphText="As industries rapidly embrace digital transformation, legacy Identity and Access Management (IAM) systems with fragmented and isolated identity infrastructures hinder enterprises from optimizing workforce productivity, IT adaptability, and data security. Without a modern identity framework, enterprises face obstacles in granting seamless access necessary for an increasingly remote workforce, while simultaneously safeguarding resources and data against potential security risks. Enterprises now require a centralized authentication authority capable of facilitating effortless access to all corporate resources from any location or device, fortifying defenses against the rising tide of security threats."
      ></ImportanceSection>

      {/* Approach Section */}
      <div className="darkapproach">
        <ApproachSection paragraphText="At ComplySimpli, our workforce authentication approach aligns deeply with the Zero Trust principle of 'never trust, always verify.' We aim to deliver a unified login experience for your employees and partners across on-premises, cloud, and SaaS resources, maintaining a delicate equilibrium between user convenience and effective risk management. Our collaborative efforts focus on tailoring solutions that address your unique business use cases with precision and customization" />
      </div>

      {/* OUR FOCUS */}
      <div className="focus-bg-remove">
        <OurFocusSection data={focusData}></OurFocusSection>
      </div>

      {/* ENTERPRISE GAINS */}
      <div className="bg-add">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <EnterpriseGainSection data={enterpriseData}></EnterpriseGainSection>
      </div>

      {/* OUR VENDORS */}
      <div className="ourvendor-section">
        <div className="container">
          <h1>OUR VENDORS</h1>
          <div className="vendor-img">
            <img src={vendor1} alt="" />
            <img src={vendor2} alt="" />
            <img src={vendor3} alt="" />
            <img src={vendor4} alt="" />
          </div>
        </div>
      </div>

      {/* HarnessIAMMastery */}
      {/* <HarnessIAMMastery></HarnessIAMMastery> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Harness Decades of IAM Mastery"
          bodyText="Our cybersecurity solutions are expertly crafted to empower your organization's digital transformation. With decades of expertise and a relentless commitment to excellence, we provide practical, tailor-made solutions that optimize your security posture and ROI. Experience the ComplySimpli difference today."
          linkSrc="/contact?focus=contact"
          btnText="Connect With Us"
        />
      </div>
    </div>
  );
};
