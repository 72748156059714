export const valuesData = [
  {
    title: 'Simplicity',
    contentPart:
      'We believe in simplifying complex cybersecurity challenges, making compliance accessible and actionable for businesses of all sizes.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Trust',
    contentPart:
      'Trust is at the core of our interactions. We uphold honesty, integrity, and transparency in every engagement, ensuring the security and confidence of our clients.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Collaboration',
    contentPart:
      'We value collaboration and teamwork, working closely with clients and partners to co-create effective solutions that address their unique needs and aspirations.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Expertise',
    contentPart:
      'Our commitment to knowledge and expertise drives us to stay ahead of industry trends, providing informed guidance and strategic insights that empower informed decisions.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Impact',
    contentPart:
      'We are dedicated to delivering tangible results - from enhanced security posture and compliance assurance to cost savings and risk mitigation - ensuring businesses thrive in a secure digital world.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Thoroughness',
    contentPart:
      'Thoroughness is at the heart of our commitment, encompassingmeticulous data collection and comprehensive assessments that leave no aspect unexplored, ensuring accurate insights and actionable recommendations.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
