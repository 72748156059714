import { ReactComponent as CIAMSVG } from '../images/solutions/CIAM_Accordian.svg';
import { ReactComponent as EIAMSVG } from '../images/solutions/EIAM_Accordian.svg';
import { ReactComponent as IGASVG } from '../images/solutions/IGA_Accordian.svg';
import { ReactComponent as PAMSVG } from '../images/solutions/PAM_Accordian.svg';

export const solutionsData = [
  {
    title: 'Customer Identity and Access Management (CIAM)',
    contentPart: (
      <ul>
        <li>Elevate customer experiences securely</li>
        <li>Streamlined access, improved data protection</li>
      </ul>
    ),
    imgSrc: <CIAMSVG />,
    linkSrc: '/ciam',
    linksbtn: true,
  },
  {
    title: 'Enterprise Identity and Access Management (EIAM)',
    contentPart: (
      <ul>
        <li>Empower your workforce productivity</li>
        <li>Secure access to corporate resources</li>
      </ul>
    ),
    imgSrc: <EIAMSVG />,
    linkSrc: '/eiam',
    linksbtn: true,
  },
  {
    title: 'Identity Governance and Administration (IGA)',
    contentPart: (
      <ul>
        <li>Master Identity Lifecycle management</li>
        <li>Improve security, reduce risk, and increase compliance</li>
      </ul>
    ),
    imgSrc: <IGASVG />,
    linkSrc: '/iga',
    linksbtn: true,
  },
  {
    title: 'Privileged Access Management (PAM)',
    contentPart: (
      <ul>
        <li>Guard privileged access, protect critical assets.</li>
        <li>Seamless integration with IAM and IGA platforms</li>
      </ul>
    ),
    imgSrc: <PAMSVG />,
    linkSrc: '/pam',
    linksbtn: true,
  },
];
