export const ciamFocusData = [
  {
    title: 'Customer Data Protection',
    contentPart:
      "We prioritize the security of your customers' sensitive information, ensuring robust protection against potential threats.",
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'User-Centric Access Management',
    contentPart:
      'Our solutions offer a balance between stringent security and user-friendliness, allowing your clients to manage their digital identities seamlessly.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Scalable Solutions',
    contentPart:
      'We provide CIAM solutions that grow with your business, adapting to changing requirements and ensuring long-term viability.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
  {
    title: 'Vendor Neutral Solutions',
    contentPart:
      'Our solutions are rooted in vendor-agnostic principles and industry-standard access protocols to ensure interoperability across platforms and services.',
    imgSrc: null,
    linkSrc: null,
    linksbtn: false,
  },
];
