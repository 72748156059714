import React from 'react';
import PropTypes from 'prop-types';
import './OurFocusSection.scss';
import AccordianSection from '../../common/AccordianSection/AccordianSection';

const OurFocusSection = ({ data }) => {
  return (
    <div>
      <div className="ourfocus-section round-animation">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <div className="ourfocus-section-data">
            <h1>OUR FOCUS</h1>
            <AccordianSection dataArray={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

OurFocusSection.propTypes = {
  data: PropTypes.array.isRequired,
};

export default OurFocusSection;
