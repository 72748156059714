import React from 'react';
import './VirtualIAMArchitect.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import VirtualIAMAnimation from '../../components/animations/VirtualIAMAnimation/VirtualIAMAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { virtualIamFocusData } from '../../assets/data/virtualIAMFocusData.js';
import { virtualIamEnterpriseData } from '../../assets/data/virtualIAMEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const VirtualIAMArchitect = () => {
  useMoveToTopFeature();

  return (
    <div>
      <div className="virtual-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="VIRTUAL IAM ARCHITECT ADVISORY SERVICES"
          mainText="Proactive Cybersecurity"
          paragarphText="Your Guardian of Cybersecurity Health"
          AnimationComponent={<VirtualIAMAnimation></VirtualIAMAnimation>}
        />
      </div>

      {/* Approach Section */}
      <ApproachSection paragraphText="The Virtual IAM Architect offers proactive security measures there by contributing to cost-efficiency, and peace of mind. By continuously monitoring your security infrastructure, detecting threats, and responding to incidents, they ensure that your organization's digital assets remain secure and compliant with industry regulations." />

      {/* OUR FOCUS */}
      <OurFocusSection data={virtualIamFocusData}></OurFocusSection>

      {/* ENTERPRISE GAINS */}
      <EnterpriseGainSection
        data={virtualIamEnterpriseData}
      ></EnterpriseGainSection>

      {/* Talk To Us */}
      {/* <TalkToUsSection></TalkToUsSection> */}
      <div className="ready-mar0 butmar">
        <ReadyToUseSection
          titleText="Experience Service Excellence"
          bodyText="Our service portfolio showcases our unwavering dedication to your business's success. With a wealth of experience and expertise, we stand out in meeting your distinct requirements. Explore the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
