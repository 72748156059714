import React from 'react';
import './StaffAugmentation.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import StaffAnimation from '../../components/animations/StaffAnimation/StaffAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { staffEnterPriseData } from '../../assets/data/staffEnterPriseData.js';
import ServiceSubComponentCard from '../../components/common/ServiceSubComponentCard/ServiceSubComponentCard.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';
import { staffDigitalTeamData } from '../../assets/data/staffDigitalTeamData.js';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const StaffAugmentation = () => {
  useMoveToTopFeature();

  return (
    <div>
      <div className="staff-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="STAFF AUGMENTATION"
          mainText="The Champions of Digital Fortification for Your Enterprise"
          paragarphText="At ComplySimpli, we understand that your team might need an extra hand in the ever-evolving world of cybersecurity. Our 'Staff Augmentation' service is designed to provide skilled professionals who seamlessly integrate with your team. Whether it's project-based support or long-term collaboration, we're here to enhance your cybersecurity efforts."
          AnimationComponent={<StaffAnimation></StaffAnimation>}
        />
      </div>

      {/* Approach Section */}
      <ApproachSection paragraphText="Our 'Staff Augmentation' service goes beyond providing professionals; it's about extending your team's capabilities. We offer seamless integration, expertise alignment, and a collaborative approach to strengthen your cybersecurity workforce." />

      {/* DIGITAL TEAM */}
      <div className="whyus-section digital-team-bg">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <h1>YOUR DIGITAL SECURITY A-TEAM</h1>
          <div className="subdata-card-row">
            {staffDigitalTeamData.map((data, index) => {
              return (
                <ServiceSubComponentCard
                  key={index}
                  title={data.title}
                  paragraphText={data.paragraphText}
                  ImageComponent={data.imgComponent}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* OUR FOCUS */}
      <div className="ourfocus-section">
        <div className="container">
          <div className="ourfocus-section-data">
            <h1>OUR FOCUS</h1>
            <div className="spancolor">
              <span>
                Our focus in &apos;Staff Augmentation&apos; is to seamlessly integrate our
                professionals into your team, ensuring they align with your
                goals and contribute effectively to your cybersecurity projects.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ENTERPRISE GAINS */}
      <div className="bg-add">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <EnterpriseGainSection
          data={staffEnterPriseData}
        ></EnterpriseGainSection>
      </div>

      {/* Talk To Us */}
      {/* <div className="bg-remove">
        <TalkToUsSection></TalkToUsSection>
      </div> */}
      <div className="ready-mar0 butmar">
        <ReadyToUseSection
          titleText="Experience Service Excellence"
          bodyText="Our service portfolio showcases our unwavering dedication to your business's success. With a wealth of experience and expertise, we stand out in meeting your distinct requirements. Explore the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
