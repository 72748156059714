export const reviewsData = [
  {
    title: 'Financial Service Success',
    stars: '/img/stars-21.png',
    text: "They didn't just provide solutions; they ignited a transformation. Our partnership with them turned compliance from a burden into a strategic advantage. Their expertise not only safeguarded our business but also boosted our revenue.",
    authorName: 'John Smith',
    authorRole: 'CEO, XYZ Bank',
  },
  {
    title: 'Healthcare Triumph',
    stars: '/img/stars-21.png',
    text: "Working with ComplySimpli was like having a dedicated guardian for our data. Their meticulous approach ensured our compliance, and their solutions simplified our processes. Our patients' trust in us has never been stronger.",
    authorName: 'Dr. Emily Davis',
    authorRole: 'CIO of ABC Hospital',
  },
  {
    title: 'Manufacturing Victory',
    stars: '/img/stars-21.png',
    text: "ComplySimpli's IAM solutions optimized our manufacturing operations. They secured our sensitive data and improved access control, contributing to operational efficiency.",
    authorName: 'Robert Miller',
    authorRole: 'Operations Director at LMN Manufacturing',
  },
];
