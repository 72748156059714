import React from 'react';
import './OperationalSupportMaintenance.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import OppsSupportAnimation from '../../components/animations/OppsSupportAnimation/OppsSupportAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { opsupportFocusData } from '../../assets/data/opsSupportFocusData.js';
import { opsupportEnterpriseData } from '../../assets/data/opsSupportEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const OperationalSupportMaintenance = () => {
  useMoveToTopFeature();

  return (
    <div>
      {/* Hero section */}
      <div className="opssupport-main">
        <HeroSection
          labelText="OPERATIONAL SUPPORT AND MAINTENANCE"
          mainText="Continuous Support for Ongoing Security"
          paragarphText="Continuous Security Support, 24/7 Reliability"
          AnimationComponent={<OppsSupportAnimation></OppsSupportAnimation>}
        />
      </div>

      {/* Approach Section */}
      <ApproachSection paragraphText='Our "Operational Support and Maintenance" service extends beyond initial setup, providing continuous round-the-clock support. Count on our expertise for ongoing assistance, rooted in constant surveillance, immediate issue resolution, and proactive maintenance, ensuring your security systems operate optimally.' />

      {/* OUR FOCUS */}
      <OurFocusSection data={opsupportFocusData}></OurFocusSection>

      {/* ENTERPRISE GAINS */}
      <EnterpriseGainSection
        data={opsupportEnterpriseData}
      ></EnterpriseGainSection>

      {/* Talk To Us */}
      {/* <TalkToUsSection></TalkToUsSection> */}
      <div className="ready-mar0 butmar">
        <ReadyToUseSection
          titleText="Experience Service Excellence"
          bodyText="Our service portfolio showcases our unwavering dedication to your business's success. With a wealth of experience and expertise, we stand out in meeting your distinct requirements. Explore the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
