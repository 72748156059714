import React from 'react';
import './AuditAnimation.scss';

const AuditAnimation = () => {
  return (
    <div className="audit-animation">
      {/* Computer */}
      <div className="computer">
        <svg
          width="262"
          height="216"
          viewBox="0 0 262 216"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M71.5201 185.73L43.3301 213.66H218.66L188.73 185.73"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M254.74 1.5H7.25977C4.49834 1.5 2.25977 3.73858 2.25977 6.5V180.73C2.25977 183.491 4.49834 185.73 7.25977 185.73H254.74C257.501 185.73 259.74 183.491 259.74 180.73V6.5C259.74 3.73858 257.501 1.5 254.74 1.5Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Document */}
      <div className="document">
        <svg
          width="152"
          height="173"
          viewBox="0 0 152 173"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M42.8809 42V137.37C42.8809 140.02 44.8408 142.15 47.2608 142.15H121.501"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M133.831 142.14H145.871C148.291 142.14 150.251 140 150.251 137.36V7.10983C150.251 4.45983 148.281 2.31982 145.871 2.31982H47.2608C44.8308 2.31982 42.8809 4.46983 42.8809 7.10983V20.8798"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.8809 41.9999V20.8799H30.8409C28.4109 20.8799 26.4609 23.0299 26.4609 25.6599V155.93C26.4609 158.57 28.4209 160.71 30.8409 160.71H129.441C131.871 160.71 133.831 158.56 133.831 155.93V142.15H121.511"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.4606 42H18.5206C16.1006 42 14.1406 44.13 14.1406 46.78V170.73"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M121.5 170.73V160.71"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.81055 170.73V67.8996C1.81055 65.2596 3.77055 63.1196 6.20055 63.1196H14.1306"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.6191 24.79H137.139"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.6191 45.23H137.139"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.6191 65.6797H137.139"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.6191 86.1299H137.139"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.6191 106.57H137.139"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Lens */}
      <div className="lens">
        <svg
          width="112"
          height="77"
          viewBox="0 0 112 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M79.5999 62.5001C96.2679 62.5001 109.78 48.9881 109.78 32.3201C109.78 15.6522 96.2679 2.14014 79.5999 2.14014C62.932 2.14014 49.4199 15.6522 49.4199 32.3201C49.4199 48.9881 62.932 62.5001 79.5999 62.5001Z"
            fill="#091A2A"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.9192 55.7481L37.5605 43.8394L1.4992 63.0943L7.85786 75.003L43.9192 55.7481Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.4902 44.5898L40.7402 49.7898"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M69.3594 4.06006V60.1501"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Signal */}
      <div className="signal">
        <div className="gradient-blur"></div>
        <div className="gradient-blur2"></div>
        <svg
          width="53"
          height="151"
          viewBox="0 0 53 151"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="signal-animation"
        >
          <path
            d="M46.83 1.5H6.38998C3.96545 1.5 2 3.46547 2 5.89V144.76C2 147.185 3.96545 149.15 6.38998 149.15H46.83C49.2545 149.15 51.22 147.185 51.22 144.76V5.89C51.22 3.46547 49.2545 1.5 46.83 1.5Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.64 38.0798C33.6209 38.0798 39.28 32.4207 39.28 25.4398C39.28 18.4589 33.6209 12.7998 26.64 12.7998C19.6591 12.7998 14 18.4589 14 25.4398C14 32.4207 19.6591 38.0798 26.64 38.0798Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="first-round"
          />
          <path
            d="M26.64 87.9602C33.6209 87.9602 39.28 82.3011 39.28 75.3202C39.28 68.3393 33.6209 62.6802 26.64 62.6802C19.6591 62.6802 14 68.3393 14 75.3202C14 82.3011 19.6591 87.9602 26.64 87.9602Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.64 137.85C33.6209 137.85 39.28 132.191 39.28 125.21C39.28 118.229 33.6209 112.57 26.64 112.57C19.6591 112.57 14 118.229 14 125.21C14 132.191 19.6591 137.85 26.64 137.85Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="third-round"
          />
        </svg>
      </div>
    </div>
  );
};

export default AuditAnimation;
