import React from 'react';
import './Resources.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import ResourceAnimation from '../../components/animations/ResourceAnimation/ResourceAnimation.jsx';
import img1 from '../../assets/images/img1.jpg';
import img2 from '../../assets/images/img2.jpg';
import img3 from '../../assets/images/img3.jpg';
import dotimg from '../../assets/images/grid dots.png';
import griddots from '../../assets/images/griddots.png';
import { Link } from 'react-router-dom';
import useVisibilityAnimation from '../../Features/onLoadAnimation.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';

export const Resources = () => {
  const { isVisible, targetRef } = useVisibilityAnimation();
  useMoveToTopFeature();

  const { isVisible: mobileLineVisible1, targetRef: mobileLineRef1 } =
    useVisibilityAnimation();
  const { isVisible: mobileLineVisible2, targetRef: mobileLineRef2 } =
    useVisibilityAnimation();
  useMoveToTopFeature();

  return (
    <div>
      <div className="resources-hero-section">
        <HeroSection
          labelText="RESOURCES"
          mainText="Empower Your Compliance Journey with Expert Resources"
          paragarphText="Unlock the full potential of your compliance journey with ComplySimpli's authoritative resources. Our years of expertise, practical insights, and unwavering commitment to excellence empower you with valuable tools that ensure your business stays ahead of the regulatory curve."
          AnimationComponent={<ResourceAnimation></ResourceAnimation>}
        />
      </div>

      <div className="resources-content">
        <div className="container">
          <div className="topimg">
            <img src={dotimg} alt="" />
          </div>
          <div className="centerimg">
            <img src={griddots} alt="" />
          </div>
          <div className="resources-bg">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1131"
              height="1995"
              viewBox="0 0 1131 1995"
              fill="none"
            >
              <path
                d="M1085 2520C1167.67 2456 1190.6 2203.2 621 1704C-91 1080 909 912 1029 412C1125 12 687.667 -21.3334 457 12C233 44.3699 -139 179.2 -219 384"
                stroke="#9654F4"
                strokeWidth="3"
                className="line-animation"
              />
            </svg> */}
            <svg
              width="3000"
              height="2522"
              viewBox="0 0 3000 2522"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              ref={targetRef}
            >
              {isVisible ? (
                <path
                  className={`${isVisible ? 'line-animation' : ''}`}
                  d="M-6 1075C-6 1075 29.5 1085.04 205 956.771C380.5 828.5 340.634 568.7 515.5 383.771C722.53 164.827 967.5 44.1413 1191.5 11.7713C1422.17 -21.562 1859.5 11.7713 1763.5 411.771C1643.5 911.771 643.5 1079.77 1355.5 1703.77C1925.1 2202.97 1819.5 2519.77 1819.5 2519.77H2437.5H3026"
                  stroke="#9654F4"
                  strokeWidth="3"
                />
              ) : (
                ''
              )}
            </svg>
          </div>

          <div className="resources-content-row">
            <div
              className="resources-content-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={img3} alt="" />
              <label htmlFor="">WHITEPAPERS AND EBOOKS</label>
              <h1>In-Depth Insights, Delivered to Your Desk</h1>
              <span>
                Explore our collection of comprehensive whitepapers and eBooks,
                providing deep dives into compliance trends, best practices, and
                actionable strategies. Updated regularly, these resources are
                your go-to for expert guidance.
              </span>
              <Link to="/resources">
                <div className="deep-dive-link ">
                  <a>Explore Resources</a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="16"
                    viewBox="0 0 46 16"
                    fill="none"
                  >
                    <path
                      d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.5588V7.165H1V8.835Z"
                      fill="#4E4FDC"
                    />
                    <circle
                      cx="38.0589"
                      cy="8.00001"
                      r="7.10618"
                      stroke="#4E4FDC"
                      strokeWidth="1.67"
                    />
                  </svg>
                </div>
              </Link>
            </div>
            <div></div>
          </div>

          {/* Line */}
          <div className="resources-bg-mobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="250"
              viewBox="0 0 4 250"
              fill="none"
            >
              {mobileLineVisible1 ? (
                <path
                  id={`${mobileLineVisible1 ? 'resources-mobile-draw-animation-1' : ''}`}
                  d="M2 0V250"
                  stroke="#9654F4"
                  strokeWidth="3"
                />
              ) : (
                ''
              )}
            </svg>
          </div>
          {/* Line */}

          <div className="resources-content-row reverse-col">
            <div></div>
            <div
              className="resources-content-box"
              ref={mobileLineRef1}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={img2} alt="" />
              <label htmlFor="">WEBINARS AND VIDEOS</label>
              <h1>Visual Learning for Compliance Excellence</h1>
              <span>
                Dive into the world of compliance with our engaging webinars and
                videos. Covering a wide range of topics and featuring industry
                experts, these resources offer a dynamic way to expand your
                knowledge.
              </span>
              <Link to="/resources">
                <div className="deep-dive-link ">
                  <a>Watch Now</a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="16"
                    viewBox="0 0 46 16"
                    fill="none"
                  >
                    <path
                      d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.5588V7.165H1V8.835Z"
                      fill="#4E4FDC"
                    />
                    <circle
                      cx="38.0589"
                      cy="8.00001"
                      r="7.10618"
                      stroke="#4E4FDC"
                      strokeWidth="1.67"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>

          {/* Line */}
          <div className="resources-bg-mobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="250"
              viewBox="0 0 4 250"
              fill="none"
            >
              {mobileLineVisible2 ? (
                <path
                  id={`${mobileLineVisible2 ? 'resources-mobile-draw-animation-2' : ''}`}
                  d="M2 0V250"
                  stroke="#9654F4"
                  strokeWidth="3"
                />
              ) : (
                ''
              )}
            </svg>
          </div>
          {/* Line */}

          <div
            className="resources-content-row"
            ref={mobileLineRef2}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="resources-content-box">
              <img src={img1} alt="" />
              <label htmlFor="">BLOG</label>
              <h1>Stay Informed with Our Compliance Updates</h1>
              <span>
                Our blog is your daily dose of compliance insights. From the
                latest industry news to practical compliance tips, we keep you
                in the know. Discover a wealth of information with regular
                updates.
              </span>
              <Link to="/resources">
                <div className="deep-dive-link ">
                  <a>Read Blog Articles</a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="16"
                    viewBox="0 0 46 16"
                    fill="none"
                  >
                    <path
                      d="M1 7.165C0.538842 7.165 0.165 7.53884 0.165 8C0.165 8.46116 0.538842 8.835 1 8.835V7.165ZM1 8.835H30.5588V7.165H1V8.835Z"
                      fill="#4E4FDC"
                    />
                    <circle
                      cx="38.0589"
                      cy="8.00001"
                      r="7.10618"
                      stroke="#4E4FDC"
                      strokeWidth="1.67"
                    />
                  </svg>
                </div>
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
