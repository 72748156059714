import React, { useEffect, useState } from 'react';
import './CIAM.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import ImportanceSection from '../../components/sections/ImportanceSection/ImportanceSection.jsx';
import CIAMAnimation from '../../components/animations/CIAMAnimation/CiamAnimation.jsx';
import ApproachSection from '../../components/sections/ApproachSection/ApproachSection.jsx';
import { ciamFocusData } from '../../assets/data/ciamFocusData.js';
import { ciamEnterpriseData } from '../../assets/data/ciamEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';

import vendor1 from '../../assets/images/ping Identity.png';
import vendor2 from '../../assets/images/Okta.png';
import vendor3 from '../../assets/images/ForgeRock.png';
import vendor4 from '../../assets/images/Okta2.png';
import CiamMobileAnimation from '../../components/animations/CiamMobileAnimation/CiamMobileAnimation.jsx';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const CIAM = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useMoveToTopFeature();

  return (
    <div className="ciam-page">
      <div className="ciam-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="CUSTOMER IDENTITY AND ACCESS MANAGEMENT (CIAM)"
          mainText="Delivering Exceptional Customer Experiences"
          paragarphText="Simple, practical, and empowering customer experiences that are both convenient and secure"
          AnimationComponent={
            windowWidth <= 768 ? (
              <CiamMobileAnimation></CiamMobileAnimation>
            ) : (
              <CIAMAnimation></CIAMAnimation>
            )
          }
        />
      </div>

      {/* Importance Section */}
      <ImportanceSection
        title="IMPORTANCE OF CIAM"
        paragraphText="As businesses embrace digital transformation, they must provide customers with a seamless experience while safeguarding their data. To do so, they must capture more identity data from users, with their outright consent, and then transform it into meaningful information to increase consumer satisfaction and, ultimately, improve their bottom lines. Customer Identity and Access Management (CIAM) establishes trust and fuels growth, making it indispensable in the current digital age. However, it comes with its unique challenges, particularly in ensuring usable security."
      ></ImportanceSection>

      {/* Approach Section */}
      <div className="darkapproach">
        <ApproachSection
          paragraphText="We craft tailored CIAM solutions by gaining deep insight into your priorities, metrics, and customer needs to optimize ROI. Understanding your unique business intricacies, we ensure your identity management journey is as distinct as your business.
  With our approach, CIAM is not just another security tool, it becomes the primary driver for enabling new digital experiences, adding functionality and working with agility at the speed of the business."
        />
      </div>

      {/* CONQUERING section */}
      <div className="conquering-section">
        <ImportanceSection
          title="CONQUERING USABLE SECURITY"
          paragraphText="In today's digitally connected world, customer identity and access management (CIAM) faces one of the most critical challenges is ensuring 'usable security.' While security is paramount, it should not come at the cost of user convenience and experience. At ComplySimpli, we recognize the delicate balance between robust security and user-friendly access. Usable security encompasses various aspects, such as convenience, security and privacy. We address them with a combination of innovative technology and a deep understanding of user behavior. We focus on delivering secure CIAM solutions that prioritize usability, making it easier for your customers to interact with your digital services without compromising data privacy."
        ></ImportanceSection>
      </div>

      {/* OUR FOCUS */}
      <OurFocusSection data={ciamFocusData}></OurFocusSection>

      {/* ENTERPRISE GAINS */}
      <EnterpriseGainSection data={ciamEnterpriseData}></EnterpriseGainSection>

      {/* OUR VENDORS */}
      <div className="ourvendor-section">
        <div className="container">
          <h1>OUR VENDORS</h1>
          <div className="vendor-img">
            <img src={vendor1} alt="" />
            <img src={vendor2} alt="" />
            <img src={vendor3} alt="" />
            <img src={vendor4} alt="" />
          </div>
        </div>
      </div>

      {/* HarnessIAMMastery */}
      {/* <HarnessIAMMastery></HarnessIAMMastery> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Harness Decades of IAM Mastery"
          bodyText="Our cybersecurity solutions are expertly crafted to empower your organization's digital transformation. With decades of expertise and a relentless commitment to excellence, we provide practical, tailor-made solutions that optimize your security posture and ROI. Experience the ComplySimpli difference today."
          linkSrc="/contact?focus=contact"
          btnText="Connect With Us"
        />
      </div>
    </div>
  );
};
