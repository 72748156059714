import React from 'react';
import './OppsSupportAnimation.scss';

const OppsSupportAnimation = () => {
  return (
    <div className="opssupport-animation">
      <div className="cloud-img">
        <svg
          width="200"
          height="100"
          viewBox="0 0 200 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M112.352 81.1475H103.415C100.557 81.1475 98.0956 78.7229 98.0018 75.6097C98.0018 75.571 98.0018 75.5322 98.0018 75.4934C97.9183 71.3231 100.797 67.6378 104.583 67.1238C105.657 66.9783 106.721 67.0753 107.68 67.3856C108.671 65.4556 110.35 63.9912 112.352 63.419C112.968 63.2444 113.604 63.1475 114.271 63.1475C118.443 63.1475 121.832 66.8425 121.832 71.4104C121.832 71.4686 121.832 71.5268 121.832 71.5753C121.832 71.5753 126.505 70.7412 127.798 75.1248C128.674 78.1022 126.588 81.1378 123.731 81.1378H112.363"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            className="cloud-sub"
          />
        </svg>
      </div>
      <div className="cloud-img">
        <svg
          width="200"
          height="100"
          viewBox="0 0 200 100"
          xmlns="http://www.w3.org/2000/svg"
          className="cloud2"
        >
          <path
            d="M112.352 81.1475H103.415C100.557 81.1475 98.0956 78.7229 98.0018 75.6097C98.0018 75.571 98.0018 75.5322 98.0018 75.4934C97.9183 71.3231 100.797 67.6378 104.583 67.1238C105.657 66.9783 106.721 67.0753 107.68 67.3856C108.671 65.4556 110.35 63.9912 112.352 63.419C112.968 63.2444 113.604 63.1475 114.271 63.1475C118.443 63.1475 121.832 66.8425 121.832 71.4104C121.832 71.4686 121.832 71.5268 121.832 71.5753C121.832 71.5753 126.505 70.7412 127.798 75.1248C128.674 78.1022 126.588 81.1378 123.731 81.1378H112.363"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            className="cloud-sub"
          />
        </svg>
      </div>

      <div className="cloud-line">
        <svg
          width="72"
          height="13"
          viewBox="0 0 72 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2V11H70V4.42308"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <svg
        width="40"
        height="36"
        viewBox="0 0 49 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="todo-svg"
      >
        <g className="todo-1">
          <path
            d="M2 7.5L6.5 11L15.5 2"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M23 6H47"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>

        <g className="todo-3">
          <path
            d="M2 35.5L6.5 39L15.5 30"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M23 34H47"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>

        <g className="todo-2">
          <path
            d="M2 21.5L6.5 25L15.5 16"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M23 20H47"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>
      </svg>

      <svg
        width="225"
        height="135"
        viewBox="0 0 225 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sub-ops"
      >
        <path
          d="M64.15 9.15723H10.69C6.99521 9.15723 4 12.1524 4 15.8472V94.7172C4 98.412 6.99521 101.407 10.69 101.407H64.15C67.8448 101.407 70.84 98.412 70.84 94.7172V15.8472C70.84 12.1524 67.8448 9.15723 64.15 9.15723Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.54 101.407L113 119.557H190.97L177.66 101.407"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216.99 89.0977H86.9902"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216.99 49.4575V94.6275C216.99 98.2375 214.07 101.157 210.46 101.157H93.5202C89.9102 101.157 86.9902 98.2375 86.9902 94.6275V15.6775C86.9902 12.0675 89.9102 9.14746 93.5202 9.14746H170.4"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g className="opsupport-lock">
          <path
            d="M56.7492 74.4273V85.1873C56.7492 86.9473 55.0092 88.3773 52.8792 88.3773H39.8192C37.6792 88.3773 35.9492 86.9473 35.9492 85.1873V74.4273C35.9492 72.6673 37.6892 71.2373 39.8192 71.2373H52.8792C55.0192 71.2373 56.7492 72.6673 56.7492 74.4273Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.8809 71.2274V65.7674C39.8809 63.8674 42.7709 62.3174 46.3309 62.3174C48.1109 62.3174 49.7309 62.7074 50.9109 63.3274C52.0709 63.9574 52.8109 64.8174 52.8109 65.7674V71.2274"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>

      <svg
        width="60"
        height="59"
        viewBox="0 0 60 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="gear-sub-1"
      >
        <path
          d="M37.0844 32.4782C35.6939 35.9071 32.0537 37.7605 28.553 37.0191C28.1292 36.9293 27.7057 36.7987 27.2828 36.6271C23.431 35.065 21.5814 30.6711 23.1435 26.8193C23.9123 24.9318 25.3508 23.5224 27.0856 22.7662C28.8774 21.973 30.9791 21.885 32.9356 22.6766C36.7874 24.2387 38.6465 28.6264 37.0844 32.4782Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.421 26.8308C10.6543 25.2646 11.0652 23.7114 11.6853 22.1778C12.0413 21.3101 12.4489 20.4697 12.911 19.6818C13.2514 19.0814 13.626 18.5129 14.0209 17.965"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.3966 14.0187C44.9813 16.026 46.9786 18.6387 48.2705 21.5613Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0336 49.424C31.2885 49.7771 34.5481 49.2947 37.5074 48.1007Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.8054 32.474C49.5737 34.0324 49.1594 35.6013 48.5427 37.1192C47.9227 38.6527 47.1326 40.06 46.2038 41.3477"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.95 37.7344C13.2498 40.6586 15.247 43.2714 17.8318 45.2786Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.713 11.195C25.6801 10.0027 28.9319 9.51865 32.1868 9.87176Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.1868 9.87176L35.311 2.16802L45.5224 6.3072L42.3966 14.0187"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0209 17.965L8.91496 11.4055L17.5991 4.6334L22.713 11.195"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.421 26.8308L2.19091 27.9748L3.71032 38.8844L11.95 37.7344"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0336 49.424L24.9093 57.1276L24.3422 56.9009L14.7057 52.9901L17.8318 45.2786"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.2038 41.3477L51.3132 47.8918L42.6291 54.6639L37.5074 48.1007"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.2705 21.5613L56.518 20.4129L58.0295 31.3208L49.8054 32.474"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="gear-sub-2"
      >
        <path
          d="M23.7781 24.1597C21.9415 25.8051 19.2196 25.8487 17.341 24.3796C17.1136 24.2018 16.8983 23.9996 16.6954 23.773C14.847 21.7099 15.0265 18.5378 17.0896 16.6894C18.1026 15.7846 19.3765 15.3621 20.6364 15.4175C21.9414 15.4673 23.2254 16.0301 24.1648 17.077C26.0132 19.1401 25.8412 22.3112 23.7781 24.1597Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.4699 12.9706C10.0682 12.1013 10.7691 11.2918 11.5894 10.5553C12.0566 10.1401 12.5467 9.75638 13.0541 9.42006C13.4337 9.16031 13.8245 8.92966 14.2213 8.71733"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.364 14.6646C33.3235 16.6231 33.754 18.7721 33.6715 20.9Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3973 31.6537C15.2425 32.8182 17.3351 33.484 19.4563 33.6359Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.3947 27.8824C30.7996 28.7475 30.0922 29.5654 29.2785 30.2934C28.4582 31.0299 27.5731 31.641 26.64 32.1399"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.19223 19.9457C7.11396 22.077 7.54448 24.2259 8.50401 26.1845Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4074 7.20991C23.5328 7.36507 25.6212 8.02755 27.4663 9.19213Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.4663 9.19213L31.5926 5.49521L36.4935 10.9635L32.364 14.6646"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2213 8.71733L13.0856 3.29521L20.2675 1.78426L21.4074 7.20991"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.4699 12.9706L4.20801 11.2453L1.92278 18.2212L7.19223 19.9457"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3973 31.6537L9.27102 35.3505L8.99794 35.0487L4.37434 29.8855L8.50401 26.1845"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.64 32.1399L27.7823 37.5538L20.6004 39.0648L19.4563 33.6359"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.6715 20.9L38.9452 22.6278L36.6557 29.6005L31.3947 27.8824"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="clock"
      >
        <path
          d="M45.5806 26.6602H42.6406"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5601 26.6602H7.62012"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5996 7.68018V10.6202"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5996 45.6402V42.7002"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6 51.7C40.4623 51.7 51.7 40.4623 51.7 26.6C51.7 12.7377 40.4623 1.5 26.6 1.5C12.7377 1.5 1.5 12.7377 1.5 26.6C1.5 40.4623 12.7377 51.7 26.6 51.7Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M26.5996 26.6001V42.7001"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="clock-tick"
        />
      </svg>
    </div>
  );
};

export default OppsSupportAnimation;
