import React from 'react';
import './Industries.scss';
import IndustriesAnimation from '../../components/animations/IndustriesAnimation/IndustriesAnimation.jsx';
import AccordianSection from '../../components/common/AccordianSection/AccordianSection';
import { industriesHeroData } from '../../assets/data/industriesHeroData';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';

export const Industries = () => {
  useMoveToTopFeature();

  return (
    <div>
      {/* Hero Section */}
      <div className="secondmain-section round-animation">
        <div className="animation-section-round1"></div>
        <div className="animation-section-round2"></div>
        <div className="animation-section-round3"></div>
        <div className="container">
          <div className="secondmain-section-data">
            <div>
              <label htmlFor="">INDUSTRIES</label>
              <h1>Unveil Industry-Specific IAM Mastery</h1>
              <span>
                At ComplySimpli, we&quot;ve spent years perfecting the art of
                Industry-Specific Identity and Access Management (IAM). Discover
                how our expertise, tailored solutions, and relentless focus on
                regulatory compliance ensure seamless access and data security
                across various industries.
              </span>
            </div>
            <div className="animationimg">
              <IndustriesAnimation></IndustriesAnimation>
            </div>
          </div>
          <div className="secondmain-accordian">
            <AccordianSection dataArray={industriesHeroData} />
          </div>
        </div>
      </div>

      {/* Talk To Us */}
      {/* <div className="talktous-section">
        <div className="container">
          <div className="talktous-text">
            <h1>Harness Decades of IAM Mastery</h1>
            <span>
              Our industry-specific IAM solutions are practical and bespoke,
              backed by <br />
              years of experience and expertise. We excel in managing
              industry-specific <br />
              data, navigating complex regulations, and ensuring identity
              control at all <br />
              organizational levels. Discover the ComplySimpli advantage today.
            </span>
            <Link to="/contact">
              <button>
                Talk To Us
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.4043 1.65436L14.7499 8.00001L8.4043 14.3457"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.7499 8L1.25 8"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Link>
          </div>
          <div className="talktous-img">
            <svg
              ref={targetRef}
              className={`${
                isVisible ? "circle-svg" : "circle-svg-before-anim"
              }`}
              width="597"
              height="550"
              viewBox="0 0 597 550"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_761_14719)">
                <g opacity="0.25">
                  <path
                    d="M100.323 302.732L-0.000152588 302.732C2.22702 462.644 129.995 592.089 289.119 597L289.119 496.624C185.38 491.803 102.515 407.195 100.323 302.732Z"
                    fill="#695FFF"
                  />
                  <path
                    d="M496.677 302.732C494.485 407.18 411.639 491.778 307.871 496.624L307.871 597C467.019 592.064 594.773 462.629 597 302.732L496.677 302.732Z"
                    fill="#695FFF"
                  />
                  <path
                    d="M496.197 283.971L596.745 283.971C589.314 128.84 463.594 4.82642 307.871 -5.05084e-05L307.87 100.376C408.209 105.058 488.976 184.314 496.197 283.971Z"
                    fill="#695FFF"
                  />
                  <path
                    d="M193.385 302.732L142.514 302.732C143.643 383.822 208.436 449.459 289.124 451.952L289.124 401.055C236.521 398.606 194.499 355.703 193.39 302.732L193.385 302.732Z"
                    fill="#695FFF"
                  />
                  <path
                    d="M403.61 302.732C402.496 355.698 360.489 398.596 307.871 401.049L307.871 451.947C388.574 449.444 453.352 383.812 454.485 302.727L403.615 302.727L403.61 302.732Z"
                    fill="#695FFF"
                  />
                  <path
                    d="M403.37 283.971L454.355 283.971C450.59 205.309 386.836 142.42 307.871 139.972L307.871 190.869C358.751 193.243 399.705 233.433 403.37 283.966L403.37 283.971Z"
                    fill="#695FFF"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_761_14719">
                  <rect
                    width="597"
                    height="597"
                    fill="white"
                    transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 597 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div> */}
      <div className="ready-mar0">
        <ReadyToUseSection
          titleText="Harness Decades of IAM Mastery"
          bodyText="Our industry-specific IAM solutions are practical and bespoke, backed by years of experience and expertise. We excel in managing industry-specific data, navigating complex regulations, and ensuring identity control at all organizational levels. Discover the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
