export const auditEnterpriseData = [
  {
    title: 'Risk Mitigation',
    contentPart:
      'Our services help mitigate the risk of non-compliance, protecting your organization from potential legal and financial consequences.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Data Security',
    contentPart:
      'We ensure that your sensitive data is secure and complies with data protection regulations, reducing the risk of data breaches.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Confidence in Compliance',
    contentPart:
      "With your proactive monitoring and assessments, you can have confidence in your organization's compliance status.",
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Focus on Core Operations',
    contentPart:
      'We take care of your compliance, allowing you to focus on your business operations.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
  {
    title: 'Regulatory Expertise',
    contentPart:
      'Our team brings deep regulatory expertise to help you navigate the complex landscape.',
    imgSrc: '',
    linkSrc: '',
    linksbtn: false,
  },
];
