import React from 'react';
import PropTypes from 'prop-types';
import './HeroSection.scss';

const HeroSection = ({
  labelText,
  mainText,
  paragarphText,
  AnimationComponent,
}) => {
  return (
    <div className="main-section round-animation">
      <div className="animation-section-round1"></div>
      <div className="animation-section-round2"></div>
      <div className="animation-section-round3"></div>
      <div className="container">
        <div className="main-section-data">
          <div className="main-section-content">
            <label htmlFor="">{labelText}</label>
            <h1>{mainText}</h1>
            <span>{paragarphText}</span>
          </div>
          <div className="animationimg">{AnimationComponent}</div>
        </div>
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  labelText: PropTypes.array.isRequired,
  mainText: PropTypes.array.isRequired,
  paragarphText: PropTypes.array.isRequired,
  AnimationComponent: PropTypes.array.isRequired,
};

export default HeroSection;
