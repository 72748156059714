import React from 'react';
import PropTypes from 'prop-types';
import griddots from '../../../assets/images/grid dots.png';
import './ApproachSection.scss';
import img from '../../../assets/images/Frame 82.png';

const ApproachSection = ({ paragraphText }) => {

  return (
    <div className="approach-section">
      <div className="container">
        <div className="approach-row">
          <img src={img} alt="" />
          <div>
            <h1>Our Approach</h1>
            <div className="span-text">
              <span>{paragraphText}</span>
            </div>
          </div>
        </div>
        <div className="griddot">
          <img src={griddots} alt="" />
        </div>
      </div>
    </div>
  );
};

ApproachSection.propTypes = {
  paragraphText: PropTypes.array.isRequired,
};

export default ApproachSection;
