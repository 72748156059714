import React, { useEffect, useRef, useState } from 'react';
import './Header.scss';
import logo from '../../../assets/images/CS Logo.svg';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({
    services: false,
    solutions: false,
    resources: false,
    about: false,
  });

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleSubMenu = (type) => {
    setOpenSubMenu(!openSubMenu);
    setOpenSubMenu({ ...openSubMenu, [type]: !openSubMenu[type] });
  };

  const closeMenu = () => {
    setOpenMenu(false);
    setOpenSubMenu({
      services: false,
      solutions: false,
      resources: false,
      about: false,
    });
  };

  // Move to First section
  const firstSectionRef = useRef(null);

  useEffect(() => {
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      setOpenMenu(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        firstSectionRef.current &&
        !firstSectionRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={firstSectionRef}>
      <div className="top-navbar">
        <h3>We have an announcement to make</h3>
      </div>
      <div className="main-navbar">
        <div className="container">
          <div className="navbar">
            <Link onClick={closeMenu} to="/">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </Link>
            <div className={`menu-list ${openMenu ? 'showmenu' : ''}`}>
              <ul>
                <li className="services">
                  <a>
                    <Link onClick={closeMenu} to="/services">
                      Services
                    </Link>
                    <svg
                      onClick={() => handleSubMenu('services')}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                    >
                      <path
                        d="M1 1.08333L6.83333 6.91666L12.6667 1.08333"
                        style={{
                          transform: openSubMenu.services
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transformOrigin: 'center center',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                        stroke="white"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <ul
                    className={`submenu ${
                      openMenu && openSubMenu.services ? 'showsubmenu' : ''
                    }`}
                  >
                    <Link onClick={closeMenu} to="/strategy">
                      <li>
                        <a>Strategy & Roadmap</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/implementation">
                      <li>
                        <a>Implementation & Integration</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/audit">
                      <li>
                        <a>Audit & Compliance</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/virtualiam">
                      <li>
                        <a>Virtual IAM Architect</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/opssupport">
                      <li>
                        <a>Operational Support & Maintenance</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/staff">
                      <li>
                        <a>Staff Augmentation</a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li className="solutions">
                  <a>
                    <Link onClick={closeMenu} to="/solutions">
                      Solutions
                    </Link>
                    <svg
                      onClick={() => handleSubMenu('solutions')}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                    >
                      <path
                        d="M1 1.08333L6.83333 6.91666L12.6667 1.08333"
                        style={{
                          transform: openSubMenu.solutions
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transformOrigin: 'center center',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                        stroke="white"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <ul
                    className={`submenu ${
                      openMenu && openSubMenu.solutions ? 'showsubmenu' : ''
                    }`}
                  >
                    <Link onClick={closeMenu} to="/ciam">
                      <li>
                        <a>Customer Identity & Access Management (CIAM)</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/eiam">
                      <li>
                        <a>Enterprise Identity & Access Management (EIAM)</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/iga">
                      <li>
                        <a>Identity Governance & Administration (IGA)</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/pam">
                      <li>
                        <a>Privileged Access Management (PAM)</a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li className="resources">
                  <a>
                    <Link onClick={closeMenu} to="/resources">
                      Resources
                    </Link>
                    <svg
                      onClick={() => handleSubMenu('resources')}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                    >
                      <path
                        d="M1 1.08333L6.83333 6.91666L12.6667 1.08333"
                        style={{
                          transform: openSubMenu.resources
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transformOrigin: 'center center',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                        stroke="white"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <ul
                    className={`submenu ${
                      openMenu && openSubMenu.resources ? 'showsubmenu' : ''
                    }`}
                  >
                    <Link onClick={closeMenu} to="/resources">
                      <li>
                        <a>Whitepapers & E-books</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/resources">
                      <li>
                        <a>Webinars & Videos</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/resources">
                      <li>
                        <a>Blog</a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li>
                  <a>
                    <Link onClick={closeMenu} to="/industries">
                      Industries
                    </Link>
                  </a>
                </li>
                <li className="about">
                  <a>
                    <Link onClick={closeMenu} to="/about">
                      About
                    </Link>
                    <svg
                      onClick={() => handleSubMenu('about')}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                    >
                      <path
                        d="M1 1.08333L6.83333 6.91666L12.6667 1.08333"
                        style={{
                          transform: openSubMenu.about
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transformOrigin: 'center center',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                        stroke="white"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <ul
                    className={`submenu ${
                      openMenu && openSubMenu.about ? 'showsubmenu' : ''
                    }`}
                  >
                    <Link onClick={closeMenu} to="/about">
                      <li>
                        <a>Our Story</a>
                      </li>
                    </Link>
                    <Link onClick={closeMenu} to="/our-wins">
                      <li>
                        <a>Case Studies</a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li>
                  <a>
                    <Link onClick={closeMenu} to="/contact">
                      Contact
                    </Link>
                  </a>
                </li>
              </ul>
              <div className="mobile-info">
                <h2>For queries or Information</h2>
                <span>+1 999 999 9999 </span>
                <span>info@complysimpli.com</span>
              </div>
            </div>
            <div className="mobile-icon" onClick={handleMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
              >
                <line
                  x1="31"
                  y1="1.97018"
                  x2="1.39197"
                  y2="1.97018"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="31.0002"
                  y1="13.2645"
                  x2="1.39221"
                  y2="13.2645"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="31.0002"
                  y1="24.4587"
                  x2="1.39221"
                  y2="24.4587"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
