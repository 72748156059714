import React from 'react';
import './VirtualIAMAnimation.scss';

const VirtualIAMAnimation = () => {
  return (
    <div className="virtual-iam-animation">
      <svg
        width="253"
        height="164"
        viewBox="0 0 253 164"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64.0899 101.92H68.2999C79.5599 101.92 88.6999 111.06 88.6999 122.32V131.65H43.6899V122.32C43.6899 111.06 52.8299 101.92 64.0899 101.92Z"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.6401 55C54.8501 51.58 59.2101 48 67.7801 48C84.1001 48 84.1001 63.78 84.1001 63.78L84.1201 67.77L93.4101 71.58L85.1901 75.93C85.1901 75.93 79.8101 104.34 55.3301 90.21"
          stroke="#FFA900"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g className="left-part">
          <path
            d="M33.8301 32.4303V45.7603H49.2001V32.4303H33.8301Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.5 47.3004L1.5 60.6304H16.87V47.3004H1.5Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.5 75.9703L1.5 89.3003H16.87V75.9703H1.5Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.0098 45.7603V97.7403"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="animation-line"
          />
          <path
            d="M41.5101 45.7603V77.6503C41.5101 80.4103 38.3501 82.6403 34.4601 82.6403H16.8701"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.5101 55.3101H16.8701"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g className="certification">
          <path
            d="M149.25 92.4201C156.2 107.38 172.96 97.9601 172.96 97.9601C172.96 97.9601 181.76 139.11 148.63 148.2C115.5 139.11 124.3 97.9601 124.3 97.9601C124.3 97.9601 141.07 107.38 148.01 92.4201C148.23 91.9401 149.02 91.9401 149.24 92.4201H149.25Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M137.52 122.67L145.92 129.65L164.4 112.64"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g className="eye">
          <path
            d="M217.56 16.26C197.88 35.94 165.98 35.94 146.3 16.26C165.98 -3.42 197.88 -3.42 217.56 16.26Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M181.93 25.52C188.563 25.52 193.94 20.1429 193.94 13.51C193.94 6.87706 188.563 1.5 181.93 1.5C175.297 1.5 169.92 6.87706 169.92 13.51C169.92 20.1429 175.297 25.52 181.93 25.52Z"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <g className="animation-boxes">
          <rect
            x="117.001"
            y="45"
            width="57"
            height="11"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            className="animation-box-1"
          />
          <rect
            x="117.001"
            y="64"
            width="25"
            height="11"
            stroke="#8889E8"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            className="animation-box-2"
          />
        </g>

        <path
          d="M193.41 40.2798V162.5H100.04V19.0098H135.52"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="clock"
      >
        <path
          d="M45.5806 26.6602H42.6406"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5601 26.6602H7.62012"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5996 7.68018V10.6202"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5996 45.6402V42.7002"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6 51.7C40.4623 51.7 51.7 40.4623 51.7 26.6C51.7 12.7377 40.4623 1.5 26.6 1.5C12.7377 1.5 1.5 12.7377 1.5 26.6C1.5 40.4623 12.7377 51.7 26.6 51.7Z"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M26.5996 26.6001V42.7001"
          stroke="#8889E8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="clock-tick"
        />
      </svg>
    </div>
  );
};

export default VirtualIAMAnimation;
