import React from 'react';
import './AuditCompliance.scss';
import HeroSection from '../../components/sections/HeroSection/HeroSection.jsx';
import AuditAnimation from '../../components/animations/AuditAnimation/AuditAnimation.jsx';
import ServiceSubComponentCard from '../../components/common/ServiceSubComponentCard/ServiceSubComponentCard.jsx';
import { auditApproachData } from '../../assets/data/auditApproachData.js';
import { auditFocusData } from '../../assets/data/auditFocusData.js';
import { auditEnterpriseData } from '../../assets/data/auditEnterpriseData.js';
import OurFocusSection from '../../components/sections/OurFocusSection/OurFocusSection.jsx';
import EnterpriseGainSection from '../../components/sections/EnterpriseGainSection/EnterpriseGainSection.jsx';
import ReadyToUseSection from '../../components/sections/DemoSection/ReadyToUseSection';
import useMoveToTopFeature from '../../Features/moveToTop.jsx';

export const AuditCompliance = () => {
  useMoveToTopFeature();

  return (
    <div>
      <div className="audit-hero-section">
        {/* Hero section */}
        <HeroSection
          labelText="AUDIT AND COMPLIANCE"
          mainText="Stay Ahead of Regulations"
          paragarphText="Effortless Compliance Management Starts Here"
          AnimationComponent={<AuditAnimation></AuditAnimation>}
        />
      </div>

      {/* why us */}
      <div className="whyus-section">
        <div className="container">
          <h1>OUR APPROACH</h1>
          <div className="subdata-card-row">
            {auditApproachData.map((data, index) => {
              return (
                <ServiceSubComponentCard
                  key={index}
                  title={data.title}
                  paragraphText={data.paragraphText}
                  ImageComponent={data.imgComponent}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* OUR FOCUS */}
      <OurFocusSection data={auditFocusData}></OurFocusSection>

      {/* ENTERPRISE GAINS */}
      <EnterpriseGainSection data={auditEnterpriseData}></EnterpriseGainSection>

      {/* Talk To Us */}
      {/* <TalkToUsSection></TalkToUsSection> */}
      <div className="ready-mar0 butmar">
        <ReadyToUseSection
          titleText="Experience Service Excellence"
          bodyText="Our service portfolio showcases our unwavering dedication to your business's success. With a wealth of experience and expertise, we stand out in meeting your distinct requirements. Explore the ComplySimpli advantage today."
          linkSrc="/contact?focus=contact"
          btnText="Talk To Us"
        />
      </div>
    </div>
  );
};
